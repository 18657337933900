const reportingStandards = [
    {
        "id": 1,
        "name": "MSFI za MSP (mala i srednja pravna lica)",
        "description": "MSFI za MSP (mala i srednja pravna lica)",
    },
    {
        "id": 2,
        "name": "MRS/MSFI (velika pravna lica)",
        "description": "MRS/MSFI (velika pravna lica)",
    }
];

export { reportingStandards }