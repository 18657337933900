<template>
    <div>
        <app-header></app-header>
        <div class="content">
            <router-view></router-view>
        </div>
        <div class="push"></div>
        <app-footer></app-footer>
    </div>
</template>

<script>
    import Header from "./components/common/Header.vue"
    import Footer from "./components/common/Footer.vue"
    import { mapActions } from 'vuex'

    export default {
        components: {
            appHeader: Header,
            appFooter: Footer
        },
        methods: {
            ...mapActions(["loadLanguages", "loadContents", "fetchAccessTokenInitial"]),
        },
        created() {
            this.loadLanguages();
            this.loadContents();
            this.fetchAccessTokenInitial();
        }
    }
</script>

<style>
    html {
        position: relative;
        min-height: 100%;
        scroll-behavior: smooth;
    }
    /*Normalize html and body elements,this style is just good to have*/
    html, body {
        margin: 0;
        padding: 0;
    }
    .push{
        height:20vh;
    }
    .footer {
        position: absolute;
        bottom: 0;
    }
    .content {
        padding: 30px 5%;
    }
    div{
        font-family: Roboto;
    }

    @media only screen and (max-width: 600px) {

        .push {
            height:45vh;
        }
    }

    @media only screen and (max-width: 300px) {

        .push {
            height: 50vh;
        }
    }

    .custom-file-input:lang(en) ~ .custom-file-label::after {
        content: 'Browse';
    }

    .custom-file-input:lang(bs) ~ .custom-file-label::after {
        content: 'Odaberi';
    }

    .custom-file-input:lang(hr) ~ .custom-file-label::after {
        content: 'Odaberi';
    }


</style>
