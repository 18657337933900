<template>
    <div class="panel rounded">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-9 col-md-8">
                        <span class="mb-0 font-weight-bold">{{ content.title }}</span>
                    </div>
                    <div class="col-lg-3 col-md-4 form-group align-self-top">
                        <div class="text-muted text-right small"><i class="fa fa-calendar mr-2"></i> {{ content.dateTime | moment($t("dateFormat"))}}</div>
                    </div>
                </div>
                <hr class="mt-0" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-9">
                <div v-if="hasContentCoverPhoto" class="image-placeholder mb-3">
                    <img v-bind:src="content.url" v-bind:alt="content.alt" class="rounded border" />
                </div>
                <div v-html="htmlContent">
                </div>
                <hr v-show="isMediaPanelVisible" />
                
            </div>
            <div class="col-md-3" v-show="isMediaPanelVisible">
                <div class="row pl-2 pr-3">
                    <app-media v-for="(media, index) in media" v-bind:key="media.id" v-bind:media="media" v-on:click.native="previewMedia(index)"></app-media>
                </div>
                <hr v-if="media.length > 0 && isTagsPanelVisible" />
                <div class="row" v-if="isTagsPanelVisible">
                    <app-content-tags v-bind:tags="getContentTags"></app-content-tags>
                </div>
            </div>
        </div>
        <app-media-gallery ref="mediaPreviewer" v-bind:media="media" v-bind:show-caption="true" v-bind:show-light-box="false"></app-media-gallery>
    </div>
</template>

<script>
    import Media from "../media/Media.vue"
    import ContentTags from "./ContentTags.vue"
    import MediaGallery from "vue-it-bigger"
    import { mapGetters, mapActions } from 'vuex'
    import('vue-it-bigger/dist/vue-it-bigger.min.css')


    export default {
        data() {
            return {
                media: [],
                htmlContent: ""
            }
        },
        props: ["content"],
        components: {
            appMedia: Media,
            appMediaGallery: MediaGallery,
            appContentTags: ContentTags
        },
        methods: {
            ...mapActions(["fetchContentById"]),
            loadData() {
                this.media = this.getContentMedia(this.content.id);
                this.fetchContentById(this.content.id).then((contentResposnse) => {
                    this.htmlContent = contentResposnse.content;
                });
            },
            previewMedia(index) {
                this.$refs.mediaPreviewer.showImage(index);
            }
        },
        computed: {
            ...mapGetters(["getContentMedia", "getSelectedLanguage"]),
            isMediaPanelVisible() {
                return this.media.length > 0 || this.isTagsPanelVisible;
            },
            hasContentCoverPhoto() {
                return this.content.url && this.content.url.length > 0;
            },
            getContentTags() {
                return this.content.tags ? JSON.parse(this.content.tags) : [];
            },
            isTagsPanelVisible() {
                return this.content.tags;
            }
        },
        created() {
            this.loadData();
        },
        watch: {
            content() {
                this.loadData();
            }
        },
        updated() {
            var el = this.$el;
            el.scrollIntoView();
        }
    }
</script>

<style scoped>
    .text-muted {
        color: #243B80 !important;
        opacity: 0.6; 
        font-weight: bold;
    }

    .image-placeholder {
        width: 100%;
        /*height: 275px;*/
        border-radius: 5px;
    }

        .image-placeholder img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border-radius: 5px;
        }

    .panel {
        padding: 4%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
    }

</style>
