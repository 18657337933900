<template>
    <div>
        <div class="text-center">
            <span class="tag rounded small d-inline-block pl-4 pr-4 mb-3 mr-2" v-bind:class="{'selected': year == selectedYear}" v-for="year in years" v-bind:key="year" v-on:click="selectYear(year)">{{ year }}</span>
        </div>
        <hr class="mt-0" />
        <div class="text-center">
            <span class="tag rounded report-tag small d-inline-block pl-4 pr-4 mb-3 mr-2" v-bind:class="{'selected': financialReport.id == selectedFinancialReportId}" v-for="financialReport in financialReports" v-bind:key="financialReport.id" v-on:click="selectFinancialReport(financialReport.id)">{{ financialReport.name }}</span>
        </div>
        <!--<span class="small text-muted"><i class="fa fa-info-circle info-msg" v-bind:title="$t('chooseYearToSeeResults')"></i></span>-->
        <table class="table table-sm mb-0 table-responsive" v-show="financialIndicator && selectedYear<2022">
            <tbody >
                <tr>
                    <td class="small font-weight-bold" width="100%">{{ $t("budget_bsA_001")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_001" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_bsA_010")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_010" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_bsA_021")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_021" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_bsP_022")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_022" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_bsP_029")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_029" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_bsP_033")}}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_033" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_rpiR_138")}}</td>
                    <td class="small text-right">
                        <canvas ref="rPiR_138" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_rpiR_187_188")}}</td>
                    <td class="small text-right">
                        <canvas ref="rPiR_187" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_ppP_915")}}</td>
                    <td class="small text-right">
                        <canvas ref="ppP_915" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>

        </table>
        <table class="table table-sm mb-0" v-show="financialIndicator && selectedYear>=2022" >
            <tbody>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_Imovina")}}</td>
                    <td class="small text-right">
                        <canvas ref="bS_Imovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_Obaveze")}}</td>
                    <td class="small text-right">
                        <canvas ref="bS_Obaveze" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("budget_IzvorStalnihSredstava")}}</td>
                    <td class="small text-right">
                        <canvas ref="bS_Kapital" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_VrijednostPoslovanja")}}</td>
                    <td class="small text-right">
                        <canvas ref="bU_VrijednostPoslovanja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_RezultatPoslovanja")}}</td>
                    <td class="small text-right">
                        <canvas ref="bU_RezultatPoslovanja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BrojZaposlenih")}}</td>
                    <td class="small text-right">
                        <canvas ref="pP_BrojZaposlenih" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center small text-muted p-3" v-show="!financialIndicator && selectedYear">
            {{ $t("noResultsForSelectedYear")}}
        </div>
    </div>
</template>

<script>
    import { mapActions } from "vuex"

    export default {
        data() {
            return {
                financialIndicators: [],
                financialReports: [],
                selectedYear: null,
                selectedFinancialReportId: null,
                window: {
                    width: null
                }
            }
        },
        props: ["legalEntityId", "years"],
        methods: {
            ...mapActions(["fetchFinancialIndicators"]),
            formatNumber(value) {
                return new Intl.NumberFormat('de-DE').format(value);
            },
            selectYear(year) {
                this.selectedYear = year;
                //TODO: Log Search

                if (this.financialIndicators.length > 0) {
                    this.financialReports = this.financialIndicators.sort(fi => fi.reportSubmissionDate).filter(x => x.year == this.selectedYear).map(x => { return x.reportType });
                    this.selectFinancialReport(this.financialIndicators.filter(fi => fi.year == this.selectedYear).sort(fi => fi.reportSubmissionDate)[0].reportTypeId);
                }
            },
            selectFinancialReport(financialReportId) {
                this.selectedFinancialReportId = financialReportId;

                if (this.financialIndicator) {
                    for (let [key, value] of Object.entries(this.$refs)) {

                        let ctx = value.getContext("2d");
                        ctx.clearRect(0, 0, value.width, value.height); //Clear canvas before drawing new text into it
                        ctx.font = "12px Roboto"; //setting font
                        ctx.textAlign = "end" //Aligning text

                        let width = 95;
                        let negativeValue = false;

                        if (key == "rpiR_187" && this.financialIndicator[key] == 0) {
                            key = "rpiR_188";
                            if (this.financialIndicator[key] != 0)
                                negativeValue = true;
                        }

                        ctx.fillText(this.formatNumber((negativeValue ? (-1) : 1) * this.financialIndicator[key]), width, 24); //Drawing text into canvas
                    }
                }
            },
            handleResize() {
                this.window.width = window.innerWidth;
            }
        },
        computed: {
            financialIndicator() {
                if (this.selectedYear == null || this.selectedFinancialReportId == null)
                    return null;

                return this.financialIndicators.find(fi => fi.year == this.selectedYear && fi.reportTypeId == this.selectedFinancialReportId);
            },
            windowWidth() {
                return this.window.width;
            }
        },
        created() {
            let searchObject = { legalEntityId: this.legalEntityId, searchType: 2 };

            this.fetchFinancialIndicators(searchObject).then((financialIndicators) => {
                this.financialIndicators = financialIndicators;
                this.selectYear(this.years[this.years.length - 1]);
            });

            //window.addEventListener('resize', this.handleResize);
            //this.handleResize();
        }
    }

</script>

<style scoped>
    .tag {
        background-color: rgba(169, 169, 169, 0.5);
        color: #243B80;
        font-weight: bold;
        padding: 5px;
        cursor: pointer;
        transition: transform .2s;
    }

        .tag:hover {
            transform: scale(1.1);
        }

    .selected {
        background-color: #243B80;
        color: white;
    }

    td {
        vertical-align: middle;
    }

    .info-msg {
        font-size: 150%;
        position: absolute;
        top: 23px;
        cursor: pointer;
        transition: transform .2s;
    }

        .info-msg:hover {
            color: #243B80;
            transform: scale(1.1);
        }
</style>

