<template>
    <div class="row">
        <div class="col-md-12 form-group">
            <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
        </div>
        <div class="form-group col-lg-6 col-md-12">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("businessUnitsMap") }}</h5>
                        <hr />
                    </div>
                    <div class="col-lg-12 form-group">
                        <div id="image-map">
                            <img src="../../../assets/bihMapa.jpg" alt="Our Locations" style="width:100%;" v-on:mouseover="hoverPinPoint(null)" class="rounded">
                            <div v-for="businessUnit in businessUnitsAndBranchOffices" v-bind:key="businessUnit.id" class="pin badge rounded-circle text-white" :class="{'bg-danger': hoveredPinPoint && (hoveredPinPoint == businessUnit.id || hoveredPinPoint == businessUnit.parentId), 'bg-success p-1': businessUnit.parentId == null, 'bg-warning': businessUnit.parentId != null}" :style="{ left: businessUnit.positionX + '%', top: businessUnit.positionY + '%'}" v-on:mouseover="hoverPinPoint(businessUnit.id)">
                                <i class="test" :class="businessUnit.icon"></i>
                                <div class="card text-dark" v-show="hoveredPinPoint == businessUnit.id">
                                    <div class="card-body">
                                        <span class="d-block">{{ businessUnit.businessUnit }}</span>
                                        <hr />
                                        <div class="d-block form-group text-left font-weight-normal"><i class="fa fa-phone mr-3"></i><a v-bind:href="'call:' + businessUnit.phone">{{ businessUnit.phone }}</a></div>
                                        <div class="d-block form-group text-left font-weight-normal"><i class="fa fa-map-marker mr-3"></i>{{ businessUnit.address }}</div>
                                        <div class="d-block text-left font-weight-normal"><i class="fa fa-envelope mr-3"></i>{{ businessUnit.ppt }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <hr />
                        <div class="d-inline-block">
                            <span class="badge rounded-circle text-white bg-success p-1">
                                <i class="fa fa-building"></i>
                            </span>
                            <span class="small text-muted">
                                {{ $t("businessUnit") }}
                            </span>
                        </div>
                        <div class="d-inline-block ml-3" v-if="showBranchOffices">
                            <span class="badge rounded-circle text-white bg-warning">
                                <i class="fa fa-map-marker"></i>
                            </span>
                            <span class="small text-muted">
                                {{ $t("branchOffice") }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-lg-6 col-md-12">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("listOfBusinessUnits") }}</h5>
                        <hr />
                        <app-business-unit-item v-for="businessUnit in businessUnits" v-bind:key="businessUnit.id" v-bind:businessUnit="businessUnit" v-on:mouseover.native="hoverPinPoint(businessUnit.id)" v-on:mouseleave.native="hoverPinPoint(null)"></app-business-unit-item>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BusinessUnitItem from './BusinessUnitItem.vue'
    import { businessUnits } from '../../../assets/staticData/businessUnits'
    import { BBreadcrumb } from 'bootstrap-vue'
    import { mapGetters } from "vuex"


    export default {
        data() {
            return {
                hoveredPinPoint: null,
                showBranchOffices: true
            }
        },
        computed: {
            ...mapGetters(["getContentById", "getContentByUrl"]),
            businessUnitsAndBranchOffices() {
                return this.showBranchOffices ? businessUnits.sort((a, b) => { a.businessUnit - b.businessUnit }) : businessUnits.filter(bu => !bu.parentId).sort((a, b) => { a.businessUnit - b.businessUnit });
            },
            businessUnits() {
                return businessUnits.filter(bu => !bu.parentId).sort((a, b) => { a.businessUnit - b.businessUnit });
            },
            breadcrumbItems() {

                let contentId = this.getContentByUrl(this.$route.name).id;

                let items = [];

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;

                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            }
        },
        components: {
            AppBusinessUnitItem: BusinessUnitItem,
            appBreadcrumb: BBreadcrumb

        },
        methods: {
            hoverPinPoint(id) {
                this.hoveredPinPoint = id;
            }
        }
    };

    
</script>

<style scoped>
    .panel {
        padding: 5%;
        background-color: #FFF;
        border-top: 1px solid rgba(0,0,0,0.06);
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
    }

    .validation-error {
        border: 1px solid red;
    }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #243B80;
            color: white;
        }


    textarea {
        min-height: 100px;
    }

    .validation-error {
        border: 1px solid red;
    }

    .width-65 {
        width: 65% !important;
    }

    .width-35 {
        width: 35% !important;
    }


    #image-map {
        position: relative;
    }

    .pin {
        position: absolute;
        background-color: whitesmoke;
        cursor: pointer;
    }

    .card {
        position: absolute;
        bottom: 80%;
        left: 50%;
        z-index: 3;
        box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.33);
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }


    

    @media only screen and (max-width: 650px)  {
        .pin .test {
            font-size: 8px;
        }

        .pin {
            padding-left: 3px;
            padding-top: 1px;
            padding-bottom: 1px;
            padding-right: 3px;
        }
    }
</style>
