<template>
    <div class="footer">
        <div class="row">
            <app-sub-menu-item-footer class="col-md-12" v-for="menuItem in [secondaryMenuItems[1]]" v-bind:key="menuItem.id" v-bind:menu="menuItem" v-bind:isDetails="true" v-bind:hierarchyLevel="hierarchyLevel"></app-sub-menu-item-footer>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr class="mt-2 mb-2" />
                <div class="col-md-2 d-inline-block">
                </div>
                <div class="text-left col-md-6 d-inline-block">
                    <a href="https://goo.gl/maps/pwWuixvXcYbSgnkv6" class="small pointer p-2 rounded mr-4 d-inline-block contact"><i class="fa fa-map-marker mr-2"></i> Ložionička 3, 71000 Sarajevo</a>
                    <a href="mailto:info@fia.ba" class="small pointer p-2 rounded mr-4 d-inline-block contact"><i class="fa fa-envelope-o mr-2"></i> info@fia.ba</a>
                    <a href="tel:38733255200" class="small pointer p-2 rounded d-inline-block contact"><i class="fa fa-phone mr-2"></i> +387 33 255 200</a>
                </div>
                <div class="text-right col-md-2 d-inline-block">
                    <a href="https://ebra.be" target="_blank"><img src="../../assets/Ebra-Logo.png" class="shadowed img mr-3 pointer" v-bind:title="$t('ebraLogo')" /></a>
                </div>
            </div>
            <!-- <div class="col-md-12 mb-2 mt-1">
                <span class="small text-white copyright">@Copyright 2020 - {{ $t("fiaFullName")}}</span>
            </div> -->
        </div>
        <div class="row text-center">
            <div class="col-md-12 mb-2 mt-1">
                <span class="small text-white copyright">@Copyright 2020 - {{ $t("fiaFullName")}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import SubMenuItemFooter from '../menu/SubMenuItemFooter'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                menu: null,
                hierarchyLevel: 0,
                defaultIndent: 10,
            }
        },
        components: {
            appSubMenuItemFooter: SubMenuItemFooter
        },
        computed: {
            ...mapGetters(["secondaryMenuItems"]),
        }
    }
</script>

<style scoped>
    .footer {
        background-color: #243B80;
        width: 100%;
        padding: 0.3% 5% 0% 5%;
        box-shadow: 0px 2px 15px 5px rgba(0,0,0,0.30);
    }

    .copyright{
        font-size: 10px;
    }

    .img {
        height: 35px;
        width: 35px;
    }

    .pointer {
        cursor: pointer;
    }


    .contact {
        color: white;
        text-decoration: none;
        transition: transform .2s;
        font-size: 12px;
    }


        .contact:hover {
            background-color: #374e96;
        }
</style>
