<template>
    <div>
        <div class="row">
            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-md-4 form-group">
                <div class="col-md-12 form-group content-menu-title rounded p-4 font-weight-bold">
                    <div class="row">
                        <div class="col-xs-1">
                            <i class="ml-2 fa fa-list mr-3"></i>
                        </div>
                        <div class="col-xs-11">
                            <span>{{ hasContentChildren(id) ? getContentById(id).title.toUpperCase() : getContentById(getContentById(id).parentId).title.toUpperCase() }}</span>
                        </div>
                    </div>
                </div>
                <app-side-menu-item v-for="menuItem in getChildrenByParentId(id, true)" v-bind:key="menuItem.id" v-bind:menu="menuItem" v-bind:clicked="menuItem.id == id"></app-side-menu-item>
            </div>
            <div class="col-md-8 form-group">
                <app-document-content v-if="getContentById(id).contentTypeId == 4" v-bind:content="getContentById(id)"></app-document-content>
                <app-news-content v-else v-bind:content="getContentById(id)"></app-news-content>
            </div>
        </div>
    </div>
</template>

<script>
    import SideMenuItem from '../menu/SideMenuItem'
    import NewsContent from '../content/NewsContent'
    import DocumentContent from '../content/DocumentContent'
    import { mapGetters } from 'vuex'
    import { BBreadcrumb } from 'bootstrap-vue'

    export default {
        data() {
            return {
                hierarchyLevel: 1,
                defaultIndent: 10,
                breadcrumbItems: []
            }
        },
        components: {
            appNewsContent: NewsContent,
            appDocumentContent: DocumentContent,
            appSideMenuItem: SideMenuItem,
            appBreadcrumb: BBreadcrumb
        },
        props: ["id"],
        computed: {
            ...mapGetters(["getChildrenByParentId", "getContentById", "hasContentChildren"]),
        },
        methods: {
            loadBreadcrumbs() {
                let contentId = this.id;
                let items = [];
                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;

                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse();
                this.breadcrumbItems = items;
            }
        },
        created() {
            this.loadBreadcrumbs();
        },
        watch: {
            id() {
                this.loadBreadcrumbs();
            }
        }
    }
</script>

<style scoped>
    .content-menu-title {
        background-color: #FFF;
        padding: 2%;
        color: #243B80;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #243B80;
            }



</style>
