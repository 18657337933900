import { store } from "./store/store"
//import router from "./routes"


const requestInterceptor = (config) => {
    const lang = store.getters.getSelectedLanguage.code || store.state.locale.defaultLanguageCode;

    config.withCredentials = true;

    if (!config.headers.Authorization) {

        const token = localStorage.getItem(process.env.VUE_APP_TOKEN);
        const refreshToken = localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN);

        if (token) {
            config.headers.Authorization = token;
            config.headers.RefreshToken = refreshToken;
        }
    }

    config.headers["accept-language"] = lang;
    config.headers["ClientId"] = process.env.VUE_APP_CLIENT_ID;

    return config;
};

const requestErrorInterceptor = (error) => {
    return Promise.reject(error);
}


const responseErrorInterceptor = (error) => {
    if (error.response) {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    break;
                case 403:
                    break;
                default: break;
            }
            return Promise.reject(error.response);
        }
    }
    else {
        return Promise.reject(null);
    }
   
}

const responseInterceptor = (response) => {
    if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(response);
    }
}

export default { requestInterceptor, requestErrorInterceptor, responseInterceptor, responseErrorInterceptor };

