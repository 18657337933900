<template>
    <div class="row">
        <div class="col-md-12 form-group">
            <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("usefulInfo") }}</h5>
                        <hr />
                    </div>
                    <div class="col-lg-12">
                        <table cellpadding="5" style="width:100%">
                            <tbody>
                                <tr>
                                    <td colspan="2" class="small font-weight-bold">{{ $t("bihFullName").toUpperCase() }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="small font-weight-bold">{{ $t("fbih").toUpperCase() }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="small font-weight-bold">{{ $t("fiaFullName") }}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="small">Ložionička 3, 71000 Sarajevo</td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        <div>
                                            <hr />
                                        </div>
                                    </td>
                                </tr>
                                <tr class="small">
                                    <td class="width-65">{{ $t("email") }}</td>
                                    <td class="text-right width-35"><a href="mailto:info.fia.ba">info@fia.ba</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="width-65">{{ $t("central") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 200">+387 33 255 200</a></td>
                                </tr>
                                <tr class="small">
                                    <td colspan="2" class="">{{ $t("directorsOffice") }}</td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("phone") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 205">+387 33 255 205</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("fax") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 206">+387 33 255 206</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="width-65">{{ $t("sectorForEconomicAndFinancialAffairs") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 220">+387 33 255 220</a></td>
                                </tr>
                                <tr class="small">
                                    <td colspan="2" class="">{{ $t("departmentOfFinanceAndAccounting") }}</td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("phone") }} 1</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 203">+387 33 255 203</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("phone") }} 2</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 204">+387 33 255 204</a></td>
                                </tr>
                                <tr class="small">
                                    <td colspan="2" class="">{{ $t("registryDepartment") }}</td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("phone") }} 1</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 214">+387 33 255 214</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="pl-4 width-65">- {{ $t("phone") }} 2</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 219">+387 33 255 219</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="width-65">{{ $t("sectorForSupervisionAndControlOfBusinessUnits") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 218">+387 36 321 218</a></td>
                                </tr>
                                <tr class="small">
                                    <td class="width-65">{{ $t("sectorForLegalAndJointAffairs") }}</td>
                                    <td class="text-right width-35"><a href="tel:+387 33 255 217">+387 33 255 217</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-lg-4 col-md-6">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("contactForm") }}</h5>
                        <hr />
                    </div>
                    <div class="col-lg-6 col-md-6 form-group">
                        <label class="small text-muted">{{ $t("firstName")}}</label>
                        <input class="form-control" type="text" v-model="firstName" v-bind:placeholder="$t('firstName')" v-bind:class="{'validation-error': $v.firstName.$error}" v-on:input="$v.firstName.$touch()" />
                        <p class="small text-danger m-0" v-if="$v.firstName.$dirty && !$v.firstName.required">{{ $t('firstNameIsRequired') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 form-group">
                        <label class="small text-muted">{{ $t("lastName")}}</label>
                        <input class="form-control" type="text" v-model="lastName" v-bind:placeholder="$t('lastName')" v-bind:class="{'validation-error': $v.lastName.$error}" v-on:input="$v.lastName.$touch()" />
                        <p class="small text-danger m-0" v-if="$v.lastName.$dirty && !$v.lastName.required">{{ $t('lastNameIsRequired') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 form-group">
                        <label class="small text-muted">{{ $t("email")}}</label>
                        <input class="form-control" v-model="email" v-bind:placeholder="$t('email')" v-bind:class="{'validation-error': $v.email.$error}" v-on:input="$v.email.$touch()" />
                        <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.required">{{ $t('emailIsRequired') }}</p>
                        <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.email">{{ $t('emailFormatIncorrect') }}</p>
                    </div>
                    <div class="col-lg-6 col-md-6 form-group">
                        <label class="small text-muted">{{ $t("subject")}}</label>
                        <input class="form-control" v-model="subject" v-bind:placeholder="$t('subject')" v-bind:class="{'validation-error': $v.subject.$error}" v-on:input="$v.subject.$touch()" />
                        <p class="small text-danger m-0" v-if="$v.subject.$dirty && !$v.subject.required">{{ $t('subjectIsRequired') }}</p>
                    </div>
                    <div class="col-lg-12">
                        <label class="small text-muted">{{ $t("messageContent")}}</label>
                        <textarea class="form-control text-area" rows="4" width="100%" v-model="content" v-bind:placeholder="$t('enterYourMessageContent')" v-bind:class="{'validation-error': $v.content.$error}" v-on:input="$v.content.$touch()"></textarea>
                        <p class="small text-danger m-0" v-if="$v.content.$dirty && !$v.content.required">{{ $t('messageContentIsRequired') }}</p>
                    </div>
                    <div class="col-lg-12">
                        <hr />
                        <app-vue-recaptcha v-bind:sitekey="recaptchaSiteKey" v-on:verify="onVerify" v-on:expired="onExpired" class="form-group"></app-vue-recaptcha>
                        <button v-on:click="send" class="btn btn-block" v-bind:disabled="isRobot">{{ $t("sendEmail") }}</button>
                        <div class="form-group mt-3 rounded border border-success bg-light p-2 text-center small text-success font-weight-bold" v-if="showSuccessMessage">
                            {{ $t("emailSuccessMessage") }}
                        </div>
                        <div class="form-group mt-3 rounded border border-danger bg-light p-2 text-center small text-danger font-weight-bold" v-if="showErrorMessage">
                            {{ $t("emailErrorMessage") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-lg-4 col-md-12">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("visitUs") }}</h5>
                        <hr />
                    </div>
                    <div class="col-lg-12">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.1282941295794!2d18.382044815925674!3d43.853169479115074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4758c91661616111%3A0x2ad47a8d3af98af0!2sFinansijsko-informati%C4%8Dka%20agencija!5e0!3m2!1sbs!2sba!4v1603976953084!5m2!1sbs!2sba" frameborder="0" style="border: 1px solid lightgray; width: 100%; height: 470px;" allowfullscreen="" aria-hidden="false" tabindex="0" class="rounded"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { required, email } from "vuelidate/lib/validators"
    import { mapActions, mapGetters } from "vuex"
    import VueRecaptcha from 'vue-recaptcha'
    import { BBreadcrumb } from 'bootstrap-vue'

    export default {
        data() {
            return {
                firstName: null,
                lastName: null,
                email: null,
                subject: null,
                content: null,
                recaptchaToken: null,
                isRobot: true,
                showSuccessMessage: false,
                showErrorMessage: false
            }
        },
        components: {
            appVueRecaptcha: VueRecaptcha,
            appBreadcrumb: BBreadcrumb
        },
        methods: {
            ...mapActions(["sendEmail"]),
            send() {

                if (this.$v.$invalid) {
                    this.$v.$touch();
                }
                else {
                    if (this.isRobot) {
                        this.resetRecaptcha();
                    }
                    else {
                        this.sendEmail({ firstName: this.firstName, lastName: this.lastName, email: this.email, subject: this.subject, content: this.content, recaptchaToken: this.recaptchaToken }).then((success) => {
                            if (success) {
                                this.firstName = this.lastName = this.email = this.subject = this.content = null;
                                this.$v.$reset();
                                this.showSuccessMessage = true;
                                setTimeout(() => { this.showSuccessMessage = false }, 10000);
                            }
                            else {
                                this.showErrorMessage = true;
                                setTimeout(() => { this.showErrorMessage = false }, 10000);
                            }
                        });
                    }
                }

                this.resetRecaptcha();
            },
            onVerify(response) {
                if (response)
                    this.isRobot = false;
                else
                    this.isRobot = true;

                this.recaptchaToken = response;
            },
            onExpired() {
                this.resetRecaptcha();
            },
            resetRecaptcha() {
                window.grecaptcha.reset();
                this.recaptchaToken = null;
                this.isRobot = true;
            }
        },
        computed: {
            ...mapGetters(["getContentById", "getContentByUrl" ]),
            recaptchaSiteKey() {
                return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
            },
            breadcrumbItems() {

                let contentId = this.getContentByUrl(this.$route.name).id;

                let items = [];

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;

                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            }
        },
        filters: {
            upperCase(value) {
                return value.toUpperCase();
            }
        },
        validations: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            email: {
                required,
                email
            },
            subject: {
                required
            },
            content: {
                required
            },
        }
    }
</script>

<style scoped>
    .panel {
        padding: 5%;
        background-color: white;
        border-top: 1px solid rgba(0,0,0,0.07);
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
    }

    .validation-error {
        border: 1px solid red;
    }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #243B80;
            color: white;
        }


    textarea {
        min-height: 100px;
    }

    .width-65 {
        width: 65% !important;
    }

    .width-35 {
        width: 35% !important;
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }

</style>
