<template>
    <div class="col-lg-12 col-md-12 form-group">
        <div class="content col-md-12 p-0">
            <div class="card-item rounded pt-3 pl-3 pr-3" >
                <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + legalEntity.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="small text-muted">{{ $t("legalEntity") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.shortName }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.jib }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="small text-muted">{{ $t("county") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.county }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6 form-group">
                        <div class="small text-muted">{{ $t("municipality") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.municipality }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 form-group">
                        <div class="collapse" v-bind:id="'collapse_' + legalEntity.id">
                            <hr />
                            <div class="card card-body">
                                <div class="row">
                                    <div class="col-md-12 t able-responsive">
                                        <table class="table table-bordered table-hover table-sm small">
                                            <thead>
                                                <tr>
                                                    <th class="text-center">#</th>
                                                    <th class="text-center">{{$t("proceeding")}}</th>
                                                    <th class="text-center">{{$t("document")}}</th>
                                                    <th class="text-center">{{$t("submittedOnToFIA")}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(document, index) in documents" v-bind:key="document.id">
                                                    <td class="text-center">{{index + 1}}.</td>
                                                    <td class="text-center">
                                                        <span :class="'rounded pl-3 pr-3 mb-3 mr-2 ' + document.documentType.proceedingTypeKey">{{$t(document.documentType.proceedingTypeKey)}}</span>
                                                    </td>
                                                    <td>
                                                        <a :href="document.url" target="_blank">{{$t(document.documentType.translationKey)}}</a>
                                                    </td>
                                                    <td class="text-center">{{document.submittedOn | moment($t("dateOnlyFormat"))}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
            }
        },
        props: ["legalEntity"],
        computed: {
            documents() {
                let legalEntityDocuments = this.legalEntity.documents;

                let sorted = legalEntityDocuments.sort(function (a, b) {
                    return new Date(a.submittedOn) - new Date(b.submittedOn);
                });

                return sorted;
            }
        },
        components: {

        },
    }
</script>

<style scoped>

    .prebankruptcy {
        background-color: dodgerblue;
        color: #FFF;
        padding: 3px;
    }

    .bankruptcy {
        background-color: indianred;
        color: #FFF;
        padding: 3px;
    }

    .card-item {
        width: 100%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }

        .card-item:hover {
            transform: scale(1.01);
        }

    .card-body {
        background-color: whitesmoke;
    }

    .btn {
        background-color: #243B80;
        border: #243B80;
        color: #FFFFFF;
    }

        .btn:hover {
            background-color: #374e96;
            border: #374e96;
            color: #FFFFFF;
        }

</style>

