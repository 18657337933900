<template>
    <div class="sub-menu">
        <div class="details">
            <div class="row">
                <div class="col-md-12">
                    <router-link :to="{ name:'menuDetails', params:{id: menu.id, slug: menu.slug }}" tag="h6"><a>{{ menu.title.toUpperCase() }}</a></router-link>
                    <p class="small text-muted">{{ menu.subtitle }}</p>
                    <hr />
                </div>
            </div>
            <div class="row">
                <app-sub-menu-item  class="col-md-4 from-group mb-4" v-for="menuItem in getChildrenByParentId(menu.id)" v-bind:key="menuItem.id" v-bind:menu="menuItem" v-bind:hierarchyLevel="hierarchyLevel" v-bind:isDetails="false" v-on:click.native="menuClick"></app-sub-menu-item>
            </div>
        </div>
    </div>
</template>

<script>
    import SubMenuItem from "./SubMenuItem.vue"
    import { mapGetters, mapActions } from "vuex"


    export default {
        data() {
            return {
                hierarchyLevel: 0
            }
        },
        props: ["menu"],
        components: {
            appSubMenuItem: SubMenuItem
        },
        computed: {
            ...mapGetters(["getChildrenByParentId"])
            
        },
        methods: {
            ...mapActions(["setHoveredMenu"]),
            menuClick() {
                this.setHoveredMenu(null);
            }
        }
    }
</script>

<style scoped>
    a {
        color: #494A4B;
        font-weight: bold;
    }

    .sub-menu {
        position: absolute;
        z-index: 3;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }

    .details {
        padding: 2%;
        background-color: #ffffff;
        box-shadow: 0 10px 15px rgba(0,0,0,0.15);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .hierarchyLevel-0 {
        font-weight: bold;
    }
</style>
