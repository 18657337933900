<template>
    <div>
        <div class="row" v-on:keyup.enter="search()">
            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-lg-12 col-md-12 form-group">
                <!-- Search panel -->
                <div class="search-header p-3 form-group">
                    <i class="fa fa-search mr-3"></i>
                    <label>{{ $t("search") }}</label>
                    <hr />
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <input class="form-control" v-model="searchObject.name"
                                v-bind:placeholder="$t('legalEntityName')" />
                        </div>
                        <div class="col-lg-6 form-group">
                            <input class="form-control" v-model="searchObject.jib"
                                v-bind:placeholder="$t('uniqeIdentificationNumberShort')" />
                        </div>
                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.legalEntityTypeId" v-bind:options="legalEntityTypes"
                                v-bind:reduce="legalEntityType => legalEntityType.id" label="name"
                                v-bind:placeholder="$t('chooseLegalEntityType')" class="select2">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search" v-bind="attributes" v-on="events">
                                </template>
                            </app-select>
                        </div>
                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.countyId" v-bind:options="counties"
                                v-bind:reduce="county => county.id" label="name" v-bind:placeholder="$t('chooseCounty')"
                                class="select2">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search" v-bind="attributes" v-on="events">
                                </template>
                            </app-select>
                        </div>
                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.municipalityId" v-bind:options="municipalities"
                                v-bind:reduce="municipality => municipality.id" label="name"
                                v-bind:placeholder="$t('chooseMunicipality')" class="select2"
                                :disabled="municipalities.length == 0">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search" v-bind="attributes" v-on="events">
                                </template>
                            </app-select>
                        </div>

                        <div class="col-12 form-group">
                            <app-treeselect v-model="searchObject.marketActivityId" style="width:90%"
                                class="d-inline-flex tree-select" :multiple="false" :clearable="false"
                                :options="marketActivityTypes" :show-count="true"
                                v-bind:placeholder="$t('chooseMarketActivity')" />

                            <button style="width:9%" class="btn float-right" :title="$t('clearSelection')"
                                v-on:click="clearTreeSelect">x</button>
                        </div>

                        <div class="col-lg-9 form-group">
                            <button class="btn btn-block" v-on:click="search()"><i class="fa fa-search mr-2"></i> {{
                                    $t("search")
                            }}</button>
                        </div>
                        <div class="col-lg-3 form-group">
                            <button class="btn btn-block" v-on:click="clearSearch()"><i class="fa fa-eraser mr-2"></i>
                                {{ $t("clearSearch") }}</button>
                        </div>
                    </div>
                </div>
                <!--Search results-->
                <div class="search-result p-3" v-if="hasResults">
                    <i class="fa fa-list mr-3"></i>
                    <label>{{ $t("searchResults") }}</label>
                    <hr />
                    <div>
                        <app-entity-card v-for="legalEntity in legalEntities" v-bind:key="legalEntity.id"
                            v-bind:legalEntity="legalEntity" v-bind:componentTypeId="searchObject.legalEntityTypeId"
                            v-bind:years="years"></app-entity-card>
                        <hr />
                        <div class="col-md-12 text-center">
                            <p class="d-block small text-muted">{{ $t('paginationInfo', getPaginationInfo) }}</p>

                            <app-paginate class="d-inline-flex" v-model="searchObject.currentPage"
                                v-bind:page-count="searchObject.numberOfPages" v-bind:click-handler="changePage"
                                v-bind:prev-text="$t('previous')" v-bind:next-text="$t('next')"
                                v-bind:container-class="'pagination'" v-bind:page-class="'page-item'"
                                v-bind:page-link-class="'page-link small'" v-bind:next-class="'page-item'"
                                v-bind:next-link-class="'page-link small'" v-bind:prev-class="'page-item'"
                                v-bind:prev-link-class="'page-link small'" v-bind:page-range="3"
                                v-bind:margin-pages="1">
                            </app-paginate>
                        </div>
                    </div>
                </div>
                <div class="search-result p-3" v-else>
                    <i class="fa fa-list mr-3"></i>
                    <label>{{ $t("searchResults") }}</label>
                    <hr />
                    <div class="small text-muted">
                        <div class="col-md-12 text-center">
                            {{ $t("searchNoResults") }}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import EntityCard from "./EntityCard"
import Paginate from 'vuejs-paginate'
import { BBreadcrumb } from 'bootstrap-vue'
import Select from 'vue-select'
import 'vue-select/dist/vue-select.css';


import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'


import { locations } from '../../../assets/staticData/locations'
import { accountStatuses } from '../../../assets/staticData/accountStatuses'
import { legalEntityTypesOld } from '../../../assets/staticData/legalEntityTypesOld'
import { legalEntityTypes } from '../../../assets/staticData/legalEntityTypes'
import { legalEntitySizes } from '../../../assets/staticData/legalEntitySizes'
import { marketActivities } from '../../../assets/staticData/marketActivities'

import { mapActions, mapGetters } from "vuex"

export default {
    props: ['isPublic'],
    data() {
        return {
            legalEntities: [],
            counties: [],
            municipalities: [],
            legalEntitySizes: [],
            legalEntityTypes: [],
            marketActivityTypes: [],
            accountStatuses: [],
            years: [2019, 2020, 2021,2022],
            legalEntityStatuses: [1, 2, 3],
            searchObject: {
                legalEntityTypeId: null,
                name: null,
                jib: null,
                activityCode: null,
                countyId: null,
                municipalityId: null,
                legalEntitySizeId: null,
                isPublicEntity: null,
                legalEntityStatus: null,
                accountStatusId: null,
                year: null,
                searchType: 2, //represents legal entities profile index 
                marketActivityId: null,
                currentPage: 1,
                pageSize: 10,
                numberOfPages: 1,
                totalRecords: 0
            },
            toggleRedPortal: false
        }
    },
    components: {
        appEntityCard: EntityCard,
        appPaginate: Paginate,
        appSelect: Select,
        appBreadcrumb: BBreadcrumb,
        appTreeselect: Treeselect,
    },
    methods: {
        ...mapActions(["fetchLegalEntitiySizes", "fetchMarketActivityTypes", "fetchLocations", "fetchLegalEntities", "fetchLegalEntityTypes", "fetchAccountStatuses", "setBusinessEntitiesProfileSearchParams"]),
        changePage(clickedPageNumber) {
            this.search(clickedPageNumber);
        },
        search(currentPage) {

            let loader = this.$loading.show(this.pageLoaderOptions);

            this.searchObject.isPublicEntity = this.isPublic;

            if (this.searchObject.marketActivityId) {
                this.searchObject.activityCode = marketActivities.find(x => x.id == this.searchObject.marketActivityId).code;
            }

            this.fetchLegalEntities(this.searchObject).then((data) => {
                this.legalEntities = data.resultList || [];
                this.searchObject.totalRecords = data.count;
                this.searchObject.numberOfPages = parseInt(this.searchObject.totalRecords / this.searchObject.pageSize) + (this.searchObject.totalRecords % 10 === 0 ? 0 : 1);

                loader.hide();
            });

            this.searchObject.currentPage = currentPage || 1;

            this.setBusinessEntitiesProfileSearchParams(this.searchObject);
        },
        fetchMunicipalities() {
            if (this.searchObject.countyId > 0) {

                // Commented because Entities API is falling to sleep mode, uncomment this when fixed

                //this.fetchLocations({ locationTypeId: this.getLocationTypes.municipalityLocationTypeId, parentId: this.searchObject.countyId }).then((municipalities) => {
                //    this.municipalities = municipalities.sort((a, b) => a.name - b.name) || [];
                //});


                // Delete this when above example is working
                this.municipalities = locations.filter(l => l.parentId == this.searchObject.countyId).sort((a, b) => { a.name - b.name });

            }
            else {
                this.searchObject.municipalityId = null;
                this.municipalities = [];
            }
        },
        clearTreeSelect() {
            this.searchObject.marketActivityId = null;
            this.searchObject.activityCode = null;
        },
        clearSearch() {
            this.searchObject.name = null;
            this.searchObject.jib = null;
            this.searchObject.countyId = null;
            this.searchObject.municipalityId = null;
            this.searchObject.legalEntitySizeId = null;
            this.searchObject.legalEntityTypeId = null;
            this.searchObject.legalEntityStatus = null;
            this.searchObject.accountStatusId = null;
            this.searchObject.year = null;
            this.searchObject.marketActivityId = null;
            this.searchObject.activityCode = null;

            this.setBusinessEntitiesProfileSearchParams(null);
        }
    },
    computed: {
        ...mapGetters(["getLocationTypes", "getLegalEntityTypes", "getContentById", "getContentByUrl", "getBusinessEntitiesProfileSearchParams"]),
        getPaginationInfo() {
            var start = ((this.searchObject.currentPage - 1) * this.searchObject.pageSize) + 1;
            var end = (start - 1) + this.legalEntities.length;

            return [start, end, this.searchObject.totalRecords];
        },
        hasResults() {
            return this.legalEntities.length > 0;
        },
        //years() {
        //    var currentYear = new Date().getFullYear() - 1;
        //    return [currentYear, currentYear - 1, currentYear - 2];
        //},
        breadcrumbItems() {

            let url = "";
            if (this.isPublic) {
                 url = this.$t("businessEntitiesProfilePublicUrl");
            }
            else {
                 url = this.$t("businessEntitiesProfileUrl");
            }

            let contentId = this.getContentByUrl(url).id;
            let items = [];

            do {
                let content = this.getContentById(contentId);
                let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                contentId = content.parentId;

            } while (contentId);

            items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

            items[0].to = null;
            items[0].active = true;
            items = items.reverse()

            return items;
        }
    },
    filters: {
        lowerCase(value) {
            return value.toLowerCase();
        }
    },
    created() {

        this.$store.state.isSearching = true;

        //this.searchObject.isPublicEntity = false;
        this.searchObject.year = this.years[0];

        // Commented because Entities API is falling to sleep mode, uncomment this when fixed

        //this.fetchLegalEntitiySizes().then((sizes) => {
        //    this.legalEntitySizes = sizes || [];
        //});

        // Delete this when above example is working
        this.legalEntitySizes = legalEntitySizes.sort((a, b) => { a.name - b.name });


        // Commented because Entities API is falling to sleep mode, uncomment this when fixed

        //this.fetchMarketActivityTypes().then((marketActivityTypes) => {
        //    this.marketActivityTypes = marketActivityTypes || [];
        //});

        // Delete this when above example is working

        //this.marketActivityTypes = marketActivities.sort((a, b) => { a.code - b.code }).map(x => { return { id: x.id, name: `${x.code} - ${x.name}`, code: x.code } });

        marketActivities.filter(x => x.parentId == null).forEach(x => {

            let children = marketActivities.filter(t => t.parentId == x.id).sort((a, b) => a.code - b.code).map(level1 => {

                let childrenLevel2 = marketActivities.filter(level2 => level2.parentId == level1.id).sort((a, b) => a.code - b.code).map(level2 => {

                    let childrenLevel3 = marketActivities.filter(level3 => level3.parentId == level2.id).sort((a, b) => a.code - b.code).map(level3 => {
                        return {
                            id: level3.id,
                            label: `${level3.code} - ${level3.name}`
                        };
                    });

                    return {
                        id: level2.id,
                        label: `${level2.code} - ${level2.name}`,
                        children: childrenLevel3.length > 0 ? childrenLevel3 : null
                    };
                })

                return {
                    id: level1.id,
                    label: `${level1.code} - ${level1.name}`,
                    children: childrenLevel2.length > 0 ? childrenLevel2 : null
                };
            });

            let option = {
                id: x.id,
                label: `${x.code} - ${x.name}`,
                children: children.length > 0 ? children : null
            };

            this.marketActivityTypes.push(option);
        });


        // Commented because Entities API is falling to sleep mode, uncomment this when fixed

        //this.fetchLocations({ locationTypeId: this.getLocationTypes.countyLocationTypeId, parentId: null }).then((counties) => {
        //    this.counties = counties || [];
        //});

        // Delete this when above example is working
        this.counties = locations.filter(l => l.parentId == null).sort((a, b) => { a.code - b.code });


        // Commented because Entities API is falling to sleep mode, uncomment this when fixed

        //this.fetchLegalEntityTypes().then((legalEntityTypes) => {
        //    this.legalEntityTypes = legalEntityTypes || [];
        //});

        // Delete this when above example is working
        this.legalEntityTypes = legalEntityTypesOld.sort((a, b) => { a.name - b.name });

        // Commented because Entities API is falling to sleep mode, uncomment this when fixed

        //this.fetchAccountStatuses().then((accountStatuses) => {
        //    this.accountStatuses = accountStatuses || []; //vidjeti da li se mogu prevodi ubaciti
        //});

        // Delete this when above example is working
        this.accountStatuses = accountStatuses.sort((a, b) => { a.name - b.name });

        let searchObj = this.getBusinessEntitiesProfileSearchParams;
        if (searchObj) {
            this.searchObject = searchObj;
            this.search(this.searchObject.currentPage);
        }
        else {
            this.search(null);
        }

    },
    beforeDestroy() {
        this.$store.state.isSearching = false;
    },
    watch: {
        "searchObject.countyId"() {
            this.fetchMunicipalities();
        }
    }
}

</script>

<style scoped>
.content {
    background-color: blue;
}

.search-header,
.search-result,
.search-advance,
.no-match-found {
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    background-color: #FFF;
}

.advanced-search-item {
    cursor: pointer;
    transition: transform .2s;
}

.advanced-search-item:hover .advanced-search-item-title {
    color: #243B80;
    font-weight: bold;
}

.advanced-search-item:hover {
    transform: scale(0.95);
    background-color: lightgray;
}

.select2 {
    background-color: white;
}

.vs__search {
    height: 28px;
    color: #495057;
}

.vs__search:v-bind:placeholder {
    color: rgba(73, 80, 87, 0.9);
}

.register-item {
    background-color: #FFF;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: transform .2s;
}

.register-item:hover {
    transform: scale(1.02);
    background-color: #243B80;
    color: white;
}

.selected {
    background-color: #243B80;
    color: white;
}

.selected-danger {
    background-color: #dc3545;
    color: white;
}

.btn {
    border-color: #243B80;
    color: #243B80;
}

.btn:hover {
    background-color: #243B80;
    color: white;
}

.nav-item {
    cursor: pointer;
    background-color: darkgray;
}

.nav-item:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 1px solid white;
}

.nav-item:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 1px solid white;
}

.nav-item:hover {
    background-color: #243B80;
}

.selected {
    background-color: #243B80;
}

.breadcrumb {
    background-color: #FFF;
}

.breadcrumb a {
    color: #243B80;
    font-weight: bold;
}

.breadcrumb a:hover {
    color: #374e96;
}

.nfis-links,
.nfis-links:active {
    text-decoration: none !important;
    color: black;
}
</style>