<template>
    <!--
        -- media object description --

        media (image) {
            src: String,
            thumb: String,
            caption: String
        }

        ---------------------------------
        
        media (video) {
            thumb: String,
            sources: [
              {
                src: String,
                type: String
              }
            ],
            type: 'video',
            caption: String,
            width: 800, // Required
            height: 600, // Required
            autoplay: true, // Optional: Autoplay video when the lightbox opens
        }

    -->
    <div class="col-lg-6 col-md-12 col-sm-6 col-xs-12 p-0 pl-2 pb-2" v-on:mouseover="toogleHover(true)" v-on:mouseleave="toogleHover(false)">
        <div class="media-placeholder">
            <div class="media-hover col-md-12" v-if="showHower || media.type == 'video'">
                <i class="fa icon" :class="{'fa-play': media.type == 'video', 'fa-search': media.type != 'video'}"></i>
            </div>
            <img v-if="media.type != 'video'" v-bind:src="media.thumb" v-bind:alt="media.alt" />
            <video v-else>
                <source v-for="(video, index) in media.sources" v-bind:key="index" v-bind:src="video.src" v-bind:type="video.type" />
            </video>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showHower: false,
            }
        },
        props:["media"],
        methods: {
            toogleHover(state) {
                this.showHower = state;
            }
        }
    }
</script>

<style scoped>
    .media-hover {
        background-color: rgba(255,255,255,0.2);
        width: 100%;
        height: 100%;
        z-index: 1;
        position: absolute;
        color: white;
    }

    .media-hover:hover {
        background-color: rgba(255,255,255,0.4);
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .media-placeholder {
        border-radius: 5px;
        width: 100%;
        height: 80px;
        overflow:hidden;
    }
    
    .icon {
        font-weight: bold;
        font-size: 150%;
        position: absolute;
        top:35%;
        left:40%;
    }



</style>
