<template>
    <div class="row">
        <div class="col-md-9 form-group">
            <app-highlight-news></app-highlight-news>
            <app-highlighted-products></app-highlighted-products>
            <div class="row mt-2">
                <!--<div class="col-md-6 form-group">
                    <app-highlight-publications></app-highlight-publications>
                </div>
                <div class="col-md-6 form-group">
                    <app-featured-topics></app-featured-topics>
                </div>-->
                <div class="col-md-12">
                    <app-featured></app-featured>
                </div>
            </div>
        </div>
        <div class="col-md-3 form-group">
            <!--<app-financial-report-controls-home-button></app-financial-report-controls-home-button>-->
            <!--<app-nfis-connect></app-nfis-connect>-->
            <!--<app-calculator></app-calculator>-->
            <app-business-entities-profile-home></app-business-entities-profile-home>
            <app-highlighted-infographics></app-highlighted-infographics>
            <!-- <app-mobile-app-store></app-mobile-app-store> -->
            <!-- <appBrochuresHome></appBrochuresHome> -->

            <!--<app-social-networks></app-social-networks>-->
            <!--<app-red-portal-connect></app-red-portal-connect>-->

        </div>
        
    </div>
</template>

<script>

    //import Calculator from "./Calculator.vue"
    import HighlightNews from "./HighlightNews.vue"
    import HighlightedProducts from "./products/HighlightedProducts"
    import getHighlightedInfographics from "./infographics/InfographicHighlight.vue"
    //import HighlightedPublications from "./publications/HighlightedPublications"
    // import MobileAppStore from "./MobileAppStore.vue"
    //import SocialNetworks from "./SocialNetworks.vue"
    //import NfisConnect from "./NFISConnect.vue"
    //import FinancialReportControlsHomeButton from "./rfi/FinancialReportControlsHomeButton"
    //import RedPortalConnect from "./RedPortalConnect.vue"
    //import FeaturedTopics from "./FeaturedTopics.vue"
    import Featured from "./featured/Featured.vue"
    import BusinessEntitiesProfileHome from "./BusinessEntitiesProfileHome.vue"
    // import BrochuresHome from "./BrochuresHome.vue"
    export default {
        components: {
            //appCalculator: Calculator,
            appHighlightNews: HighlightNews,
            appHighlightedProducts: HighlightedProducts,
            appHighlightedInfographics: getHighlightedInfographics,
            // appMobileAppStore: MobileAppStore,
            //appSocialNetworks: SocialNetworks,
            //appNfisConnect: NfisConnect,
            //appRedPortalConnect: RedPortalConnect,
            //appHighlightPublications: HighlightedPublications,
            //appFeaturedTopics: FeaturedTopics,
            appFeatured: Featured,
            //appFinancialReportControlsHomeButton: FinancialReportControlsHomeButton,
            appBusinessEntitiesProfileHome: BusinessEntitiesProfileHome,
            // appBrochuresHome:BrochuresHome
        },
        mounted() {
            this.$store.state.isSearching = false;
        }
    }



</script>

<style scoped>
</style>
