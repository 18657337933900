const legalEntityTypes = [
    {
        "id": 1,
        "name": "Privredna društva",
        "description": "Privredna društva",
    },
    {
        "id": 2,
        "name": "Udruženja i fondacije",
        "description": "Udruženja i fondacije",
    },
    {
        "id": 3,
        "name": "Finansijske institucije",
        "description": "Banke i mikrokreditne organizacije",
    },
    {
        "id": 4,
        "name": "Budžetski korisnici",
        "description": "Budžetski korisnici",
    },
    {
        "id": 5,
        "name": "Investicioni fondovi",
        "description": "Investicioni fondovi",
    },
    {
        "id": 6,
        "name": "Osiguravajuća društva",
        "description": "Osiguravajuća društva",
    },
    {
        "id": 7,
        "name": "Mikro privredna društva",
        "description": "Mikro privredna društva",
    },
];

export { legalEntityTypes }