<template>
    <div class="dropdown ml-3 mb-2 float-right rounded">
        <div class="btn text-white pb-0 pt-1 pl-1 pr-1" type="button" id="dropdownMenuButton" data-toggle="dropdown">
            <div class="d-inline-block text-left mr-3 font-size">
                <span class="d-block">{{ getAuth.username }}</span>
                <span class="d-block text-muted">{{ getAuth.email }}</span>
            </div>
            <img :src="getAuth.photoUrl" class="rounded app-icon float-right" />
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a class="dropdown-item btn text-center" v-bind:href="'https://' + getMyProfileAppUrl">
                <div class="d-block">
                    <button class="btn border p-1">
                        <img :src="getAuth.photoUrl" class="rounded app-icon" />
                    </button>
                </div>
                <span class="ml-2 small font-weight-bold"> {{ getAuth.username }} </span>
                <span class="d-block small text-muted"> {{ getAuth.email }}</span>
            </a>
            <div class="dropdown-divider" v-if="hasApplications"></div>
            <a class="dropdown-item btn" v-for="app in getApplications" v-bind:key="app.name" v-bind:href="'https://' + app.redirectUrl">
                <div class="d-inline-block">
                    <button class="btn border p-1">
                        <img :src="app.resourceUrl" class="rounded app-icon" />
                    </button>
                </div>
                <span class="ml-2 small font-weight-bold text-muted">{{ app.name }}</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item btn" v-on:click="logMeOut">
                <span>
                    <span class="small font-weight-bold text-muted">{{ $t("logOut").toUpperCase() }}</span>
                    <i class="fa fa-sign-out float-right text-danger mt-1"></i>
                </span>
            </a>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                applications: [],
                identityAppId: 1024,
                portalAppId: 1004
            }
        },
        computed: {
            ...mapGetters(["getAuth"]),
            hasApplications() {
                return this.getApplications.length > 0;
            },
            getMyProfileAppUrl() {
                var identity = this.applications.find(a => a.applicationId == this.identityAppId);
                return identity ? identity.redirectUrl : "identity.fia.ba";
            },
            getApplications() {
                return this.applications.filter(a => a.applicationId != this.identityAppId && a.applicationId != this.portalAppId);
            }
        },
        methods: {
            ...mapActions(["logOut", "fetchMyApps"]),
            logMeOut() {
                this.$loading.show(this.pageLoaderOptions);
                this.applications = [];
                this.logOut();
            }
        },
        created() {
            this.fetchMyApps().then((applications) => this.applications = applications || []);
        }
    }
</script>

<style scoped>
    .app-icon {
        width: 28px;
        height: 28px;
    }

    .dropdown {
        border: 1px solid #243B80;
        border-radius: 5px;
    }

        .dropdown:hover {
            background-color:  #374e96;
        }

    .font-size{
        font-size: 10px;

    }

</style>
