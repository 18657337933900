<template>
    <div>
        <div class="row" v-on:keyup.enter="search()">
            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-lg-12 form-group">
                <!-- Search panel -->
                <div class="search-header p-3 form-group">
                    <i class="fa fa-search mr-3"></i>
                    <label>{{ $t("search") }}</label>
                    <hr />
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <div v-html="$t('jrrContentText')" class="rounded p-2 small note text-justify"></div>
                        </div>
                        <div class="col-md-6 form-group text-right">
                            <input class="form-control col-lg-6 d-inline-block" v-model="jib" v-bind:placeholder="$t('uniqeIdentificationNumberShort')" />
                            <p class="small text-danger m-0" v-if="!$v.jib.required && $v.$anyDirty">{{ $t('jibIsRequired') }}</p>
                            <p class="small text-danger m-0" v-for="(err, index) in errorMessages" :key="index">{{ err.message }}</p>
                        </div>
                        <div class="col-md-6">
                            <button class="btn col-lg-6 float-left" v-on:click="search()"><i class="fa fa-search mr-2"></i>  {{ $t("search")}}</button>
                        </div>
                    </div>
                </div>
                <div class="search-result p-3" v-if="data">
                    <div class="row">
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("legalEntity")}}</div>
                            <div class="small font-weight-bold">{{ data.legalEntity.name }}</div>
                        </div>
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort")}}</div>
                            <div class="small font-weight-bold">{{ data.legalEntity.identificationNumber }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="small text-muted">{{ $t("address")}}</div>
                            <div class="small font-weight-bold">{{ data.legalEntity.location }}</div>
                        </div>
                        <div class="col-lg-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row" v-if="data.bankAccount.length > 0">
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("bankName")}}</div>
                            <div class="small font-weight-bold">{{ data.bankAccount[0].bank.name }}</div>
                        </div>
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("mainAccountNumber")}}</div>
                            <div class="small font-weight-bold">{{ data.bankAccount[0].accountNumber }}</div>
                        </div>
                        <div class="col-md-4">
                            <div class="small text-muted">{{ $t("accountStatus")}}</div>
                            <div class="small font-weight-bold badge rounded p-1" :style="{'background-color': data.bankAccount[0].status.bodyHex, 'color': data.bankAccount[0].status.textHex}">{{ data.bankAccount[0].status.name }}</div>
                        </div>
                        <div class="col-lg-12">
                            <hr />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="rounded small text-justify p-3" :style="{'background-color': data.message.bodyHex, 'color': data.message.textHex}" v-html="data.message.content">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else-if="noResultPanelVisible" class="search-result p-3">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            {{ $t("searchNoResults")}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex"
    import { required } from "vuelidate/lib/validators"
    import { BBreadcrumb } from 'bootstrap-vue'

    export default {
        data() {
            return {
                jib: null,
                data: null,
                noResultPanelVisible: false,
                errorMessages: []
            }
        },
        components: {
            appBreadcrumb: BBreadcrumb
        },
        methods: {
            ...mapActions(["fetchLegalEntityMainBankAccount"]),
            search() {
                let loader = this.$loading.show(this.pageLoaderOptions);

                this.errorMessages = [];
                this.data = null;
                this.noResultPanelVisible = false;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    loader.hide();
                    return;
                }

                this.fetchLegalEntityMainBankAccount(this.jib).then((response) => {

                    if (response.status == 200) {
                        this.data = response.data;
                    }
                    else {
                        this.noResultPanelVisible = true;

                        if (response.data.errorCount > 0)
                            this.errorMessages = response.data.resultItems;
                    }

                    loader.hide();
                }).catch(() => {
                    //console.log("greska");
                    loader.hide();
                });
            }
        },
        computed: {
            ...mapGetters(["getContentById", "getContentByUrl"]),
            breadcrumbItems() {

                let contentId = this.getContentByUrl(this.$route.name).id;

                let items = [];

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;

                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            }
        },
        filters: {
            lowerCase(value) {
                return value.toLowerCase();
            }
        },
        created() {
            this.$store.state.isSearching = true;
        },
        beforeDestroy() {
            this.$store.state.isSearching = false;
        },
        validations: {
            jib: {
                required
            },
        }
    }


</script>

<style scoped>
    .search-header, .search-result, .search-advance, .no-match-found {
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        background-color: #FFF;
    }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

    .bg-primary {
        background-color: #243B80 !important;
    }

    .btn:hover {
        background-color: #243B80;
        color: white;
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }

    .note {
        background-color: whitesmoke;
        border: 1px solid rgba(97, 97, 97, 0.2);
    }

</style>