<template>
    <div>
        <div class="row">
            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-lg-6 col-md-12" v-if="legalEntity">
                <!-- Basic info - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group text-center bg-primary rounded p-2">
                        <span class="text-primary small font-weight-bold">{{ $t("basicInformation").toUpperCase() }}</span>
                    </div>
                    <hr class="mt-0" />

                    <div class="row">
                        <div class="form-group col-md-12">
                            <canvas ref="longName" width="400" :height="legalEntity.longName.length > 45 ? 65 : 30"></canvas>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("legalEntityName") }}</div>
                            <div class="small font-weight-bold text-primary" >
                                <canvas ref="shortName" width="400" :height="legalEntity.shortName.length > 40 ? 65 : 30"></canvas>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="jib" width="200" height="30"></canvas>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("taxNumber") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="taxNumber" width="200" height="30"></canvas>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("taxPayerType") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="legalEntityType" width="200" height="30"></canvas>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("registrationDate") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="registrationDateTime" width="200" height="30"></canvas>

                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("county") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="county" width="200" height="30"></canvas>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("municipality") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="municipality" width="200" height="30"></canvas>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("address") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="address" width="200" height="30"></canvas>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("email") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="email" width="200" height="30"></canvas>

                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("phone") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="phone" width="200" height="30"></canvas>

                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="small text-muted">{{ $t("webSite") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="webSite" width="200" height="30"></canvas>
                            </div>
                        </div>
                    </div>


                </div>

                <!-- Basic info - END -->
                <!-- Activities - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group text-center bg-primary rounded p-2">
                        <span class="text-primary small font-weight-bold">{{ $t("activities").toUpperCase() }}</span>
                    </div>
                    <hr class="mt-0" />

                    <!--<div class="row" v-for="(legalEntityActivity, index) in legalEntityActivities" v-bind:key="index">-->

                    <div class="row">
                        <div class="form-group col-md-3">
                            <div class="small text-muted">{{ $t("code") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="activityCode" width="200" height="30"></canvas>
                                <!--{{ legalEntity.activityCode }}-->
                            </div>
                        </div>
                        <div class="form-group col-md-3">
                            <div class="small text-muted">{{ $t("letter") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="letterOfActivity" width="200" height="30"></canvas>
                                <!--{{ legalEntity.letterOfActivity }}-->
                            </div>
                        </div>
                        <!--<div class="form-group col-lg-2 col-md-4">
                            <div class="small text-muted">{{ $t("priority") }}</div>
                            <div class="small font-weight-bold text-primary">{{ $t('mainActivity') }}</div>
                        </div>-->
                        <div class="form-group col-md-6">
                            <div class="small text-muted">{{ $t("description") }}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="activityCodeDescription" width="350" :height="legalEntity.activityCodeDescription.length > 65 ? 50 : 30"></canvas>
                                <!--{{ legalEntity.activityCodeDescription}}-->
                            </div>
                        </div>

                        <!--<div class="col-md-12 form-group" v-show="index != legalEntityActivities.length-1">
                            <hr />
                        </div>-->
                    </div>

                </div>

                <!-- Activities - END -->
                <!-- founding structure - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group rounded bg-primary p-2 text-center">
                        <span class="text-primary small font-weight-bold">{{ $t("foundingStructure").toUpperCase() }}</span>
                    </div>
                    <hr class="mt-0" />

                    <div class="form-group table-responsive">
                        <table class="table table-sm mb-0  table-bordered small">
                            <thead>
                                <tr>
                                    <th class="text-center">{{ $t("fullName")}}</th>
                                    <th class="text-center">{{ $t("contributionAmount")}}</th>
                                    <!--<th class="text-center">{{ $t("contributionPercentage")}}</th>-->
                                </tr>
                            </thead>
                            <tbody v-if="foundingStructure.length > 0">
                                <tr v-for="(founder, index) in foundingStructure" v-bind:key="index">
                                    <td class="text-primary">
                                        <canvas :ref="'founding_fullName_' + index" width="250" height="30"></canvas>
                                    </td>
                                    <td class="text-primary text-right">
                                        <canvas :ref="'founding_share_' + index" width="200" height="30"></canvas>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text-muted text-center"> {{ $t('noResults') }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Founding structure - END -->
                <!-- Management structure - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group rounded bg-primary p-2 text-center">
                        <span class="text-primary small font-weight-bold">{{ $t("managementStructure").toUpperCase() }}</span>
                    </div>
                    <hr class="mt-0" />
                    <div class="form-group table-responsive">
                        <table class="table table-sm mb-0  table-bordered small">
                            <thead>
                                <tr>
                                    <th class="text-center">{{ $t("fullName")}}</th>
                                    <th class="text-center">{{ $t("position")}}</th>
                                </tr>
                            </thead>
                            <tbody v-if="managementStructure.length > 0">
                                <tr v-for="(management, index) in managementStructure" v-bind:key="index">
                                    <td class="text-primary">
                                        <canvas :ref="'management_fullName_' + index" width="250" height="30"></canvas>
                                    </td>
                                    <!-- <td class="text-primary">
                                        <canvas :ref="'management_position_' + index" width="250" height="30"></canvas>
                                    </td> -->
                                    <td>
                                      <canvas :ref="'management_position_' + index" width="400" :height="'management_position_+ index'.length > 65 ? 50 : 30"></canvas>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4" class="text-muted text-center"> {{ $t('noResults') }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Management structure - END -->

            </div>
            <div class="col-lg-6 col-md-12" v-if="legalEntity">

                <!-- Financial indicators - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group bg-primary rounded p-2 text-center">
                        <span class="text-primary small font-weight-bold">{{ $t("financialIndicators").toUpperCase() }}</span>
                    </div>
                    <div class="row" v-if="legalEntity.legalEntityTypeId">
                        <div class="col-md-12">
                            <hr class="mt-0" />
                            <component v-bind:is="getLegalEntityCardComponent(legalEntity.legalEntityTypeId)" v-bind:legalEntityId="id" v-bind:years="years"></component>
                        </div>
                    </div>
                </div>

                <!-- Financial indicators - END -->
                <!-- Main account - START -->

                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group bg-primary rounded p-2 text-center">
                        <span class="text-primary small font-weight-bold">{{ $t("mainBankAccount").toUpperCase() }}</span>
                    </div>
                    <hr class="mt-0" />

                    <div class="row"  v-if="jrr.data && jrr.data.bankAccount.length > 0">
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("bankName")}}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="bankaccount_name" width="160" height="30"></canvas>

                                <!--{{ jrr.data.bankAccount[0].bank.name }}-->
                            </div>
                        </div>
                        <div class="col-md-4 form-group">
                            <div class="small text-muted">{{ $t("mainAccountNumber")}}</div>
                            <div class="small font-weight-bold text-primary">
                                <canvas ref="bankaccount_accountNumber" width="160" height="30"></canvas>
                                <!--{{ jrr.data.bankAccount[0].accountNumber }}-->
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="small text-muted">{{ $t("accountStatus")}}</div>
                            <div class="small font-weight-bold badge rounded p-1 pl-5" :style="{'background-color': jrr.data.bankAccount[0].status.bodyHex, 'color': jrr.data.bankAccount[0].status.textHex}">
                                <canvas ref="bankaccount_status" width="100" height="30"></canvas>
                                <!--{{ jrr.data.bankAccount[0].status.name }}-->
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <hr />
                        </div>
                        <div class="col-lg-12">
                            <div class="rounded small text-justify p-3" :style="{'background-color': jrr.data.message.bodyHex, 'color': jrr.data.message.textHex}" v-html="jrr.data.message.content">
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else-if="jrr.data && jrr.data.message">
                        <div class="col-md-12 small text-muted text-center">
                            {{ jrr.data.message.content }}
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-md-12 small text-muted text-center">
                            {{ $t("noResults")}}
                        </div>
                    </div>
                </div>

                <!-- Main account - END -->
            </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    import { BBreadcrumb } from 'bootstrap-vue'
    import CardContentDefault from "./CardContentDefault"
    import CardContentAssociationAndFondation from "./CardContentAssociationAndFondation"
    import CardContentFinancialInstitution from "./CardContentFinancialInstitution"
    import CardContentInsuranceCompanies from "./CardContentInsuranceCompanies"
    import CardContentInvestmentFunds from "./CardContentInvestmentFunds"
    import CardContentBudget from "./CardContentBudget"
    // import CardContentMicroCompanies from './CardContentMicroCompanies.vue'
    import CardContentMicroCompanies from './CardContentMicroCompanies.vue'
    import { mapActions, mapGetters } from "vuex"


    export default {
        data() {
            return {
                years: [2021, 2022, 2023],
                legalEntity: null,
                jrr: {
                    data: { "message": { "textHex": "#FFFFFF", "bodyHex": "#1F3E7F", "title": null, "content": "<span style=\"text-align: justify;\">Ukoliko želite dobiti potvrdu o likvidnosti i/ili svim otvorenim računima pravnog lica u elektronskoj i/ili papirnoj formi popunite obrazac na linku: <strong><a href=\"https://proizvodi.fia.ba/Account/KreirajZahtjev\" style=\"color: #FFF !important\">\r\n\r\nZahtjev za usluge iz Jedinstvenog registra računa poslovnih subjekata FBiH</a></strong>.</span>", "titleFontSize": null, "contentFontSize": null }, "legalEntity": { "identificationNumber": "423561976023", "name": "DUBIST D.O.O.", "location": "KNEZA VIŠESLAVA" }, "bankAccount": [{ "accountNumber": "N/A", "bank": { "name": "N/A", "code": "N/A" }, "status": { "name": "N/A", "code": "A", "bodyHex": "#FFF", "textHex": "#FFFFFF" } }] },
                    errorMessages: []
                }
            }
        },
        props: ["id"],
        components: {
            appBreadcrumb: BBreadcrumb,
            appCardContentDefault: CardContentDefault,
            appCardContentAssociationAndFondation: CardContentAssociationAndFondation,
            appCardContentFinancialInstitution: CardContentFinancialInstitution,
            appCardContentBudget: CardContentBudget,
            appCardContentInsuranceCompanies: CardContentInsuranceCompanies,
            appCardContentInvestmentFunds: CardContentInvestmentFunds,
            // appCardContentMicroCompanies: CardContentMicroCompanies,
        },
        methods: {
            ...mapActions(["fetchLegalEntity", "fetchLegalEntityMainBankAccount"]),
            calculateSharePercentage(share) {
                let sum = 0;

                this.foundingStructure.forEach((founder) => sum += founder.share);

                return ((share / sum) * 100).toFixed(2);
            },
            formatNumber(value) {
                return new Intl.NumberFormat('de-DE').format(value);
            }

        },
        computed: {
            ...mapGetters(["getContentByUrl", "getContentById", "getLegalEntityCardComponent"]),
            breadcrumbItems() {
                let urlToCheck = this.$t("businessEntitiesProfileUrl");

                let content = this.getContentByUrl(urlToCheck);
                if (!content) {
                    urlToCheck = "business-entities-profile";
                    content = this.getContentByUrl(urlToCheck);
                }

                let contentId = content.id;

                let items = [];

                items.push({ text: this.$t("legalEntityProfile"), to: null });

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.url == urlToCheck ? urlToCheck : content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;
                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            },
            windowWidth() {
                return this.window.width;
            },
            foundingStructure() {
                return this.legalEntity ? this.legalEntity.legalEntityRelations.filter(ler => ler.relationTypeId == 1) : []; // Osnivačka struktura je RelationTypeId = 1
            },
            managementStructure() {
                return this.legalEntity ? this.legalEntity.legalEntityRelations.filter(ler => ler.relationTypeId == 2) : []; // Upravljačka struktura je RelationTypeId = 2
            },
        },
        filters: {
            lowerCase(value) {
                return value.toLowerCase();
            }
        },
        created() {
            this.$store.state.isSearching = true;
            let loader = this.$loading.show(this.pageLoaderOptions);

            this.fetchLegalEntity(this.id).then((legalEntity) => {
                this.legalEntity = legalEntity;

                this.fetchLegalEntityMainBankAccount(this.legalEntity.jib).then((response) => {
                    //console.log(response);

                    if (response) {
                        if (response.status && response.status == 200) {
                            this.jrr.errorMessages = [];
                            this.jrr.data = response.data;
                        }
                        //else {
                        //    if (response.data && response.data.errorCount > 0) {
                        //        this.jrr.errorMessages = response.data.resultItems;
                        //        this.jrr.data = null;
                        //    }
                        //}
                    }
                    else {
                        this.jrr.data = null;
                        this.jrr.errorMessages = [];
                    }

                    if (this.legalEntity) {
                        for (let [key, value] of Object.entries(this.$refs)) {

                            if (value.length > 0)
                                value = value[0];

                            let ctx = value.getContext("2d");
                            ctx.clearRect(0, 0, value.width, value.height); //Clear canvas before drawing new text into it
                            ctx.font = "12px Roboto"; //setting font

                            ctx.textAlign = "start" //Aligning text
                            let width = 0;

                            var keyGroup = key.split("_")[0];

                            if (keyGroup == "founding") {

                                let keyProperty = key.split("_")[1];
                                let index = key.split("_")[2];

                                if (keyProperty == "fullName") {
                                    ctx.fillText(`${this.foundingStructure[index]["fullName"]}`, width, 20);
                                }
                                else if (keyProperty == "share") {
                                    ctx.textAlign = "end"
                                    width = 190;
                                    ctx.fillText(this.foundingStructure[index][keyProperty] ? this.formatNumber(this.foundingStructure[index][keyProperty]) : "N/A", width, 20);
                                }
                            }
                            else if (keyGroup == "management") {
                                ctx.textAlign = "start"

                                let keyProperty = key.split("_")[1];
                                let index = key.split("_")[2];

                                if (keyProperty == "name")
                                    ctx.fillText(`${this.managementStructure[index]["firstName"]} ${this.managementStructure[index]["lastName"]}`, width, 20);
                                else
                                    ctx.fillText(this.managementStructure[index][keyProperty], width, 20);
                            }
                            else if (keyGroup == "bankaccount") {
                                ctx.font = "bold 12px Roboto"; //setting font
                                ctx.textAlign = "start";

                                let keyProperty = key.split("_")[1];

                                if (this.jrr.data && this.jrr.data.bankAccount.length > 0) {
                                    if (keyProperty == "name")
                                        ctx.fillText(this.jrr.data.bankAccount[0].bank.name, width, 20);
                                    else if (keyProperty == "accountNumber")
                                        ctx.fillText(this.jrr.data.bankAccount[0].accountNumber, width, 20);
                                    else {
                                        ctx.moveTo(150, 20);
                                        ctx.fillStyle = "white";
                                        ctx.fillText(this.jrr.data.bankAccount[0].status.name, width, 20);
                                    }
                                }
                            }
                            else {
                                ctx.font = "bold 12px Roboto"; //setting font

                                //< !--legalEntity.registrationDateTime | moment($t("dateFormat")) }

                                if (key == "registrationDateTime") {
                                    ctx.fillText(moment(new Date(this.legalEntity[key])).format(this.$t("dateOnlyFormat")), width, 20);
                                }
                                else if (key == "longName") {
                                    ctx.font = "bold 14px Roboto"; //settings font

                                    let longName = this.legalEntity[key];
                                    let namePartCount = 1;

                                    if (longName.length > 45) {

                                        while (longName.length > 45) {
                                            let splitedLongName = longName.split(" ");
                                            let numberOfCharactersFirstSentence = 0;

                                            for (var x = 0; x < splitedLongName.length; x++) {
                                                if ((numberOfCharactersFirstSentence + splitedLongName[x].length + 1) < 45)
                                                    numberOfCharactersFirstSentence += splitedLongName[x].length + 1;
                                                else
                                                    break;
                                            }

                                            let namePart = longName.substring(0, numberOfCharactersFirstSentence);

                                            ctx.fillText(namePart, width, 20 * namePartCount);

                                            longName = longName.substring(numberOfCharactersFirstSentence, longName.length);
                                            namePartCount++;
                                        }

                                        ctx.fillText(longName, width, 20 * namePartCount);

                                    }
                                    else {
                                        ctx.fillText(longName, width, 20);
                                    }

                                }
                                else if (key == "shortName") {
                                    let longName = this.legalEntity[key];
                                    let namePartCount = 1;

                                    if (longName.length > 40) {

                                        while (longName.length > 40) {
                                            let splitedLongName = longName.split(" ");
                                            let numberOfCharactersFirstSentence = 0;

                                            for (var j = 0; j < splitedLongName.length; j++) {
                                                if ((numberOfCharactersFirstSentence + splitedLongName[j].length + 1) < 40)
                                                    numberOfCharactersFirstSentence += splitedLongName[j].length + 1;
                                                else
                                                    break;
                                            }

                                            let namePart = longName.substring(0, numberOfCharactersFirstSentence);

                                            ctx.fillText(namePart, width, 20 * namePartCount);

                                            longName = longName.substring(numberOfCharactersFirstSentence, longName.length);
                                            namePartCount++;
                                        }

                                        ctx.fillText(longName, width, 20 * namePartCount);

                                    }
                                    else {
                                        ctx.fillText(longName, width, 20);
                                    }
                                }
                                else if (key == "activityCodeDescription") {
                                    let codeDescription = this.legalEntity[key];
                                    let namePartCount = 1;

                                    if (codeDescription.length > 65) {

                                        while (codeDescription.length > 65) {
                                            let splitedLongName = codeDescription.split(" ");
                                            let numberOfCharactersFirstSentence = 0;

                                            for (var k = 0; k < splitedLongName.length; k++) {
                                                if ((numberOfCharactersFirstSentence + splitedLongName[k].length + 1) < 65)
                                                    numberOfCharactersFirstSentence += splitedLongName[k].length + 1;
                                                else
                                                    break;
                                            }

                                            let namePart = codeDescription.substring(0, numberOfCharactersFirstSentence);

                                            ctx.fillText(namePart, width, 20 * namePartCount);

                                            codeDescription = codeDescription.substring(numberOfCharactersFirstSentence, codeDescription.length);
                                            namePartCount++;
                                        }

                                        ctx.fillText(codeDescription, width, 20 * namePartCount);

                                    }
                                    else {
                                        ctx.fillText(codeDescription, width, 20);
                                    }
                                }
                                else {
                                    ctx.fillText(this.legalEntity[key] ? this.legalEntity[key] : "N/A", width, 20);
                                }
                            }
                        }
                    }

                    loader.hide();
                }).catch(() => {
                    //console.log("greska");
                });
            });

        },
        beforeDestroy() {
            this.$store.state.isSearching = false;
        }
    }

</script>

<style scoped>
    .content {
        background-color: blue;
    }

    .search-header, .search-result, .search-advance, .no-match-found {
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        background-color: #FFF;
    }

    .advanced-search-item {
        cursor: pointer;
        transition: transform .2s;
    }

        .advanced-search-item:hover .advanced-search-item-title {
            color: #243B80;
            font-weight: bold;
        }

        .advanced-search-item:hover {
            transform: scale(0.95);
            background-color: lightgray;
        }

    .select2 {
        background-color: white;
    }

    .vs__search {
        height: 28px;
        color: #495057;
    }

        .vs__search:v-bind:placeholder {
            color: rgba(73, 80, 87, 0.9);
        }

    .register-item {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        cursor: pointer;
        transition: transform .2s;
    }

        .register-item:hover {
            transform: scale(1.02);
            background-color: #243B80;
            color: white;
        }

    .selected {
        background-color: #243B80;
        color: white;
    }

    .selected-danger {
        background-color: #dc3545;
        color: white;
    }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #243B80;
            color: white;
        }

    .nav-item {
        cursor: pointer;
        background-color: darkgray;
    }

        .nav-item:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-left: 1px solid white;
        }

        .nav-item:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-right: 1px solid white;
        }

        .nav-item:hover {
            background-color: #243B80;
        }

    .selected {
        background-color: #243B80;
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }

    .nfis-links, .nfis-links:active {
        text-decoration: none !important;
        color: black;
    }

    .panel {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }


    .text-primary {
        color: #243B80 !important;
    }

    .bg-primary {
        background-color: rgba(169, 169, 169, 0.3) !important;
    }

    .panel:hover {
        transform: scale(1.01);
    }
</style>