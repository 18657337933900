<template>
    <div class="row">
        <div class="col-md-12 form-group">
            <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
        </div>
        <div class="form-group col-md-12">
            <div class="panel rounded">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("financialReportsControl") }}</h5>
                        <hr />
                    </div>
                    <div class="col-lg-12 text-muted text-justify">
                        <span class="small" v-html="$t('financialReportsControlText')"></span>
                        <span class="small d-block font-weight-bold mt-3">
                            {{ $t("note").toUpperCase() }}:
                        </span>
                        <ul>
                            <!-- <li>
                                <span class="small d-block text-success font-weight-bold">
                                    {{ $t("multipleFilesUploadEnabled") }}
                                </span>
                            </li>
                            <li>
                                <span class="small d-block text-success font-weight-bold">
                                    {{ $t("onlyExcelFilesAllowed") }}
                                </span>
                            </li> -->
                            <li>
                                <span class="small d-block text-success font-weight-bold">
                                    {{ $t("emailReportControlResultsEnabled") }}
                                </span>
                            </li>
                            <li>
                                <span class="small d-block text-success font-weight-bold">
                                    {{ $t("sendingReportsOnline") }}
                                </span>
                            </li>
                            <li>
                                <span class="small d-block text-success font-weight-bold">
                                    {{ $t("onlyMathAndLogicControl") }}
                                </span>
                            </li>
                            <li>
                                <span class="small d-block text-danger font-weight-bold">
                                    {{ $t("noChainControlIsExecuted") }}
                                </span>
                            </li>
                            <li>
                                <span class="small d-block text-danger font-weight-bold">
                                    {{ $t("onlyOneFileAllowed") }}
                                </span>
                            </li>
                        </ul>
                        <hr />
                    </div>
                    <div class="col-lg-12">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupFileAddon01"><i
                                        class="fa fa-file"></i></span>
                            </div>
                            <div class="custom-file">
                                <input type="file" v-on:change="onFileChange" :accept="allowedFileTypes"
                                    v-bind:disabled="this.files.length > 0" class="custom-file-input"
                                    v-bind:lang="this.$route.params.lang" id="mainFileInput">
                                <label class="custom-file-label"> {{ $t("chooseFiles") }} </label>
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div class="col-md-12 small" v-if="files.length > 0">
                        <div class="row mb-1" v-for="(file, index) in files" v-bind:key="index">
                            <div class="col-md-1 align-self-center">
                                {{ index + 1 }}.
                            </div>
                            <div class="col-md-9 align-self-center">
                                {{ file.name }}
                            </div>
                            <div class="col-md-2 text-right">
                                <button class="btn btn-sm" v-bind:title="$t('remove')"
                                    v-on:click="removeFile(index, 1)">
                                    <i class="fa fa-close"></i></button>
                            </div>
                        </div>
                        <!-- <div class="row mb-1">
                            <div class="col-md-6 align-self-center" style="fontSize:12px">
                               {{ $t("onlyOneFileAllowed") }}
                            </div>
                        </div> -->
                    </div>

                    <div class="col-lg-12" id="test-recaptcha" v-if="files.length > 0">
                        <hr />
                        <app-vue-recaptcha v-bind:sitekey="recaptchaSiteKey" v-on:verify="onVerifyCheck"
                            v-on:expired="onExpiredCheck" class="form-group"></app-vue-recaptcha>
                        <button v-on:click="check" class="btn btn-block">{{
                                $t("checkFiles")
                        }}</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group col-md-12 result-panel">
            <div class="panel rounded" v-if="this.scrollToResults && this.showResults">
                <div class="row">
                    <div class="col-lg-12">
                        <h5>{{ $t("financialReporstControlResult") }}</h5>
                        <hr />
                    </div>
                     <div class="col-md-12" >
                        <app-financial-report-item v-for="file in this.result" v-bind:key="file.id" v-bind:file="file">
                        </app-financial-report-item>
                        <div v-if="this.hasErrorsInFile" style="margin-top: 1%;">
                            <div class="col-md-12 form-group">
                                <input class="form-control" type="email" v-model="email"
                                    v-bind:placeholder="$t('enterYourEmailAddress')"
                                    v-bind:class="{ 'validation-error': $v.email.$error }"
                                    v-on:input="$v.email.$touch()" />
                                <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.required">{{
                                        $t('emailIsRequired')
                                }}</p>
                                <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.email">{{
                                        $t('emailFormatIncorrect')
                                }}</p>
                                <p class="small text-danger m-0" v-if="showForbiddenEmailMessage">{{
                                        $t('emailIsForbidden')
                                }}</p>
                            </div>
                            <div class="col-md-12 form-group">
                                <button v-on:click="send" class="btn btn-block">{{ $t("sendResultsToEmail") }}</button>
                            </div>
                            <div class="col-md-12 form-group" v-if="showSuccessMessage">
                                <div
                                    class="rounded border border-success bg-light text-center small text-success font-weight-bold p-2">
                                    {{ $t("emailReportResultSuccessMessage") }}
                                </div>
                            </div>
                            <div class="col-md-12" v-if="showErrorMessage">
                                <div
                                    class="rounded border border-danger bg-light p-2 text-center small text-danger font-weight-bold">
                                    {{ $t("emailReportResultErrorMessage") }}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row" v-if="showAdditionalReports" style="margin-top: 1%">
                            <div class="col-lg-12" style="margin-bottom: 1%;">
                                <h5>{{ $t("chooseMandatoryReports") }}</h5>
                            </div>
                            <div style="width: 95%;">
                                <div class="custom-file" style="margin-bottom: 1%; margin-left: 2%; width: 100%;">
                                    <input type="file" v-on:change="onFileChangeBiljeske" 
                                        v-bind:disabled="this.filesBiljeske.length > 0"
                                        :accept="allowedFileTypesAdditional" class="custom-file-input"
                                        v-bind:lang="this.$route.params.lang" id="BiljeskeFileInput">
                                    <label class="custom-file-label"> {{ $t("chooseFilesBiljeske") }} </label>
                                </div>
                                <div class="col-md-12 small" v-if="filesBiljeske.length > 0"
                                    style="margin-bottom: 1%; margin-left: 2%;">
                                    <div class="row mb-1" v-for="(file, index) in filesBiljeske" v-bind:key="index">
                                        <div class="col-md-1 align-self-center">
                                            {{ index + 1 }}.
                                        </div>
                                        <div class="col-md-9 align-self-center">
                                            {{ file.name }}
                                        </div>
                                        <div class="col-md-2 text-right">
                                            <button class="btn btn-sm" v-bind:title="$t('remove')"
                                                v-on:click="removeFile(index, 2)">
                                                <i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                        <div class="row" v-if="showAdditionalReports" style="margin-top: 1%;">
                            <div class="col-lg-12" style="margin-bottom: 1%;">
                                <h5>{{ $t("chooseAdditionalReports") }}</h5>
                            </div>
                            <div style="width: 95%;">
                                <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                                    <input type="file" v-on:change="onFileChangeIzvjestaj"
                                        v-bind:disabled="this.filesIzvjestaj.length > 0"
                                        :accept="allowedFileTypesAdditional" class="custom-file-input"
                                        v-bind:lang="this.$route.params.lang" id="IzvjestajFileInput">
                                    <label class="custom-file-label"> {{ $t("chooseFilesGodisnjiIzvjestaj") }} </label>
                                </div>
                                <div class="col-md-12 small" v-if="filesIzvjestaj.length > 0"
                                    style="margin-bottom: 1%; margin-left: 2%;">
                                    <div class="row mb-1" v-for="(file, index) in filesIzvjestaj" v-bind:key="index">
                                        <div class="col-md-1 align-self-center">
                                            {{ index + 1 }}.
                                        </div>
                                        <div class="col-md-9 align-self-center">
                                            {{ file.name }}
                                        </div>
                                        <div class="col-md-2 text-right">
                                            <button class="btn btn-sm" v-bind:title="$t('remove')"
                                                v-on:click="removeFile(index, 3)">
                                                <i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                                    <input type="file" v-on:change="onFileChangeOdlukeUtvrdjivanje"
                                        v-bind:disabled="this.filesUtvrdjivanje.length > 0"
                                        :accept="allowedFileTypesAdditional" class="custom-file-input"
                                        v-bind:lang="this.$route.params.lang" id="odlukaUtvrdjivanjeFileInput">
                                    <label class="custom-file-label"> {{ $t("chooseFilesOdlukaUtvrdjivaje") }} </label>
                                </div>
                                <div class="col-md-12 small" v-if="filesUtvrdjivanje.length > 0"
                                    style="margin-bottom: 1%; margin-left: 2%;">
                                    <div class="row mb-1" v-for="(file, index) in filesUtvrdjivanje" v-bind:key="index">
                                        <div class="col-md-1 align-self-center">
                                            {{ index + 1 }}.
                                        </div>
                                        <div class="col-md-9 align-self-center">
                                            {{ file.name }}
                                        </div>
                                        <div class="col-md-2 text-right">
                                            <button class="btn btn-sm" v-bind:title="$t('remove')"
                                                v-on:click="removeFile(index, 5)">
                                                <i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                                    <input type="file" v-on:change="onFileChangeOdluke" :accept="allowedFileTypes"
                                        v-bind:disabled="this.filesOdluka.length > 0"
                                        class="custom-file-input" v-bind:lang="this.$route.params.lang" id="OdlukaFileInput">
                                    <label class="custom-file-label"> {{ $t("chooseFilesOdluka") }} </label>
                                </div>
                                <div class="col-md-12 small" v-if="filesOdluka.length > 0"
                                    style="margin-bottom: 1%; margin-left: 2%;">
                                    <div class="row mb-1" v-for="(file, index) in filesOdluka" v-bind:key="index">
                                        <div class="col-md-1 align-self-center">
                                            {{ index + 1 }}.
                                        </div>
                                        <div class="col-md-9 align-self-center">
                                            {{ file.name }}
                                        </div>
                                        <div class="col-md-2 text-right">
                                            <button class="btn btn-sm" v-bind:title="$t('remove')"
                                                v-on:click="removeFile(index, 4)">
                                                <i class="fa fa-close"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <hr />
                                <div class="row">
                                    <div class="col-md-12 form-group" v-if="showSuccessMessage">
                                        <div
                                            class="rounded border border-success bg-light text-center small text-success font-weight-bold p-2">
                                            {{ $t("emailReportResultSuccessMessage") }}
                                        </div>
                                    </div>
                                    <div class="col-md-12" v-if="showErrorMessage">
                                        <div
                                            class="rounded border border-danger bg-light p-2 text-center small text-danger font-weight-bold">
                                            {{ $t("emailReportResultErrorMessage") }}
                                        </div>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <button v-b-modal.modal-lg class="btn btn-block" :disabled="!isReady">{{ $t("saveReport") }}</button>
                                        <b-modal id="modal-lg" size="lg" hide-footer centered>
                                            <template #modal-title>
                                                {{ $t("confirmSendng") }}
                                            </template>
                                            <div class="col-md-12 form-group" style="display: flex ;">
                                                <div class="col-md-6 form-group">
                                                    <button class="btn btn-block" block
                                                        @click="$bvModal.hide('modal-lg')">{{
                                                                $t("CancelReport")
                                                        }}
                                                    </button>
                                                </div>
                                                <div class="col-md-6 form-group">
                                                    <button class="btn btn-block " block v-on:click="transmit">{{
                                                            $t("SubmitReport")
                                                    }}
                                                    </button>
                                                </div>
                                            </div>
                                        </b-modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>

            <!-- <div class="panel rounded" v-if="showAdditionalReports" style=" margin-top: 1%;">
                <div class="row">
                    <div class="col-lg-12" style="margin-bottom: 1%;">
                        <h5>{{ $t("chooseAdditionalReports") }}</h5>
                    </div>
                    <div style="width: 95%;" v-if="showAdditionalReports">
                        <div class="custom-file" style="margin-bottom: 1%; margin-left: 2%; width: 100%;">
                            <input type="file" v-on:change="onFileChangeBiljeske" :accept="allowedFileTypesAdditional"
                                class="custom-file-input" v-bind:lang="this.$route.params.lang">
                            <label class="custom-file-label"> {{ $t("chooseFilesBiljeske") }} </label>
                        </div>
                        <div class="col-md-12 small" v-if="filesBiljeske.length > 0"
                            style="margin-bottom: 1%; margin-left: 2%;">
                            <div class="row mb-1" v-for="(file, index) in filesBiljeske" v-bind:key="index">
                                <div class="col-md-1 align-self-center">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-md-9 align-self-center">
                                    {{ file.name }}
                                </div>
                                <div class="col-md-2 text-right">
                                    <button class="btn btn-sm" v-bind:title="$t('remove')"
                                        v-on:click="removeFile(index, 2)">
                                        <i class="fa fa-close"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                            <input type="file" v-on:change="onFileChangeIzvjestaj" :accept="allowedFileTypesAdditional"
                                class="custom-file-input" v-bind:lang="this.$route.params.lang">
                            <label class="custom-file-label"> {{ $t("chooseFilesGodisnjiIzvjestaj") }} </label>
                        </div>
                        <div class="col-md-12 small" v-if="filesIzvjestaj.length > 0"
                            style="margin-bottom: 1%; margin-left: 2%;">
                            <div class="row mb-1" v-for="(file, index) in filesIzvjestaj" v-bind:key="index">
                                <div class="col-md-1 align-self-center">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-md-9 align-self-center">
                                    {{ file.name }}
                                </div>
                                <div class="col-md-2 text-right">
                                    <button class="btn btn-sm" v-bind:title="$t('remove')"
                                        v-on:click="removeFile(index, 3)">
                                        <i class="fa fa-close"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                            <input type="file" v-on:change="onFileChangeOdluke" :accept="allowedFileTypes"
                                class="custom-file-input" v-bind:lang="this.$route.params.lang">
                            <label class="custom-file-label"> {{ $t("chooseFilesOdluka") }} </label>
                        </div>
                        <div class="col-md-12 small" v-if="filesOdluka.length > 0"
                            style="margin-bottom: 1%; margin-left: 2%;">
                            <div class="row mb-1" v-for="(file, index) in filesOdluka" v-bind:key="index">
                                <div class="col-md-1 align-self-center">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-md-9 align-self-center">
                                    {{ file.name }}
                                </div>
                                <div class="col-md-2 text-right">
                                    <button class="btn btn-sm" v-bind:title="$t('remove')"
                                        v-on:click="removeFile(index, 4)">
                                        <i class="fa fa-close"></i></button>
                                </div>
                            </div>
                        </div>

                        <div class="custom-file" style="margin-bottom: 1%;  margin-left: 2%;">
                            <input type="file" v-on:change="onFileChangeOdlukeUtvrdjivanje"
                                :accept="allowedFileTypesAdditional" class="custom-file-input"
                                v-bind:lang="this.$route.params.lang">
                            <label class="custom-file-label"> {{ $t("chooseFilesOdlukaUtvrdjivaje") }} </label>
                        </div>
                        <div class="col-md-12 small" v-if="filesUtvrdjivanje.length > 0"
                            style="margin-bottom: 1%; margin-left: 2%;">
                            <div class="row mb-1" v-for="(file, index) in filesUtvrdjivanje" v-bind:key="index">
                                <div class="col-md-1 align-self-center">
                                    {{ index + 1 }}.
                                </div>
                                <div class="col-md-9 align-self-center">
                                    {{ file.name }}
                                </div>
                                <div class="col-md-2 text-right">
                                    <button class="btn btn-sm" v-bind:title="$t('remove')"
                                        v-on:click="removeFile(index, 5)">
                                        <i class="fa fa-close"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <hr />
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <button v-on:click="send" class="btn btn-block">{{ $t("sendResultsToEmail") }}</button>
                            </div>
                            <div class="col-md-12 form-group" v-if="showSuccessMessage">
                                <div
                                    class="rounded border border-success bg-light text-center small text-success font-weight-bold p-2">
                                    {{ $t("emailReportResultSuccessMessage") }}
                                </div>
                            </div>
                            <div class="col-md-12" v-if="showErrorMessage">
                                <div
                                    class="rounded border border-danger bg-light p-2 text-center small text-danger font-weight-bold">
                                    {{ $t("emailReportResultErrorMessage") }}
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <button v-b-modal.modal-lg class="btn btn-block">{{ $t("saveReport") }}</button>
                                <b-modal id="modal-lg" size="lg" hide-footer centered>
                                    <template #modal-title>
                                        {{ $t("confirmSendng") }}
                                    </template>
                                    <div class="col-md-12 form-group" style="display: flex ;">
                                        <div class="col-md-6 form-group">
                                            <button class="btn btn-block" block @click="$bvModal.hide('modal-lg')">{{
                                                    $t("CancelReport")
                                            }}
                                            </button>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <button class="btn btn-block " block v-on:click="transmit">{{
                                                    $t("SubmitReport")
                                            }}
                                            </button>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div> 
            </div> -->
            <!-- <div class="panel rounded" style="margin-top: 1%;" v-if="showAdditionalReports">
                <div class="row">
                     <div class="col-lg-12">
                        <div
                            class="bg-primary reports-counter float-left width-33-p text-center text-white rounded p-3 font-weight-bold">
                            <span> {{ numberOfReports }} </span>
                            <div class="small">
                                {{ $t("reports").toUpperCase() }}
                            </div>
                        </div>
                        <div
                            class="bg-success success-counter float-right width-33-p text-center text-white rounded p-3 font-weight-bold">
                            <span> {{ numberOfCorrectReports }} </span>
                            <div class="small">
                                {{ $t("correct").toUpperCase() }}
                            </div>
                        </div>
                        <div
                            class="bg-danger error-counter float-left width-33-p text-center text-white rounded p-3 font-weight-bold">
                            <span> {{ numberOfIncorrectReports }} </span>
                            <div class="small">
                                {{ $t("incorrect").toUpperCase() }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <hr />
                        <div class="row">
                            <div class="col-md-12 form-group">
                                <input class="form-control" type="email" v-model="email"
                                    v-bind:placeholder="$t('enterYourEmailAddress')"
                                    v-bind:class="{ 'validation-error': $v.email.$error }"
                                    v-on:input="$v.email.$touch()" />
                                <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.required">{{
                                        $t('emailIsRequired')
                                }}</p>
                                <p class="small text-danger m-0" v-if="$v.email.$dirty && !$v.email.email">{{
                                        $t('emailFormatIncorrect')
                                }}</p>
                                <p class="small text-danger m-0" v-if="showForbiddenEmailMessage">{{
                                        $t('emailIsForbidden')
                                }}</p>
                            </div>
                            <div class="col-md-12 form-group">
                                <button v-on:click="send" class="btn btn-block">{{ $t("sendResultsToEmail") }}</button>
                            </div>
                            <div class="col-md-12 form-group" v-if="showSuccessMessage">
                                <div
                                    class="rounded border border-success bg-light text-center small text-success font-weight-bold p-2">
                                    {{ $t("emailReportResultSuccessMessage") }}
                                </div>
                            </div>
                            <div class="col-md-12" v-if="showErrorMessage">
                                <div
                                    class="rounded border border-danger bg-light p-2 text-center small text-danger font-weight-bold">
                                    {{ $t("emailReportResultErrorMessage") }}
                                </div>
                            </div>
                            <div class="col-md-12 form-group">
                                <button v-b-modal.modal-lg class="btn btn-block">{{ $t("saveReport") }}</button>
                                <b-modal id="modal-lg" size="lg" hide-footer centered >
                                    <template #modal-title>
                                        {{ $t("confirmSendng") }}
                                    </template>
                                    <div class="d-block text-center" v-if="this.successfullFiles.length > 0">
                                        <h4>{{ $t("reportsThatWillBeSaved") }}</h4>
                                        <app-financial-report-item v-for="file in this.successfullFiles"
                                            v-bind:key="file.id" v-bind:file="file">
                                        </app-financial-report-item>
                                    </div>
                                    <div class="d-block text-center" v-if="this.unsuccessfullFiles.length > 0">
                                        <h4>{{ $t("reportsThatWontBeSaved") }}</h4>
                                        <app-financial-report-item v-for="file in this.unsuccessfullFiles"
                                            v-bind:key="file.id" v-bind:file="file">
                                        </app-financial-report-item>
                                    </div>
                                    <div class="col-md-12 form-group" style="display: flex ;">
                                        <div class="col-md-6 form-group">
                                            <button class="btn btn-block" block @click="$bvModal.hide('modal-lg')">{{
                                                    $t("CancelReport")
                                            }}
                                            </button>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <button class="btn btn-block " block v-on:click="transmit">{{
                                                    $t("SubmitReport")
                                            }}
                                            </button>
                                        </div>
                                    </div>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import { mapActions, mapGetters } from "vuex"
import VueRecaptcha from 'vue-recaptcha'
import FinancialReportItem from './FinancialReportItem.vue'
import { BBreadcrumb } from 'bootstrap-vue'

export default {
    data() {
        return {
            allowedFileTypes: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/* ovo je LibreOffice (.ods) extenzija, "application/vnd.oasis.opendocument.spreadsheet"*/],
            allowedFileTypesAdditional: ["application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword", "application/pdf"],
            recaptchaTokenCheck: null,
            isRobotCheck: true,
            email: null,
            showSuccessMessage: false,
            showErrorMessage: false,
            showNoFileSelectedMessage: false,
            scrollToResults: false,
            showForbiddenEmailMessage: false,
            hasSuccessfull: false,
            boxSuccess: '',
            boxError: '',
            dismissSecs: 5,
            dismissCountDown: 0,
            forbiddenEmails: ["info@fia.ba", "kabinet@fia.ba", "direktor@fia.ba"],
            files: [],
            filesBiljeske: [],
            filesIzvjestaj: [],
            filesOdluka: [],
            filesUtvrdjivanje: [],
            result: [],
            // reportResult: [],
            filesNoError: [],
            successfullFiles: [],
            unsuccessfullFiles: [],
            showAdditionalReports: false,
            isReady: false,
            // isReady: true,
            hasErrorsInFile: false,
            fileToSend:null,
            showResults:false,
            transmitFilesList:[]
        }
    },
    components: {
        appVueRecaptcha: VueRecaptcha,
        appBreadcrumb: BBreadcrumb,
        appFinancialReportItem: FinancialReportItem
    },
    methods: {
        ...mapActions(["sendEmailWithReportControlResultNew", "checkFinancialReport", "transmitReportNew"]),
        check() {
            if (this.files.length == 0)
                return;

            this.successfullFiles = [];
            this.unsuccessfullFiles = [];
            this.hasSuccessfull = false;

            let loader = this.$loading.show(this.pageLoaderOptions);

            // this.isRobotCheck = false;

            if (this.isRobotCheck) {
                this.resetRecaptchaCheck();
                loader.hide();
            }
            else {
                var formData = new FormData();

                this.files.forEach(f => {
                    formData.append("files", f, f.name);
                });

                this.checkFinancialReport(formData).then((response) => {
                    if (response.data.errors.length == 0) {
                        this.hasSuccessfull = true;
                        this.successfullFiles.push(response.data);
                    }
                    else {
                        this.unsuccessfullFiles.push(response.data);
                    }

                    if (response.status == 200) {
                        this.result = response;
                        // console.log('result: ', this.response);
                        this.scrollToResults = true;
                        this.showResults=true;
                        if (this.result.data.errors.length === 0) {
                            //console.log(this.result.data);
                            this.showAdditionalReports = true;
                        }
                        else {
                            this.showAdditionalReports = false;
                            this.hasErrorsInFile = true;
                            this.fileToSend=response.data;
                        }
                    }

                    this.resetRecaptchaCheck();
                    loader.hide();
                }).catch(err => { loader.hide(); this.resetRecaptchaCheck(); });
            }
        },
        send() {
            this.showForbiddenEmailMessage = false;

            if (this.result.length == 0)
                return;

            if (this.forbiddenEmails.filter(fe => fe == this.email).length > 0) {
                this.showForbiddenEmailMessage = true;
                return;
            }

            let loader = this.$loading.show(this.pageLoaderOptions);

            if (this.$v.$invalid) {
                this.$v.$touch();
                loader.hide();
            }
            else {
                this.sendEmailWithReportControlResultNew({ receiverEmail: this.email, recaptchaToken: this.recaptchaTokenSend, financialReportControlResults: this.fileToSend}).then((success) => {
                    if (success) {
                        this.email = null;
                        this.$v.$reset();
                        this.showSuccessMessage = true;
                        setTimeout(() => { this.showSuccessMessage = false }, 30000);
                        loader.hide();
                    }
                    else {
                        this.showErrorMessage = true;
                        setTimeout(() => { this.showErrorMessage = false }, 30000);
                        loader.hide();
                    }
                }).catch(() => { loader.hide(); });

            }

        },
        transmit() {
            if (this.files.length == 0)
                return;

            let loader = this.$loading.show(this.pageLoaderOptions);
            var formData = new FormData();
          
            this.files.forEach(f => {
                formData.append("files", f,"MFI_" + f.name);
                //MFI-finansijski izvjesta glavni
            });
            this.filesBiljeske.forEach(f => {
                formData.append("files", f,"BFI_" + f.name);
                //BFI-Bilješke uz finansijske izvještaje
             
            });
            this.filesIzvjestaj.forEach(f => {
                formData.append("files", f,"GIP_" + f.name);
                //GIP-Godišnji izvještaj o poslovanju
                
            });
            this.filesOdluka.forEach(f => {
                formData.append("files", f,"RPR_"+ f.name);
                //RPR-Odluka o raspodjeli poslovnog rezultata
                
            });
            this.filesUtvrdjivanje.forEach(f => {
                formData.append("files", f,"UIF_"+ f.name);
                //UIF-Odluka o utvrđivanju finansijskih izvještaja
                
            });

            this.transmitReportNew(formData).then((response) => {
                // console.log('Response: ', response);
                if (response.status == 200) {
                    this.result = response.data;
                    this.scrollToResults = true;
                    this.showMsgBoxSuccess();
                } else {
                    this.showMsgBoxError(response.data);
                }
        
                loader.hide();
                
                this.$bvModal.hide('modal-lg');
                this.files=[];
                this.filesBiljeske=[];
                this.filesIzvjestaj=[];
                this.filesOdluka=[];
                this.filesUtvrdjivanje=[];
                this.showResults=false;
                this.isReady=false;
                // this.isReady=true;
                this.showAdditionalReports=false;
                document.getElementById("mainFileInput").value=null;
                document.getElementById("BiljeskeFileInput").value=null;
                document.getElementById("IzvjestajFileInput").value=null;
                document.getElementById("OdlukaFileInput").value=null;
                document.getElementById("odlukaUtvrdjivanjeFileInput").value=null;

            }).catch(err => { loader.hide(); });
        },
        showMsgBoxSuccess() {
            let modalId = 'my-modal'
            // let timeoutID = setTimeout(() => {
            //     this.$bvModal.hide(modalId)
            // }, 2500)
            this.boxSuccess = ''
            this.$bvModal.msgBoxOk(this.$t("SuccessfulSubmitLong"), {
                id: modalId,
                title: this.$t("TransmitTitleResult"),
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'success',
                headerClass: 'p-3 border-bottom-0',
                centered: true,
            })
                .then(value => {
                    this.boxSuccess = value
                    // clearTimeout(timeoutID)
                })
        },
        showMsgBoxError(message) {
            let modalId = 'my-modal'
            // let timeoutID = setTimeout(() => {
            //     this.$bvModal.hide(modalId)
            // }, 2500)
            this.boxError = ''
            this.$bvModal.msgBoxOk(message, {
                id: modalId,
                title: "GREŠKA",
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                headerClass: 'p-3 border-bottom-0',
                centered: true,
            })
                .then(value => {
                    this.boxError = value
                    // clearTimeout(timeoutID)
                })
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            files.forEach(f => {
                if (this.files.filter(tf => tf.name == f.name).length == 0 && this.allowedFileTypes.filter(aft => aft == f.type).length > 0) {
                    this.files.push(f);
                }
            });
        },
        onFileChangeBiljeske(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }

            files.forEach(f => {
                if (this.filesBiljeske.filter(tf => tf.name == f.name).length == 0 && this.allowedFileTypesAdditional.filter(aft => aft == f.type).length > 0) {
                    this.filesBiljeske.push(f);
                    this.isReady = true;
                }
            });
        },
        onFileChangeIzvjestaj(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            files.forEach(f => {
                if (this.filesIzvjestaj.filter(tf => tf.name == f.name).length == 0 && this.allowedFileTypesAdditional.filter(aft => aft == f.type).length > 0) {
                    this.filesIzvjestaj.push(f);
                }
            });
        },
        onFileChangeOdluke(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            files.forEach(f => {
                if (this.filesOdluka.filter(tf => tf.name == f.name).length == 0 && this.allowedFileTypes.filter(aft => aft == f.type).length > 0) {
                    this.filesOdluka.push(f);
                }
            });
        },
        onFileChangeOdlukeUtvrdjivanje(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;

            files.forEach(f => {
                if (this.filesUtvrdjivanje.filter(tf => tf.name == f.name).length == 0 && this.allowedFileTypesAdditional.filter(aft => aft == f.type).length > 0) {
                    this.filesUtvrdjivanje.push(f);
                }
            });
        },
        onVerifyCheck(response) {
            if (response)
                this.isRobotCheck = false;
            else
                this.isRobotCheck = true;

            this.recaptchaTokenCheck = response;
        },
        onExpiredCheck() {
            this.resetRecaptchaCheck();
        },
        resetRecaptchaCheck() {
            window.grecaptcha.reset();
            this.recaptchaTokenCheck = null;
            this.isRobotCheck = true;
        },
        removeFile(index, type) {
            if (type === 1) {
                this.showResults=false;
                //this.files.splice(index, 1);
                document.getElementById("mainFileInput").value=null;
                this.files = [];
                document.getElementById("BiljeskeFileInput").value=null;
                this.filesBiljeske = [];
                this.scrollToResults=false;
                document.getElementById("IzvjestajFileInput").value=null;
                his.filesIzvjestaj = [];
                document.getElementById("OdlukaFileInput").value=null;
                his.filesOdluka = [];
                this.showAdditionalReports = false;
                document.getElementById("odlukaUtvrdjivanjeFileInput").value=null;
                his.filesUtvrdjivanje = [];
                this.hasErrorsInFile=false;
                this.isReady = false;
            } else if (type == 2) {
                document.getElementById("BiljeskeFileInput").value=null;
                this.filesBiljeske.splice(index, 1);
                this.filesBiljeske = [];
                this.isReady = false;
            } else if (type == 3) {
                document.getElementById("IzvjestajFileInput").value=null;
                this.filesIzvjestaj.splice(index, 1);
                his.filesIzvjestaj = [];
            } else if (type == 4) {
                document.getElementById("OdlukaFileInput").value=null;
                this.filesOdluka.splice(index, 1);
                his.filesOdluka = [];
            } else if (type == 5) {
                document.getElementById("odlukaUtvrdjivanjeFileInput").value=null;
                this.filesUtvrdjivanje.splice(index, 1);
                his.filesUtvrdjivanje = [];
            }


            this.successfullFiles = [];
            this.unsuccessfullFiles = [];

            if (this.files.length == 0)
                this.result = [];
        }
    },
    computed: {
        ...mapGetters(["getContentById", "getContentByUrl", "getForbiddenEmails"]),
        recaptchaSiteKey() {
            return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
        },
        numberOfReports() {
            return this.result.length;
        },
        numberOfCorrectReports() {
            return this.result.filter(r => r.errors.length == 0).length;
        },
        numberOfIncorrectReports() {
            return this.result.filter(r => r.errors.length > 0).length;
        },
        breadcrumbItems() {

            let contentId = this.getContentByUrl(this.$route.name).id;

            let items = [];

            do {
                let content = this.getContentById(contentId);
                let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                contentId = content.parentId;

            } while (contentId);

            items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

            items[0].to = null;
            items[0].active = true;
            items = items.reverse()

            return items;
        }
    },
    filters: {
        upperCase(value) {
            return value.toUpperCase();
        }
    },
    validations: {
        email: {
            email,
            required
        }
    },
    updated() {
        if (this.numberOfReports > 0 && this.scrollToResults) {
            document.getElementsByClassName("result-panel")[0].scrollIntoView();
            this.scrollToResults = false;
        }
    }
}
</script>

<style scoped>
.panel {
    padding: 4%;
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
}

.validation-error {
    border: 1px solid red;
}

.btn {
    border-color: #243B80;
    color: #243B80;
}

.btn:hover {
    background-color: #243B80;
    color: white;
}


textarea {
    min-height: 100px;
}

.width-65 {
    width: 65% !important;
}

.width-35 {
    width: 35% !important;
}

.breadcrumb {
    background-color: #FFF;
}

.breadcrumb a {
    color: #243B80;
    font-weight: bold;
}

.breadcrumb a:hover {
    color: #374e96;
}

.width-33-p {
    width: 33.33% !important;
}
</style>
