import Axios from "axios"
import interceptors from "../interceptors"

const identity = Axios.create({
    baseURL: "https://api-identity.fia.ba", 
    //baseURL: "https://testapi-identity.fia.ba/api",
    //baseURL: "http://localapi-identity.fia.ba:44389",
    timeout: 60000,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

identity.interceptors.request.use(interceptors.requestInterceptor, interceptors.requestErrorInterceptor);
identity.interceptors.response.use(interceptors.responseInterceptor, interceptors.responseErrorInterceptor);

export default identity;
