const marketActivities = [
        {
            "id": 2719,
            "name": "Poljoprivreda, šumarstvo i ribolov",
            "code": "A",
            "parentId": null
        },
        {
            "id": 2720,
            "name": "Vađenje ruda i kamena",
            "code": "B",
            "parentId": null
        },
        {
            "id": 2721,
            "name": "Prerađivačka industrija",
            "code": "C",
            "parentId": null
        },
        {
            "id": 2722,
            "name": "Proizvodnja i snabdijevanje/opskrba električnom energijom, gasom/plinom, parom i klimatizacija",
            "code": "D",
            "parentId": null
        },
        {
            "id": 2723,
            "name": "Snabdijevanje/opskrba vodom; uklanjanje otpadnih voda, upravljanje/gospodarenje otpadom te djelatnosti sanacije okoliša",
            "code": "E",
            "parentId": null
        },
        {
            "id": 2724,
            "name": "Građevinarstvo",
            "code": "F",
            "parentId": null
        },
        {
            "id": 2725,
            "name": "Trgovina na veliko i na malo; popravak/popravka motornih vozila i motocikala",
            "code": "G",
            "parentId": null
        },
        {
            "id": 2726,
            "name": "Transport/prijevoz i skladištenje",
            "code": "H",
            "parentId": null
        },
        {
            "id": 2727,
            "name": "Djelatnosti pružanja smještaja te pripreme i posluživanja hrane (hotelijerstvo i ugostiteljstvo)",
            "code": "I",
            "parentId": null
        },
        {
            "id": 2728,
            "name": "Informacije i komunikacije",
            "code": "J",
            "parentId": null
        },
        {
            "id": 2729,
            "name": "Finansijske/financijske djelatnosti i djelatnosti osiguranja",
            "code": "K",
            "parentId": null
        },
        {
            "id": 2730,
            "name": "Poslovanje nekretninama",
            "code": "L",
            "parentId": null
        },
        {
            "id": 2731,
            "name": "Stručne, naučne/znanstvene i tehničke djelatnosti",
            "code": "M",
            "parentId": null
        },
        {
            "id": 2732,
            "name": "Administrativne i pomoćne uslužne djelatnosti",
            "code": "N",
            "parentId": null
        },
        {
            "id": 2733,
            "name": "Javna uprava i odbrana/obrana; obavezno/obvezno socijalno osiguranje",
            "code": "O",
            "parentId": null
        },
        {
            "id": 2734,
            "name": "Obrazovanje",
            "code": "P",
            "parentId": null
        },
        {
            "id": 2735,
            "name": "Djelatnosti zdravstvene i socijalne zaštite/skrbi",
            "code": "Q",
            "parentId": null
        },
        {
            "id": 2736,
            "name": "Umjetnost, zabava i rekreacija",
            "code": "R",
            "parentId": null
        },
        {
            "id": 2737,
            "name": "Ostale uslužne djelatnosti",
            "code": "S",
            "parentId": null
        },
        {
            "id": 2738,
            "name": "Djelatnosti kućanstava kao poslodavaca; djelatnosti kućanstava koja proizvode različita dobra i obavljaju različite usluge za vlastite potrebe",
            "code": "T",
            "parentId": null
        },
        {
            "id": 2739,
            "name": "Djelatnosti izvanteritorijalnih organizacijskih tijela",
            "code": "U",
            "parentId": null
        },
        {
            "id": 3092,
            "name": "Djelatnosti iznajmljivanja i davanja u zakup (leasing)",
            "code": "77",
            "parentId": 2732
        },
        {
            "id": 3093,
            "name": "Djelatnosti posredovanja u zapošljavanju",
            "code": "78",
            "parentId": 2732
        },
        {
            "id": 3094,
            "name": "Putnicke agencije,  organizatori putovanja, turoperatori i ostale rezervacijske usluge te djelatnosti u vezi s njima",
            "code": "79",
            "parentId": 2732
        },
        {
            "id": 3095,
            "name": "Zaštitne i istražne djelatnosti",
            "code": "80",
            "parentId": 2732
        },
        {
            "id": 3096,
            "name": "Usluge u  vezi  s  upravljanjem  i  održavanjem  zgrada  te djelatnosti uredenja i održavanja zelenih površina",
            "code": "81",
            "parentId": 2732
        },
        {
            "id": 3097,
            "name": "Kancelarijske  administrativne i pomocne djelatnosti te ostale poslovne pomocne djelatnosti",
            "code": "82",
            "parentId": 2732
        },
        {
            "id": 3098,
            "name": "Javna uprava i odbrana; obavezno socijalno osiguranje",
            "code": "84",
            "parentId": 2733
        },
        {
            "id": 3099,
            "name": "Obrazovanje",
            "code": "85",
            "parentId": 2734
        },
        {
            "id": 3100,
            "name": "Djelatnosti zdravstvene zaštite",
            "code": "86",
            "parentId": 2735
        },
        {
            "id": 3101,
            "name": "Djelatnosti socijalne zaštite u ustanovama sa smještajem",
            "code": "87",
            "parentId": 2735
        },
        {
            "id": 3102,
            "name": "Djelatnosti socijalne zaštite bez smještaja",
            "code": "88",
            "parentId": 2735
        },
        {
            "id": 3103,
            "name": "Kreativne, umjetnicke i zabavne djelatnosti",
            "code": "90",
            "parentId": 2736
        },
        {
            "id": 3104,
            "name": "Biblioteke, arhivi, muzeji i ostale kulturne djelatnosti",
            "code": "91",
            "parentId": 2736
        },
        {
            "id": 3105,
            "name": "Djelatnosti kockanja i kladenja",
            "code": "92",
            "parentId": 2736
        },
        {
            "id": 3106,
            "name": "Sportske, zabavne i rekreacijske djelatnosti",
            "code": "93",
            "parentId": 2736
        },
        {
            "id": 3107,
            "name": "Djelatnosti clanskih organizacija",
            "code": "94",
            "parentId": 2737
        },
        {
            "id": 3108,
            "name": "Popravak racunara i predmeta za licnu upotrebu i domacinstvo",
            "code": "95",
            "parentId": 2737
        },
        {
            "id": 3109,
            "name": "Ostale licne uslužne djelatnosti",
            "code": "96",
            "parentId": 2737
        },
        {
            "id": 3110,
            "name": "Djelatnosti  domacinstava kao poslodavaca koji zapošljavaju poslugu",
            "code": "97",
            "parentId": 2738
        },
        {
            "id": 3111,
            "name": "Djelatnosti privatnih domacinstava koja proizvode razlicita dobra i obavljaju razlicite usluge za vlastite potrebe",
            "code": "98",
            "parentId": 2738
        },
        {
            "id": 3112,
            "name": "Djelatnosti vanteritorijalnih organizacija i organa",
            "code": "99",
            "parentId": 2739
        },
        {
            "id": 3113,
            "name": "Biljna i stocarska proizvodnja, lovstvo i uslužne djelatnosti u vezi s njima",
            "code": "01",
            "parentId": 2719
        },
        {
            "id": 3114,
            "name": "Šumarstvo i sjeca drva (iskorištavanje šuma)",
            "code": "02",
            "parentId": 2719
        },
        {
            "id": 3115,
            "name": "Ribolov i akvakultura",
            "code": "03",
            "parentId": 2719
        },
        {
            "id": 3116,
            "name": "Vadenje ugljena i lignita",
            "code": "05",
            "parentId": 2720
        },
        {
            "id": 3117,
            "name": "Vadenje sirove nafte i prirodnog plina",
            "code": "06",
            "parentId": 2720
        },
        {
            "id": 3118,
            "name": "Vadenje metalnih ruda",
            "code": "07",
            "parentId": 2720
        },
        {
            "id": 3119,
            "name": "Vadenje ostalih ruda i kamena",
            "code": "08",
            "parentId": 2720
        },
        {
            "id": 3120,
            "name": "Pomocne uslužne djelatnosti u vadenju ruda i kamena",
            "code": "09",
            "parentId": 2720
        },
        {
            "id": 3121,
            "name": "Proizvodnja prehrambenih proizvoda",
            "code": "10",
            "parentId": 2721
        },
        {
            "id": 3122,
            "name": "Proizvodnja pica",
            "code": "11",
            "parentId": 2721
        },
        {
            "id": 3123,
            "name": "Proizvodnja duhanskih proizvoda",
            "code": "12",
            "parentId": 2721
        },
        {
            "id": 3124,
            "name": "Proizvodnja tekstila",
            "code": "13",
            "parentId": 2721
        },
        {
            "id": 3125,
            "name": "Proizvodnja odjece",
            "code": "14",
            "parentId": 2721
        },
        {
            "id": 3126,
            "name": "Proizvodnja kože i srodnih proizvoda",
            "code": "15",
            "parentId": 2721
        },
        {
            "id": 3127,
            "name": "Prerada drva i proizvoda od drva i pluta, osim namještaja; proizvodnja predmeta od slame i pletarskih materijala",
            "code": "16",
            "parentId": 2721
        },
        {
            "id": 3128,
            "name": "Proizvodnja papira i proizvoda od papira",
            "code": "17",
            "parentId": 2721
        },
        {
            "id": 3129,
            "name": "Štampanje i umnožavanje snimljenih zapisa",
            "code": "18",
            "parentId": 2721
        },
        {
            "id": 3130,
            "name": "Proizvodnja koksa i rafiniranih naftnih proizvoda",
            "code": "19",
            "parentId": 2721
        },
        {
            "id": 3131,
            "name": "Proizvodnja hemikalija i hemijskih proizvoda",
            "code": "20",
            "parentId": 2721
        },
        {
            "id": 3132,
            "name": "Proizvodnja osnovnih  farmaceutskih proizvoda  i farmaceutskih preparata",
            "code": "21",
            "parentId": 2721
        },
        {
            "id": 3133,
            "name": "Proizvodnja proizvoda od gume i plasticnih masa",
            "code": "22",
            "parentId": 2721
        },
        {
            "id": 3134,
            "name": "Proizvodnja ostalih nemetalnih mineralnih proizvoda",
            "code": "23",
            "parentId": 2721
        },
        {
            "id": 3135,
            "name": "Proizvodnja baznih metala",
            "code": "24",
            "parentId": 2721
        },
        {
            "id": 3136,
            "name": "Proizvodnja gotovih  metalnih  proizvoda,  osim  mašina  i opreme",
            "code": "25",
            "parentId": 2721
        },
        {
            "id": 3137,
            "name": "Proizvodnja racunara te elektronickih i optickih proizvoda",
            "code": "26",
            "parentId": 2721
        },
        {
            "id": 3138,
            "name": "Proizvodnja elektricne opreme",
            "code": "27",
            "parentId": 2721
        },
        {
            "id": 3139,
            "name": "Proizvodnja mašina i uredaja, d. n.",
            "code": "28",
            "parentId": 2721
        },
        {
            "id": 3140,
            "name": "Proizvodnja motornih vozila, prikolica i poluprikolica",
            "code": "29",
            "parentId": 2721
        },
        {
            "id": 3141,
            "name": "Proizvodnja ostalih prijevoznih sredstava",
            "code": "30",
            "parentId": 2721
        },
        {
            "id": 3142,
            "name": "Proizvodnja namještaja",
            "code": "31",
            "parentId": 2721
        },
        {
            "id": 3143,
            "name": "Ostala preradivacka industrija",
            "code": "32",
            "parentId": 2721
        },
        {
            "id": 3144,
            "name": "Popravak i instaliranje mašina i opreme",
            "code": "33",
            "parentId": 2721
        },
        {
            "id": 3145,
            "name": "Proizvodnja i snabdijevanje elektricnom energijom, plinom, parom i klimatizacija",
            "code": "35",
            "parentId": 2722
        },
        {
            "id": 3146,
            "name": "Sakupljanje, procišcavanje i snabdijevanje vodom",
            "code": "36",
            "parentId": 2723
        },
        {
            "id": 3147,
            "name": "Uklanjanje otpadnih voda",
            "code": "37",
            "parentId": 2723
        },
        {
            "id": 3148,
            "name": "Sakupljanje otpada, djelatnosti obrade i zbrinjavanja otpada; reciklaža materijala",
            "code": "38",
            "parentId": 2723
        },
        {
            "id": 3149,
            "name": "Djelatnosti sanacije okoliša te ostale djelatnosti upravljanja otpadom",
            "code": "39",
            "parentId": 2723
        },
        {
            "id": 3150,
            "name": "Gradnja gradevina visokogradnje",
            "code": "41",
            "parentId": 2724
        },
        {
            "id": 3151,
            "name": "Gradnja gradevina niskogradnje",
            "code": "42",
            "parentId": 2724
        },
        {
            "id": 3152,
            "name": "Specijalizirane gradevinske djelatnosti",
            "code": "43",
            "parentId": 2724
        },
        {
            "id": 3153,
            "name": "Trgovina na veliko i na malo motornim vozilima i motociklima; popravak motornih vozila i motocikala",
            "code": "45",
            "parentId": 2725
        },
        {
            "id": 3154,
            "name": "Trgovina na veliko, osim trgovine motornim  vozilima  i motociklima",
            "code": "46",
            "parentId": 2725
        },
        {
            "id": 3155,
            "name": "Trgovina na malo, osim trgovine motornim vozilima i motociklima",
            "code": "47",
            "parentId": 2725
        },
        {
            "id": 3156,
            "name": "Kopneni prijevoz i cjevovodni transport",
            "code": "49",
            "parentId": 2726
        },
        {
            "id": 3157,
            "name": "Vodeni prijevoz",
            "code": "50",
            "parentId": 2726
        },
        {
            "id": 3158,
            "name": "Zracni prijevoz",
            "code": "51",
            "parentId": 2726
        },
        {
            "id": 3159,
            "name": "Skladištenje i pomocne djelatnosti u prijevozu",
            "code": "52",
            "parentId": 2726
        },
        {
            "id": 3160,
            "name": "Poštanske i kurirske djelatnosti",
            "code": "53",
            "parentId": 2726
        },
        {
            "id": 3161,
            "name": "Smještaj",
            "code": "55",
            "parentId": 2727
        },
        {
            "id": 3162,
            "name": "Djelatnosti pripreme i usluživanja hrane i pica",
            "code": "56",
            "parentId": 2727
        },
        {
            "id": 3163,
            "name": "Izdavacke djelatnosti",
            "code": "58",
            "parentId": 2728
        },
        {
            "id": 3164,
            "name": "Proizvodnja filmova, videofilmova i televizijskog programa, djelatnosti snimanja zvucnih zapisa i izdavanja muzickih zapisa",
            "code": "59",
            "parentId": 2728
        },
        {
            "id": 3165,
            "name": "Emitiranje programa",
            "code": "60",
            "parentId": 2728
        },
        {
            "id": 3166,
            "name": "Telekomunikacije",
            "code": "61",
            "parentId": 2728
        },
        {
            "id": 3167,
            "name": "Racunarsko programiranje, savjetovanje i djelatnosti u vezi s s njima",
            "code": "62",
            "parentId": 2728
        },
        {
            "id": 3168,
            "name": "Informacijske uslužne djelatnosti",
            "code": "63",
            "parentId": 2728
        },
        {
            "id": 3169,
            "name": "Finansijske uslužne djelatnosti, osim osiguranja i penzijskih fondova",
            "code": "64",
            "parentId": 2729
        },
        {
            "id": 3170,
            "name": "Osiguranje, reosiguranje i penzijski fondovi, osim obaveznog socijalnog osiguranja",
            "code": "65",
            "parentId": 2729
        },
        {
            "id": 3171,
            "name": "Pomocne djelatnosti kod finansijskih usluga i djelatnosti osiguranja",
            "code": "66",
            "parentId": 2729
        },
        {
            "id": 3172,
            "name": "Poslovanje nekretninama",
            "code": "68",
            "parentId": 2730
        },
        {
            "id": 3173,
            "name": "Pravne i racunovodstvene djelatnosti",
            "code": "69",
            "parentId": 2731
        },
        {
            "id": 3174,
            "name": "Upravljacke djelatnosti; savjetovanje u vezi s upravljanjem",
            "code": "70",
            "parentId": 2731
        },
        {
            "id": 3175,
            "name": "Arhitektonske i inžinjerske djelatnosti; tehnicko ispitivanje i analiza",
            "code": "71",
            "parentId": 2731
        },
        {
            "id": 3176,
            "name": "Naucno istraživanje i razvoj",
            "code": "72",
            "parentId": 2731
        },
        {
            "id": 3177,
            "name": "Promocija (reklama i propaganda) i istraživanje tržišta",
            "code": "73",
            "parentId": 2731
        },
        {
            "id": 3178,
            "name": "Ostale strucne, naucne i tehnicke djelatnosti",
            "code": "74",
            "parentId": 2731
        },
        {
            "id": 3179,
            "name": "Veterinarske djelatnosti",
            "code": "75",
            "parentId": 2731
        },
        {
            "id": 6477,
            "name": "Uzgoj jednogodišnjih usjeva",
            "code": "01.1",
            "parentId": 3113
        },
        {
            "id": 6478,
            "name": "Uzgoj višegodišnjih usjeva",
            "code": "01.2",
            "parentId": 3113
        },
        {
            "id": 6479,
            "name": "Uzgoj sadnog materijala i ukrasnog bilja",
            "code": "01.3",
            "parentId": 3113
        },
        {
            "id": 6480,
            "name": "Uzgoj životinja",
            "code": "01.4",
            "parentId": 3113
        },
        {
            "id": 6481,
            "name": "Mješovita poljoprivredna proizvodnja (biljna i stocna proizvodnja)",
            "code": "01.5",
            "parentId": 3113
        },
        {
            "id": 6482,
            "name": "Pomocne djelatnosti u poljoprivredi i djelatnosti koje se obavljaju nakon žetve usjeva",
            "code": "01.6",
            "parentId": 3113
        },
        {
            "id": 6483,
            "name": "Lov, stupicarenje i uslužne djelatnosti u vezi s njima",
            "code": "01.7",
            "parentId": 3113
        },
        {
            "id": 6484,
            "name": "Uzgoj šuma i ostale djelatnosti u šumarstvu",
            "code": "02.1",
            "parentId": 3114
        },
        {
            "id": 6485,
            "name": "Sjeca drva (iskorištavanje šuma)",
            "code": "02.2",
            "parentId": 3114
        },
        {
            "id": 6486,
            "name": "Sakupljanje nekultiviranih šumskih plodova i proizvoda, osim šumskih sortimenata",
            "code": "02.3",
            "parentId": 3114
        },
        {
            "id": 6487,
            "name": "Pomocne usluge u šumarstvu",
            "code": "02.4",
            "parentId": 3114
        },
        {
            "id": 6488,
            "name": "Ribolov",
            "code": "03.1",
            "parentId": 3115
        },
        {
            "id": 6489,
            "name": "Akvakultura",
            "code": "03.2",
            "parentId": 3115
        },
        {
            "id": 6490,
            "name": "Vadenje kamenog ugljena",
            "code": "05.1",
            "parentId": 3116
        },
        {
            "id": 6491,
            "name": "Vadenje lignita",
            "code": "05.2",
            "parentId": 3116
        },
        {
            "id": 6492,
            "name": "Vadenje sirove nafte",
            "code": "06.1",
            "parentId": 3117
        },
        {
            "id": 6493,
            "name": "Vadenje prirodnog plina",
            "code": "06.2",
            "parentId": 3117
        },
        {
            "id": 6494,
            "name": "Vadenje željeznih ruda",
            "code": "07.1",
            "parentId": 3118
        },
        {
            "id": 6495,
            "name": "Vadenje ruda obojenih metala",
            "code": "07.2",
            "parentId": 3118
        },
        {
            "id": 6496,
            "name": "Vadenje kamena, pijeska i gline",
            "code": "08.1",
            "parentId": 3119
        },
        {
            "id": 6497,
            "name": "Vadenje ruda i kamena d. n.",
            "code": "08.9",
            "parentId": 3119
        },
        {
            "id": 6498,
            "name": "Pomocne djelatnosti za vadenje nafte i prirodnog plina",
            "code": "09.1",
            "parentId": 3120
        },
        {
            "id": 6499,
            "name": "Pomocne djelatnosti za ostalo vadenje ruda i kamena",
            "code": "09.9",
            "parentId": 3120
        },
        {
            "id": 6500,
            "name": "Prerada i konzerviranje mesa i proizvodnja mesnih proizvoda",
            "code": "10.1",
            "parentId": 3121
        },
        {
            "id": 6501,
            "name": "Prerada i konzerviranje riba, ljuskara i mekušaca",
            "code": "10.2",
            "parentId": 3121
        },
        {
            "id": 6502,
            "name": "Prerada i konzerviranje voca i povrca",
            "code": "10.3",
            "parentId": 3121
        },
        {
            "id": 6503,
            "name": "Proizvodnja biljnih i životinjskih ulja i masti",
            "code": "10.4",
            "parentId": 3121
        },
        {
            "id": 6504,
            "name": "Proizvodnja mlijecnih proizvoda",
            "code": "10.5",
            "parentId": 3121
        },
        {
            "id": 6505,
            "name": "Proizvodnja mlinskih proizvoda, škroba i škrobnih proizvoda",
            "code": "10.6",
            "parentId": 3121
        },
        {
            "id": 6506,
            "name": "Proizvodnja pekarskih proizvoda, proizvoda od brašna i kolaca",
            "code": "10.7",
            "parentId": 3121
        },
        {
            "id": 6507,
            "name": "Proizvodnja ostalih prehrambenih proizvoda",
            "code": "10.8",
            "parentId": 3121
        },
        {
            "id": 6508,
            "name": "Proizvodnja pripremljene hrane za životinje",
            "code": "10.9",
            "parentId": 3121
        },
        {
            "id": 6509,
            "name": "Proizvodnja pica",
            "code": "11.0",
            "parentId": 3122
        },
        {
            "id": 6510,
            "name": "Proizvodnja duhanskih proizvoda",
            "code": "12.0",
            "parentId": 3123
        },
        {
            "id": 6511,
            "name": "Priprema i predenje tekstilnih vlakana",
            "code": "13.1",
            "parentId": 3124
        },
        {
            "id": 6512,
            "name": "Tkanje tekstila",
            "code": "13.2",
            "parentId": 3124
        },
        {
            "id": 6513,
            "name": "Dovršavanje tekstila",
            "code": "13.3",
            "parentId": 3124
        },
        {
            "id": 6514,
            "name": "Proizvodnja ostalog tekstila",
            "code": "13.9",
            "parentId": 3124
        },
        {
            "id": 6515,
            "name": "Proizvodnja odjece, osim krznene odjece",
            "code": "14.1",
            "parentId": 3125
        },
        {
            "id": 6516,
            "name": "Proizvodnja proizvoda od krzna",
            "code": "14.2",
            "parentId": 3125
        },
        {
            "id": 6517,
            "name": "Proizvodnja pletene i heklane odjece",
            "code": "14.3",
            "parentId": 3125
        },
        {
            "id": 6518,
            "name": "Štavljenje i obrada kože; proizvodnja putnih i rucnih torbi, sedlarskih i kaišarskih proizvoda; dorada i bojenje krzna",
            "code": "15.1",
            "parentId": 3126
        },
        {
            "id": 6519,
            "name": "Proizvodnja obuce",
            "code": "15.2",
            "parentId": 3126
        },
        {
            "id": 6520,
            "name": "Piljenje i blanjanje drva  (proizvodnja  rezane grade); impregnacija drveta",
            "code": "16.1",
            "parentId": 3127
        },
        {
            "id": 6521,
            "name": "Proizvodnja proizvoda od drva, pluta, slame i pletarskih materijala",
            "code": "16.2",
            "parentId": 3127
        },
        {
            "id": 6522,
            "name": "Proizvodnja celuloze, papira i kartona",
            "code": "17.1",
            "parentId": 3128
        },
        {
            "id": 6523,
            "name": "Proizvodnja proizvoda od papira i kartona",
            "code": "17.2",
            "parentId": 3128
        },
        {
            "id": 6524,
            "name": "Štampanje i uslužne djelatnosti u vezi sa štampanjem",
            "code": "18.1",
            "parentId": 3129
        },
        {
            "id": 6525,
            "name": "Umnožavanje snimljenih zapisa",
            "code": "18.2",
            "parentId": 3129
        },
        {
            "id": 6526,
            "name": "Proizvodnja proizvoda koksnih peci",
            "code": "19.1",
            "parentId": 3130
        },
        {
            "id": 6527,
            "name": "Proizvodnja rafiniranih naftnih proizvoda",
            "code": "19.2",
            "parentId": 3130
        },
        {
            "id": 6528,
            "name": "Proizvodnja osnovnih hemikalija, gnojiva i dušicnih spojeva, plastike i sintetickog kaucuka u primarnim oblicima",
            "code": "20.1",
            "parentId": 3131
        },
        {
            "id": 6529,
            "name": "Proizvodnja pesticida i drugih agrohemijskih proizvoda",
            "code": "20.2",
            "parentId": 3131
        },
        {
            "id": 6530,
            "name": "Proizvodnja boja, lakova i slicnih premaza, grafickih boja i kitova",
            "code": "20.3",
            "parentId": 3131
        },
        {
            "id": 6531,
            "name": "Proizvodnja sapuna i deterdženata, sredstava za cišcenje i poliranje, parfema i toaletno-kozmetickih preparata",
            "code": "20.4",
            "parentId": 3131
        },
        {
            "id": 6532,
            "name": "Proizvodnja ostalih hemijskih proizvoda",
            "code": "20.5",
            "parentId": 3131
        },
        {
            "id": 6533,
            "name": "Proizvodnja umjetnih vlakana",
            "code": "20.6",
            "parentId": 3131
        },
        {
            "id": 6534,
            "name": "Proizvodnja osnovnih farmaceutskih proizvoda",
            "code": "21.1",
            "parentId": 3132
        },
        {
            "id": 6535,
            "name": "Proizvodnja farmaceutskih preparata",
            "code": "21.2",
            "parentId": 3132
        },
        {
            "id": 6536,
            "name": "Proizvodnja proizvoda od gume",
            "code": "22.1",
            "parentId": 3133
        },
        {
            "id": 6537,
            "name": "Proizvodnja proizvoda od plasticnih masa",
            "code": "22.2",
            "parentId": 3133
        },
        {
            "id": 6538,
            "name": "Proizvodnja stakla i proizvoda od stakla",
            "code": "23.1",
            "parentId": 3134
        },
        {
            "id": 6539,
            "name": "Proizvodnja vatrostalnih proizvoda",
            "code": "23.2",
            "parentId": 3134
        },
        {
            "id": 6540,
            "name": "Proizvodnja proizvoda od gline za gradevinarstvo",
            "code": "23.3",
            "parentId": 3134
        },
        {
            "id": 6541,
            "name": "Proizvodnja ostalih proizvoda od porculana i keramike",
            "code": "23.4",
            "parentId": 3134
        },
        {
            "id": 6542,
            "name": "Proizvodnja cementa, kreca i gipsa",
            "code": "23.5",
            "parentId": 3134
        },
        {
            "id": 6543,
            "name": "Proizvodnja proizvoda od betona, cementa i gipsa",
            "code": "23.6",
            "parentId": 3134
        },
        {
            "id": 6544,
            "name": "Rezanje, oblikovanje i obrada kamena",
            "code": "23.7",
            "parentId": 3134
        },
        {
            "id": 6545,
            "name": "Proizvodnja brusnih proizvoda i nemetalnih mineralnih proizvoda, d. n.",
            "code": "23.9",
            "parentId": 3134
        },
        {
            "id": 6546,
            "name": "Proizvodnja sirovog željeza, celika i ferolegura",
            "code": "24.1",
            "parentId": 3135
        },
        {
            "id": 6547,
            "name": "Proizvodnja cijevi, crijeva, šupljih profila i  pripadajuceg pribora od celika",
            "code": "24.2",
            "parentId": 3135
        },
        {
            "id": 6548,
            "name": "Proizvodnja ostalih proizvoda primarne prerade celika",
            "code": "24.3",
            "parentId": 3135
        },
        {
            "id": 6549,
            "name": "Proizvodnja baznih plemenitih i ostalih obojenih metala",
            "code": "24.4",
            "parentId": 3135
        },
        {
            "id": 6550,
            "name": "Lijevanje metala",
            "code": "24.5",
            "parentId": 3135
        },
        {
            "id": 6551,
            "name": "Proizvodnja metalnih konstrukcija",
            "code": "25.1",
            "parentId": 3136
        },
        {
            "id": 6552,
            "name": "Proizvodnja metalnih cisterni, rezervoara i slicnih posuda",
            "code": "25.2",
            "parentId": 3136
        },
        {
            "id": 6553,
            "name": "Proizvodnja parnih kotlova, osim kotlova za centralno grijanje",
            "code": "25.3",
            "parentId": 3136
        },
        {
            "id": 6554,
            "name": "Proizvodnja oružja i municije",
            "code": "25.4",
            "parentId": 3136
        },
        {
            "id": 6555,
            "name": "Kovanje, presovanje, štancanje i valjanje metala; metalurgija praha",
            "code": "25.5",
            "parentId": 3136
        },
        {
            "id": 6556,
            "name": "Površinska  obrada  i  prevlacenje  metala;  mašinska obrada metala",
            "code": "25.6",
            "parentId": 3136
        },
        {
            "id": 6557,
            "name": "Proizvodnja  sjeciva, alata  i  metalnih  proizvoda za opcu namjenu",
            "code": "25.7",
            "parentId": 3136
        },
        {
            "id": 6558,
            "name": "Proizvodnja ostalih gotovih proizvoda od metala",
            "code": "25.9",
            "parentId": 3136
        },
        {
            "id": 6559,
            "name": "Proizvodnja elektronickih komponenata i ploca",
            "code": "26.1",
            "parentId": 3137
        },
        {
            "id": 6560,
            "name": "Proizvodnja racunara i periferne opreme",
            "code": "26.2",
            "parentId": 3137
        },
        {
            "id": 6561,
            "name": "Proizvodnja komunikacijske opreme",
            "code": "26.3",
            "parentId": 3137
        },
        {
            "id": 6562,
            "name": "Proizvodnja elektronickih uredaja za široku potrošnju",
            "code": "26.4",
            "parentId": 3137
        },
        {
            "id": 6563,
            "name": "Proizvodnja instrumenata i aparata za mjerenje, ispitivanje i navodenje; proizvodnja satova",
            "code": "26.5",
            "parentId": 3137
        },
        {
            "id": 6564,
            "name": "Proizvodnja  opreme  za  zracenje,  elektromedicinske  i elektroterapeutske opreme",
            "code": "26.6",
            "parentId": 3137
        },
        {
            "id": 6565,
            "name": "Proizvodnja optickih instrumenata i fotografske opreme",
            "code": "26.7",
            "parentId": 3137
        },
        {
            "id": 6566,
            "name": "Proizvodnja magnetnih i optickih medija",
            "code": "26.8",
            "parentId": 3137
        },
        {
            "id": 6567,
            "name": "Proizvodnja elektromotora, generatora, transformatora te uredaja za distribuciju i kontrolu elektricne energije",
            "code": "27.1",
            "parentId": 3138
        },
        {
            "id": 6568,
            "name": "Proizvodnja baterija i akumulatora",
            "code": "27.2",
            "parentId": 3138
        },
        {
            "id": 6569,
            "name": "Proizvodnja žice i elektroinstalacijskog materijala",
            "code": "27.3",
            "parentId": 3138
        },
        {
            "id": 6570,
            "name": "Proizvodnja elektricne opreme za rasvjetu",
            "code": "27.4",
            "parentId": 3138
        },
        {
            "id": 6571,
            "name": "Proizvodnja aparata za domacinstvo",
            "code": "27.5",
            "parentId": 3138
        },
        {
            "id": 6572,
            "name": "Proizvodnja ostale elektricne opreme",
            "code": "27.9",
            "parentId": 3138
        },
        {
            "id": 6573,
            "name": "Proizvodnja mašina za opce namjene",
            "code": "28.1",
            "parentId": 3139
        },
        {
            "id": 6574,
            "name": "Proizvodnja ostalih mašina za opce namjene",
            "code": "28.2",
            "parentId": 3139
        },
        {
            "id": 6575,
            "name": "Proizvodnja mašina za poljoprivredu i šumarstvo",
            "code": "28.3",
            "parentId": 3139
        },
        {
            "id": 6576,
            "name": "Proizvodnja mašina za obradu metala i alatnih mašina",
            "code": "28.4",
            "parentId": 3139
        },
        {
            "id": 6577,
            "name": "Proizvodnja ostalih mašina za posebne namjene",
            "code": "28.9",
            "parentId": 3139
        },
        {
            "id": 6578,
            "name": "Proizvodnja motornih vozila",
            "code": "29.1",
            "parentId": 3140
        },
        {
            "id": 6579,
            "name": "Proizvodnja karoserija za motorna  vozila; proizvodnja prikolica i poluprikolica",
            "code": "29.2",
            "parentId": 3140
        },
        {
            "id": 6580,
            "name": "Proizvodnja dijelova i pribora za motorna vozila",
            "code": "29.3",
            "parentId": 3140
        },
        {
            "id": 6581,
            "name": "Gradnja brodova i camaca",
            "code": "30.1",
            "parentId": 3141
        },
        {
            "id": 6582,
            "name": "Proizvodnja željeznickih lokomotiva i tracnickih vozila",
            "code": "30.2",
            "parentId": 3141
        },
        {
            "id": 6583,
            "name": "Proizvodnja aviona i svemirskih letjelica te  srodnih prijevoznih sredstava i opreme",
            "code": "30.3",
            "parentId": 3141
        },
        {
            "id": 6584,
            "name": "Proizvodnja vojnih borbenih vozila",
            "code": "30.4",
            "parentId": 3141
        },
        {
            "id": 6585,
            "name": "Proizvodnja prijevoznih sredstava, d. n.",
            "code": "30.9",
            "parentId": 3141
        },
        {
            "id": 6586,
            "name": "Proizvodnja namještaja",
            "code": "31.0",
            "parentId": 3142
        },
        {
            "id": 6587,
            "name": "Proizvodnja nakita, imitacije nakita  (bižuterije) i srodnih proizvoda",
            "code": "32.1",
            "parentId": 3143
        },
        {
            "id": 6588,
            "name": "Proizvodnja muzickih instrumenata",
            "code": "32.2",
            "parentId": 3143
        },
        {
            "id": 6589,
            "name": "Proizvodnja sportske opreme",
            "code": "32.3",
            "parentId": 3143
        },
        {
            "id": 6590,
            "name": "Proizvodnja igara i igracaka",
            "code": "32.4",
            "parentId": 3143
        },
        {
            "id": 6591,
            "name": "Proizvodnja medicinskih i stomatoloških instrumenata i pribora",
            "code": "32.5",
            "parentId": 3143
        },
        {
            "id": 6592,
            "name": "Preradivacka industrija, d. n.",
            "code": "32.9",
            "parentId": 3143
        },
        {
            "id": 6593,
            "name": "Popravak proizvoda od metala, mašina i opreme",
            "code": "33.1",
            "parentId": 3144
        },
        {
            "id": 6594,
            "name": "Instaliranje industrijskih mašina i opreme",
            "code": "33.2",
            "parentId": 3144
        },
        {
            "id": 6595,
            "name": "Proizvodnja, prijenos i distribucija elektricne energije",
            "code": "35.1",
            "parentId": 3145
        },
        {
            "id": 6596,
            "name": "Proizvodnja plina; distribucija plinovitih goriva distribucijskom mrežom",
            "code": "35.2",
            "parentId": 3145
        },
        {
            "id": 6597,
            "name": "Proizvodnja i snabdijevanje parom i klimatizacija",
            "code": "35.3",
            "parentId": 3145
        },
        {
            "id": 6598,
            "name": "Sakupljanje, procišcavanje i snabdijevanje vodom",
            "code": "36.0",
            "parentId": 3146
        },
        {
            "id": 6599,
            "name": "Uklanjanje otpadnih voda",
            "code": "37.0",
            "parentId": 3147
        },
        {
            "id": 6600,
            "name": "Sakupljanje otpada",
            "code": "38.1",
            "parentId": 3148
        },
        {
            "id": 6601,
            "name": "Obrada i zbrinjavanje otpada",
            "code": "38.2",
            "parentId": 3148
        },
        {
            "id": 6602,
            "name": "Reciklaža materijala",
            "code": "38.3",
            "parentId": 3148
        },
        {
            "id": 6603,
            "name": "Djelatnosti sanacije okoliša te ostale djelatnosti upravljanja otpadom",
            "code": "39.0",
            "parentId": 3149
        },
        {
            "id": 6604,
            "name": "Organizacija izvodenja gradevinskih projekata",
            "code": "41.1",
            "parentId": 3150
        },
        {
            "id": 6605,
            "name": "Gradnja stambenih i nestambenih zgrada",
            "code": "41.2",
            "parentId": 3150
        },
        {
            "id": 6606,
            "name": "Gradnja cesta i željeznickih pruga",
            "code": "42.1",
            "parentId": 3151
        },
        {
            "id": 6607,
            "name": "Gradnja  cjevovoda,  vodova  za  elektricnu  struju  i telekomunikacije",
            "code": "42.2",
            "parentId": 3151
        },
        {
            "id": 6608,
            "name": "Gradnja ostalih gradevina niskogradnje",
            "code": "42.9",
            "parentId": 3151
        },
        {
            "id": 6609,
            "name": "Uklanjanje gradevina i pripremni radovi na gradilištu",
            "code": "43.1",
            "parentId": 3152
        },
        {
            "id": 6610,
            "name": "Elektroinstalacijski  radovi,  uvodenje instalacija  vodovoda, kanalizacije i plina i ostali gradevinski instalacijski radovi",
            "code": "43.2",
            "parentId": 3152
        },
        {
            "id": 6611,
            "name": "Završni gradevinski radovi",
            "code": "43.3",
            "parentId": 3152
        },
        {
            "id": 6612,
            "name": "Ostale specijalizirane gradevinske djelatnosti",
            "code": "43.9",
            "parentId": 3152
        },
        {
            "id": 6613,
            "name": "Trgovina motornim vozilima",
            "code": "45.1",
            "parentId": 3153
        },
        {
            "id": 6614,
            "name": "Održavanje i popravak motornih vozila",
            "code": "45.2",
            "parentId": 3153
        },
        {
            "id": 6615,
            "name": "Trgovina dijelovima i priborom za motorna vozila",
            "code": "45.3",
            "parentId": 3153
        },
        {
            "id": 6616,
            "name": "Trgovina motociklima, dijelovima i priborom za motocikle te održavanje i popravak motocikala",
            "code": "45.4",
            "parentId": 3153
        },
        {
            "id": 6617,
            "name": "Trgovina na veliko uz naknadu ili na osnovu ugovora",
            "code": "46.1",
            "parentId": 3154
        },
        {
            "id": 6618,
            "name": "Trgovina na veliko poljoprivrednim sirovinama i živim životinjama",
            "code": "46.2",
            "parentId": 3154
        },
        {
            "id": 6619,
            "name": "Trgovina na veliko hranom, picima i duhanskim proizvodima",
            "code": "46.3",
            "parentId": 3154
        },
        {
            "id": 6620,
            "name": "Trgovina na veliko proizvodima za domacinstvo",
            "code": "46.4",
            "parentId": 3154
        },
        {
            "id": 6621,
            "name": "Trgovina na veliko informacijsko-komunikacijskom opremom",
            "code": "46.5",
            "parentId": 3154
        },
        {
            "id": 6622,
            "name": "Trgovina na veliko ostalim mašinima, opremom i priborom",
            "code": "46.6",
            "parentId": 3154
        },
        {
            "id": 6623,
            "name": "Ostala specijalizirana trgovina na veliko",
            "code": "46.7",
            "parentId": 3154
        },
        {
            "id": 6624,
            "name": "Nespecijalizirana trgovina na veliko",
            "code": "46.9",
            "parentId": 3154
        },
        {
            "id": 6625,
            "name": "Trgovina na malo u nespecijaliziranim prodavnicama",
            "code": "47.1",
            "parentId": 3155
        },
        {
            "id": 6626,
            "name": "Trgovina na malo hranom, picima i duhanskim proizvodima u specijaliziranim prodavnicama",
            "code": "47.2",
            "parentId": 3155
        },
        {
            "id": 6627,
            "name": "Trgovina  na  malo  motornim  gorivima  u  specijaliziranim prodavnicama",
            "code": "47.3",
            "parentId": 3155
        },
        {
            "id": 6628,
            "name": "Trgovina na malo informacijsko-komunikacijskom opremom u specijaliziranim prodavnicama",
            "code": "47.4",
            "parentId": 3155
        },
        {
            "id": 6629,
            "name": "Trgovina na malo ostalom opremom za domacinstvo u specijaliziranim prodavnicama",
            "code": "47.5",
            "parentId": 3155
        },
        {
            "id": 6630,
            "name": "Trgovina na malo proizvodima za kulturu i rekreaciju u specijaliziranim prodavnicama",
            "code": "47.6",
            "parentId": 3155
        },
        {
            "id": 6631,
            "name": "Trgovina  na  malo  ostalom  robom  u  specijaliziranim prodavnicama",
            "code": "47.7",
            "parentId": 3155
        },
        {
            "id": 6632,
            "name": "Trgovina na malo na štandovima i tržnicama",
            "code": "47.8",
            "parentId": 3155
        },
        {
            "id": 6633,
            "name": "Trgovina na malo izvan prodavnica, štandova i tržnica",
            "code": "47.9",
            "parentId": 3155
        },
        {
            "id": 6634,
            "name": "Željeznicki prijevoz putnika, medugradski",
            "code": "49.1",
            "parentId": 3156
        },
        {
            "id": 6635,
            "name": "Željeznicki prijevoz robe",
            "code": "49.2",
            "parentId": 3156
        },
        {
            "id": 6636,
            "name": "Ostali kopneni prijevoz putnika",
            "code": "49.3",
            "parentId": 3156
        },
        {
            "id": 6637,
            "name": "Cestovni prijevoz robe i usluge preseljenja",
            "code": "49.4",
            "parentId": 3156
        },
        {
            "id": 6638,
            "name": "Cjevovodni transport",
            "code": "49.5",
            "parentId": 3156
        },
        {
            "id": 6639,
            "name": "Pomorski i priobalni prijevoz putnika",
            "code": "50.1",
            "parentId": 3157
        },
        {
            "id": 6640,
            "name": "Pomorski i priobalni prijevoz robe",
            "code": "50.2",
            "parentId": 3157
        },
        {
            "id": 6641,
            "name": "Prijevoz putnika unutrašnjim vodenim putevima",
            "code": "50.3",
            "parentId": 3157
        },
        {
            "id": 6642,
            "name": "Prijevoz robe unutrašnjim vodenim putevima",
            "code": "50.4",
            "parentId": 3157
        },
        {
            "id": 6643,
            "name": "Zracni prijevoz putnika",
            "code": "51.1",
            "parentId": 3158
        },
        {
            "id": 6644,
            "name": "Zracni prijevoz robe i svemirski prijevoz",
            "code": "51.2",
            "parentId": 3158
        },
        {
            "id": 6645,
            "name": "Skladištenje robe",
            "code": "52.1",
            "parentId": 3159
        },
        {
            "id": 6646,
            "name": "Pomocne djelatnosti u prijevozu",
            "code": "52.2",
            "parentId": 3159
        },
        {
            "id": 6647,
            "name": "Djelatnosti pružanja univerzalnih poštanskih usluga",
            "code": "53.1",
            "parentId": 3160
        },
        {
            "id": 6648,
            "name": "Djelatnosti pružanja ostalih poštanskih i kurirskih usluga",
            "code": "53.2",
            "parentId": 3160
        },
        {
            "id": 6649,
            "name": "Hoteli i slican smještaj",
            "code": "55.1",
            "parentId": 3161
        },
        {
            "id": 6650,
            "name": "Odmarališta i slicni objekti za kraci odmor",
            "code": "55.2",
            "parentId": 3161
        },
        {
            "id": 6651,
            "name": "Kampovi i prostori za kampiranje",
            "code": "55.3",
            "parentId": 3161
        },
        {
            "id": 6652,
            "name": "Ostali smještaj",
            "code": "55.9",
            "parentId": 3161
        },
        {
            "id": 6653,
            "name": "Djelatnosti restorana i ostalih objekata za pripremu i usluživanje hrane",
            "code": "56.1",
            "parentId": 3162
        },
        {
            "id": 6654,
            "name": "Djelatnosti  keteringa  i  ostale  djelatnosti  pripreme  i usluživanja hrane",
            "code": "56.2",
            "parentId": 3162
        },
        {
            "id": 6655,
            "name": "Djelatnosti pripreme i usluživanja pica",
            "code": "56.3",
            "parentId": 3162
        },
        {
            "id": 6656,
            "name": "Izdavanje knjiga, periodicnih publikacija i ostale izdavacke djelatnosti",
            "code": "58.1",
            "parentId": 3163
        },
        {
            "id": 6657,
            "name": "Izdavanje softvera",
            "code": "58.2",
            "parentId": 3163
        },
        {
            "id": 6658,
            "name": "Proizvodnja i distribucija filmova, videofilmova i televizijskog programa",
            "code": "59.1",
            "parentId": 3164
        },
        {
            "id": 6659,
            "name": "Djelatnosti snimanja zvucnih zapisa i izdavanja muzickih zapisa",
            "code": "59.2",
            "parentId": 3164
        },
        {
            "id": 6660,
            "name": "Emitiranje radijskog programa",
            "code": "60.1",
            "parentId": 3165
        },
        {
            "id": 6661,
            "name": "Emitiranje televizijskog programa",
            "code": "60.2",
            "parentId": 3165
        },
        {
            "id": 6662,
            "name": "Djelatnosti žicane telekomunikacije",
            "code": "61.1",
            "parentId": 3166
        },
        {
            "id": 6663,
            "name": "Djelatnosti bežicne telekomunikacije",
            "code": "61.2",
            "parentId": 3166
        },
        {
            "id": 6664,
            "name": "Djelatnosti satelitske telekomunikacije",
            "code": "61.3",
            "parentId": 3166
        },
        {
            "id": 6665,
            "name": "Ostale telekomunikacijske djelatnosti",
            "code": "61.9",
            "parentId": 3166
        },
        {
            "id": 6666,
            "name": "Racunarsko programiranje, savjetovanje i djelatnosti u vezi s njima",
            "code": "62.0",
            "parentId": 3167
        },
        {
            "id": 6667,
            "name": "Obrada podataka, usluge hostinga i djelatnosti u vezi s njima; internetski portali",
            "code": "63.1",
            "parentId": 3168
        },
        {
            "id": 6668,
            "name": "Ostale informacijske uslužne djelatnosti",
            "code": "63.9",
            "parentId": 3168
        },
        {
            "id": 6669,
            "name": "Novcarsko posredovanje",
            "code": "64.1",
            "parentId": 3169
        },
        {
            "id": 6670,
            "name": "Djelatnosti finansijskih holding-društava",
            "code": "64.2",
            "parentId": 3169
        },
        {
            "id": 6671,
            "name": "Trustovi, ostali fondovi i slicni finansijski subjekti",
            "code": "64.3",
            "parentId": 3169
        },
        {
            "id": 6672,
            "name": "Ostale  finansijske uslužne  djelatnosti,  osim  osiguranja  i penzijskih fondova",
            "code": "64.9",
            "parentId": 3169
        },
        {
            "id": 6673,
            "name": "Osiguranje",
            "code": "65.1",
            "parentId": 3170
        },
        {
            "id": 6674,
            "name": "Reosiguranje",
            "code": "65.2",
            "parentId": 3170
        },
        {
            "id": 6675,
            "name": "Penzijski fondovi",
            "code": "65.3",
            "parentId": 3170
        },
        {
            "id": 6676,
            "name": "Pomocne djelatnosti kod finansijskih usluga, osim osiguranja i penzijskih fondova",
            "code": "66.1",
            "parentId": 3171
        },
        {
            "id": 6677,
            "name": "Pomocne djelatnosti u osiguranju i penzijskim fondovima",
            "code": "66.2",
            "parentId": 3171
        },
        {
            "id": 6678,
            "name": "Djelatnosti upravljanja fondovima",
            "code": "66.3",
            "parentId": 3171
        },
        {
            "id": 6679,
            "name": "Kupovina i prodaja vlastitih nekretnina",
            "code": "68.1",
            "parentId": 3172
        },
        {
            "id": 6680,
            "name": "Iznajmljivanje  i  upravljanje  vlastitim  nekretninama  ili nekretninama uzetim u zakup (leasing)",
            "code": "68.2",
            "parentId": 3172
        },
        {
            "id": 6681,
            "name": "Poslovanje nekretninama uz naknadu ili na osnovu ugovora",
            "code": "68.3",
            "parentId": 3172
        },
        {
            "id": 6682,
            "name": "Pravne djelatnosti",
            "code": "69.1",
            "parentId": 3173
        },
        {
            "id": 6683,
            "name": "Racunovodstvene,  knjigovodstvene i  revizijske  djelatnosti; porezno savjetovanje",
            "code": "69.2",
            "parentId": 3173
        },
        {
            "id": 6684,
            "name": "Upravljacke djelatnosti",
            "code": "70.1",
            "parentId": 3174
        },
        {
            "id": 6685,
            "name": "Savjetovanje u vezi s upravljanjem",
            "code": "70.2",
            "parentId": 3174
        },
        {
            "id": 6686,
            "name": "Arhitektonske i inžinjerske djelatnosti i s njima povezano tehnicko savjetovanje",
            "code": "71.1",
            "parentId": 3175
        },
        {
            "id": 6687,
            "name": "Tehnicko ispitivanje i analiza",
            "code": "71.2",
            "parentId": 3175
        },
        {
            "id": 6688,
            "name": "Istraživanje i eksperimentalni razvoj u prirodnim, tehnickim i tehnološkim naukama",
            "code": "72.1",
            "parentId": 3176
        },
        {
            "id": 6689,
            "name": "Istraživanje  i  eksperimentalni  razvoj  u  društvenim  i humanistickim naukama",
            "code": "72.2",
            "parentId": 3176
        },
        {
            "id": 6690,
            "name": "Promocija (reklama i propaganda)",
            "code": "73.1",
            "parentId": 3177
        },
        {
            "id": 6691,
            "name": "Istraživanje tržišta i ispitivanje javnog mnijenja",
            "code": "73.2",
            "parentId": 3177
        },
        {
            "id": 6692,
            "name": "Specijalizirane dizajnerske djelatnosti",
            "code": "74.1",
            "parentId": 3178
        },
        {
            "id": 6693,
            "name": "Fotografske djelatnosti",
            "code": "74.2",
            "parentId": 3178
        },
        {
            "id": 6694,
            "name": "Prevodilacke djelatnosti i usluge tumaca",
            "code": "74.3",
            "parentId": 3178
        },
        {
            "id": 6695,
            "name": "Ostale strucne, naucne i tehnicke djelatnosti, d. n.",
            "code": "74.9",
            "parentId": 3178
        },
        {
            "id": 6696,
            "name": "Veterinarske djelatnosti",
            "code": "75.0",
            "parentId": 3179
        },
        {
            "id": 6697,
            "name": "Iznajmljivanje i davanje u zakup (leasing) motornih vozila",
            "code": "77.1",
            "parentId": 3092
        },
        {
            "id": 6698,
            "name": "Iznajmljivanje i davanje u zakup (leasing) predmeta za licnu upotrebu i domacinstvo",
            "code": "77.2",
            "parentId": 3092
        },
        {
            "id": 6699,
            "name": "Iznajmljivanje i davanje u zakup (leasing) ostalih mašina, opreme te materijalnih dobara",
            "code": "77.3",
            "parentId": 3092
        },
        {
            "id": 6700,
            "name": "Davanje u zakup (leasing) prava na upotrebu intelektualnog vlasništva i slicnih proizvoda, osim radova koji su zašticeni autorskim pravima",
            "code": "77.4",
            "parentId": 3092
        },
        {
            "id": 6701,
            "name": "Djelatnosti agencija za zapošljavanje",
            "code": "78.1",
            "parentId": 3093
        },
        {
            "id": 6702,
            "name": "Djelatnosti agencija za privremeno zapošljavanje",
            "code": "78.2",
            "parentId": 3093
        },
        {
            "id": 6703,
            "name": "Ostalo ustupanje ljudskih resursa",
            "code": "78.3",
            "parentId": 3093
        },
        {
            "id": 6704,
            "name": "Djelatnosti putnickih agencija i turoperatora",
            "code": "79.1",
            "parentId": 3094
        },
        {
            "id": 6705,
            "name": "Ostale rezervacijske usluge i djelatnosti u vezi s njima",
            "code": "79.9",
            "parentId": 3094
        },
        {
            "id": 6706,
            "name": "Djelatnosti privatne zaštite",
            "code": "80.1",
            "parentId": 3095
        },
        {
            "id": 6707,
            "name": "Usluge zaštite uz pomoc sigurnosnih sistema",
            "code": "80.2",
            "parentId": 3095
        },
        {
            "id": 6708,
            "name": "Istražne djelatnosti",
            "code": "80.3",
            "parentId": 3095
        },
        {
            "id": 6709,
            "name": "Pomocne djelatnosti upravljanja zgradama",
            "code": "81.1",
            "parentId": 3096
        },
        {
            "id": 6710,
            "name": "Djelatnosti cišcenja",
            "code": "81.2",
            "parentId": 3096
        },
        {
            "id": 6711,
            "name": "Uslužne djelatnosti uredenja i održavanja zelenih površina",
            "code": "81.3",
            "parentId": 3096
        },
        {
            "id": 6712,
            "name": "Kancelarijske administrativne i pomocne djelatnosti",
            "code": "82.1",
            "parentId": 3097
        },
        {
            "id": 6713,
            "name": "Djelatnosti pozivnih centara",
            "code": "82.2",
            "parentId": 3097
        },
        {
            "id": 6714,
            "name": "Organizacija sastanaka i poslovnih sajmova",
            "code": "82.3",
            "parentId": 3097
        },
        {
            "id": 6715,
            "name": "Poslovne pomocne uslužne djelatnosti, d. n.",
            "code": "82.9",
            "parentId": 3097
        },
        {
            "id": 6716,
            "name": "Javna uprava te ekonomska i socijalna politika zajednice",
            "code": "84.1",
            "parentId": 3098
        },
        {
            "id": 6717,
            "name": "Pružanje usluga zajednici kao cjelini",
            "code": "84.2",
            "parentId": 3098
        },
        {
            "id": 6718,
            "name": "Djelatnosti obaveznog socijalnog osiguranja",
            "code": "84.3",
            "parentId": 3098
        },
        {
            "id": 6719,
            "name": "Predškolsko obrazovanje",
            "code": "85.1",
            "parentId": 3099
        },
        {
            "id": 6720,
            "name": "Osnovno obrazovanje",
            "code": "85.2",
            "parentId": 3099
        },
        {
            "id": 6721,
            "name": "Srednje obrazovanje",
            "code": "85.3",
            "parentId": 3099
        },
        {
            "id": 6722,
            "name": "Visoko obrazovanje",
            "code": "85.4",
            "parentId": 3099
        },
        {
            "id": 6723,
            "name": "Ostalo obrazovanje i poucavanje",
            "code": "85.5",
            "parentId": 3099
        },
        {
            "id": 6724,
            "name": "Pomocne uslužne djelatnosti u obrazovanju",
            "code": "85.6",
            "parentId": 3099
        },
        {
            "id": 6725,
            "name": "Djelatnosti bolnica",
            "code": "86.1",
            "parentId": 3100
        },
        {
            "id": 6726,
            "name": "Djelatnosti medicinske i stomatološke prakse",
            "code": "86.2",
            "parentId": 3100
        },
        {
            "id": 6727,
            "name": "Ostale djelatnosti zdravstvene zaštite",
            "code": "86.9",
            "parentId": 3100
        },
        {
            "id": 6728,
            "name": "Djelatnosti ustanova sa smještajem koje ukljucuju odredeni stepen zdravstvene njege",
            "code": "87.1",
            "parentId": 3101
        },
        {
            "id": 6729,
            "name": "Djelatnosti socijalne zaštite u ustanovama sa smještajem za starije osobe i osobe s invaliditetom bez  ili s minimalnom njegom",
            "code": "87.3",
            "parentId": 3101
        },
        {
            "id": 6730,
            "name": "Ostale djelatnosti socijalne zaštite u ustanovama sa smještajem",
            "code": "87.9",
            "parentId": 3101
        },
        {
            "id": 6731,
            "name": "Djelatnosti socijalne zaštite bez smještaja za starije osobe i osobe s invaliditetom",
            "code": "88.1",
            "parentId": 3102
        },
        {
            "id": 6732,
            "name": "Ostale djelatnosti socijalne zaštite bez smještaja",
            "code": "88.9",
            "parentId": 3102
        },
        {
            "id": 6733,
            "name": "Kreativne, umjetnicke i zabavne djelatnosti",
            "code": "90.0",
            "parentId": 3103
        },
        {
            "id": 6734,
            "name": "Biblioteke, arhivi, muzeji i ostale kulturne djelatnosti",
            "code": "91.0",
            "parentId": 3104
        },
        {
            "id": 6735,
            "name": "Djelatnosti  u  ustanovama  sa  smještajem  za  osobe  s teškocama  u  razvoju,  duševno  bolesne  i  osobe  ovisne  o alkoholu,  drogama  ili  drugim  opojnim sredstvima s ukljucenim odredenim stepenom zdravstvene njege",
            "code": "87.2",
            "parentId": 3101
        },
        {
            "id": 6736,
            "name": "Djelatnosti kockanja i kladenja",
            "code": "92.0",
            "parentId": 3105
        },
        {
            "id": 6737,
            "name": "Sportske djelatnosti",
            "code": "93.1",
            "parentId": 3106
        },
        {
            "id": 6738,
            "name": "Zabavne i rekreacijske djelatnosti",
            "code": "93.2",
            "parentId": 3106
        },
        {
            "id": 6739,
            "name": "Djelatnosti poslovnih organizacija, udruženja poslodavaca i strukovnih clanskih organizacija",
            "code": "94.1",
            "parentId": 3107
        },
        {
            "id": 6740,
            "name": "Djelatnosti sindikata",
            "code": "94.2",
            "parentId": 3107
        },
        {
            "id": 6741,
            "name": "Djelatnosti ostalih clanskih organizacija",
            "code": "94.9",
            "parentId": 3107
        },
        {
            "id": 6742,
            "name": "Popravak racunara i komunikacijske opreme",
            "code": "95.1",
            "parentId": 3108
        },
        {
            "id": 6743,
            "name": "Popravak predmeta za licnu upotrebu i domacinstvo",
            "code": "95.2",
            "parentId": 3108
        },
        {
            "id": 6744,
            "name": "Ostale licne uslužne djelatnosti",
            "code": "96.0",
            "parentId": 3109
        },
        {
            "id": 6745,
            "name": "Djelatnosti domacinstava kao poslodavaca koji zapošljavaju poslugu",
            "code": "97.0",
            "parentId": 3110
        },
        {
            "id": 6746,
            "name": "Djelatnosti privatnih domacinstava koja  proizvode razlicita dobra za vlastite potrebe",
            "code": "98.1",
            "parentId": 3111
        },
        {
            "id": 6747,
            "name": "Djelatnosti privatnih domacinstava koja obavljaju razlicite usluge za vlastite potrebe",
            "code": "98.2",
            "parentId": 3111
        },
        {
            "id": 6748,
            "name": "Djelatnosti vanteritorijalnih organizacija i organa",
            "code": "99.0",
            "parentId": 3112
        },
        {
            "id": 6749,
            "name": "Obrazovanje",
            "code": "85.0",
            "parentId": 3099
        },
        {
            "id": 6750,
            "name": "Djelatnosti socijalne zaštite bez smještaja",
            "code": "88.0",
            "parentId": 3102
        },
        {
            "id": 6751,
            "name": "Uzgoj žitarica (osim riže), mahunarki i sjemenja uljarica",
            "code": "01.11",
            "parentId": 6477
        },
        {
            "id": 6752,
            "name": "Uzgoj riže",
            "code": "01.12",
            "parentId": 6477
        },
        {
            "id": 6753,
            "name": "Uzgoj povrća, dinja i lubenica, korjenastog i gomoljastog povrća",
            "code": "01.13",
            "parentId": 6477
        },
        {
            "id": 6754,
            "name": "Uzgoj šećerne trske",
            "code": "01.14",
            "parentId": 6477
        },
        {
            "id": 6755,
            "name": "Uzgoj duhana",
            "code": "01.15",
            "parentId": 6477
        },
        {
            "id": 6756,
            "name": "Uzgoj tekstilnih biljaka",
            "code": "01.16",
            "parentId": 6477
        },
        {
            "id": 6757,
            "name": "Uzgoj ostalih jednogodišnjih usjeva",
            "code": "01.19",
            "parentId": 6477
        },
        {
            "id": 6758,
            "name": "Uzgoj grožđa",
            "code": "01.21",
            "parentId": 6478
        },
        {
            "id": 6759,
            "name": "Uzgoj tropskog i suptropskog voća",
            "code": "01.22",
            "parentId": 6478
        },
        {
            "id": 6760,
            "name": "Uzgoj agruma",
            "code": "01.23",
            "parentId": 6478
        },
        {
            "id": 6761,
            "name": "Uzgoj jezgričavog i koštuničavog voća",
            "code": "01.24",
            "parentId": 6478
        },
        {
            "id": 6762,
            "name": "Uzgoj bobičastog, orašastog i ostalog voća",
            "code": "01.25",
            "parentId": 6478
        },
        {
            "id": 6763,
            "name": "Uzgoj plodova uljarica",
            "code": "01.26",
            "parentId": 6478
        },
        {
            "id": 6764,
            "name": "Uzgoj biljaka za pripremanje napitaka",
            "code": "01.27",
            "parentId": 6478
        },
        {
            "id": 6765,
            "name": "Uzgoj bilja za uporabu u farmaciji, aromatskog, začinskog i ljekovitog bilja",
            "code": "01.28",
            "parentId": 6478
        },
        {
            "id": 6766,
            "name": "Uzgoj ostalih višegodišnjih usjeva",
            "code": "01.29",
            "parentId": 6478
        },
        {
            "id": 6767,
            "name": "Uzgoj sadnog materijala i ukrasnog bilja",
            "code": "01.30",
            "parentId": 6479
        },
        {
            "id": 6768,
            "name": "Uzgoj muznih krava",
            "code": "01.41",
            "parentId": 6480
        },
        {
            "id": 6769,
            "name": "Uzgoj ostalih goveda i bivola",
            "code": "01.42",
            "parentId": 6480
        },
        {
            "id": 6770,
            "name": "Uzgoj konja, magaraca, mula i mazgi",
            "code": "01.43",
            "parentId": 6480
        },
        {
            "id": 6771,
            "name": "Uzgoj deva i lama",
            "code": "01.44",
            "parentId": 6480
        },
        {
            "id": 6772,
            "name": "Uzgoj ovaca i koza",
            "code": "01.45",
            "parentId": 6480
        },
        {
            "id": 6773,
            "name": "Uzgoj svinja",
            "code": "01.46",
            "parentId": 6480
        },
        {
            "id": 6774,
            "name": "Uzgoj peradi",
            "code": "01.47",
            "parentId": 6480
        },
        {
            "id": 6775,
            "name": "Uzgoj ostalih životinja",
            "code": "01.49",
            "parentId": 6480
        },
        {
            "id": 6776,
            "name": "Mješovita poljoprivredna proizvodnja (biljna i stočna proizvodnja)",
            "code": "01.50",
            "parentId": 6481
        },
        {
            "id": 6777,
            "name": "Pomoćne djelatnosti za uzgoj usjeva",
            "code": "01.61",
            "parentId": 6482
        },
        {
            "id": 6778,
            "name": "Pomoćne djelatnosti za uzgoj životinja",
            "code": "01.62",
            "parentId": 6482
        },
        {
            "id": 6779,
            "name": "Djelatnosti koje se obavljaju nakon žetve/berbe poljoprivrednih proizvoda (priprema za primarna tržišta)",
            "code": "01.63",
            "parentId": 6482
        },
        {
            "id": 6780,
            "name": "Dorada sjemena za sjemenski materijal",
            "code": "01.64",
            "parentId": 6482
        },
        {
            "id": 6781,
            "name": "Lov, stupičarenje i uslužne djelatnosti povezane s njima",
            "code": "01.70",
            "parentId": 6483
        },
        {
            "id": 6782,
            "name": "Uzgoj šuma i ostale djelatnosti u šumarstvu",
            "code": "02.10",
            "parentId": 6484
        },
        {
            "id": 6783,
            "name": "Sječa drva (iskorištavanje šuma)",
            "code": "02.20",
            "parentId": 6485
        },
        {
            "id": 6784,
            "name": "Skupljanje nekultiviranih šumskih plodova i proizvoda, osim šumskih sortimenata",
            "code": "02.30",
            "parentId": 6486
        },
        {
            "id": 6785,
            "name": "Pomoćne usluge u šumarstvu",
            "code": "02.40",
            "parentId": 6487
        },
        {
            "id": 6786,
            "name": "Morski ribolov",
            "code": "03.11",
            "parentId": 6488
        },
        {
            "id": 6787,
            "name": "Slatkovodni ribolov",
            "code": "03.12",
            "parentId": 6488
        },
        {
            "id": 6788,
            "name": "Morska akvakultura",
            "code": "03.21",
            "parentId": 6489
        },
        {
            "id": 6789,
            "name": "Slatkovodna akvakultura",
            "code": "03.22",
            "parentId": 6489
        },
        {
            "id": 6790,
            "name": "Vađenje kamenog ugljena",
            "code": "05.10",
            "parentId": 6490
        },
        {
            "id": 6791,
            "name": "Vađenje lignita",
            "code": "05.20",
            "parentId": 6491
        },
        {
            "id": 6792,
            "name": "Vađenje sirove nafte",
            "code": "06.10",
            "parentId": 6492
        },
        {
            "id": 6793,
            "name": "Vađenje prirodnog plina",
            "code": "06.20",
            "parentId": 6493
        },
        {
            "id": 6794,
            "name": "Vađenje željeznih ruda",
            "code": "07.10",
            "parentId": 6494
        },
        {
            "id": 6795,
            "name": "Vađenje uranovih i torijevih ruda",
            "code": "07.21",
            "parentId": 6495
        },
        {
            "id": 6796,
            "name": "Vađenje ostalih ruda obojenih metala",
            "code": "07.29",
            "parentId": 6495
        },
        {
            "id": 6797,
            "name": "Vađenje ukrasnoga kamena i kamena za gradnju, vapnenca, gipsa, krede i škriljevca",
            "code": "08.11",
            "parentId": 6496
        },
        {
            "id": 6798,
            "name": "Djelatnosti kopova šljunka i pijeska; vađenje gline i kaolina",
            "code": "08.12",
            "parentId": 6496
        },
        {
            "id": 6799,
            "name": "Vađenje minerala za proizvodnju kemikalije i prirodnih mineralnih gnojiva",
            "code": "08.91",
            "parentId": 6497
        },
        {
            "id": 6800,
            "name": "Vađenje treseta",
            "code": "08.92",
            "parentId": 6497
        },
        {
            "id": 6801,
            "name": "Vađenje soli",
            "code": "08.93",
            "parentId": 6497
        },
        {
            "id": 6802,
            "name": "Vađenje ostalih ruda i kamena, d. n.",
            "code": "08.99",
            "parentId": 6497
        },
        {
            "id": 6803,
            "name": "Pomoćne djelatnosti za vađenje nafte i prirodnog plina",
            "code": "09.10",
            "parentId": 6498
        },
        {
            "id": 6804,
            "name": "Pomoćne djelatnosti za ostalo vađenje ruda i kamena",
            "code": "09.90",
            "parentId": 6499
        },
        {
            "id": 6805,
            "name": "Prerada i konzerviranje mesa",
            "code": "10.11",
            "parentId": 6500
        },
        {
            "id": 6806,
            "name": "Prerada i konzerviranje mesa peradi",
            "code": "10.12",
            "parentId": 6500
        },
        {
            "id": 6807,
            "name": "Proizvodnja proizvoda od mesa i mesa peradi",
            "code": "10.13",
            "parentId": 6500
        },
        {
            "id": 6808,
            "name": "Prerada i konzerviranje riba, ljuskara i mekušaca",
            "code": "10.20",
            "parentId": 6501
        },
        {
            "id": 6809,
            "name": "Prerada i konzerviranje krompira",
            "code": "10.31",
            "parentId": 6502
        },
        {
            "id": 6810,
            "name": "Proizvodnja sokova od voća i povrća",
            "code": "10.32",
            "parentId": 6502
        },
        {
            "id": 6811,
            "name": "Ostala prerada i konzerviranje voća i povrća",
            "code": "10.39",
            "parentId": 6502
        },
        {
            "id": 6812,
            "name": "Proizvodnja ulja i masti",
            "code": "10.41",
            "parentId": 6503
        },
        {
            "id": 6813,
            "name": "Proizvodnja margarina i sličnih jestivih masti",
            "code": "10.42",
            "parentId": 6503
        },
        {
            "id": 6814,
            "name": "Proizvodnja mlijeka, mliječnih proizvoda i sira",
            "code": "10.51",
            "parentId": 6504
        },
        {
            "id": 6815,
            "name": "Proizvodnja sladoleda i drugih smrznutih smjesa",
            "code": "10.52",
            "parentId": 6504
        },
        {
            "id": 6816,
            "name": "Proizvodnja mlinskih proizvoda",
            "code": "10.61",
            "parentId": 6505
        },
        {
            "id": 6817,
            "name": "Proizvodnja škroba i škrobnih proizvoda",
            "code": "10.62",
            "parentId": 6505
        },
        {
            "id": 6818,
            "name": "Proizvodnja kruha; svježih peciva i kolača",
            "code": "10.71",
            "parentId": 6506
        },
        {
            "id": 6819,
            "name": "Proizvodnja dvopeka i keksa; proizvodnja trajnih peciva i kolača",
            "code": "10.72",
            "parentId": 6506
        },
        {
            "id": 6820,
            "name": "Proizvodnja makarona, rezanaca, kuskusa i sličnih proizvoda odbrašna",
            "code": "10.73",
            "parentId": 6506
        },
        {
            "id": 6821,
            "name": "Proizvodnja šećera",
            "code": "10.81",
            "parentId": 6507
        },
        {
            "id": 6822,
            "name": "Proizvodnja kakaa, čokolade i konditorskih proizvoda",
            "code": "10.82",
            "parentId": 6507
        },
        {
            "id": 6823,
            "name": "Prerada čaja i kave",
            "code": "10.83",
            "parentId": 6507
        },
        {
            "id": 6824,
            "name": "Proizvodnja začina i drugih dodataka hrani",
            "code": "10.84",
            "parentId": 6507
        },
        {
            "id": 6825,
            "name": "Proizvodnja gotove hrane i jela",
            "code": "10.85",
            "parentId": 6507
        },
        {
            "id": 6826,
            "name": "Proizvodnja homogeniziranih prehrambenih pripravaka i dijetetske hrane",
            "code": "10.86",
            "parentId": 6507
        },
        {
            "id": 6827,
            "name": "Proizvodnja ostalih prehrambenih proizvoda, d. n.",
            "code": "10.89",
            "parentId": 6507
        },
        {
            "id": 6828,
            "name": "Proizvodnja pripremljene stočne hrane",
            "code": "10.91",
            "parentId": 6508
        },
        {
            "id": 6829,
            "name": "Proizvodnja pripremljene hrane za kućne ljubimce",
            "code": "10.92",
            "parentId": 6508
        },
        {
            "id": 6830,
            "name": "Destiliranje, pročišćavanje i miješanje alkoholnih pića",
            "code": "11.01",
            "parentId": 6509
        },
        {
            "id": 6831,
            "name": "Proizvodnja vina od grožđa",
            "code": "11.02",
            "parentId": 6509
        },
        {
            "id": 6832,
            "name": "Proizvodnja jabukovače i ostalih voćnih vina",
            "code": "11.03",
            "parentId": 6509
        },
        {
            "id": 6833,
            "name": "Proizvodnja ostalih nedestiliranih fermentiranih pića",
            "code": "11.04",
            "parentId": 6509
        },
        {
            "id": 6834,
            "name": "Proizvodnja piva",
            "code": "11.05",
            "parentId": 6509
        },
        {
            "id": 6835,
            "name": "Proizvodnja slada",
            "code": "11.06",
            "parentId": 6509
        },
        {
            "id": 6836,
            "name": "Proizvodnja osvježavajućih pića; proizvodnja mineralne vode i drugih flaširanih voda",
            "code": "11.07",
            "parentId": 6509
        },
        {
            "id": 6837,
            "name": "Proizvodnja duhanskih proizvoda",
            "code": "12.00",
            "parentId": 6510
        },
        {
            "id": 6838,
            "name": "Priprema i predenje tekstilnih vlakana",
            "code": "13.10",
            "parentId": 6511
        },
        {
            "id": 6839,
            "name": "Tkanje tekstila",
            "code": "13.20",
            "parentId": 6512
        },
        {
            "id": 6840,
            "name": "Dovršavanje tekstila",
            "code": "13.30",
            "parentId": 6513
        },
        {
            "id": 6841,
            "name": "Proizvodnja pletenih i kukičanih tkanina",
            "code": "13.91",
            "parentId": 6514
        },
        {
            "id": 6842,
            "name": "Proizvodnja gotovih tekstilnih proizvoda, osim odjeće",
            "code": "13.92",
            "parentId": 6514
        },
        {
            "id": 6843,
            "name": "Proizvodnja tepiha i prekrivača za pod",
            "code": "13.93",
            "parentId": 6514
        },
        {
            "id": 6844,
            "name": "Proizvodnja užadi, konopaca, upletenoga konca i mreža",
            "code": "13.94",
            "parentId": 6514
        },
        {
            "id": 6845,
            "name": "Proizvodnja netkanog tekstila i proizvoda od netkanog tekstila, osim odjeće",
            "code": "13.95",
            "parentId": 6514
        },
        {
            "id": 6846,
            "name": "Proizvodnja ostalih tehničkih i industrijskih tekstilnih proizvoda",
            "code": "13.96",
            "parentId": 6514
        },
        {
            "id": 6847,
            "name": "Proizvodnja ostalih tekstilnih proizvoda, d. n.",
            "code": "13.99",
            "parentId": 6514
        },
        {
            "id": 6848,
            "name": "Proizvodnja kožne odjeće",
            "code": "14.11",
            "parentId": 6515
        },
        {
            "id": 6849,
            "name": "Proizvodnja radne odjeće",
            "code": "14.12",
            "parentId": 6515
        },
        {
            "id": 6850,
            "name": "Proizvodnja ostale vanjske odjeće",
            "code": "14.13",
            "parentId": 6515
        },
        {
            "id": 6851,
            "name": "Proizvodnja rublja",
            "code": "14.14",
            "parentId": 6515
        },
        {
            "id": 6852,
            "name": "Proizvodnja ostale odjeće i pribora za odjeću",
            "code": "14.19",
            "parentId": 6515
        },
        {
            "id": 6853,
            "name": "Proizvodnja proizvoda od krzna",
            "code": "14.20",
            "parentId": 6516
        },
        {
            "id": 6854,
            "name": "Proizvodnja pletenih i kukičanih čarapa",
            "code": "14.31",
            "parentId": 6517
        },
        {
            "id": 6855,
            "name": "Proizvodnja ostale pletene i kukičane odjeće",
            "code": "14.39",
            "parentId": 6517
        },
        {
            "id": 6856,
            "name": "Štavljenje i obrada kože; dorada i bojenje krzna",
            "code": "15.11",
            "parentId": 6518
        },
        {
            "id": 6857,
            "name": "Proizvodnja putnih i ručnih torba i sličnih proizvoda, sedlarskih i remenarskih proizvoda",
            "code": "15.12",
            "parentId": 6518
        },
        {
            "id": 6858,
            "name": "Proizvodnja obuće",
            "code": "15.20",
            "parentId": 6519
        },
        {
            "id": 6859,
            "name": "Piljenje i blanjanje drva (proizvodnja rezane građe); impregnacija drveta",
            "code": "16.10",
            "parentId": 6520
        },
        {
            "id": 6860,
            "name": "Proizvodnja furnira i ostalih ploča od drva",
            "code": "16.21",
            "parentId": 6521
        },
        {
            "id": 6861,
            "name": "Proizvodnja sastavljenog parketa",
            "code": "16.22",
            "parentId": 6521
        },
        {
            "id": 6862,
            "name": "Proizvodnja ostale građevne stolarije i elemenata",
            "code": "16.23",
            "parentId": 6521
        },
        {
            "id": 6863,
            "name": "Proizvodnja ambalaže od drva",
            "code": "16.24",
            "parentId": 6521
        },
        {
            "id": 6864,
            "name": "Proizvodnja ostalih proizvoda od drva, proizvoda od pluta, slame i pletarskih materijala",
            "code": "16.29",
            "parentId": 6521
        },
        {
            "id": 6865,
            "name": "Proizvodnja celuloze",
            "code": "17.11",
            "parentId": 6522
        },
        {
            "id": 6866,
            "name": "Proizvodnja papira i kartona",
            "code": "17.12",
            "parentId": 6522
        },
        {
            "id": 6867,
            "name": "Proizvodnja valovitog papira i kartona te ambalaže od papira i kartona",
            "code": "17.21",
            "parentId": 6523
        },
        {
            "id": 6868,
            "name": "Proizvodnja proizvoda za kućanstvo i higijenu te toaletnih potrepština od papira",
            "code": "17.22",
            "parentId": 6523
        },
        {
            "id": 6869,
            "name": "Proizvodnja uredskog materijala od papira",
            "code": "17.23",
            "parentId": 6523
        },
        {
            "id": 6870,
            "name": "Proizvodnja zidnih tapeta",
            "code": "17.24",
            "parentId": 6523
        },
        {
            "id": 6871,
            "name": "Proizvodnja ostalih proizvoda od papira i kartona",
            "code": "17.29",
            "parentId": 6523
        },
        {
            "id": 6872,
            "name": "Tiskanje novina",
            "code": "18.11",
            "parentId": 6524
        },
        {
            "id": 6873,
            "name": "Ostalo tiskanje",
            "code": "18.12",
            "parentId": 6524
        },
        {
            "id": 6874,
            "name": "Usluge pripreme za tisak i objavljivanje",
            "code": "18.13",
            "parentId": 6524
        },
        {
            "id": 6875,
            "name": "Knjigoveške i srodne usluge",
            "code": "18.14",
            "parentId": 6524
        },
        {
            "id": 6876,
            "name": "Umnožavanje snimljenih zapisa",
            "code": "18.20",
            "parentId": 6525
        },
        {
            "id": 6877,
            "name": "Proizvodnja proizvoda koksnih peći",
            "code": "19.10",
            "parentId": 6526
        },
        {
            "id": 6878,
            "name": "Proizvodnja rafiniranih naftnih proizvoda",
            "code": "19.20",
            "parentId": 6527
        },
        {
            "id": 6879,
            "name": "Proizvodnja industrijskih plinova",
            "code": "20.11",
            "parentId": 6528
        },
        {
            "id": 6880,
            "name": "Proizvodnja koloranata i pigmenata",
            "code": "20.12",
            "parentId": 6528
        },
        {
            "id": 6881,
            "name": "Proizvodnja ostalih anorganskih osnovnih kemikalija",
            "code": "20.13",
            "parentId": 6528
        },
        {
            "id": 6882,
            "name": "Proizvodnja ostalih osnovnih organskih kemikalija",
            "code": "20.14",
            "parentId": 6528
        },
        {
            "id": 6883,
            "name": "Proizvodnja gnojiva i dušičnih spojeva",
            "code": "20.15",
            "parentId": 6528
        },
        {
            "id": 6884,
            "name": "Proizvodnja plastičnih masa u primarnim oblicima",
            "code": "20.16",
            "parentId": 6528
        },
        {
            "id": 6885,
            "name": "Proizvodnja sintetičkoga kaučuka u primarnim oblicima",
            "code": "20.17",
            "parentId": 6528
        },
        {
            "id": 6886,
            "name": "Proizvodnja pesticida i drugih agrokemijskih proizvoda",
            "code": "20.20",
            "parentId": 6529
        },
        {
            "id": 6887,
            "name": "Proizvodnja boja, lakova i sličnih premaza, grafičkih boja i kitova",
            "code": "20.30",
            "parentId": 6530
        },
        {
            "id": 6888,
            "name": "Proizvodnja sapuna i deterdženata, sredstava za čišćenje i poliranje",
            "code": "20.41",
            "parentId": 6531
        },
        {
            "id": 6889,
            "name": "Proizvodnja parfema i toaletno-kozmetičkih preparata",
            "code": "20.42",
            "parentId": 6531
        },
        {
            "id": 6890,
            "name": "Proizvodnja eksploziva",
            "code": "20.51",
            "parentId": 6532
        },
        {
            "id": 6891,
            "name": "Proizvodnja ljepila",
            "code": "20.52",
            "parentId": 6532
        },
        {
            "id": 6892,
            "name": "Proizvodnja eteričnih ulja",
            "code": "20.53",
            "parentId": 6532
        },
        {
            "id": 6893,
            "name": "Proizvodnja ostalih kemijskih proizvoda, d. n.",
            "code": "20.59",
            "parentId": 6532
        },
        {
            "id": 6894,
            "name": "Proizvodnja umjetnih vlakana",
            "code": "20.60",
            "parentId": 6533
        },
        {
            "id": 6895,
            "name": "Proizvodnja osnovnih farmaceutskih proizvoda",
            "code": "21.10",
            "parentId": 6534
        },
        {
            "id": 6896,
            "name": "Proizvodnja farmaceutskih pripravaka",
            "code": "21.20",
            "parentId": 6535
        },
        {
            "id": 6897,
            "name": "Proizvodnja vanjskih i unutrašnjih guma za vozila; protektiranje vanjskih guma za vozila",
            "code": "22.11",
            "parentId": 6536
        },
        {
            "id": 6898,
            "name": "Proizvodnja ostalih proizvoda od gume",
            "code": "22.19",
            "parentId": 6536
        },
        {
            "id": 6899,
            "name": "Proizvodnja ploča, listova, cijevi i profila od plastičnih masa",
            "code": "22.21",
            "parentId": 6537
        },
        {
            "id": 6900,
            "name": "Proizvodnja ambalaže od plastičnih masa",
            "code": "22.22",
            "parentId": 6537
        },
        {
            "id": 6901,
            "name": "Proizvodnja proizvoda od plastičnih masa za građevinarstvo",
            "code": "22.23",
            "parentId": 6537
        },
        {
            "id": 6902,
            "name": "Proizvodnja ostalih proizvoda od plastičnih masa",
            "code": "22.29",
            "parentId": 6537
        },
        {
            "id": 6903,
            "name": "Proizvodnja ravnog stakla",
            "code": "23.11",
            "parentId": 6538
        },
        {
            "id": 6904,
            "name": "Oblikovanje i obrada ravnog stakla",
            "code": "23.12",
            "parentId": 6538
        },
        {
            "id": 6905,
            "name": "Proizvodnja šupljeg stakla",
            "code": "23.13",
            "parentId": 6538
        },
        {
            "id": 6906,
            "name": "Proizvodnja staklenih vlakana",
            "code": "23.14",
            "parentId": 6538
        },
        {
            "id": 6907,
            "name": "Proizvodnja i obrada ostalog stakla uključujući tehničke proizvode od stakla",
            "code": "23.19",
            "parentId": 6538
        },
        {
            "id": 6908,
            "name": "Proizvodnja vatrostalnih proizvoda",
            "code": "23.20",
            "parentId": 6539
        },
        {
            "id": 6909,
            "name": "Proizvodnja keramičkih pločica i podnih ploča",
            "code": "23.31",
            "parentId": 6540
        },
        {
            "id": 6910,
            "name": "Proizvodnja opeke, crijepa i ostalih proizvoda od pečene gline za građevinarstvo",
            "code": "23.32",
            "parentId": 6540
        },
        {
            "id": 6911,
            "name": "Proizvodnja keramičkih proizvoda za kućanstvo i ukrasnih predmeta",
            "code": "23.41",
            "parentId": 6541
        },
        {
            "id": 6912,
            "name": "Proizvodnja sanitarne opreme od keramike",
            "code": "23.42",
            "parentId": 6541
        },
        {
            "id": 6913,
            "name": "Proizvodnja keramičkih izolatora i izolacijskog pribora",
            "code": "23.43",
            "parentId": 6541
        },
        {
            "id": 6914,
            "name": "Proizvodnja ostalih tehničkih proizvoda od keramike",
            "code": "23.44",
            "parentId": 6541
        },
        {
            "id": 6915,
            "name": "Proizvodnja ostalih proizvoda od keramike",
            "code": "23.49",
            "parentId": 6541
        },
        {
            "id": 6916,
            "name": "Proizvodnja cementa",
            "code": "23.51",
            "parentId": 6542
        },
        {
            "id": 6917,
            "name": "Proizvodnja vapna i gipsa",
            "code": "23.52",
            "parentId": 6542
        },
        {
            "id": 6918,
            "name": "Proizvodnja proizvoda od betona za građevinarstvo",
            "code": "23.61",
            "parentId": 6543
        },
        {
            "id": 6919,
            "name": "Proizvodnja proizvoda od gipsa za građevinarstvo",
            "code": "23.62",
            "parentId": 6543
        },
        {
            "id": 6920,
            "name": "Proizvodnja gotove betonske smjese",
            "code": "23.63",
            "parentId": 6543
        },
        {
            "id": 6921,
            "name": "Proizvodnja žbuke",
            "code": "23.64",
            "parentId": 6543
        },
        {
            "id": 6922,
            "name": "Proizvodnja (vlaknastog) fibro-cementa",
            "code": "23.65",
            "parentId": 6543
        },
        {
            "id": 6923,
            "name": "Proizvodnja ostalih proizvoda od betona, cementa i gipsa",
            "code": "23.69",
            "parentId": 6543
        },
        {
            "id": 6924,
            "name": "Rezanje, oblikovanje i obrada kamena",
            "code": "23.70",
            "parentId": 6544
        },
        {
            "id": 6925,
            "name": "Proizvodnja brusnih proizvoda",
            "code": "23.91",
            "parentId": 6545
        },
        {
            "id": 6926,
            "name": "Proizvodnja ostalih nemetalnih mineralnih proizvoda, d. n.",
            "code": "23.99",
            "parentId": 6545
        },
        {
            "id": 6927,
            "name": "Proizvodnja sirovog željeza, čelika i ferolegura",
            "code": "24.10",
            "parentId": 6546
        },
        {
            "id": 6928,
            "name": "Proizvodnja cijevi, crijeva, šupljih profila i pripadajućeg pribora od čelika",
            "code": "24.20",
            "parentId": 6547
        },
        {
            "id": 6929,
            "name": "Hladno vučenje šipki",
            "code": "24.31",
            "parentId": 6548
        },
        {
            "id": 6930,
            "name": "Hladno valjanje uskih vrpci",
            "code": "24.32",
            "parentId": 6548
        },
        {
            "id": 6931,
            "name": "Hladno oblikovanje i profiliranje",
            "code": "24.33",
            "parentId": 6548
        },
        {
            "id": 6932,
            "name": "Hladno vučenje žice",
            "code": "24.34",
            "parentId": 6548
        },
        {
            "id": 6933,
            "name": "Proizvodnja plemenitih metala",
            "code": "24.41",
            "parentId": 6549
        },
        {
            "id": 6934,
            "name": "Proizvodnja aluminija",
            "code": "24.42",
            "parentId": 6549
        },
        {
            "id": 6935,
            "name": "Proizvodnja olova, cinka i kositra",
            "code": "24.43",
            "parentId": 6549
        },
        {
            "id": 6936,
            "name": "Proizvodnja bakra",
            "code": "24.44",
            "parentId": 6549
        },
        {
            "id": 6937,
            "name": "Proizvodnja ostalih obojenih metala",
            "code": "24.45",
            "parentId": 6549
        },
        {
            "id": 6938,
            "name": "Obrada nuklearnoga goriva",
            "code": "24.46",
            "parentId": 6549
        },
        {
            "id": 6939,
            "name": "Lijevanje željeza",
            "code": "24.51",
            "parentId": 6550
        },
        {
            "id": 6940,
            "name": "Lijevanje čelika",
            "code": "24.52",
            "parentId": 6550
        },
        {
            "id": 6941,
            "name": "Lijevanje lakih metala",
            "code": "24.53",
            "parentId": 6550
        },
        {
            "id": 6942,
            "name": "Lijevanje ostalih obojenih metala",
            "code": "24.54",
            "parentId": 6550
        },
        {
            "id": 6943,
            "name": "Proizvodnja metalnih konstrukcija i njihovih dijelova",
            "code": "25.11",
            "parentId": 6551
        },
        {
            "id": 6944,
            "name": "Proizvodnja vrata i prozora od metala",
            "code": "25.12",
            "parentId": 6551
        },
        {
            "id": 6945,
            "name": "Proizvodnja radijatora i kotlova za centralno grijanje",
            "code": "25.21",
            "parentId": 6552
        },
        {
            "id": 6946,
            "name": "Proizvodnja ostalih metalnih cisterni, rezervoara i sličnih posuda",
            "code": "25.29",
            "parentId": 6552
        },
        {
            "id": 6947,
            "name": "Proizvodnja parnih kotlova, osim kotlova za centralno grijanje",
            "code": "25.30",
            "parentId": 6553
        },
        {
            "id": 6948,
            "name": "Proizvodnja oružja i streljiva",
            "code": "25.40",
            "parentId": 6554
        },
        {
            "id": 6949,
            "name": "Kovanje, prešanje, štancanje i valjanje metala; metalurgija praha",
            "code": "25.50",
            "parentId": 6555
        },
        {
            "id": 6950,
            "name": "Površinska obrada i prevlačenje metala",
            "code": "25.61",
            "parentId": 6556
        },
        {
            "id": 6951,
            "name": "Strojna obrada metala",
            "code": "25.62",
            "parentId": 6556
        },
        {
            "id": 6952,
            "name": "Proizvodnja sječiva",
            "code": "25.71",
            "parentId": 6557
        },
        {
            "id": 6953,
            "name": "Proizvodnja brava i okova",
            "code": "25.72",
            "parentId": 6557
        },
        {
            "id": 6954,
            "name": "Proizvodnja alata",
            "code": "25.73",
            "parentId": 6557
        },
        {
            "id": 6955,
            "name": "Proizvodnja čeličnih buradi i sličnih posuda od čelika",
            "code": "25.91",
            "parentId": 6558
        },
        {
            "id": 6956,
            "name": "Proizvodnja ambalaže od lakih metala",
            "code": "25.92",
            "parentId": 6558
        },
        {
            "id": 6957,
            "name": "Proizvodnja proizvoda od žice, lanaca i opruga",
            "code": "25.93",
            "parentId": 6558
        },
        {
            "id": 6958,
            "name": "Proizvodnja veznih i vijčanih proizvoda",
            "code": "25.94",
            "parentId": 6558
        },
        {
            "id": 6959,
            "name": "Proizvodnja ostalih gotovih proizvoda od metala, d. n.",
            "code": "25.99",
            "parentId": 6558
        },
        {
            "id": 6960,
            "name": "Proizvodnja elektroničkih komponenata",
            "code": "26.11",
            "parentId": 6559
        },
        {
            "id": 6961,
            "name": "Proizvodnja punih elektroničkih ploča",
            "code": "26.12",
            "parentId": 6559
        },
        {
            "id": 6962,
            "name": "Proizvodnja računala i periferne opreme",
            "code": "26.20",
            "parentId": 6560
        },
        {
            "id": 6963,
            "name": "Proizvodnja komunikacijske opreme",
            "code": "26.30",
            "parentId": 6561
        },
        {
            "id": 6964,
            "name": "Proizvodnja elektroničkih uređaja za široku potrošnju",
            "code": "26.40",
            "parentId": 6562
        },
        {
            "id": 6965,
            "name": "Proizvodnja instrumenata i aparata za mjerenje, ispitivanje i navigaciju",
            "code": "26.51",
            "parentId": 6563
        },
        {
            "id": 6966,
            "name": "Proizvodnja satova",
            "code": "26.52",
            "parentId": 6563
        },
        {
            "id": 6967,
            "name": "Proizvodnja opreme za zračenje, elektromedicinske i elektroterapeutske opreme",
            "code": "26.60",
            "parentId": 6564
        },
        {
            "id": 6968,
            "name": "Proizvodnja optičkih instrumenata i fotografske opreme",
            "code": "26.70",
            "parentId": 6565
        },
        {
            "id": 6969,
            "name": "Proizvodnja magnetskih i optičkih medija",
            "code": "26.80",
            "parentId": 6566
        },
        {
            "id": 6970,
            "name": "Proizvodnja elektromotora, generatora i transformatora",
            "code": "27.11",
            "parentId": 6567
        },
        {
            "id": 6971,
            "name": "Proizvodnja uređaja za distribuciju i kontrolu električne energije",
            "code": "27.12",
            "parentId": 6567
        },
        {
            "id": 6972,
            "name": "Proizvodnja baterija i akumulatora",
            "code": "27.20",
            "parentId": 6568
        },
        {
            "id": 6973,
            "name": "Proizvodnja kablova od optičkih vlakana",
            "code": "27.31",
            "parentId": 6569
        },
        {
            "id": 6974,
            "name": "Proizvodnja ostalih elektroničkih i električnih žica i kablova",
            "code": "27.32",
            "parentId": 6569
        },
        {
            "id": 6975,
            "name": "Proizvodnja elektroinstalacijskog materijala",
            "code": "27.33",
            "parentId": 6569
        },
        {
            "id": 6976,
            "name": "Proizvodnja električne opreme za rasvjetu",
            "code": "27.40",
            "parentId": 6570
        },
        {
            "id": 6977,
            "name": "Proizvodnja električnih aparata za kućanstvo",
            "code": "27.51",
            "parentId": 6571
        },
        {
            "id": 6978,
            "name": "Proizvodnja neelektričnih aparata za kućanstvo",
            "code": "27.52",
            "parentId": 6571
        },
        {
            "id": 6979,
            "name": "Proizvodnja ostale električne opreme",
            "code": "27.90",
            "parentId": 6572
        },
        {
            "id": 6980,
            "name": "Proizvodnja motora i turbina, osim motora za zrakoplove i motorna vozila",
            "code": "28.11",
            "parentId": 6573
        },
        {
            "id": 6981,
            "name": "Proizvodnja hidrauličnih pogonskih uređaja",
            "code": "28.12",
            "parentId": 6573
        },
        {
            "id": 6982,
            "name": "Proizvodnja ostalih crpki i kompresora",
            "code": "28.13",
            "parentId": 6573
        },
        {
            "id": 6983,
            "name": "Proizvodnja ostalih slavina i ventila",
            "code": "28.14",
            "parentId": 6573
        },
        {
            "id": 6984,
            "name": "Proizvodnja ležajeva, prijenosnika te prijenosnih i pogonskih elemenata",
            "code": "28.15",
            "parentId": 6573
        },
        {
            "id": 6985,
            "name": "Proizvodnja peći, ložišta i plamenika",
            "code": "28.21",
            "parentId": 6574
        },
        {
            "id": 6986,
            "name": "Proizvodnja uređaja za dizanje i prenošenje",
            "code": "28.22",
            "parentId": 6574
        },
        {
            "id": 6987,
            "name": "Proizvodnja uredskih strojeva i opreme (osim proizvodnje računala i periferne opreme)",
            "code": "28.23",
            "parentId": 6574
        },
        {
            "id": 6988,
            "name": "Proizvodnja ručnih prenosivih alata s vlastitim pogonom",
            "code": "28.24",
            "parentId": 6574
        },
        {
            "id": 6989,
            "name": "Proizvodnja rashladne i ventilacijske opreme, osim za kućanstvo",
            "code": "28.25",
            "parentId": 6574
        },
        {
            "id": 6990,
            "name": "Proizvodnja ostalih strojeva za opće namjene, d. n.",
            "code": "28.29",
            "parentId": 6574
        },
        {
            "id": 6991,
            "name": "Proizvodnja strojeva za poljoprivredu i šumarstvo",
            "code": "28.30",
            "parentId": 6575
        },
        {
            "id": 6992,
            "name": "Proizvodnja strojeva za obradu metala",
            "code": "28.41",
            "parentId": 6576
        },
        {
            "id": 6993,
            "name": "Proizvodnja ostalih alatnih strojeva",
            "code": "28.49",
            "parentId": 6576
        },
        {
            "id": 6994,
            "name": "Proizvodnja strojeva za metalurgiju",
            "code": "28.91",
            "parentId": 6577
        },
        {
            "id": 6995,
            "name": "Proizvodnja strojeva za rudnike, kamenolome i građevinarstvo",
            "code": "28.92",
            "parentId": 6577
        },
        {
            "id": 6996,
            "name": "Proizvodnja strojeva za industriju hrane, pića i duhana",
            "code": "28.93",
            "parentId": 6577
        },
        {
            "id": 6997,
            "name": "Proizvodnja strojeva za industriju tekstila, odjeće i kože",
            "code": "28.94",
            "parentId": 6577
        },
        {
            "id": 6998,
            "name": "Proizvodnja strojeva za industriju papira i kartona",
            "code": "28.95",
            "parentId": 6577
        },
        {
            "id": 6999,
            "name": "Proizvodnja strojeva za plastiku i gumu",
            "code": "28.96",
            "parentId": 6577
        },
        {
            "id": 7000,
            "name": "Proizvodnja ostalih strojeva za posebne namjene, d. n.",
            "code": "28.99",
            "parentId": 6577
        },
        {
            "id": 7001,
            "name": "Proizvodnja motornih vozila",
            "code": "29.10",
            "parentId": 6578
        },
        {
            "id": 7002,
            "name": "Proizvodnja karoserija za motorna vozila; proizvodnja prikolica i poluprikolica",
            "code": "29.20",
            "parentId": 6579
        },
        {
            "id": 7003,
            "name": "Proizvodnja električne i elektroničke opreme za motorna vozila",
            "code": "29.31",
            "parentId": 6580
        },
        {
            "id": 7004,
            "name": "Proizvodnja ostalih dijelova i pribora za motorna vozila",
            "code": "29.32",
            "parentId": 6580
        },
        {
            "id": 7005,
            "name": "Gradnja brodova i plovećih objekata",
            "code": "30.11",
            "parentId": 6581
        },
        {
            "id": 7006,
            "name": "Gradnja čamaca za razonodu i sportskih čamaca",
            "code": "30.12",
            "parentId": 6581
        },
        {
            "id": 7007,
            "name": "Proizvodnja željezničkih lokomotiva i tračničkih vozila",
            "code": "30.20",
            "parentId": 6582
        },
        {
            "id": 7008,
            "name": "Proizvodnja zrakoplova i svemirskih letjelica te srodnih prijevoznih sredstava i opreme",
            "code": "30.30",
            "parentId": 6583
        },
        {
            "id": 7009,
            "name": "Proizvodnja motocikala",
            "code": "30.91",
            "parentId": 6585
        },
        {
            "id": 7010,
            "name": "Proizvodnja bicikala i invalidskih kolica",
            "code": "30.92",
            "parentId": 6585
        },
        {
            "id": 7011,
            "name": "Proizvodnja ostalih prijevoznih sredstava, d. n.",
            "code": "30.99",
            "parentId": 6585
        },
        {
            "id": 7012,
            "name": "Proizvodnja namještaja za poslovne i prodajne prostore",
            "code": "31.01",
            "parentId": 6586
        },
        {
            "id": 7013,
            "name": "Proizvodnja kuhinjskog namještaja",
            "code": "31.02",
            "parentId": 6586
        },
        {
            "id": 7014,
            "name": "Proizvodnja madraca",
            "code": "31.03",
            "parentId": 6586
        },
        {
            "id": 7015,
            "name": "Proizvodnja ostalog namještaja",
            "code": "31.09",
            "parentId": 6586
        },
        {
            "id": 7016,
            "name": "Proizvodnja kovanog novca",
            "code": "32.11",
            "parentId": 6587
        },
        {
            "id": 7017,
            "name": "Proizvodnja nakita i srodnih proizvoda",
            "code": "32.12",
            "parentId": 6587
        },
        {
            "id": 7018,
            "name": "Proizvodnja imitacije nakita (bižuterije) i srodnih proizvoda",
            "code": "32.13",
            "parentId": 6587
        },
        {
            "id": 7019,
            "name": "Proizvodnja glazbenih instrumenata",
            "code": "32.20",
            "parentId": 6588
        },
        {
            "id": 7020,
            "name": "Proizvodnja sportske opreme",
            "code": "32.30",
            "parentId": 6589
        },
        {
            "id": 7021,
            "name": "Proizvodnja igara i igračaka",
            "code": "32.40",
            "parentId": 6590
        },
        {
            "id": 7022,
            "name": "Proizvodnja medicinskih i stomatoloških instrumenata i pribora",
            "code": "32.50",
            "parentId": 6591
        },
        {
            "id": 7023,
            "name": "Proizvodnja metla i četaka",
            "code": "32.91",
            "parentId": 6592
        },
        {
            "id": 7024,
            "name": "Ostala prerađivačka industrija, d. n.",
            "code": "32.99",
            "parentId": 6592
        },
        {
            "id": 7025,
            "name": "Popravak proizvoda od metala",
            "code": "33.11",
            "parentId": 6593
        },
        {
            "id": 7026,
            "name": "Popravak strojeva",
            "code": "33.12",
            "parentId": 6593
        },
        {
            "id": 7027,
            "name": "Popravak elektroničke i optičke opreme",
            "code": "33.13",
            "parentId": 6593
        },
        {
            "id": 7028,
            "name": "Popravak električne opreme",
            "code": "33.14",
            "parentId": 6593
        },
        {
            "id": 7029,
            "name": "Popravak i održavanje brodova i čamaca",
            "code": "33.15",
            "parentId": 6593
        },
        {
            "id": 7030,
            "name": "Popravak i održavanje zrakoplova i svemirskih letjelica",
            "code": "33.16",
            "parentId": 6593
        },
        {
            "id": 7031,
            "name": "Popravak i održavanje ostalih prijevoznih sredstava",
            "code": "33.17",
            "parentId": 6593
        },
        {
            "id": 7032,
            "name": "Popravak ostale opreme",
            "code": "33.19",
            "parentId": 6593
        },
        {
            "id": 7033,
            "name": "Instaliranje industrijskih strojeva i opreme",
            "code": "33.20",
            "parentId": 6594
        },
        {
            "id": 7034,
            "name": "Proizvodnja električne energije",
            "code": "35.11",
            "parentId": 6595
        },
        {
            "id": 7035,
            "name": "Prijenos električne energije",
            "code": "35.12",
            "parentId": 6595
        },
        {
            "id": 7036,
            "name": "Distribucija električne energije",
            "code": "35.13",
            "parentId": 6595
        },
        {
            "id": 7037,
            "name": "Trgovina električnom energijom",
            "code": "35.14",
            "parentId": 6595
        },
        {
            "id": 7038,
            "name": "Proizvodnja plina",
            "code": "35.21",
            "parentId": 6596
        },
        {
            "id": 7039,
            "name": "Distribucija plinovitih goriva distribucijskom mrežom",
            "code": "35.22",
            "parentId": 6596
        },
        {
            "id": 7040,
            "name": "Trgovina plinom distribucijskom mrežom",
            "code": "35.23",
            "parentId": 6596
        },
        {
            "id": 7041,
            "name": "Proizvodnja i opskrba parom i klimatizacija",
            "code": "35.30",
            "parentId": 6597
        },
        {
            "id": 7042,
            "name": "Skupljanje, pročišćavanje i opskrba vodom",
            "code": "36.00",
            "parentId": 6598
        },
        {
            "id": 7043,
            "name": "Uklanjanje otpadnih voda",
            "code": "37.00",
            "parentId": 6599
        },
        {
            "id": 7044,
            "name": "Skupljanje neopasnog otpada",
            "code": "38.11",
            "parentId": 6600
        },
        {
            "id": 7045,
            "name": "Skupljanje opasnog otpada",
            "code": "38.12",
            "parentId": 6600
        },
        {
            "id": 7046,
            "name": "Obrada i zbrinjavanje neopasnog otpada",
            "code": "38.21",
            "parentId": 6601
        },
        {
            "id": 7047,
            "name": "Obrada i zbrinjavanje opasnog otpada",
            "code": "38.22",
            "parentId": 6601
        },
        {
            "id": 7048,
            "name": "Rastavljanje olupina",
            "code": "38.31",
            "parentId": 6602
        },
        {
            "id": 7049,
            "name": "Reciklaža posebno izdvojenih materijala",
            "code": "38.32",
            "parentId": 6602
        },
        {
            "id": 7050,
            "name": "Djelatnosti sanacije okoliša te ostale usluge gospodarenja otpadom",
            "code": "39.00",
            "parentId": 6603
        },
        {
            "id": 7051,
            "name": "Organizacija izvedbe građevinskih projekata",
            "code": "41.10",
            "parentId": 6604
        },
        {
            "id": 7052,
            "name": "Gradnja stambenih i nestambenih zgrada",
            "code": "41.20",
            "parentId": 6605
        },
        {
            "id": 7053,
            "name": "Gradnja cesta i autocesta",
            "code": "42.11",
            "parentId": 6606
        },
        {
            "id": 7054,
            "name": "Gradnja željezničkih pruga i podzemnih željeznica",
            "code": "42.12",
            "parentId": 6606
        },
        {
            "id": 7055,
            "name": "Gradnja mostova i tunela",
            "code": "42.13",
            "parentId": 6606
        },
        {
            "id": 7056,
            "name": "Gradnja cjevovoda za tekućine i plinove",
            "code": "42.21",
            "parentId": 6607
        },
        {
            "id": 7057,
            "name": "Gradnja vodova za električnu struju i telekomunikacije",
            "code": "42.22",
            "parentId": 6607
        },
        {
            "id": 7058,
            "name": "Gradnja hidrograđevinskih objekata",
            "code": "42.91",
            "parentId": 6608
        },
        {
            "id": 7059,
            "name": "Gradnja ostalih građevina niskogradnje, d. n.",
            "code": "42.99",
            "parentId": 6608
        },
        {
            "id": 7060,
            "name": "Uklanjanje građevina",
            "code": "43.11",
            "parentId": 6609
        },
        {
            "id": 7061,
            "name": "Pripremni radovi na gradilištu",
            "code": "43.12",
            "parentId": 6609
        },
        {
            "id": 7062,
            "name": "Ispitivanje terena za gradnju bušenjem i sondiranjem",
            "code": "43.13",
            "parentId": 6609
        },
        {
            "id": 7063,
            "name": "Elektroinstalacijski radovi",
            "code": "43.21",
            "parentId": 6610
        },
        {
            "id": 7064,
            "name": "Uvođenje instalacija vodovoda, kanalizacije i plina i instalacija za grijanje i klimatizaciju",
            "code": "43.22",
            "parentId": 6610
        },
        {
            "id": 7065,
            "name": "Ostali građevinski instalacijski radovi",
            "code": "43.29",
            "parentId": 6610
        },
        {
            "id": 7066,
            "name": "Fasadni i štukaturski radovi",
            "code": "43.31",
            "parentId": 6611
        },
        {
            "id": 7067,
            "name": "Ugradnja stolarije",
            "code": "43.32",
            "parentId": 6611
        },
        {
            "id": 7068,
            "name": "Postavljanje podnih i zidnih obloga",
            "code": "43.33",
            "parentId": 6611
        },
        {
            "id": 7069,
            "name": "Bojenje i staklarski radovi",
            "code": "43.34",
            "parentId": 6611
        },
        {
            "id": 7070,
            "name": "Ostali završni građevinski radovi",
            "code": "43.39",
            "parentId": 6611
        },
        {
            "id": 7071,
            "name": "Podizanje krovnih konstrukcija i pokrivanje krovova",
            "code": "43.91",
            "parentId": 6612
        },
        {
            "id": 7072,
            "name": "Ostale specijalizirane građevinske djelatnosti, d. n.",
            "code": "43.99",
            "parentId": 6612
        },
        {
            "id": 7073,
            "name": "Trgovina automobilima i motornim vozilima lake kategorije",
            "code": "45.11",
            "parentId": 6613
        },
        {
            "id": 7074,
            "name": "Trgovina ostalim motornim vozilima",
            "code": "45.19",
            "parentId": 6613
        },
        {
            "id": 7075,
            "name": "Održavanje i popravak motornih vozila",
            "code": "45.20",
            "parentId": 6614
        },
        {
            "id": 7076,
            "name": "Trgovina na veliko dijelovima i priborom za motorna vozila",
            "code": "45.31",
            "parentId": 6615
        },
        {
            "id": 7077,
            "name": "Trgovina na malo dijelovima i priborom za motorna vozila",
            "code": "45.32",
            "parentId": 6615
        },
        {
            "id": 7078,
            "name": "Trgovina motociklima, dijelovima i priborom za motocikle te održavanje i popravak motocikala",
            "code": "45.40",
            "parentId": 6616
        },
        {
            "id": 7079,
            "name": "Posredovanje u trgovini poljoprivrednim sirovinama, živim životinjama, tekstilnim sirovinama i poluproizvodima",
            "code": "46.11",
            "parentId": 6617
        },
        {
            "id": 7080,
            "name": "Posredovanje u trgovini gorivima, rudama, metalima i industrijskim kemikalijama",
            "code": "46.12",
            "parentId": 6617
        },
        {
            "id": 7081,
            "name": "Posredovanje u trgovini drvenom građom i građevinskim materijalom",
            "code": "46.13",
            "parentId": 6617
        },
        {
            "id": 7082,
            "name": "Posredovanje u trgovini strojevima, industrijskom opremom, brodovima i zrakoplovima",
            "code": "46.14",
            "parentId": 6617
        },
        {
            "id": 7083,
            "name": "Posredovanje u trgovini namještajem, proizvodima za kućanstvo i željeznom robom",
            "code": "46.15",
            "parentId": 6617
        },
        {
            "id": 7084,
            "name": "Posredovanje u trgovini tekstilom, odjećom, krznom, obućom i kožnim proizvodima",
            "code": "46.16",
            "parentId": 6617
        },
        {
            "id": 7085,
            "name": "Posredovanje u trgovini hranom, pićima i duhanom",
            "code": "46.17",
            "parentId": 6617
        },
        {
            "id": 7086,
            "name": "Posredovanje u trgovini specijaliziranoj za određene proizvode ili grupe ostalih proizvoda",
            "code": "46.18",
            "parentId": 6617
        },
        {
            "id": 7087,
            "name": "Posredovanje u trgovini raznovrsnim proizvodima",
            "code": "46.19",
            "parentId": 6617
        },
        {
            "id": 7088,
            "name": "Trgovina na veliko žitaricama, sirovim duhanom, sjemenjem i hranom za životinje",
            "code": "46.21",
            "parentId": 6618
        },
        {
            "id": 7089,
            "name": "Trgovina na veliko cvijećem i sadnicama",
            "code": "46.22",
            "parentId": 6618
        },
        {
            "id": 7090,
            "name": "Trgovina na veliko živim životinjama",
            "code": "46.23",
            "parentId": 6618
        },
        {
            "id": 7091,
            "name": "Trgovina na veliko sirovim, štavljenim i dovršenim kožama",
            "code": "46.24",
            "parentId": 6618
        },
        {
            "id": 7092,
            "name": "Trgovina na veliko voćem i povrćem",
            "code": "46.31",
            "parentId": 6619
        },
        {
            "id": 7093,
            "name": "Trgovina na veliko mesom i mesnim proizvodima",
            "code": "46.32",
            "parentId": 6619
        },
        {
            "id": 7094,
            "name": "Trgovina na veliko mlijekom, mliječnim proizvodima, jajima, jestivim uljima i mastima",
            "code": "46.33",
            "parentId": 6619
        },
        {
            "id": 7095,
            "name": "Trgovina na veliko pićima",
            "code": "46.34",
            "parentId": 6619
        },
        {
            "id": 7096,
            "name": "Trgovina na veliko duhanskim proizvodima",
            "code": "46.35",
            "parentId": 6619
        },
        {
            "id": 7097,
            "name": "Trgovina na veliko šećerom, čokoladom i slatkišima",
            "code": "46.36",
            "parentId": 6619
        },
        {
            "id": 7098,
            "name": "Trgovina na veliko kavom, čajem, kakaom i začinima",
            "code": "46.37",
            "parentId": 6619
        },
        {
            "id": 7099,
            "name": "Trgovina na veliko ostalom hranom uključujući ribe, ljuskare i mekušce",
            "code": "46.38",
            "parentId": 6619
        },
        {
            "id": 7100,
            "name": "Nespecijalizirana trgovina na veliko hranom, pićima i duhanskim proizvodima",
            "code": "46.39",
            "parentId": 6619
        },
        {
            "id": 7101,
            "name": "Trgovina na veliko tekstilom",
            "code": "46.41",
            "parentId": 6620
        },
        {
            "id": 7102,
            "name": "Trgovina na veliko odjećom i obućom",
            "code": "46.42",
            "parentId": 6620
        },
        {
            "id": 7103,
            "name": "Trgovina na veliko električnim aparatima za kućanstvo",
            "code": "46.43",
            "parentId": 6620
        },
        {
            "id": 7104,
            "name": "Trgovina na veliko porculanom, proizvodima od stakla i sredstvima za čišćenje",
            "code": "46.44",
            "parentId": 6620
        },
        {
            "id": 7105,
            "name": "Trgovina na veliko parfemima i kozmetikom",
            "code": "46.45",
            "parentId": 6620
        },
        {
            "id": 7106,
            "name": "Trgovina na veliko farmaceutskim proizvodima",
            "code": "46.46",
            "parentId": 6620
        },
        {
            "id": 7107,
            "name": "Trgovina na veliko namještajem, sagovima i opremom za rasvjetu",
            "code": "46.47",
            "parentId": 6620
        },
        {
            "id": 7108,
            "name": "Trgovina na veliko satovima i nakitom",
            "code": "46.48",
            "parentId": 6620
        },
        {
            "id": 7109,
            "name": "Trgovina na veliko ostalim proizvodima za kućanstvo",
            "code": "46.49",
            "parentId": 6620
        },
        {
            "id": 7110,
            "name": "Trgovina na veliko računalima, perifernom opremom i softverom",
            "code": "46.51",
            "parentId": 6621
        },
        {
            "id": 7111,
            "name": "Trgovina na veliko elektroničkim i telekomunikacijskim dijelovima i opremom",
            "code": "46.52",
            "parentId": 6621
        },
        {
            "id": 7112,
            "name": "Trgovina na veliko poljoprivrednim strojevima, opremom i priborom",
            "code": "46.61",
            "parentId": 6622
        },
        {
            "id": 7113,
            "name": "Trgovina na veliko alatnim strojevima",
            "code": "46.62",
            "parentId": 6622
        },
        {
            "id": 7114,
            "name": "Trgovina na veliko strojevima za rudarstvo i građevinarstvo",
            "code": "46.63",
            "parentId": 6622
        },
        {
            "id": 7115,
            "name": "Trgovina na veliko strojevima za tekstilnu industriju te strojevima za šivanje i pletenje",
            "code": "46.64",
            "parentId": 6622
        },
        {
            "id": 7116,
            "name": "Trgovina na veliko uredskim namještajem",
            "code": "46.65",
            "parentId": 6622
        },
        {
            "id": 7117,
            "name": "Trgovina na veliko ostalim uredskim strojevima i opremom",
            "code": "46.66",
            "parentId": 6622
        },
        {
            "id": 7118,
            "name": "Trgovina na veliko ostalim strojevima i opremom",
            "code": "46.69",
            "parentId": 6622
        },
        {
            "id": 7119,
            "name": "Trgovina na veliko krutim, tekućim i plinovitim gorivima i srodnim proizvodima",
            "code": "46.71",
            "parentId": 6623
        },
        {
            "id": 7120,
            "name": "Trgovina na veliko metalima i metalnim rudama",
            "code": "46.72",
            "parentId": 6623
        },
        {
            "id": 7121,
            "name": "Trgovina na veliko drvom, građevinskim materijalom i sanitarnom opremom",
            "code": "46.73",
            "parentId": 6623
        },
        {
            "id": 7122,
            "name": "Trgovina na veliko metalnom robom, instalacijskim materijalom, uređajima i opremom za vodovod i grijanje",
            "code": "46.74",
            "parentId": 6623
        },
        {
            "id": 7123,
            "name": "Trgovina na veliko kemijskim proizvodima",
            "code": "46.75",
            "parentId": 6623
        },
        {
            "id": 7124,
            "name": "Trgovina na veliko ostalim poluproizvodima",
            "code": "46.76",
            "parentId": 6623
        },
        {
            "id": 7125,
            "name": "Trgovina na veliko ostacima i otpacima",
            "code": "46.77",
            "parentId": 6623
        },
        {
            "id": 7126,
            "name": "Nespecijalizirana trgovina na veliko",
            "code": "46.90",
            "parentId": 6624
        },
        {
            "id": 7127,
            "name": "Trgovina na malo u nespecijaliziranim prodavaonicama pretežno hranom, pićima i duhanskim proizvodima",
            "code": "47.11",
            "parentId": 6625
        },
        {
            "id": 7128,
            "name": "Ostala trgovina na malo u nespecijaliziranim prodavaonicama",
            "code": "47.19",
            "parentId": 6625
        },
        {
            "id": 7129,
            "name": "Trgovina na malo voćem i povrćem u specijaliziranim prodavaonicama",
            "code": "47.21",
            "parentId": 6626
        },
        {
            "id": 7130,
            "name": "Trgovina na malo mesom i mesnim proizvodima u specijaliziranim prodavaonicama",
            "code": "47.22",
            "parentId": 6626
        },
        {
            "id": 7131,
            "name": "Trgovina na malo ribama, ljuskarima i mekušcima u specijaliziranim prodavaonicama",
            "code": "47.23",
            "parentId": 6626
        },
        {
            "id": 7132,
            "name": "Trgovina na malo kruhom, proizvodima od brašna, kolačima i slatkišima u specijaliziranim prodavaonicama",
            "code": "47.24",
            "parentId": 6626
        },
        {
            "id": 7133,
            "name": "Trgovina na malo pićima u specijaliziranim prodavaonicama",
            "code": "47.25",
            "parentId": 6626
        },
        {
            "id": 7134,
            "name": "Trgovina na malo duhanskim proizvodima u specijaliziranim prodavaonicama",
            "code": "47.26",
            "parentId": 6626
        },
        {
            "id": 7135,
            "name": "Ostala    trgovina    na   malo   prehrambenim proizvodima u specijaliziranim prodavaonicama",
            "code": "47.29",
            "parentId": 6626
        },
        {
            "id": 7136,
            "name": "Trgovina na malo motornim gorivima u specijaliziranim prodavaonicama",
            "code": "47.30",
            "parentId": 6627
        },
        {
            "id": 7137,
            "name": "Trgovina na malo računalima, perifernim jedinicama i softverom u specijaliziranim prodavaonicama",
            "code": "47.41",
            "parentId": 6628
        },
        {
            "id": 7138,
            "name": "Trgovina na malo telekomunikacijskom opremom u specijaliziranim prodavaonicama",
            "code": "47.42",
            "parentId": 6628
        },
        {
            "id": 7139,
            "name": "Trgovina na malo audio i videoopremom u specijaliziranim prodavaonicama",
            "code": "47.43",
            "parentId": 6628
        },
        {
            "id": 7140,
            "name": "Trgovina na malo tekstilom u specijaliziranim prodavaonicama",
            "code": "47.51",
            "parentId": 6629
        },
        {
            "id": 7141,
            "name": "Trgovina na malo metalnom robom, bojama i staklom u specijaliziranim prodavaonicama",
            "code": "47.52",
            "parentId": 6629
        },
        {
            "id": 7142,
            "name": "Trgovina na malo sagovima i prostiračima za pod, zidnim i podnim oblogama u specijaliziranim prodavaonicama",
            "code": "47.53",
            "parentId": 6629
        },
        {
            "id": 7143,
            "name": "Trgovina na malo električnim aparatima za kućanstvo u specijaliziranim prodavaonicama",
            "code": "47.54",
            "parentId": 6629
        },
        {
            "id": 7144,
            "name": "Trgovina na malo namještajem, opremom za rasvjetu i ostalim proizvodima za kućanstvo u specijaliziranim prodavaonicama",
            "code": "47.59",
            "parentId": 6629
        },
        {
            "id": 7145,
            "name": "Trgovina na malo knjigama u specijaliziranim prodavaonicama",
            "code": "47.61",
            "parentId": 6630
        },
        {
            "id": 7146,
            "name": "Trgovina na malo novinama, papirnatom robom i pisaćim priborom u specijaliziranim prodavaonicama",
            "code": "47.62",
            "parentId": 6630
        },
        {
            "id": 7147,
            "name": "Trgovina na malo glazbenim i videozapisima u specijaliziranim prodavaonicama",
            "code": "47.63",
            "parentId": 6630
        },
        {
            "id": 7148,
            "name": "Trgovina na malo sportskom opremom u specijaliziranim prodavaonicama",
            "code": "47.64",
            "parentId": 6630
        },
        {
            "id": 7149,
            "name": "Trgovina na malo igrama i igračkama u specijaliziranim prodavaonicama",
            "code": "47.65",
            "parentId": 6630
        },
        {
            "id": 7150,
            "name": "Trgovina na malo odjećom u specijaliziranim prodavaonicama",
            "code": "47.71",
            "parentId": 6631
        },
        {
            "id": 7151,
            "name": "Trgovina na malo obućom i proizvodima od kože u specijaliziranim prodavaonicama",
            "code": "47.72",
            "parentId": 6631
        },
        {
            "id": 7152,
            "name": "Ljekarne",
            "code": "47.73",
            "parentId": 6631
        },
        {
            "id": 7153,
            "name": "Trgovina na malo medicinskim pripravcima i ortopedskim pomagalima u specijaliziranim prodavaonicama",
            "code": "47.74",
            "parentId": 6631
        },
        {
            "id": 7154,
            "name": "Trgovina na malo kozmetičkim i toaletnim proizvodima u specijaliziranim prodavaonicama",
            "code": "47.75",
            "parentId": 6631
        },
        {
            "id": 7155,
            "name": "Trgovina na malo cvijećem, sadnicama, sjemenjem, gnojivom, kućnim ljubimcima i hranom za kućne ljubimce u specijaliziranim prodavaonicama",
            "code": "47.76",
            "parentId": 6631
        },
        {
            "id": 7156,
            "name": "Trgovina na malo satovima i nakitom u specijaliziranim prodavaonicama",
            "code": "47.77",
            "parentId": 6631
        },
        {
            "id": 7157,
            "name": "Ostala trgovina na malo novom robom u specijaliziranim prodavaonicama",
            "code": "47.78",
            "parentId": 6631
        },
        {
            "id": 7158,
            "name": "Trgovina na malo rabljenom robom u specijaliziranim prodavaonicama",
            "code": "47.79",
            "parentId": 6631
        },
        {
            "id": 7159,
            "name": "Trgovina na malo hranom, pićima i duhanskim proizvodima na štandovima i tržnicama",
            "code": "47.81",
            "parentId": 6632
        },
        {
            "id": 7160,
            "name": "Trgovina na malo tekstilom, odjećom i obućom na štandovima i tržnicama",
            "code": "47.82",
            "parentId": 6632
        },
        {
            "id": 7161,
            "name": "Trgovina na malo ostalom robom na štandovima i tržnicama",
            "code": "47.89",
            "parentId": 6632
        },
        {
            "id": 7162,
            "name": "Trgovina na malo preko pošte ili interneta",
            "code": "47.91",
            "parentId": 6633
        },
        {
            "id": 7163,
            "name": "Ostala trgovina na malo izvan prodavaonica, štandova i tržnica",
            "code": "47.99",
            "parentId": 6633
        },
        {
            "id": 7164,
            "name": "Željeznički prijevoz putnika, međugradski",
            "code": "49.10",
            "parentId": 6634
        },
        {
            "id": 7165,
            "name": "Željeznički prijevoz robe",
            "code": "49.20",
            "parentId": 6635
        },
        {
            "id": 7166,
            "name": "Gradski i prigradski kopneni prijevoz putnika",
            "code": "49.31",
            "parentId": 6636
        },
        {
            "id": 7167,
            "name": "Taksi služba",
            "code": "49.32",
            "parentId": 6636
        },
        {
            "id": 7168,
            "name": "Ostali kopneni prijevoz putnika, d. n.",
            "code": "49.39",
            "parentId": 6636
        },
        {
            "id": 7169,
            "name": "Cestovni prijevoz robe",
            "code": "49.41",
            "parentId": 6637
        },
        {
            "id": 7170,
            "name": "Usluge preseljenja",
            "code": "49.42",
            "parentId": 6637
        },
        {
            "id": 7171,
            "name": "Cjevovodni transport",
            "code": "49.50",
            "parentId": 6638
        },
        {
            "id": 7172,
            "name": "Pomorski i priobalni prijevoz putnika",
            "code": "50.10",
            "parentId": 6639
        },
        {
            "id": 7173,
            "name": "Pomorski i priobalni prijevoz robe",
            "code": "50.20",
            "parentId": 6640
        },
        {
            "id": 7174,
            "name": "Prijevoz putnika unutrašnjim vodenim putovima",
            "code": "50.30",
            "parentId": 6641
        },
        {
            "id": 7175,
            "name": "Prijevoz robe unutrašnjim vodenim putovima",
            "code": "50.40",
            "parentId": 6642
        },
        {
            "id": 7176,
            "name": "Zračni prijevoz putnika",
            "code": "51.10",
            "parentId": 6643
        },
        {
            "id": 7177,
            "name": "Zračni prijevoz robe",
            "code": "51.21",
            "parentId": 6644
        },
        {
            "id": 7178,
            "name": "Svemirski prijevoz",
            "code": "51.22",
            "parentId": 6644
        },
        {
            "id": 7179,
            "name": "Skladištenje robe",
            "code": "52.10",
            "parentId": 6645
        },
        {
            "id": 7180,
            "name": "Uslužne djelatnosti u vezi s kopnenim prijevozom",
            "code": "52.21",
            "parentId": 6646
        },
        {
            "id": 7181,
            "name": "Uslužne djelatnosti u vezi s vodenim prijevozom",
            "code": "52.22",
            "parentId": 6646
        },
        {
            "id": 7182,
            "name": "Uslužne djelatnosti u vezi sa zračnim prijevozom",
            "code": "52.23",
            "parentId": 6646
        },
        {
            "id": 7183,
            "name": "Prekrcaj tereta",
            "code": "52.24",
            "parentId": 6646
        },
        {
            "id": 7184,
            "name": "Ostale pomoćne djelatnosti u prijevozu",
            "code": "52.29",
            "parentId": 6646
        },
        {
            "id": 7185,
            "name": "Djelatnosti pružanja univerzalnih poštanskih usluga",
            "code": "53.10",
            "parentId": 6647
        },
        {
            "id": 7186,
            "name": "Djelatnosti pružanja ostalih poštanskih i kurirskih usluga",
            "code": "53.20",
            "parentId": 6648
        },
        {
            "id": 7187,
            "name": "Hoteli i sličan smještaj",
            "code": "55.10",
            "parentId": 6649
        },
        {
            "id": 7188,
            "name": "Odmarališta i slični objekti za kraći odmor",
            "code": "55.20",
            "parentId": 6650
        },
        {
            "id": 7189,
            "name": "Kampovi i prostori za kampiranje",
            "code": "55.30",
            "parentId": 6651
        },
        {
            "id": 7190,
            "name": "Ostali smještaj",
            "code": "55.90",
            "parentId": 6652
        },
        {
            "id": 7191,
            "name": "Djelatnosti restorana i ostalih objekata za pripremu i usluživanje hrane",
            "code": "56.10",
            "parentId": 6653
        },
        {
            "id": 7192,
            "name": "Djelatnosti keteringa",
            "code": "56.21",
            "parentId": 6654
        },
        {
            "id": 7193,
            "name": "Ostale djelatnosti pripreme i usluživanja hrane",
            "code": "56.29",
            "parentId": 6654
        },
        {
            "id": 7194,
            "name": "Djelatnosti pripreme i usluživanja pića",
            "code": "56.30",
            "parentId": 6655
        },
        {
            "id": 7195,
            "name": "Izdavanje knjiga",
            "code": "58.11",
            "parentId": 6656
        },
        {
            "id": 7196,
            "name": "Izdavanje imenika i popisa korisničkih adresa",
            "code": "58.12",
            "parentId": 6656
        },
        {
            "id": 7197,
            "name": "Izdavanje novina",
            "code": "58.13",
            "parentId": 6656
        },
        {
            "id": 7198,
            "name": "Izdavanje časopisa i periodičnih publikacija",
            "code": "58.14",
            "parentId": 6656
        },
        {
            "id": 7199,
            "name": "Ostala izdavačka djelatnost",
            "code": "58.19",
            "parentId": 6656
        },
        {
            "id": 7200,
            "name": "Izdavanje računalnih igara",
            "code": "58.21",
            "parentId": 6657
        },
        {
            "id": 7201,
            "name": "Izdavanje ostalog softvera",
            "code": "58.29",
            "parentId": 6657
        },
        {
            "id": 7202,
            "name": "Proizvodnja filmova, videofilmova i televizijskog programa",
            "code": "59.11",
            "parentId": 6658
        },
        {
            "id": 7203,
            "name": "Djelatnosti koje slijede nakon proizvodnje filmova, videofilmova i televizijskog programa",
            "code": "59.12",
            "parentId": 6658
        },
        {
            "id": 7204,
            "name": "Distribucija filmova, videofilmova i televizijskog programa",
            "code": "59.13",
            "parentId": 6658
        },
        {
            "id": 7205,
            "name": "Djelatnosti prikazivanja filmova",
            "code": "59.14",
            "parentId": 6658
        },
        {
            "id": 7206,
            "name": "Djelatnosti snimanja zvučnih zapisa i izdavanja glazbenih zapisa",
            "code": "59.20",
            "parentId": 6659
        },
        {
            "id": 7207,
            "name": "Emitiranje radijskog programa",
            "code": "60.10",
            "parentId": 6660
        },
        {
            "id": 7208,
            "name": "Emitiranje televizijskog programa",
            "code": "60.20",
            "parentId": 6661
        },
        {
            "id": 7209,
            "name": "Djelatnosti žičane telekomunikacije",
            "code": "61.10",
            "parentId": 6662
        },
        {
            "id": 7210,
            "name": "Djelatnosti bežične telekomunikacije",
            "code": "61.20",
            "parentId": 6663
        },
        {
            "id": 7211,
            "name": "Djelatnosti satelitske telekomunikacije",
            "code": "61.30",
            "parentId": 6664
        },
        {
            "id": 7212,
            "name": "Ostale telekomunikacijske djelatnosti",
            "code": "61.90",
            "parentId": 6665
        },
        {
            "id": 7213,
            "name": "Računalno programiranje",
            "code": "62.01",
            "parentId": 6666
        },
        {
            "id": 7214,
            "name": "Savjetovanje u vezi s računalima",
            "code": "62.02",
            "parentId": 6666
        },
        {
            "id": 7215,
            "name": "Upravljanje računalnom opremom i sustavom",
            "code": "62.03",
            "parentId": 6666
        },
        {
            "id": 7216,
            "name": "Ostale uslužne djelatnosti u vezi s informacijskom tehnologijom i računalima",
            "code": "62.09",
            "parentId": 6666
        },
        {
            "id": 7217,
            "name": "Obrada podataka, usluge hostinga i djelatnosti povezane s njima",
            "code": "63.11",
            "parentId": 6667
        },
        {
            "id": 7218,
            "name": "Internetski portali",
            "code": "63.12",
            "parentId": 6667
        },
        {
            "id": 7219,
            "name": "Djelatnosti novinskih agencija",
            "code": "63.91",
            "parentId": 6668
        },
        {
            "id": 7220,
            "name": "Ostale informacijske uslužne djelatnosti, d. n.",
            "code": "63.99",
            "parentId": 6668
        },
        {
            "id": 7221,
            "name": "Djelatnost Centralne banke",
            "code": "64.11",
            "parentId": 6669
        },
        {
            "id": 7222,
            "name": "Ostalo novčarsko posredovanje",
            "code": "64.19",
            "parentId": 6669
        },
        {
            "id": 7223,
            "name": "Djelatnosti financijskih holding-društava",
            "code": "64.20",
            "parentId": 6670
        },
        {
            "id": 7224,
            "name": "Trustovi, fondovi i slični financijski subjekti",
            "code": "64.30",
            "parentId": 6671
        },
        {
            "id": 7225,
            "name": "Financijski zakup (leasing)",
            "code": "64.91",
            "parentId": 6672
        },
        {
            "id": 7226,
            "name": "Ostalo kreditno posredovanje",
            "code": "64.92",
            "parentId": 6672
        },
        {
            "id": 7227,
            "name": "Ostale financijske uslužne djelatnosti, osim osiguranja i mirovinskih fondova, d. n.",
            "code": "64.99",
            "parentId": 6672
        },
        {
            "id": 7228,
            "name": "Životno osiguranje",
            "code": "65.11",
            "parentId": 6673
        },
        {
            "id": 7229,
            "name": "Ostalo osiguranje",
            "code": "65.12",
            "parentId": 6673
        },
        {
            "id": 7230,
            "name": "Reosiguranje",
            "code": "65.20",
            "parentId": 6674
        },
        {
            "id": 7231,
            "name": "Mirovinski fondovi",
            "code": "65.30",
            "parentId": 6675
        },
        {
            "id": 7232,
            "name": "Poslovanje financijskih tržišta",
            "code": "66.11",
            "parentId": 6676
        },
        {
            "id": 7233,
            "name": "Djelatnosti posredovanja u poslovanju vrijednosnim papirima i robnim ugovorima",
            "code": "66.12",
            "parentId": 6676
        },
        {
            "id": 7234,
            "name": "Ostale pomoćne djelatnosti kod financijskih usluga, osim osiguranja i mirovinskih fondova",
            "code": "66.19",
            "parentId": 6676
        },
        {
            "id": 7235,
            "name": "Procjena rizika i štete",
            "code": "66.21",
            "parentId": 6677
        },
        {
            "id": 7236,
            "name": "Djelatnosti agenata i posrednika osiguranja",
            "code": "66.22",
            "parentId": 6677
        },
        {
            "id": 7237,
            "name": "Ostale pomoćne djelatnosti u osiguranju i mirovinskim fondovima",
            "code": "66.29",
            "parentId": 6677
        },
        {
            "id": 7238,
            "name": "Djelatnosti upravljanja fondovima",
            "code": "66.30",
            "parentId": 6678
        },
        {
            "id": 7239,
            "name": "Kupnja i prodaja vlastitih nekretnina",
            "code": "68.10",
            "parentId": 6679
        },
        {
            "id": 7240,
            "name": "Iznajmljivanje i upravljanje vlastitim nekretninama ili nekretninama uzetim u zakup (leasing)",
            "code": "68.20",
            "parentId": 6680
        },
        {
            "id": 7241,
            "name": "Agencije za poslovanje nekretninama",
            "code": "68.31",
            "parentId": 6681
        },
        {
            "id": 7242,
            "name": "Upravljanje nekretninama uz naknadu ili na osnovi ugovora",
            "code": "68.32",
            "parentId": 6681
        },
        {
            "id": 7243,
            "name": "Pravne djelatnosti",
            "code": "69.10",
            "parentId": 6682
        },
        {
            "id": 7244,
            "name": "Računovodstvene, knjigovodstvene i revizijske djelatnosti; porezno savjetovanje",
            "code": "69.20",
            "parentId": 6683
        },
        {
            "id": 7245,
            "name": "Upravljačke djelatnosti",
            "code": "70.10",
            "parentId": 6684
        },
        {
            "id": 7246,
            "name": "Odnosi s javnošću i djelatnosti priopćivanja",
            "code": "70.21",
            "parentId": 6685
        },
        {
            "id": 7247,
            "name": "Savjetovanje u vezi s poslovanjem i ostalim upravljanjem",
            "code": "70.22",
            "parentId": 6685
        },
        {
            "id": 7248,
            "name": "Arhitektonske djelatnosti",
            "code": "71.11",
            "parentId": 6686
        },
        {
            "id": 7249,
            "name": "Inženjerske djelatnosti i s njima povezano tehničko savjetovanje",
            "code": "71.12",
            "parentId": 6686
        },
        {
            "id": 7250,
            "name": "Tehničko ispitivanje i analiza",
            "code": "71.20",
            "parentId": 6687
        },
        {
            "id": 7251,
            "name": "Istraživanje i eksperimentalni razvoj u biotehnologiji",
            "code": "72.11",
            "parentId": 6688
        },
        {
            "id": 7252,
            "name": "Ostalo istraživanje i eksperimentalni razvoj u prirodnim, tehničkim i tehnološkim znanostima",
            "code": "72.19",
            "parentId": 6688
        },
        {
            "id": 7253,
            "name": "Istraživanje i eksperimentalni razvoj u društvenim i humanističkim znanostima",
            "code": "72.20",
            "parentId": 6689
        },
        {
            "id": 7254,
            "name": "Agencije za promidžbu (reklamu i propagandu)",
            "code": "73.11",
            "parentId": 6690
        },
        {
            "id": 7255,
            "name": "Oglašavanje preko medija",
            "code": "73.12",
            "parentId": 6690
        },
        {
            "id": 7256,
            "name": "Istraživanje tržišta i ispitivanje javnoga mnijenja",
            "code": "73.20",
            "parentId": 6691
        },
        {
            "id": 7257,
            "name": "Specijalizirane dizajnerske djelatnosti",
            "code": "74.10",
            "parentId": 6692
        },
        {
            "id": 7258,
            "name": "Fotografske djelatnosti",
            "code": "74.20",
            "parentId": 6693
        },
        {
            "id": 7259,
            "name": "Prevoditeljske djelatnosti i usluge tumača",
            "code": "74.30",
            "parentId": 6694
        },
        {
            "id": 7260,
            "name": "Ostale stručne, znanstvene i tehničke djelatnosti, d. n.",
            "code": "74.90",
            "parentId": 6695
        },
        {
            "id": 7261,
            "name": "Veterinarske djelatnosti",
            "code": "75.00",
            "parentId": 6696
        },
        {
            "id": 7262,
            "name": "Iznajmljivanje i davanje u zakup (leasing) automobila i motornih vozila lake kategorije",
            "code": "77.11",
            "parentId": 6697
        },
        {
            "id": 7263,
            "name": "Iznajmljivanje i davanje u zakup (leasing) kamiona",
            "code": "77.12",
            "parentId": 6697
        },
        {
            "id": 7264,
            "name": "Iznajmljivanje i davanje u zakup (leasing) opreme za rekraciju i sport",
            "code": "77.21",
            "parentId": 6698
        },
        {
            "id": 7265,
            "name": "Iznajmljivanje videokaseta i diskova",
            "code": "77.22",
            "parentId": 6698
        },
        {
            "id": 7266,
            "name": "Iznajmljivanje i davanje u zakup (leasing) ostalih predmeta za osobnu uporabu i kućanstvo",
            "code": "77.29",
            "parentId": 6698
        },
        {
            "id": 7267,
            "name": "Iznajmljivanje i davanje u zakup (leasing) poljoprivrednih strojeva i opreme",
            "code": "77.31",
            "parentId": 6699
        },
        {
            "id": 7268,
            "name": "Iznajmljivanje i davanje u zakup (leasing) strojeva i opreme za građevinarstvo",
            "code": "77.32",
            "parentId": 6699
        },
        {
            "id": 7269,
            "name": "Iznajmljivanje i davanje u zakup (leasing) uredskih strojeva i opreme (uključujući računala)",
            "code": "77.33",
            "parentId": 6699
        },
        {
            "id": 7270,
            "name": "Iznajmljivanje i davanje u zakup (leasing) plovnih prijevoznih sredstava",
            "code": "77.34",
            "parentId": 6699
        },
        {
            "id": 7271,
            "name": "Iznajmljivanje i davanje u zakup (leasing) zračnih prijevoznih sredstava",
            "code": "77.35",
            "parentId": 6699
        },
        {
            "id": 7272,
            "name": "Iznajmljivanje i davanje u zakup (leasing) ostalih strojeva, opreme i materijalnih dobara, d. n.",
            "code": "77.39",
            "parentId": 6699
        },
        {
            "id": 7273,
            "name": "Davanje u zakup (leasing) prava na uporabu intelektualnog vlasništva i sličnih proizvoda, osim radova koji su zaštićeni autorskim pravima",
            "code": "77.40",
            "parentId": 6700
        },
        {
            "id": 7274,
            "name": "Djelatnosti agencija za zapošljavanje",
            "code": "78.10",
            "parentId": 6701
        },
        {
            "id": 7275,
            "name": "Djelatnosti agencija za privremeno zapošljavanje",
            "code": "78.20",
            "parentId": 6702
        },
        {
            "id": 7276,
            "name": "Ostalo ustupanje ljudskih resursa",
            "code": "78.30",
            "parentId": 6703
        },
        {
            "id": 7277,
            "name": "Djelatnosti putničkih agencija",
            "code": "79.11",
            "parentId": 6704
        },
        {
            "id": 7278,
            "name": "Djelatnosti turoperatora",
            "code": "79.12",
            "parentId": 6704
        },
        {
            "id": 7279,
            "name": "Ostale rezervacijske usluge i djelatnosti povezane s njima",
            "code": "79.90",
            "parentId": 6705
        },
        {
            "id": 7280,
            "name": "Djelatnosti privatne zaštite",
            "code": "80.10",
            "parentId": 6706
        },
        {
            "id": 7281,
            "name": "Usluge zaštite uz pomoć sigurnosnih sustava",
            "code": "80.20",
            "parentId": 6707
        },
        {
            "id": 7282,
            "name": "Istražne djelatnosti",
            "code": "80.30",
            "parentId": 6708
        },
        {
            "id": 7283,
            "name": "Pomoćne djelatnosti upravljanja zgradama",
            "code": "81.10",
            "parentId": 6709
        },
        {
            "id": 7284,
            "name": "Osnovno čišćenje zgrada",
            "code": "81.21",
            "parentId": 6710
        },
        {
            "id": 7285,
            "name": "Ostale djelatnosti čišćenja zgrada i objekata",
            "code": "81.22",
            "parentId": 6710
        },
        {
            "id": 7286,
            "name": "Ostale djelatnosti čišćenja",
            "code": "81.29",
            "parentId": 6710
        },
        {
            "id": 7287,
            "name": "Uslužne djelatnosti uređenja i održavanja zelenih površina",
            "code": "81.30",
            "parentId": 6711
        },
        {
            "id": 7288,
            "name": "Kombinirane uredske administrativne uslužne djelatnosti",
            "code": "82.11",
            "parentId": 6712
        },
        {
            "id": 7289,
            "name": "Fotokopiranje, priprema dokumenata i ostale specijalizirane uredske pomoćne djelatnosti",
            "code": "82.19",
            "parentId": 6712
        },
        {
            "id": 7290,
            "name": "Djelatnosti pozivnih centara",
            "code": "82.20",
            "parentId": 6713
        },
        {
            "id": 7291,
            "name": "Organizacija sastanaka i poslovnih sajmova",
            "code": "82.30",
            "parentId": 6714
        },
        {
            "id": 7292,
            "name": "Djelatnosti agencija za prikupljanje i naplatu računa te kreditnih ureda",
            "code": "82.91",
            "parentId": 6715
        },
        {
            "id": 7293,
            "name": "Djelatnosti pakiranja",
            "code": "82.92",
            "parentId": 6715
        },
        {
            "id": 7294,
            "name": "Ostale poslovne pomoćne uslužne djelatnosti, d. n.",
            "code": "82.99",
            "parentId": 6715
        },
        {
            "id": 7295,
            "name": "Opće djelatnosti javne uprave",
            "code": "84.11",
            "parentId": 6716
        },
        {
            "id": 7296,
            "name": "Reguliranje djelatnosti javnih subjekata koji pružaju zdravstvenu zaštitu, usluge u obrazovanju i kulturi i druge društvene usluge, osim obveznoga socijalnog osiguranja",
            "code": "84.12",
            "parentId": 6716
        },
        {
            "id": 7297,
            "name": "Reguliranje i doprinos poboljšavanju poslovanja u gospodarstvu",
            "code": "84.13",
            "parentId": 6716
        },
        {
            "id": 7298,
            "name": "Vanjski poslovi",
            "code": "84.21",
            "parentId": 6717
        },
        {
            "id": 7299,
            "name": "Poslovi obrane",
            "code": "84.22",
            "parentId": 6717
        },
        {
            "id": 7300,
            "name": "Sudske i pravosudne djelatnosti",
            "code": "84.23",
            "parentId": 6717
        },
        {
            "id": 7301,
            "name": "Poslovi javnog reda i sigurnosti",
            "code": "84.24",
            "parentId": 6717
        },
        {
            "id": 7302,
            "name": "Djelatnosti vatrogasne službe",
            "code": "84.25",
            "parentId": 6717
        },
        {
            "id": 7303,
            "name": "Djelatnosti obveznoga socijalnog osiguranja",
            "code": "84.30",
            "parentId": 6718
        },
        {
            "id": 7304,
            "name": "Predškolsko obrazovanje",
            "code": "85.10",
            "parentId": 6719
        },
        {
            "id": 7305,
            "name": "Osnovno obrazovanje",
            "code": "85.20",
            "parentId": 6720
        },
        {
            "id": 7306,
            "name": "Opće srednje obrazovanje",
            "code": "85.31",
            "parentId": 6721
        },
        {
            "id": 7307,
            "name": "Tehničko i strukovno srednje obrazovanje",
            "code": "85.32",
            "parentId": 6721
        },
        {
            "id": 7308,
            "name": "Obrazovanje nakon srednjeg koje nije visoko",
            "code": "85.41",
            "parentId": 6722
        },
        {
            "id": 7309,
            "name": "Visoko obrazovanje",
            "code": "85.42",
            "parentId": 6722
        },
        {
            "id": 7310,
            "name": "Obrazovanje i poučavanje u području sporta i rekreacije",
            "code": "85.51",
            "parentId": 6723
        },
        {
            "id": 7311,
            "name": "Obrazovanje i poučavanje u području kulture",
            "code": "85.52",
            "parentId": 6723
        },
        {
            "id": 7312,
            "name": "Djelatnosti vozačkih škola",
            "code": "85.53",
            "parentId": 6723
        },
        {
            "id": 7313,
            "name": "Ostalo obrazovanje i poučavanje, d. n.",
            "code": "85.59",
            "parentId": 6723
        },
        {
            "id": 7314,
            "name": "Pomoćne uslužne djelatnosti u obrazovanju",
            "code": "85.60",
            "parentId": 6724
        },
        {
            "id": 7315,
            "name": "Djelatnosti bolnica",
            "code": "86.10",
            "parentId": 6725
        },
        {
            "id": 7316,
            "name": "Djelatnosti opće medicinske prakse",
            "code": "86.21",
            "parentId": 6726
        },
        {
            "id": 7317,
            "name": "Djelatnosti specijalističke medicinske prakse",
            "code": "86.22",
            "parentId": 6726
        },
        {
            "id": 7318,
            "name": "Djelatnosti stomatološke prakse",
            "code": "86.23",
            "parentId": 6726
        },
        {
            "id": 7319,
            "name": "Ostale djelatnosti zdravstvene zaštite",
            "code": "86.90",
            "parentId": 6727
        },
        {
            "id": 7320,
            "name": "Djelatnosti ustanova sa smještajem koje uključuju određeni stupanj zdravstvene njege",
            "code": "87.10",
            "parentId": 6728
        },
        {
            "id": 7321,
            "name": "Djelatnosti u ustanovama sa smještajem za osobe s teškoćama u razvoju, duševno bolesne i osobe ovisne o alkoholu, drogama ili drugim opojnim sredstvima sa uključenim određenim stupnjem zdravstvene njege",
            "code": "87.20",
            "parentId": 6735
        },
        {
            "id": 7322,
            "name": "Djelatnosti socijalne skrbi u ustanovama sa smještajem za starije osobe i osobe s invaliditetom bez ili sa minimalnom njegom",
            "code": "87.30",
            "parentId": 6729
        },
        {
            "id": 7323,
            "name": "Ostale djelatnosti socijalne skrbi u ustanovama sa smještajem",
            "code": "87.90",
            "parentId": 6730
        },
        {
            "id": 7324,
            "name": "Djelatnosti socijalne skrbi bez smještaja za starije osobe i osobe s invaliditetom",
            "code": "88.10",
            "parentId": 6731
        },
        {
            "id": 7325,
            "name": "Djelatnosti dnevne skrbi o djeci",
            "code": "88.91",
            "parentId": 6732
        },
        {
            "id": 7326,
            "name": "Ostale djelatnosti socijalne skrbi bez smještaja, d. n.",
            "code": "88.99",
            "parentId": 6732
        },
        {
            "id": 7327,
            "name": "Izvođačka umjetnost",
            "code": "90.01",
            "parentId": 6733
        },
        {
            "id": 7328,
            "name": "Pomoćne djelatnosti u izvođačkoj umjetnosti",
            "code": "90.02",
            "parentId": 6733
        },
        {
            "id": 7329,
            "name": "Umjetničko stvaralaštvo",
            "code": "90.03",
            "parentId": 6733
        },
        {
            "id": 7330,
            "name": "Rad umjetničkih objekata",
            "code": "90.04",
            "parentId": 6733
        },
        {
            "id": 7331,
            "name": "Djelatnosti knjižnica i arhiva",
            "code": "91.01",
            "parentId": 6734
        },
        {
            "id": 7332,
            "name": "Djelatnosti muzeja",
            "code": "91.02",
            "parentId": 6734
        },
        {
            "id": 7333,
            "name": "Rad povijesnih mjesta i građevina te sličnih znamenitosti za posjetitelje",
            "code": "91.03",
            "parentId": 6734
        },
        {
            "id": 7334,
            "name": "Djelatnosti botaničkih i zooloških vrtova i prirodnih rezervata",
            "code": "91.04",
            "parentId": 6734
        },
        {
            "id": 7335,
            "name": "Djelatnosti kockanja i klađenja",
            "code": "92.00",
            "parentId": 6736
        },
        {
            "id": 7336,
            "name": "Rad sportskih objekata",
            "code": "93.11",
            "parentId": 6737
        },
        {
            "id": 7337,
            "name": "Djelatnosti sportskih klubova",
            "code": "93.12",
            "parentId": 6737
        },
        {
            "id": 7338,
            "name": "Fitnes centri",
            "code": "93.13",
            "parentId": 6737
        },
        {
            "id": 7339,
            "name": "Ostale sportske djelatnosti",
            "code": "93.19",
            "parentId": 6737
        },
        {
            "id": 7340,
            "name": "Djelatnosti zabavnih i tematskih parkova",
            "code": "93.21",
            "parentId": 6738
        },
        {
            "id": 7341,
            "name": "Ostale zabavne i rekreacijske djelatnosti",
            "code": "93.29",
            "parentId": 6738
        },
        {
            "id": 7342,
            "name": "Djelatnosti poslovnih organizacija i udruženja poslodavaca",
            "code": "94.11",
            "parentId": 6739
        },
        {
            "id": 7343,
            "name": "Djelatnosti strukovnih članskih organizacija",
            "code": "94.12",
            "parentId": 6739
        },
        {
            "id": 7344,
            "name": "Djelatnosti sindikata",
            "code": "94.20",
            "parentId": 6740
        },
        {
            "id": 7345,
            "name": "Djelatnosti vjerskih organizacija",
            "code": "94.91",
            "parentId": 6741
        },
        {
            "id": 7346,
            "name": "Djelatnosti političkih organizacija",
            "code": "94.92",
            "parentId": 6741
        },
        {
            "id": 7347,
            "name": "Djelatnosti ostalih članskih organizacija, d. n.",
            "code": "94.99",
            "parentId": 6741
        },
        {
            "id": 7348,
            "name": "Popravak računala i periferne opreme",
            "code": "95.11",
            "parentId": 6742
        },
        {
            "id": 7349,
            "name": "Popravak komunikacijske opreme",
            "code": "95.12",
            "parentId": 6742
        },
        {
            "id": 7350,
            "name": "Popravak elektroničkih uređaja za široku potrošnju",
            "code": "95.21",
            "parentId": 6743
        },
        {
            "id": 7351,
            "name": "Popravak aparata za kućanstvo te opreme za kuću i vrt",
            "code": "95.22",
            "parentId": 6743
        },
        {
            "id": 7352,
            "name": "Popravak obuće i proizvoda od kože",
            "code": "95.23",
            "parentId": 6743
        },
        {
            "id": 7353,
            "name": "Popravak namještaja i pokućstva",
            "code": "95.24",
            "parentId": 6743
        },
        {
            "id": 7354,
            "name": "Popravak satova i nakita",
            "code": "95.25",
            "parentId": 6743
        },
        {
            "id": 7355,
            "name": "Popravak ostalih predmeta za osobnu uporabu i kućanstvo",
            "code": "95.29",
            "parentId": 6743
        },
        {
            "id": 7356,
            "name": "Pranje i kemijsko čišćenje tekstila i krznenih proizvoda",
            "code": "96.01",
            "parentId": 6744
        },
        {
            "id": 7357,
            "name": "Frizerski i drugi tretmani za uljepšavanje",
            "code": "96.02",
            "parentId": 6744
        },
        {
            "id": 7358,
            "name": "Pogrebne i srodne djelatnosti",
            "code": "96.03",
            "parentId": 6744
        },
        {
            "id": 7359,
            "name": "Djelatnosti za njegu i održavanje tijela",
            "code": "96.04",
            "parentId": 6744
        },
        {
            "id": 7360,
            "name": "Ostale osobne uslužne djelatnosti, d. n.",
            "code": "96.09",
            "parentId": 6744
        },
        {
            "id": 7361,
            "name": "Djelatnosti   kućanstava  kao   poslodavaca koji zapošljavaju poslugu",
            "code": "97.00",
            "parentId": 6745
        },
        {
            "id": 7362,
            "name": "Djelatnosti privatnih kućanstava koja proizvode različita dobra za vlastite potrebe",
            "code": "98.10",
            "parentId": 6746
        },
        {
            "id": 7363,
            "name": "Djelatnosti privatnih kućanstava koja obavljaju različite usluge za vlastite potrebe",
            "code": "98.20",
            "parentId": 6747
        },
        {
            "id": 7364,
            "name": "Djelatnosti izvanteritorijalnih organizacija i tijela",
            "code": "99.00",
            "parentId": 6748
        },
        {
            "id": 7366,
            "name": "Uzgoj jednogodišnjih usjeva",
            "code": "01.10",
            "parentId": 6477
        },
        {
            "id": 7367,
            "name": "Uzgoj višegodišnjih usjeva",
            "code": "01.20",
            "parentId": 6478
        },
        {
            "id": 7368,
            "name": "Uzgoj životinja",
            "code": "01.40",
            "parentId": 6480
        },
        {
            "id": 7369,
            "name": "Pomoćne djelatnosti u poljoprivredi i djelatnosti koje se obavljaju nakon žetve usjeva",
            "code": "01.60",
            "parentId": 6482
        },
        {
            "id": 7372,
            "name": "Ribolov",
            "code": "03.10",
            "parentId": 6488
        },
        {
            "id": 7373,
            "name": "Akvakultura",
            "code": "03.20",
            "parentId": 6489
        },
        {
            "id": 7377,
            "name": "Vađenje ruda obojenih metala",
            "code": "07.20",
            "parentId": 6495
        },
        {
            "id": 7379,
            "name": "Vađenje kamena, pijeska i gline",
            "code": "08.10",
            "parentId": 6496
        },
        {
            "id": 7380,
            "name": "Vađenje ruda i kamena d. n.",
            "code": "08.90",
            "parentId": 6497
        },
        {
            "id": 7383,
            "name": "Prerada i konzerviranje mesa i proizvodnja mesnih proizvoda",
            "code": "10.10",
            "parentId": 6500
        },
        {
            "id": 7384,
            "name": "Prerada i konzerviranje voća i povrća",
            "code": "10.30",
            "parentId": 6502
        },
        {
            "id": 7385,
            "name": "Proizvodnja biljnih i životinjskih ulja i masti",
            "code": "10.40",
            "parentId": 6503
        },
        {
            "id": 7386,
            "name": "Proizvodnja mliječnih proizvoda",
            "code": "10.50",
            "parentId": 6504
        },
        {
            "id": 7387,
            "name": "Proizvodnja mlinskih proizvoda, škroba i škrobnih proizvoda",
            "code": "10.60",
            "parentId": 6505
        },
        {
            "id": 7388,
            "name": "Proizvodnja pekarskih proizvoda, proizvoda od brašna i kolača",
            "code": "10.70",
            "parentId": 6506
        },
        {
            "id": 7389,
            "name": "Proizvodnja ostalih prehrambenih proizvoda",
            "code": "10.80",
            "parentId": 6507
        },
        {
            "id": 7390,
            "name": "Proizvodnja pripremljene hrane za životinje",
            "code": "10.90",
            "parentId": 6508
        },
        {
            "id": 7391,
            "name": "Proizvodnja pića",
            "code": "11.00",
            "parentId": 6509
        },
        {
            "id": 7393,
            "name": "Proizvodnja ostalog tekstila",
            "code": "13.90",
            "parentId": 6514
        },
        {
            "id": 7395,
            "name": "Proizvodnja odjeće, osim krznene odjeće",
            "code": "14.10",
            "parentId": 6515
        },
        {
            "id": 7396,
            "name": "Proizvodnja pletene i heklane odjeće",
            "code": "14.30",
            "parentId": 6517
        },
        {
            "id": 7398,
            "name": "Štavljenje i obrada kože; proizvodnja putnih i ručnih torbi, sedlarskih i kaišarskih proizvoda; dorada i bojenje krzna",
            "code": "15.10",
            "parentId": 6518
        },
        {
            "id": 7400,
            "name": "Proizvodnja proizvoda od drva, pluta, slame i pletarskih materijala",
            "code": "16.20",
            "parentId": 6521
        },
        {
            "id": 7402,
            "name": "Proizvodnja celuloze, papira i kartona",
            "code": "17.10",
            "parentId": 6522
        },
        {
            "id": 7403,
            "name": "Proizvodnja proizvoda od papira i kartona",
            "code": "17.20",
            "parentId": 6523
        },
        {
            "id": 7405,
            "name": "Štampanje i uslužne djelatnosti u vezi sa štampanjem",
            "code": "18.10",
            "parentId": 6524
        },
        {
            "id": 7408,
            "name": "Proizvodnja osnovnih hemikalija, gnojiva i dušičnih spojeva, plastike i sintetičkog kaučuka u primarnim oblicima",
            "code": "20.10",
            "parentId": 6528
        },
        {
            "id": 7409,
            "name": "Proizvodnja sapuna i deterdženata, sredstava za čišćenje i poliranje, parfema i toaletno-kozmetičkih preparata",
            "code": "20.40",
            "parentId": 6531
        },
        {
            "id": 7410,
            "name": "Proizvodnja ostalih hemijskih proizvoda",
            "code": "20.50",
            "parentId": 6532
        },
        {
            "id": 7413,
            "name": "Proizvodnja proizvoda od gume",
            "code": "22.10",
            "parentId": 6536
        },
        {
            "id": 7414,
            "name": "Proizvodnja proizvoda od plastičnih masa",
            "code": "22.20",
            "parentId": 6537
        },
        {
            "id": 7416,
            "name": "Proizvodnja stakla i proizvoda od stakla",
            "code": "23.10",
            "parentId": 6538
        },
        {
            "id": 7417,
            "name": "Proizvodnja proizvoda od gline za građevinarstvo",
            "code": "23.30",
            "parentId": 6540
        },
        {
            "id": 7418,
            "name": "Proizvodnja ostalih proizvoda od porculana i keramike",
            "code": "23.40",
            "parentId": 6541
        },
        {
            "id": 7419,
            "name": "Proizvodnja cementa, kreča i gipsa",
            "code": "23.50",
            "parentId": 6542
        },
        {
            "id": 7420,
            "name": "Proizvodnja proizvoda od betona, cementa i gipsa",
            "code": "23.60",
            "parentId": 6543
        },
        {
            "id": 7421,
            "name": "Proizvodnja brusnih proizvoda i nemetalnih mineralnih proizvoda, d. n.",
            "code": "23.90",
            "parentId": 6545
        },
        {
            "id": 7423,
            "name": "Proizvodnja ostalih proizvoda primarne prerade čelika",
            "code": "24.30",
            "parentId": 6548
        },
        {
            "id": 7424,
            "name": "Proizvodnja baznih plemenitih i ostalih obojenih metala",
            "code": "24.40",
            "parentId": 6549
        },
        {
            "id": 7425,
            "name": "Lijevanje metala",
            "code": "24.50",
            "parentId": 6550
        },
        {
            "id": 7427,
            "name": "Proizvodnja metalnih konstrukcija",
            "code": "25.10",
            "parentId": 6551
        },
        {
            "id": 7428,
            "name": "Proizvodnja metalnih cisterni, rezervoara i sličnih posuda",
            "code": "25.20",
            "parentId": 6552
        },
        {
            "id": 7429,
            "name": "Površinska  obrada  i  prevlačenje  metala;  mašinska obrada metala",
            "code": "25.60",
            "parentId": 6556
        },
        {
            "id": 7430,
            "name": "Proizvodnja  sječiva, alata  i  metalnih  proizvoda za opću namjenu",
            "code": "25.70",
            "parentId": 6557
        },
        {
            "id": 7431,
            "name": "Proizvodnja ostalih gotovih proizvoda od metala",
            "code": "25.90",
            "parentId": 6558
        },
        {
            "id": 7433,
            "name": "Proizvodnja elektroničkih komponenata i ploča",
            "code": "26.10",
            "parentId": 6559
        },
        {
            "id": 7434,
            "name": "Proizvodnja instrumenata i aparata za mjerenje, ispitivanje i navođenje; proizvodnja satova",
            "code": "26.50",
            "parentId": 6563
        },
        {
            "id": 7436,
            "name": "Proizvodnja elektromotora, generatora, transformatora te uređaja za distribuciju i kontrolu električne energije",
            "code": "27.10",
            "parentId": 6567
        },
        {
            "id": 7437,
            "name": "Proizvodnja žice i elektroinstalacijskog materijala",
            "code": "27.30",
            "parentId": 6569
        },
        {
            "id": 7438,
            "name": "Proizvodnja aparata za domaćinstvo",
            "code": "27.50",
            "parentId": 6571
        },
        {
            "id": 7440,
            "name": "Proizvodnja mašina za opće namjene",
            "code": "28.10",
            "parentId": 6573
        },
        {
            "id": 7441,
            "name": "Proizvodnja ostalih mašina za opće namjene",
            "code": "28.20",
            "parentId": 6574
        },
        {
            "id": 7442,
            "name": "Proizvodnja mašina za obradu metala i alatnih mašina",
            "code": "28.40",
            "parentId": 6576
        },
        {
            "id": 7443,
            "name": "Proizvodnja ostalih mašina za posebne namjene",
            "code": "28.90",
            "parentId": 6577
        },
        {
            "id": 7445,
            "name": "Proizvodnja dijelova i pribora za motorna vozila",
            "code": "29.30",
            "parentId": 6580
        },
        {
            "id": 7447,
            "name": "Gradnja brodova i čamaca",
            "code": "30.10",
            "parentId": 6581
        },
        {
            "id": 7448,
            "name": "Proizvodnja vojnih borbenih vozila",
            "code": "30.40",
            "parentId": 6584
        },
        {
            "id": 7449,
            "name": "Proizvodnja prijevoznih sredstava, d. n.",
            "code": "30.90",
            "parentId": 6585
        },
        {
            "id": 7450,
            "name": "Proizvodnja namještaja",
            "code": "31.00",
            "parentId": 6586
        },
        {
            "id": 7452,
            "name": "Proizvodnja nakita, imitacije nakita  (bižuterije) i srodnih proizvoda",
            "code": "32.10",
            "parentId": 6587
        },
        {
            "id": 7453,
            "name": "Prerađivačka industrija, d. n.",
            "code": "32.90",
            "parentId": 6592
        },
        {
            "id": 7455,
            "name": "Popravak proizvoda od metala, mašina i opreme",
            "code": "33.10",
            "parentId": 6593
        },
        {
            "id": 7457,
            "name": "Proizvodnja, prijenos i distribucija električne energije",
            "code": "35.10",
            "parentId": 6595
        },
        {
            "id": 7458,
            "name": "Proizvodnja plina; distribucija plinovitih goriva distribucijskom mrežom",
            "code": "35.20",
            "parentId": 6596
        },
        {
            "id": 7460,
            "name": "Sakupljanje otpada",
            "code": "38.10",
            "parentId": 6600
        },
        {
            "id": 7461,
            "name": "Obrada i zbrinjavanje otpada",
            "code": "38.20",
            "parentId": 6601
        },
        {
            "id": 7462,
            "name": "Reciklaža materijala",
            "code": "38.30",
            "parentId": 6602
        },
        {
            "id": 7465,
            "name": "Gradnja cesta i željezničkih pruga",
            "code": "42.10",
            "parentId": 6606
        },
        {
            "id": 7466,
            "name": "Gradnja  cjevovoda,  vodova  za  električnu  struju  i telekomunikacije",
            "code": "42.20",
            "parentId": 6607
        },
        {
            "id": 7467,
            "name": "Gradnja ostalih građevina niskogradnje",
            "code": "42.90",
            "parentId": 6608
        },
        {
            "id": 7469,
            "name": "Uklanjanje građevina i pripremni radovi na gradilištu",
            "code": "43.10",
            "parentId": 6609
        },
        {
            "id": 7470,
            "name": "Elektroinstalacijski  radovi,  uvođenje instalacija  vodovoda, kanalizacije i plina i ostali građevinski instalacijski radovi",
            "code": "43.20",
            "parentId": 6610
        },
        {
            "id": 7471,
            "name": "Završni građevinski radovi",
            "code": "43.30",
            "parentId": 6611
        },
        {
            "id": 7472,
            "name": "Ostale specijalizirane građevinske djelatnosti",
            "code": "43.90",
            "parentId": 6612
        },
        {
            "id": 7474,
            "name": "Trgovina motornim vozilima",
            "code": "45.10",
            "parentId": 6613
        },
        {
            "id": 7475,
            "name": "Trgovina dijelovima i priborom za motorna vozila",
            "code": "45.30",
            "parentId": 6615
        },
        {
            "id": 7477,
            "name": "Trgovina na veliko uz naknadu ili na osnovu ugovora",
            "code": "46.10",
            "parentId": 6617
        },
        {
            "id": 7478,
            "name": "Trgovina na veliko poljoprivrednim sirovinama i živim životinjama",
            "code": "46.20",
            "parentId": 6618
        },
        {
            "id": 7479,
            "name": "Trgovina na veliko hranom, pićima i duhanskim proizvodima",
            "code": "46.30",
            "parentId": 6619
        },
        {
            "id": 7480,
            "name": "Trgovina na veliko proizvodima za domaćinstvo",
            "code": "46.40",
            "parentId": 6620
        },
        {
            "id": 7481,
            "name": "Trgovina na veliko informacijsko-komunikacijskom opremom",
            "code": "46.50",
            "parentId": 6621
        },
        {
            "id": 7482,
            "name": "Trgovina na veliko ostalim mašinama, opremom i priborom",
            "code": "46.60",
            "parentId": 6622
        },
        {
            "id": 7483,
            "name": "Ostala specijalizirana trgovina na veliko",
            "code": "46.70",
            "parentId": 6623
        },
        {
            "id": 7485,
            "name": "Trgovina na malo u nespecijaliziranim prodavnicama",
            "code": "47.10",
            "parentId": 6625
        },
        {
            "id": 7486,
            "name": "Trgovina na malo hranom, pićima i duhanskim proizvodima u specijaliziranim prodavnicama",
            "code": "47.20",
            "parentId": 6626
        },
        {
            "id": 7487,
            "name": "Trgovina na malo informacijsko-komunikacijskom opremom u specijaliziranim prodavnicama",
            "code": "47.40",
            "parentId": 6628
        },
        {
            "id": 7488,
            "name": "Trgovina na malo ostalom opremom za domaćinstvo u specijaliziranim prodavnicama",
            "code": "47.50",
            "parentId": 6629
        },
        {
            "id": 7489,
            "name": "Trgovina na malo proizvodima za kulturu i rekreaciju u specijaliziranim prodavnicama",
            "code": "47.60",
            "parentId": 6630
        },
        {
            "id": 7490,
            "name": "Trgovina  na  malo  ostalom  robom  u  specijaliziranim prodavnicama",
            "code": "47.70",
            "parentId": 6631
        },
        {
            "id": 7491,
            "name": "Trgovina na malo na štandovima i tržnicama",
            "code": "47.80",
            "parentId": 6632
        },
        {
            "id": 7492,
            "name": "Trgovina na malo izvan prodavnica, štandova i tržnica",
            "code": "47.90",
            "parentId": 6633
        },
        {
            "id": 7494,
            "name": "Ostali kopneni prijevoz putnika",
            "code": "49.30",
            "parentId": 6636
        },
        {
            "id": 7495,
            "name": "Cestovni prijevoz robe i usluge preseljenja",
            "code": "49.40",
            "parentId": 6637
        },
        {
            "id": 7498,
            "name": "Zračni prijevoz robe i svemirski prijevoz",
            "code": "51.20",
            "parentId": 6644
        },
        {
            "id": 7500,
            "name": "Pomoćne djelatnosti u prijevozu",
            "code": "52.20",
            "parentId": 6646
        },
        {
            "id": 7504,
            "name": "Djelatnosti  keteringa  i  ostale  djelatnosti  pripreme  i usluživanja hrane",
            "code": "56.20",
            "parentId": 6654
        },
        {
            "id": 7506,
            "name": "Izdavanje knjiga, periodičnih publikacija i ostale izdavačke djelatnosti",
            "code": "58.10",
            "parentId": 6656
        },
        {
            "id": 7507,
            "name": "Izdavanje softvera",
            "code": "58.20",
            "parentId": 6657
        },
        {
            "id": 7509,
            "name": "Proizvodnja i distribucija filmova, videofilmova i televizijskog programa",
            "code": "59.10",
            "parentId": 6658
        },
        {
            "id": 7512,
            "name": "Računarsko programiranje, savjetovanje i djelatnosti u vezi s njima",
            "code": "62.00",
            "parentId": 6666
        },
        {
            "id": 7514,
            "name": "Obrada podataka, usluge hostinga i djelatnosti u vezi s njima; internetski portali",
            "code": "63.10",
            "parentId": 6667
        },
        {
            "id": 7515,
            "name": "Ostale informacijske uslužne djelatnosti",
            "code": "63.90",
            "parentId": 6668
        },
        {
            "id": 7517,
            "name": "Novčarsko posredovanje",
            "code": "64.10",
            "parentId": 6669
        },
        {
            "id": 7518,
            "name": "Ostale  finansijske uslužne  djelatnosti,  osim  osiguranja  i penzijskih fondova",
            "code": "64.90",
            "parentId": 6672
        },
        {
            "id": 7520,
            "name": "Osiguranje",
            "code": "65.10",
            "parentId": 6673
        },
        {
            "id": 7522,
            "name": "Pomoćne djelatnosti kod finansijskih usluga, osim osiguranja i penzijskih fondova",
            "code": "66.10",
            "parentId": 6676
        },
        {
            "id": 7523,
            "name": "Pomoćne djelatnosti u osiguranju i penzijskim fondovima",
            "code": "66.20",
            "parentId": 6677
        },
        {
            "id": 7525,
            "name": "Poslovanje nekretninama uz naknadu ili na osnovu ugovora",
            "code": "68.30",
            "parentId": 6681
        },
        {
            "id": 7528,
            "name": "Savjetovanje u vezi s upravljanjem",
            "code": "70.20",
            "parentId": 6685
        },
        {
            "id": 7530,
            "name": "Arhitektonske i inžinjerske djelatnosti i s njima povezano tehničko savjetovanje",
            "code": "71.10",
            "parentId": 6686
        },
        {
            "id": 7532,
            "name": "Istraživanje i eksperimentalni razvoj u prirodnim, tehničkim i tehnološkim naukama",
            "code": "72.10",
            "parentId": 6688
        },
        {
            "id": 7534,
            "name": "Promocija (reklama i propaganda)",
            "code": "73.10",
            "parentId": 6690
        },
        {
            "id": 7537,
            "name": "Iznajmljivanje i davanje u zakup (leasing) motornih vozila",
            "code": "77.10",
            "parentId": 6697
        },
        {
            "id": 7538,
            "name": "Iznajmljivanje i davanje u zakup (leasing) predmeta za ličnu upotrebu i domaćinstvo",
            "code": "77.20",
            "parentId": 6698
        },
        {
            "id": 7539,
            "name": "Iznajmljivanje i davanje u zakup (leasing) ostalih mašina, opreme te materijalnih dobara",
            "code": "77.30",
            "parentId": 6699
        },
        {
            "id": 7542,
            "name": "Djelatnosti putničkih agencija i turoperatora",
            "code": "79.10",
            "parentId": 6704
        },
        {
            "id": 7545,
            "name": "Djelatnosti čišćenja",
            "code": "81.20",
            "parentId": 6710
        },
        {
            "id": 7547,
            "name": "Kancelarijske administrativne i pomoćne djelatnosti",
            "code": "82.10",
            "parentId": 6712
        },
        {
            "id": 7548,
            "name": "Poslovne pomoćne uslužne djelatnosti, d. n.",
            "code": "82.90",
            "parentId": 6715
        },
        {
            "id": 7550,
            "name": "Javna uprava te ekonomska i socijalna politika zajednice",
            "code": "84.10",
            "parentId": 6716
        },
        {
            "id": 7551,
            "name": "Pružanje usluga zajednici kao cjelini",
            "code": "84.20",
            "parentId": 6717
        },
        {
            "id": 7552,
            "name": "Obrazovanje",
            "code": "85.00",
            "parentId": 6749
        },
        {
            "id": 7553,
            "name": "Srednje obrazovanje",
            "code": "85.30",
            "parentId": 6721
        },
        {
            "id": 7554,
            "name": "Visoko obrazovanje",
            "code": "85.40",
            "parentId": 6722
        },
        {
            "id": 7555,
            "name": "Ostalo obrazovanje i poučavanje",
            "code": "85.50",
            "parentId": 6723
        },
        {
            "id": 7557,
            "name": "Djelatnosti medicinske i stomatološke prakse",
            "code": "86.20",
            "parentId": 6726
        },
        {
            "id": 7559,
            "name": "Djelatnosti socijalne zaštite bez smještaja",
            "code": "88.00",
            "parentId": 6750
        },
        {
            "id": 7560,
            "name": "Ostale djelatnosti socijalne zaštite bez smještaja",
            "code": "88.90",
            "parentId": 6732
        },
        {
            "id": 7561,
            "name": "Kreativne, umjetničke i zabavne djelatnosti",
            "code": "90.00",
            "parentId": 6733
        },
        {
            "id": 7562,
            "name": "Biblioteke, arhivi, muzeji i ostale kulturne djelatnosti",
            "code": "91.00",
            "parentId": 6734
        },
        {
            "id": 7564,
            "name": "Sportske djelatnosti",
            "code": "93.10",
            "parentId": 6737
        },
        {
            "id": 7565,
            "name": "Zabavne i rekreacijske djelatnosti",
            "code": "93.20",
            "parentId": 6738
        },
        {
            "id": 7567,
            "name": "Djelatnosti poslovnih organizacija, udruženja poslodavaca i strukovnih članskih organizacija",
            "code": "94.10",
            "parentId": 6739
        },
        {
            "id": 7568,
            "name": "Djelatnosti ostalih članskih organizacija",
            "code": "94.90",
            "parentId": 6741
        },
        {
            "id": 7570,
            "name": "Popravak računara i komunikacijske opreme",
            "code": "95.10",
            "parentId": 6742
        },
        {
            "id": 7571,
            "name": "Popravak predmeta za ličnu upotrebu i domaćinstvo",
            "code": "95.20",
            "parentId": 6743
        },
        {
            "id": 7572,
            "name": "Ostale lične uslužne djelatnosti",
            "code": "96.00",
            "parentId": 6744
        }
];

export { marketActivities }
