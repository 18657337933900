<template>
    <div class="col-lg-12 col-md-12 form-group">
        <div class="content col-md-12 p-0">
            <div class="card-item rounded pt-3 pl-3 pr-3" >
                <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + legalEntity.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                    <div class="col-lg-3 col-md-6">
                        <div class="small text-muted">{{ $t("legalEntity") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.shortName }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.jib }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="small text-muted">{{ $t("county") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.county }}</div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        <div class="small text-muted">{{ $t("municipality") }}</div>
                        <div class="small font-weight-bold">{{ legalEntity.municipality }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 form-group">
                        <div class="collapse" v-bind:id="'collapse_' + legalEntity.id">
                            <hr />
                            <div class="card card-body">
                                <div class="row">
                                    <div class="col-md-4 form-group">
                                        <div class="small text-muted">{{ $t("legalEntityFullName") }}</div>
                                        <div class="font-weight-bold small">{{ legalEntity.longName }}</div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="small text-muted">{{ $t("taxPayerType") }}</div>
                                        <div class="font-weight-bold small">{{ legalEntity.legalEntityType }}</div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="small text-muted">{{ $t("address") }}</div>
                                        <div class="font-weight-bold small">{{ legalEntity.address }}</div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="small text-muted">{{ $t("activityCode") }}</div>
                                        <div class="font-weight-bold small">{{ legalEntity.activityCode }}</div>
                                    </div>
                                    <div class="col-md-4 form-group">
                                        <div class="small text-muted">{{ $t("activityCodeDescription") }}</div>
                                        <div class="font-weight-bold small"> ({{ legalEntity.letterOfActivity }}) - {{ legalEntity.activityCodeDescription }}</div>
                                    </div>
                                </div>
                                <div class="row" v-if="componentTypeId">
                                    <div class="col-md-12">
                                        <hr class="mt-0"/>
                                        <component v-bind:is="getLegalEntityCardComponent(componentTypeId)" v-bind:legalEntityId="legalEntity.id" v-bind:legalEntityJib="legalEntity.jib" v-bind:years="years"></component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"
    import CardContentDefault from "./CardContentDefault"
    import CardContentAssociationAndFondation from "./CardContentAssociationAndFondation"
    import CardContentFinancialInstitution from "./CardContentFinancialInstitution"
    import CardContentInsuranceCompanies from "./CardContentInsuranceCompanies"
    import CardContentInvestmentFunds from "./CardContentInvestmentFunds"
    import CardContentBudget from "./CardContentBudget"

    export default {
        data() {
            return {
            }
        },
        props: ["legalEntity", "componentTypeId", "years"],
        computed: {
            ...mapGetters(["getLegalEntityCardComponent"]),
        },
        components: {
            appCardContentDefault: CardContentDefault,
            appCardContentAssociationAndFondation: CardContentAssociationAndFondation,
            appCardContentFinancialInstitution: CardContentFinancialInstitution,
            appCardContentInsuranceCompanies: CardContentInsuranceCompanies,
            appCardContentInvestmentFunds: CardContentInvestmentFunds,
            appCardContentBudget: CardContentBudget,
        },
    }
</script>

<style scoped>
    .card-item {
        width: 100%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }

        .card-item:hover {
            transform: scale(1.01);
        }

    .card-body {
        background-color: whitesmoke;
    }

    .btn {
        background-color: #243B80;
        border: #243B80;
        color: #FFFFFF;
    }

        .btn:hover {
            background-color: #374e96;
            border: #374e96;
            color: #FFFFFF;
        }

</style>

