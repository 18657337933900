<template>
    <div class="col-lg-12 col-md-12 form-group">
        <router-link v-if="!legalEntity.isPublicEntity" :to="{ name:'legalEntityProfile', params:{ id: legalEntity.id } }" tag="div" >
            <div class="content col-md-12 p-0">
                <div class="card-item rounded pt-3 pl-3 pr-3 pb-1">
                    <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + legalEntity.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <div class="col-lg-5 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("legalEntity") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.shortName }}</div>
                        </div>
                        <div class="col-lg-2 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.jib }}</div>
                        </div>
                        <div class="col-lg-3 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("county") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.county }}</div>
                        </div>
                        <div class="col-lg-2 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("municipality") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.municipality }}</div>
                        </div>
                        <div class="col-lg-12 text-center small">
                            <hr class="mt-0 mb-0"/>
                            <span class="small text-muted text-center text-primary d-inline-block p-1">{{ $t("clickToSeeLegalEntityProfile") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
        <router-link v-if="legalEntity.isPublicEntity" :to="{ name:'legalEntityProfilePublic', params:{ id: legalEntity.id } }" tag="div" >
            <div class="content col-md-12 p-0">
                <div class="card-item rounded pt-3 pl-3 pr-3 pb-1">
                    <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + legalEntity.id" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <div class="col-lg-5 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("legalEntity") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.shortName }}</div>
                        </div>
                        <div class="col-lg-2 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("uniqeIdentificationNumberShort") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.jib }}</div>
                        </div>
                        <div class="col-lg-3 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("county") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.county }}</div>
                        </div>
                        <div class="col-lg-2 col-md-6 form-group">
                            <div class="small text-muted">{{ $t("municipality") }}</div>
                            <div class="small font-weight-bold">{{ legalEntity.municipality }}</div>
                        </div>
                        <div class="col-lg-12 text-center small">
                            <hr class="mt-0 mb-0"/>
                            <span class="small text-muted text-center text-primary d-inline-block p-1">{{ $t("clickToSeeLegalEntityProfile") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>

    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        data() {
            return {
            }
        },
        props: ["legalEntity", "componentTypeId", "years"],
        computed: {
            ...mapGetters(["getLegalEntityCardComponent"]),
        },
        components: {
        },
    }
</script>

<style scoped>
    .card-item {
        width: 100%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }

        .card-item:hover {
            transform: scale(1.01);
        }

    .card-body {
        background-color: whitesmoke;
    }

    .btn {
        background-color: #243B80;
        border: #243B80;
        color: #FFFFFF;
    }

        .btn:hover {
            background-color: #374e96;
            border: #374e96;
            color: #FFFFFF;
        }

</style>

