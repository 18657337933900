const legalEntitySizes = [
    {
        "id": 1,
        "name": "Malo",
        "description": "Malo",
    },
    {
        "id": 2,
        "name": "Srednje",
        "description": "Srednje",
    },
    {
        "id": 3,
        "name": "Veliko",
        "description": "Veliko",
    }
];

export { legalEntitySizes }