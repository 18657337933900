<template>
    <div class="content form-group">
        <div class="row">
            <div class="col-md-12 stage">
                <!--<roqquter-link tag="div" class="text-center rounded visit-profile-btn font-weight-bold prepare-control box bounce-1" :to="{ name: 'financial-reports-templates' }">
                    <i class="fa fa-table fa-large mr-2"></i>{{ $t("newPublications") | upperCase  }}
                </roqquter-link>-->
                <a class="text-center rounded visit-profile-btn font-weight-bold box"
                    style="background-color: #243B80; color: #fff; font-weight: 500 !important;"
                    href="bs/content/7100/fbih-poslovanje-pravnih-lica-u-2023-godini">
                    <i class="fa fa-area-chart fa-large mr-2"></i>{{ $t("newPublications") | upperCase }}
                </a>
                <hr />
            </div>
            <!-- <div class="col-md-12 stage">
                <router-link tag="div"
                    class="text-center rounded visit-profile-btn font-weight-bold prepare-control box "
                    style="background-color: #253746;"
                    :to="{ name: 'financial-reports-templates' }">
                    <i class="fa fa-table fa-large mr-2"></i>{{ $t("prepareControlFR") | upperCase }}
                </router-link>
                <hr />
            </div> -->

            <div class="col-md-12 stage">
                <a class="text-center rounded visit-profile-btn font-weight-bold box " target="_blank"
                style="background-color: #243B80;  width: 100%; color:white; font-weight: 550 !important;"
                    href="https://fia.ba/upload/brosura-2024/brosura.html">
                    <i class="fa fa-pie-chart fa-large mr-2"></i>{{ $t("brochures2022") | upperCase }}
                </a>
                <hr />
            </div>

            <div class="col-md-12 stage" style="border-top: 1px solid #E5E5E5; margin-top:2%">
                <router-link tag="div"
                    class="text-center rounded visit-profile-btn font-weight-bold prepare-control box "
                    style="background-color: #0165B2; color: #000; margin-top:7%; width: 100%; color:white; font-weight: 500 !important;"
                    :to="{ name: 'profil-pravnih-lica' }">
                    <i class="fa fa-bar-chart-o fa-large mr-2"></i>{{ $t("businessEntitiesProfile") | upperCase }}
                </router-link>
                <hr />
            </div>

            <div class="col-md-12 stage">
                <router-link tag="div"
                    class="text-center rounded visit-profile-btn font-weight-bold prepare-control box "
                    style="background-color: #0165B2; color: #000;  width: 100%; color:white; font-weight: 500 !important;"
                    :to="{ name: 'profil-javnih-preduzeca' }">
                    <i class="fa fa-line-chart fa-large mr-2"></i>{{ $t("legalEntitiesProfilePublic") | upperCase }}
                </router-link>
                <hr />
            </div>

            <div class="col-md-12 stage" style="border-top: 1px solid #E5E5E5; margin-top:2%">
                <router-link tag="div"
                    class="text-center rounded visit-profile-btn font-weight-bold prepare-control box "
                    style="background-color: #f68600; color: #000; margin-top:7%; width: 100%; color:white; font-weight: 500 !important;"
                    :to="{ name: 'financial-reports-templates' }">
                    <i class="fa fa-table fa-large mr-2"></i>{{ $t("prepareControlFR22") | upperCase }}
                </router-link>
                <hr />
            </div>

            <div class="col-md-12 stage">
                <a class="text-center rounded visit-profile-btn font-weight-bold box"
                    style="background-color: #f68600; color: #000; width: 100%; color:white; font-weight: 500 !important;"
                    href="bs/content/7077/koristenje-dokumentacije-i-podataka">
                    <i class="fa fa-exclamation fa-large mr-2"></i>{{ $t("napomena") | upperCase }}
                </a>
                <hr />
            </div>
        </div>
        <!-- <hr /> -->
        <!--  <div class="row">
            <div class="col-md-12">
                <span class="font-weight-bold d-inline-block title mt-1"><i class="fa fa-building fa-large mr-2"></i> {{ $t("businessEntitiesProfile") | upperCase }}</span>
                <hr />
                <img src="../../assets/legal-entity-profile.jpg" class="rounded mb-3 business-entities-profile-img" />
                <span class="text-justify text-muted small" v-html="$t('businessEntityProfileHomepageDescription')"></span>
                <hr />
                <router-link tag="div" class="text-center rounded visit-profile-btn font-weight-bold" :to="{ name: 'profil-pravnih-lica' }">
                    <i class="fa fa-search mr-2"></i>{{ $t("searchLegalEntities") | upperCase  }}
                </router-link>
            </div>
        </div> -->
    </div>
</template>

<script>

export default {
    filters: {
        upperCase(value) {
            return value.toUpperCase();
        }
    }
}
</script>

<style scoped>
.content {
    border-radius: 5px;
    padding: 5%;
    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
    font-size: 20px;
}

.visit-profile-btn:hover {
    background-color: #243B80;
}

.visit-profile-btn {
    background-color: #243B80;
    color: #FFFFFF;
    cursor: pointer;
    padding: 3%;
}

.logo {
    height: 20px;
    width: 20px;
}

.content {
    font-size: 15px;
}

.fa-large {
    font-size: 20px;
}

.business-entities-profile-img {
    width: 100%;
}

.prepare-control {
    background-color: #C82333;
}

.prepare-control:hover {
    background-color: #F72A3F;
}

.stage {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
}

.box {
    align-self: flex-end;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    margin: 0 auto 0 auto;
    transform-origin: bottom;
}

.bounce-1 {
    animation-name: bounce-1;
    animation-timing-function: ease-in-out;
}

@keyframes bounce-1 {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1, 1) translateY(0);
    }

    30% {
        transform: scale(1, 1) translateY(-15px);
    }

    50% {
        transform: scale(1, 1) translateY(0);
    }

    57% {
        transform: scale(1, 1) translateY(0);
    }

    64% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}
</style>
