<template>
    <div>
        <div class="text-center">
            <span class="tag year-tag rounded small d-inline-block pl-4 pr-4 mb-3 mr-2"
                v-bind:class="{ 'selected': year == selectedYear }" v-for="year in years" v-bind:key="year"
                v-on:click="selectYear(year)">{{ year }}</span>
        </div>
        <hr class="mt-0" />
        <div class="text-center">
            <span class="tag rounded report-tag small d-inline-block pl-4 pr-4 mb-3 mr-2"
                v-bind:class="{ 'selected': financialReport.id == selectedFinancialReportId }"
                v-for="financialReport in financialReports" v-bind:key="financialReport.id"
                v-on:click="selectFinancialReport(financialReport.id)">{{ financialReport.name }}</span>
        </div>
        <div class="form-group bg-primary rounded p-2 text-center">
            <span class="text-primary small font-weight-bold">{{ $t("abbreviatedBalanceSheet").toUpperCase()
            }}</span>
        </div>
        <!--<span class="small text-muted"><i class="fa fa-info-circle info-msg" v-bind:title="$t('chooseYearToSeeResults')"></i></span>-->
        <table class="table table-sm mb-0 table-responsive" v-show="financialIndicator && selectedYear<2022">
            <tbody>
                <tr>
                    <td class="small font-weight-bold" width="100%">{{ $t("companies_BSA_065") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_065" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_001") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_001" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_002") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_002" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_010") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_010" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_011") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_011" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_021") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_021" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_035") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_035" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_036") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_036" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_044") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_044" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_047") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_047" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSA_053") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsA_053" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSP_102") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_102" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSP_117") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_117" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSP_131") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_131" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSP_140") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_140" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BSP_152") }}</td>
                    <td class="small text-right">
                        <canvas ref="bsP_152" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>
        </table>

        <table class="table table-sm mb-0 table-responsive" v-show="financialIndicator && selectedYear>=2022">
            <tbody>
                <tr>
                    <td class="small font-weight-bold" width="100%">{{ $t("companies_JP_DugorocnaImovina") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_DugorocnaImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_NematerijalnaImovina") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_NematerijalnaImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_GradjevinskiObjekti") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_MSV_GradjevinskiObjekti" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_PostrojenjaIOpremaMSV") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_NekretninePostrojenjaIOprema" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_FinansijskaImovinaMSV") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_FinansijskaImovinaIUlaganja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_KratkorocnaImovina") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_KratkorocnaImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_ZaliheDugorocnaImovina") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_ZaliheDugorocnaImovinaNamijenjenaProdaji" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_NovacINovcaniEkvivalenti") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_NovacINovcaniEkvivalenti" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_PotrazivanjaOdKupacaMSV") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_PotrazivanjaOdKupacaIUgovorenaImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_FinImovinaMSV") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_OstalaFinansijskaImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_UkupnoImovina") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_UkupnoImovina" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_DugorocneObaveze") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_DugorocneObaveze" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_KratkorocneObaveze") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_KratkorocneObaveze" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_ObavezePremaDobavljacimaMSV") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_ObavezePremaDobavljacimaIUgovoreneObaveze" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_VlasnickiKapital") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_VlasnickiKapital" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_AkumuliranaNerasoporedjenaDobit") }}</td>
                    <td class="small text-right">
                        <canvas ref="bS_JP_AkumuliranaNerasporedjenaDobitIzPrethodnogPerioda" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-center small text-muted p-3" v-show="!financialIndicator && selectedYear">
            {{ $t("noResultsForSelectedYear") }}
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    data() {
        return {
            financialIndicators: [],
            financialReports: [],
            selectedYear: null,
            selectedFinancialReportId: null,
            window: {
                width: null
            }
        }
    },
    props: ["legalEntityId", "years"],
    methods: {
        ...mapActions(["fetchFinancialIndicators"]),
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE').format(value);
        },
        selectYear(year) {
            this.selectedYear = year;
            //TODO: Log Search

            if (this.financialIndicators.length > 0) {
                this.financialReports = this.financialIndicators.sort(fi => fi.reportSubmissionDate).filter(x => x.year == this.selectedYear).map(x => { return x.reportType });
                this.selectFinancialReport(this.financialIndicators.filter(fi => fi.year == this.selectedYear).sort(fi => fi.reportSubmissionDate)[0].reportTypeId);
            }
        },
        selectFinancialReport(financialReportId) {

            this.selectedFinancialReportId = financialReportId;

            if (this.financialIndicator) {
                for (let [key, value] of Object.entries(this.$refs)) {

                    let ctx = value.getContext("2d");
                    ctx.clearRect(0, 0, value.width, value.height); //Clear canvas before drawing new text into it
                    ctx.font = "12px Roboto"; //setting font
                    ctx.textAlign = "end" //Aligning text

                    let width = 95;

                    let negativeValue = false;

                    if (key == "bU_225" && this.financialIndicator[key] == 0) {
                        key = "bU_226";
                        if (this.financialIndicator[key] != 0)
                            negativeValue = true;
                    }

                    if (key == "bU_311" && this.financialIndicator[key] == 0) {
                        key = "bU_312";
                        if (this.financialIndicator[key] != 0)
                            negativeValue = true;
                    }

                    ctx.fillText(this.formatNumber((negativeValue ? (-1) : 1) * this.financialIndicator[key]), width, 24); //Drawing text into canvas
                }
            }

        },
        handleResize() {
            this.window.width = window.innerWidth;
        }
    },
    computed: {
        //years() {
        //    return this.financialIndicators.map(fi => fi.year).sort((a, b) => a - b);
        //},
        financialIndicator() {

            if (this.selectedYear == null || this.selectedFinancialReportId == null)
                return null;

            return this.financialIndicators.find(fi => fi.year == this.selectedYear && fi.reportTypeId == this.selectedFinancialReportId);
        },
        windowWidth() {
            return this.window.width;
        }
    },
    created() {
        let searchObject = { legalEntityId: this.legalEntityId, searchType: 2 };

        this.fetchFinancialIndicators(searchObject).then((financialIndicators) => {
            this.financialIndicators = financialIndicators;
            this.selectYear(this.years[this.years.length - 1]);
        });

        //window.addEventListener('resize', this.handleResize);
        //this.handleResize();
    }
}

</script>

<style scoped>
.tag {
    background-color: rgba(169, 169, 169, 0.5);
    color: #243B80;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
    transition: transform .2s;
}

.year-tag:hover {
    transform: scale(1.1);
}

.report-tag:hover {
    transform: scale(1.05);
}

.selected {
    background-color: #243B80;
    color: white;
}

td {
    vertical-align: middle;
}

.info-msg {
    font-size: 150%;
    position: absolute;
    top: 23px;
    cursor: pointer;
    transition: transform .2s;
}

.info-msg:hover {
    color: #243B80;
    transform: scale(1.1);
}

.bg-primary {
    background-color: rgba(169, 169, 169, 0.3) !important;
}

.text-primary {
    color: #243B80 !important;
}
</style>

