const accountStatuses = [
    {
        "id": 1,
        "name": "Nema račun u JRR",
        "description": "Račun aktivan",
    },
    {
        "id": 2,
        "name": "Nema glavni račun u JRR",
        "description": "Nema glavni račun u JRR",
    },
    {
        "id": 3,
        "name": "Ima blokiran glavni račun u JRR",
        "description": "Ima blokiran glavni račun u JRR",
    },
    {
        "id": 4,
        "name": "Ima račun u JRR",
        "description": "Ima račun u JRR",
    }
];

export { accountStatuses }