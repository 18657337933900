<template>
    <div class="col-lg-12 col-md-12 form-group">
        <div class="content col-md-12 p-0">
            <div class="card-item rounded pt-3 pr-3 pl-3 pb-1" v-bind:class="{'border border-danger': file.errors.length > 0, 'border border-success': file.errors.length == 0}">
                <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + file.id" role="button" aria-expanded="false" aria-controls="collapseExample" @click="expandCard">
                    <div class="col-md-6 form-group">
                        <div class="small text-muted">{{ $t("reportName") }}</div>
                        <div class="small font-weight-bold">{{ file.name }}</div>
                    </div>
                    <div class="col-md-6 form-group text-right align-self-center">
                        <div class="small text-muted">{{ $t("status") }}</div>
                        <i class="fa fa-check fa-2x text-success" v-if="file.errors.length == 0"></i>
                        <i class="fa fa-close fa-2x text-danger" v-if="file.errors.length > 0"></i>
                    </div>
                    <div class="col-md-12 text-center" v-show="!cardExpanded || file.errors.length == 0">
                        <hr class="m-0" />
                        <span class="small text-muted">{{ file.errors.length == 0 ? $t("noErrors") : $t("clickToShowErrors") + ` (${file.errors.length})`}}</span>
                    </div>
                </div>
                <div class="row" v-if="file.errors.length > 0">
                    <div class="col-md-12" :class="{'form-group': cardExpanded}">
                        <div class="collapse" v-bind:id="'collapse_' + file.id">
                            <hr class="mt-1"/>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 small text-danger">
                                            <ul>
                                                <li v-for="(error, index) in file.errors" v-bind:key="index" v-html="error"></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["file"],
        data() {
            return {
                cardExpanded: false
            }
        },
        methods: {
            expandCard() {
                this.cardExpanded = !this.cardExpanded;
            }
        }
    }
</script>

<style scoped>
    .card-item {
        width: 100%;
        background-color: white;
        border-top: 1px solid rgba(0,0,0,0.06);
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
    }

    .card-body {
        background-color: whitesmoke;
    }
</style>

