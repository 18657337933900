import Axios from "axios"
import interceptors from "../interceptors"

const entity = Axios.create({
    baseURL: "https://api-entities.fia.ba/api",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

entity.interceptors.request.use(interceptors.requestInterceptor, interceptors.requestErrorInterceptor);
entity.interceptors.response.use(interceptors.responseInterceptor, interceptors.responseErrorInterceptor);

export default entity;
