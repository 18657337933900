<template>
    <div v-if="hasHiglightedNews" class="higlight-placeholder rounded form-group mb-4">
        <app-vueper-slides autoplay
                           pauseOnHover
                           :touchable="false"
                           fixedHeight="100%">
            <app-vueper-slide v-for="(highlightItem) in getHighlightedNews"
                              v-bind:key="highlightItem.id"
                              v-bind:duration="5000"
                              v-bind:image="highlightItem.url"
                              v-bind:style="'border-radius: 5px; color: white; background-color: ' + color">
                <template v-slot:content>
                    <div class="text-center content">
                        <div class="row">
                            <div class="col-md-12 form-group container mt-5">
                                <h6 class="title font-weight-bold">{{ highlightItem.title }}</h6>
                                <div class="subtitle small text-center">{{ highlightItem.subtitle }}</div>
                                <router-link :to="{ name:'content', params:{id: highlightItem.id, slug: highlightItem.slug }}">
                                    <button class="btn btn-sm p-0 pl-2 pr-2 mt-2 text-white border" v-bind:style="'background-color: ' + color"><span class="small">{{ $t('seeMore') }}</span></button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </template>
            </app-vueper-slide>
            <template v-slot:bullet="{ active }">
                <span class="slide-bullet font-weight-bold rounded-circle">
                    <span class="slide-bullet-active rounded-circle" v-show="active" v-bind:style="'background-color:' + (active ? color : 'white')"></span>
                </span>
            </template>
        </app-vueper-slides>    
    </div>
</template>

<script>

    import { VueperSlides, VueperSlide } from 'vueperslides'
    import 'vueperslides/dist/vueperslides.css'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                color: "#243B80"
            }
        },
        components: {
            appVueperSlide: VueperSlide,
            appVueperSlides: VueperSlides,
        },
        computed: {
            ...mapGetters(["getHighlightedNews"]),
            hasHiglightedNews() {
                return this.getHighlightedNews.length > 0;
            }
        }
    }
</script>

<style scoped>
    .higlight-placeholder {
        width: 100%;
        height: 300px;
        z-index: 0;
        position: relative;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.33);
    }

    .slide-bullet {
        display: block;
        background-color: white;
        padding: 3px 3px 3px 3px;
    }

    .slide-bullet-active {
        display: block;
        height: 15px;
        width: 15px;
        background-color: #30abdb;
    }

    .content {
        height: 100%;
        background-color: rgba(0,0,0,0.6);
        border-radius: 5px;
    }

    .subtitle {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
    }
    
</style>

