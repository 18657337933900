<template>
    <div>       
        <div class="col-md-12 text-center">
            <ul class="nav nav-pills nav-fill rounded">
                <li v-for="menuItem in getChildrenByParentId(menu.id)" v-bind:key="menuItem.id" class="nav-item p-2 rounded">
                    <router-link v-if="!isExternalLink(menuItem)" v-bind:to="computeRoute(menuItem)" tag="div">
                        <div class="small text-white font-weight-bold font-size">{{ menuItem.title.toUpperCase() }}</div>
                    </router-link>
                    <a v-else v-bind:href="menu.url" target="_blank">
                        <div class="small text-white">{{ menuItem.title.toUpperCase() }}</div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        props: ["menu"],
        name: "AppSubMenuItemFooter",
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(["getChildrenByParentId", "hasContentChildren", "getContentTypes"]),
        },
        methods: {
            isExternalLink(menu) {
                return menu.contentTypeId == this.getContentTypes.linkContentTypeId && menu.url.startsWith("http");
            },
            isInternalLink(menu) {
                return menu.contentTypeId == this.getContentTypes.linkContentTypeId && !menu.url.startsWith("http");
            },
            computeRoute(menu) {
                return this.isInternalLink(menu) ? { name: menu.url } : { name: 'content', params: { id: menu.id, slug: menu.slug } };
            },
        }
    }
</script>

<style scoped>

    .title {
        color: #494A4B;
    }

    .nav-item {
        cursor: pointer;
        transition: transform .2s;
    }

        .nav-item:hover {
            background-color: #374e96;
        }

    .font-size {
        /* font-size:70%; */
        font-size: 10px;
        font-weight: 500 !important;
    }
</style>
