<template>
    <div class="col-lg-12 col-md-12 form-group mb-2">
        <div class="content col-md-12 p-0">
            <div class="higlighted-publication-item rounded">
                <div class="row">
                    <router-link v-bind:to="computeRoute" tag="div" class="col-12 ">
                        <div class="row">
                            <div class="col-12">
                                <img :src="infographic.url" class="col-12 pl-0 pr-0">
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["infographic"],
        computed: {
            computeRoute() {
                return { name: 'content', params: { id: this.infographic.id, slug: this.infographic.slug } };
            }
        }
    }
</script>

