<template>
    <div>
        <div class="row" v-on:keyup.enter="search()">
            
            <b-modal id="modal-container" 
                     :ok-title="$t('save')"
                     :cancel-title="$t('cancel')"
                     :title="$t('bankruptcyOrPrebankruptcyAdd')" 
                     @cancel="clearDocumentObject"
                     @close="clearDocumentObject"
                     @ok="saveBankruptcyDocument">
               
                <div class="row">
                    <div class="col-md-12 form-group">
                        <input class="form-control" v-model="document.jib" v-bind:placeholder="$t('uniqeIdentificationNumberShort')" v-bind:class="{'validation-error': $v.document.jib.$error}" v-on:input="$v.document.jib.$touch()" v-on:change="jibChanged" />
                        <p class="small text-danger m-0" v-if="$v.document.jib.$dirty && !$v.document.jib.required">{{ $t('jibIsRequired') }}</p>
                        <p class="small text-danger m-0" v-if="$v.document.jib.$dirty && (!$v.document.jib.minLength || !$v.document.jib.maxLength)">{{ $t('jibLengthError') }}</p>
                        <p class="small text-danger m-0" v-if="$v.document.jib.$dirty && !$v.document.jib.numeric">{{ $t('jibAlphabetNotAllowedError') }}</p>
                        <p class="small text-success m-0" v-if="documents_showLegalEntityStatus && documents_showLegalEntityNameOrError">{{ document.legalEntityName }}</p>
                        <p class="small text-danger m-0" v-else-if="documents_showLegalEntityStatus && !documents_showLegalEntityNameOrError">{{ $t('jibErrLegalEntityDoesntExist') }}</p>
                    </div>
                    <div class="col-md-12 form-group">
                        <app-select v-model="document.documentTypeId" v-bind:options="documentTypes" v-bind:reduce="documentType => documentType.id" label="name" v-bind:placeholder="$t('chooseDocumentType')" class="select2" v-bind:class="{'validation-error': $v.document.documentTypeId.$error}" v-on:change="$v.document.documentTypeId.$touch()">
                            <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                            <template #search="{ attributes, events }" >
                                <input class="vs__search"
                                       v-bind="attributes"
                                       v-on="events"  />
                            </template>
                        </app-select>
                        <p class="small text-danger m-0" v-if="$v.document.documentTypeId.$dirty && !$v.document.documentTypeId.required">{{ $t('documentTypeIsRequired') }}</p>
                    </div>
                    <div class="col-md-12 form-group">
                        <b-form-datepicker v-model="document.date"
                                           :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                           :placeholder="$t('chooseDate')"
                                           locale="en-GB"
                                           v-bind:class="{'validation-error': $v.document.date.$error}" v-on:input="$v.document.date.$touch()"
                                           class="mb-2"></b-form-datepicker>
                        <p class="small text-danger m-0" v-if="$v.document.date.$dirty && !$v.document.date.required">{{ $t('dateIsRequired') }}</p>
                    </div>
                    <div class="col-md-12 form-group">
                        <div class="input-group" v-bind:class="{'validation-error': $v.document.file.$error}" v-on:input="$v.document.file.$touch()">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="inputGroupFileAddon01"><i class="fa fa-file"></i></span>
                            </div>
                            <div class="custom-file" >
                                <input type="file" v-on:change="onFileChange" :accept="allowedFileTypes" class="custom-file-input" v-bind:lang="this.$route.params.lang"  />
                                <label class="custom-file-label"> {{  getUploadedFileName }} </label>
                            </div>
                        </div>
                        <p class="small text-danger m-0" v-if="$v.document.file.$dirty && !$v.document.file.required">{{ $t('fileIsRequired') }}</p>
                    </div>
                </div>

            </b-modal>

            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-lg-12 col-md-12 form-group">
                <!-- Search panel -->
                <div class="search-header p-3 form-group">
                    <i class="fa fa-search mr-3"></i>
                    <label>{{ $t("search") }}</label>
                    <!-- add btn panel -->

                    <b-button v-if="getIsAdminOrModerator()" class="float-right btn-add" v-b-modal.modal-container><i class="fa fa-plus"></i> {{ $t("add") }} </b-button>

                    <hr />
                    <div class="row">
                        <div class="col-lg-6 form-group">
                            <input class="form-control" v-model="searchObject.name" v-bind:placeholder="$t('legalEntityName')" />
                        </div>
                        <div class="col-lg-6 form-group">
                            <input class="form-control" v-model="searchObject.jib" v-bind:placeholder="$t('uniqeIdentificationNumberShort')" />
                        </div>
                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.countyId" v-bind:options="counties" v-bind:reduce="county => county.id" label="name" v-bind:placeholder="$t('chooseCounty')" class="select2">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                        </div>

                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.municipalityId" v-bind:options="municipalities" v-bind:reduce="municipality => municipality.id" label="name" v-bind:placeholder="$t('chooseMunicipality')" class="select2" :disabled="municipalities.length == 0">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                        </div>

                        <div class="col-lg-4 form-group">
                            <app-select v-model="searchObject.documentTypeId" v-bind:options="documentTypes" v-bind:reduce="documentType => documentType.id" label="name" v-bind:placeholder="$t('chooseDocumentType')" class="select2">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                        </div>

                        <!--<div class="col-12 form-group">
                    <app-treeselect v-model="searchObject.marketActivityId"
                                    style="width:90%"
                                    class="d-inline-flex tree-select"
                                    :multiple="false"
                                    :clearable="false"
                                    :options="marketActivityTypes"
                                    :show-count="true"
                                    v-bind:placeholder="$t('chooseMarketActivity')" />

                    <button style="width:9%" class="btn float-right" :title="$t('clearSelection')" v-on:click="clearTreeSelect">x</button>
                </div>-->


                        <div class="col-lg-9 form-group">
                            <button class="btn btn-block" v-on:click="search()"><i class="fa fa-search mr-2"></i>  {{ $t("search")}}</button>
                        </div>
                        <div class="col-lg-3 form-group">
                            <button class="btn btn-block" v-on:click="clearSearch()"><i class="fa fa-eraser mr-2"></i>  {{ $t("clearSearch")}}</button>
                        </div>
                    </div>
                </div>
                <!--Search results-->
                <div class="search-result p-3" v-if="hasResults">
                    <i class="fa fa-list mr-3"></i>
                    <label>{{ $t("searchResults") }}</label>
                    <hr />
                    <div>
                        <app-legal-entity-item v-for="legalEntity in legalEntities" v-bind:key="legalEntity.id" v-bind:legalEntity="legalEntity"></app-legal-entity-item>
                        <hr />
                        <div class="col-md-12 text-center">
                            <p class="d-block small text-muted">{{ $t('paginationInfo', getPaginationInfo)}}</p>

                            <app-paginate class="d-inline-flex"
                                          v-model="searchObject.currentPage"
                                          v-bind:page-count="searchObject.numberOfPages"
                                          v-bind:click-handler="changePage"
                                          v-bind:prev-text="$t('previous')"
                                          v-bind:next-text="$t('next')"
                                          v-bind:container-class="'pagination'"
                                          v-bind:page-class="'page-item'"
                                          v-bind:page-link-class="'page-link small'"
                                          v-bind:next-class="'page-item'"
                                          v-bind:next-link-class="'page-link small'"
                                          v-bind:prev-class="'page-item'"
                                          v-bind:prev-link-class="'page-link small'"
                                          v-bind:page-range="3"
                                          v-bind:margin-pages="1">
                            </app-paginate>
                        </div>
                    </div>
                </div>
                <div class="search-result p-3" v-else>
                    <i class="fa fa-list mr-3"></i>
                    <label>{{ $t("searchResults") }}</label>
                    <hr />
                    <div>
                        <div class="col-md-12 text-center">
                            {{ $t("searchNoResults")}}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import LegalEntityItem from "./LegalEntityItem.vue"
    import Paginate from 'vuejs-paginate'
    import { BBreadcrumb } from 'bootstrap-vue'
    import Select from 'vue-select'
    import 'vue-select/dist/vue-select.css';
    import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators"

    //import Treeselect from '@riophae/vue-treeselect'
    //import '@riophae/vue-treeselect/dist/vue-treeselect.css'

    import { locations } from '../../../assets/staticData/locations'
    import { legalEntityTypes } from '../../../assets/staticData/legalEntityTypes'
    //import { marketActivities } from '../../../assets/staticData/marketActivities'



    import { mapActions, mapGetters } from "vuex"

    export default {

        data() {
            return {
                allowedFileTypes: ["application/pdf"/* ovo je LibreOffice (.ods) extenzija, "application/vnd.oasis.opendocument.spreadsheet"*/],
                legalEntities: [],
                counties: [],
                municipalities: [],
                legalEntitySizes: [],
                legalEntityTypes: [],
                marketActivityTypes: [],
                documentTypes: [],
                years: [2019, 2020, 2021],
                legalEntityStatuses: [1, 2, 3],
                searchObject: {
                    legalEntityTypeId: null,
                    name: null,
                    jib: null,
                    activityCode: null,
                    countyId: null,
                    municipalityId: null,
                    documentTypeId: null,
                    legalEntitySizeId: null,
                    isPublicEntity: null,
                    legalEntityStatus: null,
                    accountStatusId: null,
                    year: null,
                    searchType: 3,
                    marketActivityId: null,
                    currentPage: 1,
                    pageSize: 10,
                    numberOfPages: 1,
                    totalRecords: 0,
                },
                document: {
                    jib: null,
                    legalEntityId: null,
                    documentTypeId: null,
                    date: null,
                    file: null,
                    legalEntityName: null
                },
                toggleRedPortal: false
            }
        },
        components: {
            appLegalEntityItem: LegalEntityItem,
            appPaginate: Paginate,
            appSelect: Select,
            appBreadcrumb: BBreadcrumb,
            //appTreeselect: Treeselect,
        },
        methods: {
            ...mapActions(["fetchLegalEntitiySizes", "fetchMarketActivityTypes", "fetchLocations", "fetchLegalEntities", "fetchLegalEntityTypes", "fetchAccountStatuses", "fetchDocumentTypes", "documentsInsert"]),
            ...mapGetters(["getIsAdminOrModerator"]),
            changePage(clickedPageNumber) {
                this.search(clickedPageNumber);
            },
            search(currentPage) {

                let loader = this.$loading.show(this.pageLoaderOptions);

                if (this.searchObject.legalEntityStatus && this.searchObject.legalEntityStatus < 3 && this.searchObject.year < 1) {
                    this.legalEntities = [];
                    loader.hide();
                    return;
                }

                this.fetchLegalEntities(this.searchObject).then((data) => {
                    this.legalEntities = data.resultList || [];
                    this.searchObject.totalRecords = data.count;
                    this.searchObject.numberOfPages = parseInt(this.searchObject.totalRecords / this.searchObject.pageSize) + (this.searchObject.totalRecords % 10 === 0 ? 0 : 1);

                    loader.hide();
                });

                this.searchObject.currentPage = currentPage || 1;


            },
            fileToByteArray(file) {
                return new Promise((resolve, reject) => {
                    try {
                        let reader = new FileReader();
                        let fileByteArray = [];
                        reader.readAsArrayBuffer(file);
                        reader.onloadend = (evt) => {
                            if (evt.target.readyState == FileReader.DONE) {
                                let arrayBuffer = evt.target.result,
                                    array = new Uint8Array(arrayBuffer);
                                array.forEach(x => fileByteArray.push(x));
                            }
                            resolve(fileByteArray);
                        }
                    }
                    catch (e) {
                        reject(e);
                    }
                })
            },
            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                var file = files[0];

                if (file && this.allowedFileTypes.filter(aft => aft == file.type).length > 0) {

                    this.document.file = file;
                }
            },
            fetchMunicipalities() {
                if (this.searchObject.countyId > 0) {

                    // Commented because Entities API is falling to sleep mode, uncomment this when fixed

                    //this.fetchLocations({ locationTypeId: this.getLocationTypes.municipalityLocationTypeId, parentId: this.searchObject.countyId }).then((municipalities) => {
                    //    this.municipalities = municipalities.sort((a, b) => a.name - b.name) || [];
                    //});


                    // Delete this when above example is working
                    this.municipalities = locations.filter(l => l.parentId == this.searchObject.countyId).sort((a, b) => { a.name - b.name });

                }
                else {
                    this.searchObject.municipalityId = null;
                    this.municipalities = [];
                }
            },
            clearDocumentObject() {
                this.document.jib = null;
                this.document.legalEntityId = null;
                this.document.documentTypeId = null;
                this.document.date = null;
                this.document.file = null;
                this.document.legalEntityName = null;

                this.$v.$reset();
            },
            saveBankruptcyDocument(bvModalEvt) {

                if (!this.getIsAdminOrModerator)
                    return;

                //preventing closing of the modal
                bvModalEvt.preventDefault();

                let loader = this.$loading.show(this.pageLoaderOptions);

                if (this.$v.$invalid) {
                    this.$v.$touch();
                    loader.hide();
                }
                else {
                    this.fileToByteArray(this.document.file).then((result) => {
                        var model = { documentTypeId: this.document.documentTypeId, legalEntityId: this.document.legalEntityId, jib: this.document.jib, submittedOn: this.document.date, file: result };

                        this.documentsInsert(model).then((success) => {
                            if (success) {
                                this.clearDocumentObject()
                                //this.showSuccessMessage = true;
                                //setTimeout(() => { this.showSuccessMessage = false }, 30000);
                                loader.hide();
                            }
                            else {
                                //this.showErrorMessage = true;
                                //setTimeout(() => { this.showErrorMessage = false }, 30000);
                                loader.hide();
                            }
                        }).catch(() => { loader.hide(); });
                    });

                    this.$nextTick(() => {
                        this.$bvModal.hide('modal-container')
                    });
                }
            },
            jibChanged() {
                if (!this.getIsAdminOrModerator)
                    return;

                this.document.legalEntityName = null;
                this.document.legalEntityId = null;

                if (this.document.jib.length == 13) {
                    this.fetchLegalEntities({ jib: this.document.jib }).then((data) => {
                        if (data.resultList != null && data.resultList.length > 0)
                        {
                            this.document.legalEntityName = data.resultList[0].shortName || data.resultList[0].longName;
                            this.document.legalEntityId = data.resultList[0].id;
                        }
                    });
                }
            },
            clearTreeSelect() {
                this.searchObject.marketActivityId = null;
                this.searchObject.activityCode = null;
            },
            clearSearch() {
                this.searchObject.name = null;
                this.searchObject.jib = null;
                this.searchObject.countyId = null;
                this.searchObject.municipalityId = null;
                this.searchObject.legalEntitySizeId = null;
                this.searchObject.legalEntityStatus = null;
                this.searchObject.accountStatusId = null;
                this.searchObject.year = null;
                this.searchObject.marketActivityId = null;
                this.searchObject.activityCode = null;
            },
        },
        computed: {
            ...mapGetters(["getLocationTypes", "getLegalEntityTypes", "getContentById", "getContentByUrl"]),
            getPaginationInfo() {
                var start = ((this.searchObject.currentPage - 1) * this.searchObject.pageSize) + 1;
                var end = (start - 1) + this.legalEntities.length;

                return [start, end, this.searchObject.totalRecords];
            },
            getUploadedFileName() {
                return this.document.file ? this.document.file.name : this.$t("choosePDFFile");
            },
            hasResults() {
                return this.legalEntities.length > 0;
            },
            documents_showLegalEntityStatus() {
                return this.document.jib != null && this.document.jib.length == 13;
            },
            documents_showLegalEntityNameOrError() {
                return this.document.legalEntityName != null && this.$v.document.jib.$dirty && this.$v.document.jib.numeric && this.$v.document.jib.minLength && this.$v.document.jib.maxLength && this.$v.document.jib.required;
            },
            //years() {
            //    var currentYear = new Date().getFullYear() - 1;
            //    return [currentYear, currentYear - 1, currentYear - 2];
            //},
            breadcrumbItems() {

                let contentId = this.getContentByUrl(this.$route.name).id;

                let items = [];

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;

                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            }
        },
        filters: {
            lowerCase(value) {
                return value.toLowerCase();
            }
        },
        created() {
            
            this.$store.state.isSearching = true;

            this.searchObject.isPublicEntity = false;
            //this.searchObject.year = this.years[0];

            // Commented because Entities API is falling to sleep mode, uncomment this when fixed

            //this.fetchLegalEntitiySizes().then((sizes) => {
            //    this.legalEntitySizes = sizes || [];
            //});

            // Delete this when above example is working
            //this.legalEntitySizes = legalEntitySizes.sort((a, b) => { a.name - b.name });


            // Commented because Entities API is falling to sleep mode, uncomment this when fixed

            //this.fetchMarketActivityTypes().then((marketActivityTypes) => {
            //    this.marketActivityTypes = marketActivityTypes || [];
            //});

            // Delete this when above example is working
            //this.marketActivityTypes = marketActivities.filter(x => x.parentId == null).sort((a, b) => { a.code - b.code }).map(x => { return { id: x.id, name: `${x.code} - ${x.name}`, code: x.code } });

            //marketActivities.filter(x => x.parentId == null).forEach(x => {

            //    let children = marketActivities.filter(t => t.parentId == x.id).sort((a, b) => a.code - b.code).map(level1 => {

            //        let childrenLevel2 = marketActivities.filter(level2 => level2.parentId == level1.id).sort((a, b) => a.code - b.code).map(level2 => {

            //            let childrenLevel3 = marketActivities.filter(level3 => level3.parentId == level2.id).sort((a, b) => a.code - b.code).map(level3 => {
            //                return {
            //                    id: level3.id,
            //                    label: `${level3.code} - ${level3.name}`
            //                };
            //            });

            //            return {
            //                id: level2.id,
            //                label: `${level2.code} - ${level2.name}`,
            //                children: childrenLevel3.length > 0 ? childrenLevel3 : null
            //            };
            //        })

            //        return {
            //            id: level1.id,
            //            label: `${level1.code} - ${level1.name}`,
            //            children: childrenLevel2.length > 0 ? childrenLevel2 : null
            //        };
            //    });

            //    let option = {
            //        id: x.id,
            //        label: `${x.code} - ${x.name}`,
            //        children: children.length > 0 ? children : null
            //    };

            //    this.marketActivityTypes.push(option);
            //});

            // Commented because Entities API is falling to sleep mode, uncomment this when fixed

            //this.fetchLocations({ locationTypeId: this.getLocationTypes.countyLocationTypeId, parentId: null }).then((counties) => {
            //    this.counties = counties || [];
            //});

            // Delete this when above example is working
            this.counties = locations.filter(l => l.parentId == null).sort((a, b) => { a.code - b.code });


            // Commented because Entities API is falling to sleep mode, uncomment this when fixed

            //this.fetchLegalEntityTypes().then((legalEntityTypes) => {
            //    this.legalEntityTypes = legalEntityTypes || [];
            //});

            // Delete this when above example is working
            this.legalEntityTypes = legalEntityTypes.sort((a, b) => { a.name - b.name });


            // Commented because Entities API is falling to sleep mode, uncomment this when fixed

            //this.fetchAccountStatuses().then((accountStatuses) => {
            //    this.accountStatuses = accountStatuses || []; //vidjeti da li se mogu prevodi ubaciti
            //});

            // Delete this when above example is working
            //this.accountStatuses = accountStatuses.sort((a, b) => { a.name - b.name });

            this.fetchDocumentTypes().then((documentTypes) => {
                if (documentTypes.length > 0)
                    documentTypes.forEach((documentType) => {
                        this.documentTypes.push({ id: documentType.id, name: this.$t(documentType.translationKey), translationKey: documentType.translationKey });
                    });
            });

            this.search(null);
        },
        beforeDestroy() {
            this.$store.state.isSearching = false;
        },
        validations: {
            document:{
                jib: {
                    required,
                    minLength: minLength(13),
                    maxLength: maxLength(13),
                    numeric
                },
                documentTypeId: {
                    required
                },
                date: {
                    required
                },
                file: {
                    required
                }
            },
    
        },
        watch: {
            "searchObject.countyId"() {
                this.fetchMunicipalities();
            },
            '$route'() {
                if (this.documentTypes.length > 0) {
                    let documentTypesCopy = this.documentTypes;
                    this.documentTypes = [];
                    documentTypesCopy.forEach((documentType) => {
                        this.documentTypes.push({ id: documentType.id, name: this.$t(documentType.translationKey), translationKey: documentType.translationKey });
                    });
                }
            }
        }
    }

</script>

<style scoped>
    .content {
        background-color: blue;
    }

    .search-header, .search-result, .search-advance, .no-match-found {
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        background-color: #FFF;
    }

    .advanced-search-item {
        cursor: pointer;
        transition: transform .2s;
    }

        .advanced-search-item:hover .advanced-search-item-title {
            color: #243B80;
            font-weight: bold;
        }

        .advanced-search-item:hover {
            transform: scale(0.95);
            background-color: lightgray;
        }

    .select2 {
        background-color: white;
    }

    .vs__search {
        height: 28px;
        color: #495057;
    }

        .vs__search:v-bind:placeholder {
            color: rgba(73, 80, 87, 0.9);
        }

    .register-item {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        cursor: pointer;
        transition: transform .2s;
    }

        .register-item:hover {
            transform: scale(1.02);
            background-color: #243B80;
            color: white;
        }

    .selected {
        background-color: #243B80;
        color: white;
    }

    .selected-danger {
        background-color: #dc3545;
        color: white;
    }

    .btn-add {
        background-color: #243B80;
        border-color: #243B80;
        color: white !important ;
    }

        .btn-add:hover {
            background-color: rgba(51, 76, 97, 0.9) !important;
            color: white;
        }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #243B80;
            color: white;
        }

    .nav-item {
        cursor: pointer;
        background-color: darkgray;
    }

        .nav-item:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-left: 1px solid white;
        }

        .nav-item:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-right: 1px solid white;
        }

        .nav-item:hover {
            background-color: #243B80;
        }

    .selected {
        background-color: #243B80;
    }

    .validation-error {
        border: 1px solid red;
        border-radius: 5px;
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }

    .nfis-links, .nfis-links:active {
        text-decoration: none !important;
        color: black;
    }


    .tree-select {
        /*border: 1px solid #ced4da !important;
        border-radius: 5px;*/
    }
</style>