<template>
    <div class="col-lg-12 col-md-12 form-group">
        <div class="content col-md-12 p-0">
            <div class="card-item rounded pt-3 pr-3 pl-3 pb-1" >
                <div class="row" data-toggle="collapse" v-bind:href="'#collapse_' + businessUnit.id" role="button" aria-expanded="false" aria-controls="collapseExample" @click="expandCard">
                    <div class="col-md-4 col-6 form-group">
                        <div class="small text-muted">{{ $t("businessUnit") }}</div>
                        <div class="small font-weight-bold">{{ businessUnit.businessUnit }}</div>
                    </div>
                    <div class="col-md-4 col-6 form-group">
                        <div class="small text-muted">{{ $t("address") }}</div>
                        <div class="small font-weight-bold">{{ businessUnit.address }}</div>
                    </div>
                    <div class="col-md-4 col-6 form-group">
                        <div class="small text-muted">{{ $t("phone") }}</div>
                        <div class="small font-weight-bold"><a v-bind:href="'call:' + businessUnit.phone">{{ businessUnit.phone }}</a></div>
                    </div>
                   
                    <div class="col-md-12 text-center" v-show="!cardExpanded || businessUnits.length == 0">
                        <hr class="m-0"/>
                        <span class="small text-muted">{{ businessUnits.length == 0 ? $t("noBranchOffices") : $t("clickToShowBranchOffices") }}</span>
                    </div>
                </div>
                <div class="row" v-if="businessUnits.length > 0">
                    <div class="col-md-12" :class="{'form-group': cardExpanded}">
                        <div class="collapse" v-bind:id="'collapse_' + businessUnit.id">
                            <hr class="mt-1"/>
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                                <table class="table table-bordered table-sm small mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>{{ $t("branchOffice") }}</th>
                                                        <th>{{ $t("address") }}</th>
                                                        <th>{{ $t("phone") }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="businessUnit in businessUnits" v-bind:key="businessUnit.id">
                                                        <td>{{ businessUnit.businessUnit }}</td>
                                                        <td>{{ businessUnit.address }}</td>
                                                        <td><a v-bind:href="'call:' + businessUnit.phone">{{ businessUnit.phone }}</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { businessUnits } from '../../../assets/staticData/businessUnits'

    export default {
        props: ["businessUnit"],
        data() {
            return {
                cardExpanded: false
            }
        },
        methods: {
            expandCard() {
                this.cardExpanded = !this.cardExpanded;
            }
        },
        computed: {
            businessUnits() {
                return businessUnits.filter(bu => bu.parentId == this.businessUnit.id).sort((a, b) => { a.businessUnit - b.businessUnit });
            }
        }
    }
</script>

<style scoped>
    .card-item {
        width: 100%;
        background-color: white;
        border-top: 1px solid rgba(0,0,0,0.06);
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        transition: transform .2s;
    }

        .card-item:hover {
            transform: scale(1.01);
        }

    .card-body {
        background-color: whitesmoke;
    }
</style>

