<template>
    <div class="items-align">
        <!-- <router-link v-if="!isExternalLink" v-bind:to="computeRoute" tag="a" v-bind:style="{textIndent: (defaultIndent * hierarchyLevel) + 'px', fontWeight: (!isZeroHierarchy ? 100 : 700)}" class="title"> -->
        <router-link v-if="!isExternalLink" v-bind:to="computeRoute" tag="a" v-bind:style="{fontWeight: (!isZeroHierarchy ? 100 : 700)}" class="title" >
            <span v-if="!isZeroHierarchy"><i class="fa fa-angle-right float-left mt-1"></i></span>
            <div v-bind:class="{'details-first-menu': styleFirstRowInMenu, 'menu-item': isContent, 'pl-3': !isZeroHierarchy, 'mb-1': isDetails }">
                <span class="font-size"> {{ menu.title }}</span>
            </div>
        </router-link>
        <!-- <a v-else v-bind:href="menu.url" target="_blank"  v-bind:style="{textIndent: (defaultIndent * hierarchyLevel) + 'px', fontWeight: (!isZeroHierarchy ? 100 : 700)}" class="title" > -->
        <a v-else v-bind:href="menu.url" target="_blank"  v-bind:style="{fontWeight: (!isZeroHierarchy ? 100 : 700)}" class="title" >
            <span v-if="!isZeroHierarchy"><i class="fa fa-angle-right float-left mt-1"></i></span>
            <div v-bind:class="{'details-first-menu': styleFirstRowInMenu, 'menu-item': isContent, 'pl-3': !isZeroHierarchy, 'mb-1': isDetails }">
                <span class="font-size"> {{ menu.title }}</span>
            </div>
        </a>
        <div v-if="hierarchyLevel < 1">
            <app-sub-menu-item v-for="menuItem in getChildrenByParentId(menu.id)" v-bind:key="menuItem.id" v-bind:menu="menuItem" v-bind:hierarchyLevel="hierarchyLevel+1" v-bind:isDetails="isDetails"></app-sub-menu-item>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        props: ["menu", "hierarchyLevel", "isDetails", "isContent"],
        name: "AppSubMenuItem",
        data() {
            return {
                defaultIndent: 5,
            }
        },
        computed: {
            ...mapGetters(["getChildrenByParentId", "hasContentChildren", "getContentTypes"]),
            styleFirstRowInMenu() {
                return (this.isDetails && this.hierarchyLevel == 0 && !this.isContent);
            },
            isZeroHierarchy() {
                return this.hierarchyLevel == 0;
            },
            isExternalLink() {
                return this.menu.contentTypeId == this.getContentTypes.linkContentTypeId && this.menu.url.startsWith("http");
            },
            isInternalLink() {
                return this.menu.contentTypeId == this.getContentTypes.linkContentTypeId && !this.menu.url.startsWith("http");
            },
            computeRoute() {
                return this.isInternalLink ? { name: this.menu.url } : { name: 'content', params: { id: this.menu.id, slug: this.menu.slug } };
            },
        }        
    }
</script>

<style scoped>
    .title {
        color: #494A4B;
    }

    .details-first-menu {
        background-color: #243B80;
        padding: 2%;
        margin-bottom: 2%;
        border-radius: 5px;
        color: #ffffff;
        font-weight: 500 !important;
    }

    .menu-item {
        text-indent: 0px;
        padding: 1%;
    }

    .menu-item:hover {
        background-color: #243B80;
        border-radius: 5px;
        color: #ffffff;
        text-indent: 10px;
    }

    .font-size {
        font-size: 85% !important;
    }

    .items-align{
        text-align: justify !important;
    }
</style>
