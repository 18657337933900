<template>
    <div class="panel rounded">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-lg-9 col-md-8">
                        <span class="mb-0 font-weight-bold">{{ content.title }}</span>
                    </div>
                    <div class="col-lg-3 col-md-4 form-group align-self-top">
                        <div class="text-muted text-right small"><i class="fa fa-calendar mr-2"></i> {{ content.dateTime | moment($t("dateFormat"))}} </div>
                    </div>
                </div>
                <hr class="mt-0" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-9 form-group" v-html="htmlContent">
            </div>
            <div class="col-md-3 form-group">
                <a v-bind:href="content.url" target="_blank" class="btn btn-block"><i class="fa fa-globe mr-2"></i> {{ $t("openInNewTab") }}</a>
                <hr v-if="isTagsPanelVisible" />
                <div class="row" v-if="isTagsPanelVisible">
                    <app-content-tags v-bind:tags="getContentTags"></app-content-tags>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ContentTags from "./ContentTags.vue"
    import { mapGetters, mapActions } from "vuex"

    export default {
        data() {
            return {
                htmlContent: ""
            }
        },
        components: {
            appContentTags: ContentTags
        },
        props: ["content"],
        methods: {
            ...mapActions(["fetchContentById"]),
            loadData() {
                this.fetchContentById(this.content.id).then((content) => {
                    this.htmlContent = content.content;
                });
            },
        },
        computed: {
            ...mapGetters(["getSelectedLanguage"]),
            getContentTags() {
                return this.content.tags ? JSON.parse(this.content.tags) : [];
            },
            isTagsPanelVisible() {
                return this.content.tags;
            }
        },
        created() {
            this.loadData();
        },
        watch: {
            content() {
                this.loadData();
            }
        },
        updated() {
            var el = this.$el;
            el.scrollIntoView();
        }
    }
</script>

<style scoped>
    .text-muted {
        color: #243B80 !important;
        opacity: 0.6; 
        font-weight: bold;
    }

    .btn {
        background-color: #243B80;
        color: white;
    }

    .btn:hover {
        background-color: #374e96;
        border-color: #374e96;
        color: white;
    }

    .panel {
        padding: 4%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
    }
</style>
