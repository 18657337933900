import vue from "vue"
import vuex from "vuex"
import httpClient from "../httpClient/httpClient"
import httpClient_identity from "../httpClient/identity"
import httpClient_entity from "../httpClient/entity"
import httpClient_accounts from "../httpClient/accounts"
//import httpClient_controls from "../httpClient/controls"
import createPersistedState from "vuex-persistedstate"
import i18N from "../i18n"
import router from "../routes.js"

vue.use(vuex);

export const store = new vuex.Store({
    plugins: [createPersistedState({ 
        overwrite: true }
    )],
    state: {
        applicationId: 1024,
        contents: [],
        locale: {
            languages: [],
            selectedLanguage: null,
            defaultLanguageCode: process.env.VUE_APP_I18N_LOCALE
        },
        contentTypes: {
            //--- Hardcoded ContentTypes -> MUST match Id-s in the database
            mainMenuContentTypeId: 1,
            secondaryMenuContentTypeId: 2,
            newsContentType: 3,
            documentsContentTypeId: 4,
            imagesContentTypeId: 5,
            videoContentTypeId: 6,
            linkContentTypeId: 7
        },
        tagIds: {
            product: 1,
            publication: 2, 
            infographic: 1069
        },
        menu: {
            hoveredMenu: null,
            clickedMenu: null
        },
        search: {
            contents: [],
            years: [],
            tags: [],
            contentTypes: [],
            pagination: {
                numberOfPages: 0,
                currentPage: 1,
                pageLength: 10,
                totalRecords: 0
            },
            params: {
                text: "",
                tag: null,
                contentTypeId: null,
                year: null
            },
            businessEntitiesProfileSearch: null
        },
        legalEntityTypeCardComponents: [{ id: 1, component: "appCardContentDefault" }, { id: 3, component: "appCardContentFinancialInstitution" }, { id: 4, component: "appCardContentBudget" }, { id: 2, component: "appCardContentAssociationAndFondation" }, { id: 5, component: "appCardContentInvestmentFunds" }, { id: 6, component: "appCardContentInsuranceCompanies" }, 
        { id: 7, component: "appCardContentMicroCompanies" }],
        locationTypes: {
            countyLocationTypeId: 1,
            municipalityLocationTypeId: 3
        },
        legalEntityTypes: {
            companies: 1,
            associationsAndFondations: 2,
            financialInstitutions: 3,
            budgetUsers: 4,
            investmentFunds: 5,
            insuranceCompanies: 6,
            microCompanies:7

        },
        auth: {
            isUserAuthenticated: false,
            username: null,
            email: null,
            photoUrl: null,
            id: null
        },
        isSearching: false
    },
    getters: {
        getBusinessEntitiesProfileSearchParams: (state) => {
            return state.search.businessEntitiesProfileSearch;
        },
        mainMenuItems: (state) => {
            return state.contents.filter(c => c.contentTypeId == state.contentTypes.mainMenuContentTypeId && c.parentId == null && c.pathLevel < 3).sort((a, b) => a.ordinalNumber - b.ordinalNumber);
        },
        secondaryMenuItems: (state) => {
            return state.contents.filter(c => c.contentTypeId == state.contentTypes.secondaryMenuContentTypeId && c.parentId == null && c.pathLevel < 3).sort((a, b) => a.ordinalNumber - b.ordinalNumber);
        },
        hoveredMenu: (state) => {
            return state.menu.hoveredMenu;
        },
        getChildrenByParentId: (state) => (parentId, isContent) => {
            var contentChildren = state.contents.filter(c => c.parentId == parentId && c.contentTypeId != state.contentTypes.imagesContentTypeId && c.contentTypeId != state.contentTypes.videoContentTypeId);
            if (contentChildren.length == 0 && isContent == true) {
                var parent = state.contents.find(c => c.id == parentId);
                contentChildren = state.contents.filter(c => c.parentId == parent.parentId && c.contentTypeId != state.contentTypes.imagesContentTypeId && c.contentTypeId != state.contentTypes.videoContentTypeId);
            }

            //Aktuelnosti
            var obj=state.contents.find(c=>c.id==parentId);
            if (parentId == 340 || obj.parentId==340) {
                return contentChildren.sort((b, a) => a.ordinalNumber - b.ordinalNumber);
            }

            return contentChildren.sort((a, b) => a.ordinalNumber - b.ordinalNumber);
        },
        getContentById: (state) => (id) => {
            return state.contents.find(c => c.id == id);
        },
        getContentByUrl: (state) => (url) => {
            return state.contents.find(c => c.url == url);
        },
        hasContentChildren: (state) => (id) => {
            return state.contents.filter(c => c.contentTypeId != state.contentTypes.imagesContentTypeId && c.contentTypeId != state.contentTypes.videoContentTypeId && c.parentId == id).length > 0;
        },
        getContentMedia: (state) => (id) => {
            var media = [];
            
            state.contents.filter(c => (c.contentTypeId == state.contentTypes.imagesContentTypeId || c.contentTypeId == state.contentTypes.videoContentTypeId) && c.parentId == id).forEach((content) => {
                if (content.contentTypeId == state.contentTypes.imagesContentTypeId) {
                    media.push({
                        src: content.url,
                        thumb: content.url,
                        caption: content.title,
                        isMain: content.isMain
                    });
                }
                else {
                    media.push({
                        thumb: "",
                        sources: [
                            {
                                src: content.url,
                                type: "video/mp4"
                            }
                        ],
                        type: "video",
                        caption: content.title,
                        width: 800,
                        height: 600,
                        autoplay: true
                    });
                }

            });

            // This is dummy video for testing

            //media.push({
            //    thumb: "",
            //    sources: [
            //        {
            //            src: "https://www.w3schools.com/tags/movie.mp4",
            //            type: 'video/mp4'
            //        }
            //    ],
            //    type: "video",
            //    caption: "Merhaba",
            //    width: 800,
            //    height: 600,
            //    autoplay: true
            //});

            return media;
        },
        getHighlightedNews: (state) => {
            return state.contents.filter(c => c.highlight && c.contentTypeId == state.contentTypes.newsContentType).sort((a, b) => a.highlight - b.highlight).slice(0,5);
        },
        getHighlightedProducts: (state) => {
            return state.contents.filter(c => c.highlight && c.contentTypeId == state.contentTypes.documentsContentTypeId && (c.tags && JSON.parse(c.tags).filter(t => t.tagId == state.tagIds.product).length > 0)).sort((a, b) => a.highlight - b.highlight).splice(0, 4);
        },
        getHighlightedPublications: (state) => {
            return state.contents.filter(c => c.highlight && c.contentTypeId == state.contentTypes.documentsContentTypeId && JSON.parse(c.tags).filter(t => t.tagId == state.tagIds.publication).length > 0).sort((a, b) => a.highlight - b.highlight);
        },
        getHighlightedInfographics: (state) => {
            return state.contents.filter(c => c.contentTypeId == state.contentTypes.newsContentType && (c.tags && JSON.parse(c.tags).filter(t => t.tagId == state.tagIds.infographic).length > 0)).sort((a, b) => a.ordinalNumber - b.ordinalNumber).slice(0,1);
        },
        getLanguages: (state) => {
            return state.locale.languages;
        },
        getSelectedLanguage: (state) => {
            if (state.locale.selectedLanguage == null) {
                state.locale.selectedLanguage = state.locale.languages.find(l => l.code.toLocaleLowerCase() == state.locale.defaultLanguageCode.toLocaleLowerCase());
            }
            return state.locale.selectedLanguage || { id: 1, code: "bs", url: "https://www.worldometers.info/img/flags/small/tn_bk-flag.gif" };
        },
        getContentTypes: (state) => {
            return state.contentTypes;
        },
        getSearch: (state) => {
            var start = (state.search.pagination.currentPage - 1) * state.search.pagination.pageLength;
            var end = state.search.pagination.pageLength * state.search.pagination.currentPage;

            return {
                params: state.search.params,
                contents: state.search.contents.slice(start, end),
                years: state.search.years,
                tags: state.search.tags,
                contentTypes: state.search.contentTypes,
                pagination: state.search.pagination,
                primarySearchVisible: state.search.primarySearchVisible
            }
        },
        getLegalEntityCardComponent: (state) => (id) => {
            if (!id)
                id = null;
            let entityType = state.legalEntityTypeCardComponents.find(le => le.id == id);
            return entityType.component;
        },
        getLocationTypes: (state) => {
            return state.locationTypes;
        },
        checkTokenValidity: () => (token) => {
            if (token === "undefined")
                return false;

            var tokenData = parseJwt(token);
            if (Date.now() >= tokenData.exp * 1000)
                return false;

            return true;
        },
        getLegalEntityTypes: (state) => {
            return state.legalEntityTypes;
        },
        getAuth: (state) => {
            return state.auth;
        },
        getFeaturedItems: (state) => {
            return state.contents.filter(c => c.highlight).sort((a, b) => a.highlight - b.highlight).splice(0, 12);
        },
        getShowSubMenu: (state) => {
            return state.menu.hoveredMenu != null;
        },
        getIsAdminOrModerator: (state) => {
            let token = localStorage.getItem(process.env.VUE_APP_TOKEN);
            if (token == undefined || token == null || !token || !state.auth.isUserAuthenticated)
                return false;

            let data = parseJwt(token);

            if (!data || !data.roleIdList || data.roleIdList.length == 0)
                return false;

            let roleIds = data.roleIdList.split(",");
            return roleIds.find(x => x == process.env.VUE_APP_ADMIN_ID || x == process.env.VUE_APP_MODERATOR_ID) != undefined;
        }
    },
    mutations: {
        setHoveredMenu: (state, payload) => {
            state.menu.hoveredMenu = payload;
        },
        setContents: (state, payload) => {
            state.contents = payload;
        },
        setLanguages: (state, payload) => {
            state.locale.languages = payload;
            if (!state.locale.selectedLanguage)
                state.locale.selectedLanguage = payload[0];
        },
        setSelectedLanguage: (state, payload) => {
            state.locale.selectedLanguage = payload;
            i18N.locale = payload.code;
        },
        setSelectedLanguageByCode: (state, payload) => {
            state.locale.selectedLanguage = state.locale.languages.find(l => l.code == payload);
        },
        setSearchResult: (state, payload) => {
            state.search = payload;
        },
        setPaginationCurrentPage: (state, payload) => {
            state.search.pagination.currentPage = payload;
        },
        setSelectedTag: (state, payload) => {
            state.search.params.tag = payload ? payload.tag : null;
            if (payload && payload.clearText)
                state.search.params.text = "";
        },
        setSelectedYear: (state, payload) => {
            state.search.params.year = payload;
        },
        setSelectedContentType: (state, payload) => {
            state.search.params.contentTypeId = payload;
        },
        clearAuthData: (state) => {
            localStorage.removeItem(process.env.VUE_APP_TOKEN);
            localStorage.removeItem(process.env.VUE_APP_REFRESH_TOKEN);

            state.auth.isUserAuthenticated = false;
            state.auth.username = null;
            state.auth.email = null;
            state.auth.photoUrl = null;
            state.auth.id = null;
        },
        setAuthData: (state, payload) => {

            localStorage.setItem(process.env.VUE_APP_TOKEN, payload.token);
            localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, payload.refreshToken);

            var tokenData = parseJwt(payload.token);

            state.auth.isUserAuthenticated = true;
            state.auth.username = tokenData.username;
            state.auth.email = tokenData.email;
            state.auth.photoUrl = tokenData.photoUrl;
            state.auth.id = tokenData.userId;

            var oneMinute = 60000;
            var tokenDuration = ((tokenData.exp - tokenData.iat) * 1000) - oneMinute;

            setTimeout(function () {
                store.dispatch("refreshToken");
            }, tokenDuration);
        },
        setBusinessEntitiesProfileSearchParams: (state, payload) => {
            state.search.businessEntitiesProfileSearch = payload;
        },
    },
    actions: {
        setBusinessEntitiesProfileSearchParams: (context, payload) => {
            context.commit("setBusinessEntitiesProfileSearchParams", payload);
        },
        setHoveredMenu: (context, payload) => {
            context.commit("setHoveredMenu", payload);
        },
        loadContents: (context) => {
            httpClient.get("/ContentTranslations/PublishedContent").then((response) => {
                context.commit("setContents", response.data);
            }).catch(() => {
                //console.log(err);

            });
        },
        loadLanguages: (context) => {
            httpClient.get("/Languages").then((response) => {
                context.commit("setLanguages", response.data.resultList);
            }).catch(() => {
                //console.log(err);

            });
        },
        changeLanguage: (context, payload) => {
            context.commit("setSelectedLanguage", payload);
        },
        searchContents: (context, payload) => {

            var searchObj = context.state.search;

            httpClient.get(
                `/ContentTranslations/Search?text=${payload.text}&tagId=${searchObj.params.tag ? searchObj.params.tag.tagId : ""}&contentTypeId=${searchObj.params.contentTypeId ? searchObj.params.contentTypeId : ""}&year=${searchObj.params.year ? searchObj.params.year : ""}`, { headers: { "accept-language": context.state.locale.selectedLanguage.code } }).then((response) => {

                    var data = response.data;
                    context.commit("setSearchResult",
                        {
                            params: {
                                text: payload.text,
                                tag: searchObj.params.tag,
                                year: searchObj.params.year,
                                contentTypeId: searchObj.params.contentTypeId
                            },
                            contents: data.contents,
                            tags: data.tags,
                            years: data.years,
                            contentTypes: data.contentTypes,
                            pagination: {
                                numberOfPages: parseInt(data.contents.length / searchObj.pagination.pageLength) + (data.contents.length % 10 === 0 ? 0 : 1),
                                currentPage: 1,
                                pageLength: 10,
                                totalRecords: data.contents.length
                            }
                        });
                }).catch(() => {
                    context.commit("setSearchResult",
                        {
                            params: {
                                text: payload.text,
                                tag: searchObj.params.tag,
                                year: searchObj.params.year,
                                contentTypeId: searchObj.params.contentTypeId
                            },
                            contents: [],
                            pagination: {
                                numberOfPages: 0,
                                currentPage: 1,
                                pageLength: 10,
                                totalRecords: 0
                            }
                        });
                });

        },
        searchChangePage: (context, payload) => {
            context.commit("setPaginationCurrentPage", payload);
        },
        setSelectedTag: (context, payload) => {
            context.commit("setSelectedTag", payload);
        },
        setSelectedYear: (context, payload) => {
            context.commit("setSelectedYear", payload);
        },
        setSelectedContentType: (context, payload) => {
            context.commit("setSelectedContentType", payload);
        },
        fetchTagById: (context, payload) => {

            httpClient.get(`TagTranslations?TagId=${payload}`, { headers: { "accept-language": context.state.locale.selectedLanguage.code || context.state.locale.defaultLanguageCode } }).then((response) => {

                if (response.data.resultList.length > 0) {
                    var tag = response.data.resultList[0];
                    context.commit("setSelectedTag", { tag: { tagId: tag.tagId, name: tag.name } });
                } else {
                    context.commit("setSelectedTag", null);
                }
            }).catch(() => {
                //console.log(err);

            });
        },
        documentsInsert: (context, payload) => {

            //console.log("payload");
            //console.log(payload);

            //return httpClient.post("FileTemplate/Check", payload, {
            //    headers: {
            //        'Content-Type': 'multipart/form-data'
            //    }
            //}).then((response) => {
            //    return response;
            //}).catch((err) => {
            //    return err;
            //});

            //{ headers: { 'Content-Type': 'multipart/form-data' } }

            return httpClient.post("documents", payload).then((response) => {
                //console.log(response);
                return response.status == 200;
            }).catch(() => {
                //console.log(err);
                return false;
            });
        },
        logIn: (context, payload) => {
            context.commit("setAuthData", payload);
            var tokenData = parseJwt(payload.token);

            setTimeout(function () {
                context.commit("clearAuthData");
                router.replace("/");
            }, tokenData.exp);
        },
        logOut: (context) => {

            httpClient_identity.get("UserSessions/SignOut").then(() => {

                context.commit("clearAuthData");
                router.go(0);

            }).catch(() => {

                context.commit("clearAuthData");
                router.go(0);

                //console.log(err);
            });

        },
        fetchMyApps: () => {
            return httpClient_identity.get("UserApplications").then((response) => {
                return response.data.resultList;
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchContentById: (context, payload) => {
            return httpClient.get(`ContentTranslations?ContentId=${payload}`).then((response) => {
                if (response.data.resultList.length > 0)
                    return response.data.resultList[0];
                else
                    return {};
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchLegalEntitiySizes: () => {
            return httpClient_entity.get("LegalEntitySizes").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, name: x.name } });
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchMarketActivityTypes: () => {
            return httpClient_entity.get("MarketActivities?MarketActivityTypeId=1").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, name: `${x.code} - ${x.name}`, code: x.code } });
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchLocations: (context, payload) => {
            return httpClient_entity.get(`Locations?LocationTypeId=${payload.locationTypeId}&parentId=${payload.parentId ? payload.parentId : ""}`).then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, name: x.name } });
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchDocumentTypes: () => {
            return httpClient.get("DocumentTypes").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, translationKey: x.translationKey } });
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchReportTypes: () => {
            return httpClient.get("ReportTypes").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, localizationKey: x.localizationKey, year:x.year, name:x.name } });
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchLegalEntities: (context, payload) => {

            var url = `LegalEntities?PageSize=${10}&includeCount=true`;

            if (payload.currentPage)
                url += `&Page=${payload.currentPage - 1}`

            if (payload.name)
                url += `&name=${payload.name}`;

            if (payload.legalEntityTypeId)
                url += `&legalEntityTypeId=${payload.legalEntityTypeId}`;

            if (payload.legalEntitySizeId)
                url += `&legalEntitySizeId=${payload.legalEntitySizeId}`;

            if (payload.jib)
                url += `&jib=${payload.jib}`;

            if (payload.countyId)
                url += `&countyId=${payload.countyId}`;

            if (payload.municipalityId)
                url += `&municipalityId=${payload.municipalityId}`;

            if (payload.activityCode)
                url += `&activityCode=${payload.activityCode}`;

            if (payload.letterOfActivity)
                url += `&letterOfActivity=${payload.letterOfActivity}`;

            // if (payload.isPublicEntity)
            //     url += `&isPublicEntity=${payload.isPublicEntity}`;

            if (payload.legalEntityStatus)
                url += `&legalEntityStatus=${payload.legalEntityStatus}`;

            if (payload.accountStatusId)
                url += `&accountStatusId=${payload.accountStatusId}`;

            if (payload.year)
                url += `&year=${payload.year}`;

            if (payload.documentTypeId)
                url += `&documentTypeId=${payload.documentTypeId}`;

            if (payload.searchType)
                url += `&searchType=${payload.searchType}`;

            if (payload.isPublicEntity != null)
                url += `&isPublicEntity=${payload.isPublicEntity}`;

            return httpClient.get(url).then((response) => {
                return response.data;
            }).catch((err) => {
                //console.log(err);

            });
        },
        fetchFinancialIndicators: (context, payload) => {
            var url = `FinancialIndicators?legalEntityId=${payload.legalEntityId}&searchType=${payload.searchType}`;
            return httpClient.get(url).then((response) => {
                return response.data.resultList;
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchLegalEntityMainBankAccount: (context, payload) => {
            var url = `BankAccount/Search?searchQuery=${payload}`;
            return httpClient_accounts.get(url).then((response) => {
                return response;
            }).catch((response) => {
                return response;
            });
        },
        fetchLegalEntityTypes: (context) => {
            return httpClient_entity.get("LegalEntityTypes").then((response) => {
                // console.log(response.data.resultList);
                // var obj={id:7, name:"Mikro privredna društva"};
                // response.data.resultList.unshift(obj);
                if (context.state.locale.selectedLanguage.code == "hr" || context.state.locale.defaultLanguageCode == "hr") {
                    if (response.data.resultList[0].id == 1)
                        response.data.resultList[0].name = "Gospodarska društva";
                    if (response.data.resultList[1].id == 2)
                        response.data.resultList[1].name = "Banke i druge financijske organizacije";
                    if (response.data.resultList[2].id == 3)
                        response.data.resultList[2].name = "Proračunski korisnici";
                    if (response.data.resultList[3].id == 4)
                        response.data.resultList[3].name = "Udruge";
                    if (response.data.resultList[4].id == 5)
                        response.data.resultList[4].name = "Društva za osiguranje";
                    if (response.data.resultList[5].id == 9)
                        response.data.resultList[5].name = "Fondovi";
                    // if (response.data.resultList[0].id == 7)
                    //     response.data.resultList[0].name = "Mikro gospodarska društva";
                }
                return response.data.resultList.map(x => { return { id: x.id, name: x.name, language: context.state.locale.selectedLanguage.code } });
            }).catch(() => {
                //console.log(err);
            });
        },
        fetchAccountants: () => {
            return httpClient.get("Accountants?isActive=true&RetrieveAll=true").then((response) => {
                //console.log(response);
                return response.data.resultList.map(x => {
                    return {
                        id: x.id, displayName: `${x.lastName} (${x.parentName}) ${x.firstName}; ${x.licenceNumber}`,
                        firstName: x.firstName, parentName: x.parentName, lastName: x.lastName, licenceNumber: x.licenceNumber
                    }
                });
            }).catch(() => {
                //console.log(err);
            });
        },
        fetchAuditCompanies: () => {
            return httpClient.get("AuditCompanies?isActive=true&RetrieveAll=true").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, displayName: `${x.name} - ${x.licenceNumber}` } });
            }).catch(() => {
                //console.log(err);
            });
        },
        generateFileTemplate: (context, payload) => {
            //console.log(payload);
            return httpClient.post("FileTemplate/generate", payload, { headers: { "Language": context.state.locale.selectedLanguage.code || context.state.locale.defaultLanguageCode }, responseType: "blob" }).then((response) => {
                return response;
            }).catch((err) => {
                //console.log(err);
            });
        },
        generateFileTemplateOld: (context, payload) => {
            //console.log(payload);
            return httpClient.post("FileTemplate/generateOld", payload, { headers: { "Language": context.state.locale.selectedLanguage.code || context.state.locale.defaultLanguageCode }, responseType: "blob" }).then((response) => {
                return response;
            }).catch((err) => {
                //console.log(err);
            });
        },
        fetchLegalEntity: (context, payload) => {
            //debugger;
            return httpClient.get(`LegalEntities/${payload}`).then((response) => {
                //console.log(response.data);
                return response.data;
            }).catch(() => {
                //console.log(err);

            });
        },
        fetchAccountStatuses: () => {
            return httpClient.get("AccountStatuses").then((response) => {
                return response.data.resultList.map(x => { return { id: x.id, name: x.name } });
            }).catch(() => {
                //console.log(err);

            });
        },
        sendEmail: (context, payload) => {
            return httpClient.post("Contact/SendEmail", payload).then((response) => {
                return response.status == 200;
            }).catch(() => {
                return false;
            });
        },
        transmitReport: (context, payload) => {
            return httpClient.post("FileTemplate/Transmit", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        },
        transmitReportNew: (context, payload) => {
            return httpClient.post("FileTemplate/upload", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        },
        sendEmailWithReportControlResult: (context, payload) => {
            return httpClient.post("Contact/SendEmailWithControlResults", payload).then((response) => {
                return response.status == 200;
            }).catch(() => {
                return false;
            });
        },
        sendEmailWithReportControlResultNew: (context, payload) => {
            return httpClient.post("Contact/SendEmailWithControlResultsNew", payload).then((response) => {
                return response.status == 200;
            }).catch(() => {
                return false;
            });
        },
        fetchAccessTokenInitial: (context) => {

            httpClient_identity.get("Users/Identity").then((response) => {
                context.commit("setAuthData", { token: response.data.accessToken, refreshToken: response.data.refreshToken });
            }).catch(() => {
                context.dispatch("refreshToken");
            });
        },
        fetchAccessToken: (context) => {
            return httpClient_identity.get("Users/Identity").then((response) => {

                context.commit("setAuthData", { token: response.data.accessToken, refreshToken: response.data.refreshToken });
                return true;

            }).catch((err) => {

                if (err.status == 403) {
                    context.commit("clearAuthData");
                }

                return false;
            });
        },
        refreshToken: (context) => {

            let token = localStorage.getItem(process.env.VUE_APP_TOKEN);
            let refreshToken = localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN);

            if (token && refreshToken) {
                return httpClient_identity.post("UserSessions/Refresh", { accessToken: token, refreshToken: refreshToken }).then((response) => {

                    if (response.status == 200)
                        context.commit("setAuthData", { token: response.data.accessToken, refreshToken: response.data.refreshToken });

                }).catch(() => {

                    if (context.state.auth.isUserAuthenticated)
                        context.dispatch("logOut");
                });
            }

            return null;
        },
        // checkFinancialReport: (context, payload) => {
        //     //return httpClient.post("Preview", payload, {
        //     return httpClient.post("FileTemplate/Check", payload, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then((response) => {
        //         return response;
        //     }).catch((err) => {
        //         return err;
        //     });

        //     //return httpClient_controls.post("Login/Check", payload, {
        //     //    headers: {
        //     //        'Content-Type': 'multipart/form-data'
        //     //    }
        //     //}).then((response) => {
        //     //    return response;
        //     //}).catch((err) => {
        //     //    return err;
        //     //});
        // }
        checkFinancialReport: (context, payload) => {
            //return httpClient.post("Preview", payload, {
            return httpClient.post("FileTemplate/Check", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        },
        checkFinancialReportOld: (context, payload) => {
            //return httpClient.post("Preview", payload, {
            return httpClient.post("FileTemplate/CheckOld", payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                return response;
            }).catch((err) => {
                return err;
            });
        }
    }
});

function parseJwt(token) {
    if (token === "undefined")
        return null;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
