<template>
    <div class="row">
        <div class="col-md-12 mb-1">
            <router-link v-if="!isExternalLink" v-bind:to="computeRoute" tag="a" class="title">
                <div class="col-md-12 menu-item rounded p-3 font-weight-bold font-size" v-bind:class="{'clicked-menu': clicked}">
                    <span>{{ menu.title }} </span>
                </div>
            </router-link>
            <a v-else v-bind:href="menu.url" target="_blank" class="title">
                <div class="col-md-12 menu-item rounded p-3 font-weight-bold font-size" v-bind:class="{'clicked-menu': clicked}">
                    <span>{{ menu.title }} </span>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        props: ["menu", "clicked"],
        computed: {
            ...mapGetters(["getContentTypes"]),
            isExternalLink() {
                return this.menu.contentTypeId == this.getContentTypes.linkContentTypeId && this.menu.url.startsWith("http");
            },
            isInternalLink() {
                return this.menu.contentTypeId == this.getContentTypes.linkContentTypeId && !this.menu.url.startsWith("http");
            },
            computeRoute() {
                return this.isInternalLink ? { name: this.menu.url } : { name: 'content', params: { id: this.menu.id, slug: this.menu.slug } };
            }
        }
    }
</script>

<style scoped>
    .title {
        color: #494A4B;
    }

    .menu-item:hover {
        background-color: #243B80;
        border-radius: 5px;
        color: #ffffff;
        transform: scale(1.015);
    }

    .clicked-menu {
        background-color: #243B80 !important;
        border-radius: 5px;
        color: white;
        font-weight: bold;
    }

    .menu-item {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        cursor: pointer;
        transition: transform .2s;
    }

    .font-size {
        font-size: 90%;
        font-weight: 500 !important;
    }
</style>
