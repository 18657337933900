<template>
    <div class="font-size">
        <h5 class="font-weight-bold"> {{ menu.title.toUpperCase() }}</h5>
        <span class="text-muted"> {{ menu.subtitle }}</span>
        <hr />
        <div class="row">
            <app-sub-menu-item class="col-md-4 form-group" v-for="menuItem in getChildrenByParentId(id)" v-bind:key="menuItem.id" v-bind:menu="menuItem" v-bind:isDetails="true" v-bind:hierarchyLevel="hierarchyLevel"></app-sub-menu-item>
        </div>
    </div>
</template>

<script>
    import SubMenuItem from './SubMenuItem'
    import { mapGetters } from 'vuex'

    export default {
        data() {
            return {
                menu: null,
                hierarchyLevel: 0,
                defaultIndent: 10
            }
        },
        components: {
            appSubMenuItem: SubMenuItem
        },
        props: ["id"],
        computed: {
            ...mapGetters(["getChildrenByParentId", "getContentById"]),
        },
        created() {
            this.menu = this.getContentById(this.id);
        },
        watch: {
            id() {
                this.menu = this.getContentById(this.id);
            }
        },
        updated() {
            var el = this.$el;
            el.scrollIntoView();
        }
    }
</script>

<style scoped>
    .font-size {
        font-size: 95% !important;
    }
</style>
