import Vue from "vue"
import App from "./App.vue"
import Router from "./routes.js"
import { store } from "./store/store.js"
import i18n from "./i18n"
import vuelidate from "vuelidate"
import moment from "vue-moment"
import { BootstrapVue } from 'bootstrap-vue'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false;
Vue.use(vuelidate);
Vue.use(moment);
Vue.use(Loading);
Vue.use(BootstrapVue);

Vue.prototype.pageLoaderOptions = {
    isFullPage: true,
    color: "#243B80",
    loader: "dots",
    backgroundColor: "whitesmoke",
    opacity: 0.8,
    height: 100,
    width: 100,
    lockScroll: true
};

Router.beforeEach((to, from, next) => {
    
    var selectedLanguage = store.getters.getSelectedLanguage;
    var locale = to.params.lang;

    if (!locale) {
        locale = selectedLanguage.code || i18n.locale || process.env.VUE_APP_I18N_FALLBACK_LOCALE;
    }

    i18n.locale = locale;

    if (locale.length > 0 && selectedLanguage.code != locale)
        store.commit("setSelectedLanguageByCode", locale);

    //next(); //Komentarisati ovu liniju kad se pravi build

    new Vue({
        created() {
            let loader = this.$loading.show(this.pageLoaderOptions);

            var tokenStorage = localStorage.getItem(process.env.VUE_APP_TOKEN);

            if (!to.meta.allowAnonymous) {
                if (tokenStorage) {
                    if (store.getters.checkTokenValidity(tokenStorage)) {

                        loader.hide();
                        next();

                    } else {
                        window.location.href = `https://identity.fia.ba?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                        //window.location.href = `http://local-identity.fia.ba:4200?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                    }

                } else {
                    store.dispatch("fetchAccessToken").then((fetched) => {

                        if (fetched) {
                            tokenStorage = localStorage.getItem(process.env.VUE_APP_TOKEN);

                            if (!tokenStorage) {
                                window.location.href = `https://identity.fia.ba?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                                //window.location.href = `http://local-identity.fia.ba:4200?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                            }

                            loader.hide();
                            next();
                        } else {
                            window.location.href = `https://identity.fia.ba?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                            //window.location.href = `http://local-identity.fia.ba:4200?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(to.path)}`;
                        }
                    });
                }
            }
            else {
                if (to.path.slice(-1) == "@") {
                    store.dispatch("fetchAccessToken").then(() => {
                        loader.hide();
                        next(to.path.slice(0,-1));
                    });
                } else {
                    loader.hide();
                    next();
                }


            }
        }
    });
});

new Vue({
    router: Router,
    store: store,
    i18n,
    render: h => h(App),
}).$mount("#app");
