<template>
    <div class="header">
        <!-- <div class="row">
            <div class="col-md-12">
                <app-profile-menu v-if="getAuth.isUserAuthenticated" class="profile-or-login-btn"></app-profile-menu>
                <a :href="identityUrl" v-else class="btn ml-3 float-right small profile-or-login-btn" :title="$t('logIn')"> {{ $t("logIn") }} <i class="fa fa-sign-in ml-3"></i></a>
                <app-language-switcher class="language-btn"></app-language-switcher>
            </div>
        </div> -->
        <div class="row logo-position">
            <div class="col-xl-3 col-md-6">
                <router-link :to="{path: '/'+ (getSelectedLanguage.code == null ? 'bs' : getSelectedLanguage.code)}">
                    <img src="../../assets/FIA_Logo.png" class="align-bottom logo" />
                </router-link>
            </div>
             <div class="col-xl-3 col-md-6 col-sm-12 header-buttons">
            </div>
            <!-- <div class="col-xl-3 col-md-6 col-sm-12 form-group align-self-center text-center" :class="{'col-xl-4': $store.state.isSearching}">
                <div>
                    <a href="https://ebra.be" target="_blank"><img src="../../assets/Ebra-Logo.png" class="shadowed align-bottom img mr-3 pointer" v-bind:title="$t('ebraLogo')" /></a>
                    <a href="https://fia.ba/bs/content/319/iso-9001-2015"><img src="../../assets/ISO9001-certificate.jpg" class="shadowed  align-bottom img pointer mr-3" v-bind:title="$t('iso9001Certificate')" /></a>
                    <a href="https://fia.ba/bs/content/321/iso-270012013"><img src="../../assets/ISO27001-certificate.jpg" class="shadowed  align-bottom img mr-3 pointer" v-bind:title="$t('iso27001Certificate')" /></a>
                    <a href="https://fia.ba/bs/content/492/iso-370012016"><img src="../../assets/ISO37001-certificate.png" class="shadowed align-bottom img pointer bg-white rounded" v-bind:title="$t('iso37001Certificate')" /></a>
                </div>
            </div> -->
            <div v-if="!$store.state.isSearching" class="col-xl-4 col-md-12 header-buttons">
                <div class="row">
                    <div class="col-lg-8 col-md-9 form-group">
                        <input class="form-control shadowed search-input-font" v-bind:placeholder="$t('startSearching')" v-model="getSearch.params.text" v-on:keyup.enter="initSearch" />
                    </div>
                    <div class="col-lg-4 col-md-3 form-group">
                        <button class="btn btn-sm btn-block search-font" v-on:click="initSearch">
                            <span class="d-inline-block">
                                <i class="fa fa-search float-right mt-1 ml-2 search-font"></i> <span>{{ $t('search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-4 col-md-12 header-buttons">
                <div class="row">
                    <div class="col-lg-12 col-md-12 form-group text-right">
                        <router-link to="/">
                            <button class="btn btn-sm home-page">
                                <span class="d-inline-block">
                                    <i class="fa fa-home float-left mt-1 mr-3"></i> <span>{{ $t('homePage')}}</span>
                                </span>
                            </button>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="col-md-2 header-buttons">
                <app-profile-menu v-if="getAuth.isUserAuthenticated" class="profile-or-login-btn"></app-profile-menu>
                <a :href="identityUrl" v-else class="btn btn-sm col-xl-6 ml-2 float-right profile-or-login-btn" :title="$t('logIn')"> {{ $t("logIn") }} <i class="fa fa-sign-in ml-1"></i></a>
                <app-language-switcher class="col-xl-4 language-btn"></app-language-switcher>
            </div>
        </div>
        <div class="row" v-show="!$store.state.isSearching">
            <div class="col-md-12 mt-3 menu-margin">
                <app-main-menu></app-main-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import MainMenu from "../menu/MainMenu.vue"
    import LanguageSwitcher from "./LanguageSwitcher.vue"
    import ProfileMenu from "./ProfileMenu.vue"
    import { mapGetters } from "vuex"

    export default {
        data() {
            return {
            }
        },
        methods: {
            initSearch() {
                var tag = this.getSearch.params.tag;
                var topic = this.getSearch.params.text;

                var route = { name: 'search', query: { topic: topic, tagId: (tag ? tag.tagId : null) } };

                if (this.$route.name != route.name || this.$route.query.topic != route.query.topic)
                    this.$router.push(route);
            }
        },
        computed: {
            ...mapGetters(["getSelectedLanguage", "getSearch", "getAuth"]),
            identityUrl() {
                //return `http://localhost:4200?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent("/#" + this.$route.fullPath)}`;
                //return `http://local-identity.fia.ba:4200?redirectTo=${ encodeURIComponent(window.location.origin) + encodeURIComponent(this.$route.fullPath)}`;
                return `https://identity.fia.ba?redirectTo=${encodeURIComponent(window.location.origin) + encodeURIComponent(this.$route.fullPath)}`;
            }
        },
        filters: {
            upperCase(val) {
                return val.toUpperCase();
            }
        },
        components: {
            appMainMenu: MainMenu,
            appLanguageSwitcher: LanguageSwitcher,
            appProfileMenu: ProfileMenu
        }
    }
</script>

<style scoped>

    .header {
        background-color: #243B80;
        width: 100%;
        padding: 0.5% 5% 0% 5%;
        box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.30);
    }

    .logo {
        width:100%;
        margin-left: -15px;
    }

    .description-item {
        color: #243B80;
        font-weight: bold;
    }

    /* .logo-position{
        margin-top:-1%;
    } */

    .btn {
        border-color: #FFFFFF;
        color: #ffffff;
    }

        .btn:hover {
            background-color: #374e96;
            color: white;
        }
        
    .pointer {
        cursor: pointer;
    }

    .img {
        height: 6.5vh !important;
        width: 6.5vh !important;
    }

    .shadowed {
        box-shadow: 0px 10px 13px -8px rgba(0,0,0,0.30);
    }
    .profile-or-login-btn {
           font-size: 13px;
        }

    .search-font{
        font-size: 13px;
    }
    .search-input-font{
        font-size: 11px;
    }

    .header-buttons{
        margin-top: 10px;
    }

    .menu-margin{
        margin-top: 0px !important;
    }

    @media only screen and (max-width: 600px) {
        .profile-or-login-btn {
            float: right !important;
            margin-top: 5px;
        }

        .language-btn {
            float: left !important;
            margin-top: 5px;
        }
       
    }

    @media only screen and (max-width: 1200px) {
        .profile-or-login-btn {
            float: right !important;
            margin-top: 5px;
        }

        .language-btn {
            float: left !important;
            margin-top: 5px;
        }
        .home-page {
            width: 100% !important;
        }
    }


</style>
