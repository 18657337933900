<template>
    <div>
        <div class="text-center">
            <span class="tag year-tag rounded small d-inline-block pl-4 pr-4 mb-3 mr-2"
                v-bind:class="{ 'selected': year == selectedYear }" v-for="year in years" v-bind:key="year"
                v-on:click="selectYear(year)">{{ year }}</span>
        </div>
        <hr class="mt-0" />
        <div class="text-center">
            <span class="tag rounded report-tag small d-inline-block pl-4 pr-4 mb-3 mr-2"
                v-bind:class="{ 'selected': financialReport.id == selectedFinancialReportId }"
                v-for="financialReport in financialReports" v-bind:key="financialReport.id"
                v-on:click="selectFinancialReport(financialReport.id)">{{ financialReport.name }}</span>
        </div>
        <div class="form-group bg-primary rounded p-2 text-center">
            <span class="text-primary small font-weight-bold">{{ $t("abbreviatedIncomeStatement").toUpperCase()
            }}</span>
        </div>
        <!--<span class="small text-muted"><i class="fa fa-info-circle info-msg" v-bind:title="$t('chooseYearToSeeResults')"></i></span>-->
        <table class="table table-sm mb-0 table-responsive" v-show="financialIndicator && selectedYear<2022">
            <tbody>
                <tr>
                    <td class="small font-weight-bold" width="100%">{{ $t("companies_BU_201") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_201" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_202") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_202" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_211") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_211" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_212") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_212" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_214") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_214" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_215") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_215" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_220") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_220" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_222") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_222" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_225") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_225" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_226") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_226" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_227") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_227" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_234") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_234" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_244") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_244" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_254") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_254" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_311") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_311" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_312") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_312" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_BU_343") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_343" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>
        </table>

       <table class="table table-sm mb-0 table-responsive" v-show="financialIndicator && selectedYear>=2022" >
            <tbody>
                <tr>
                    <td class="small font-weight-bold" width="100%">{{ $t("companies_JP_PrihodiIzUgovoraSKupcima") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_PrihodIzUgovoraSKupcima" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_PrihodiOdProdajeRobe") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_PrihodOdProdajeRoba" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_PrihodiOdGotovihProizvodaMikro") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_PrihodOdProdajeGotovihProizvodaPruzenihUsluga" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_PoslovniRashodi") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_PoslovniPrihod" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_TroskoviSiroveneMatEnerGor") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_TroskoviSirovinaMaterijalaEnergijeIGoriva" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_TroskoviPlataILicnihPrimanja") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_TroskoviPlacaIOstalihLicnihPrimanja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_Amortizacija") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_Amortizacija" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_DobitakPrijeOporezMikro") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_DobitPrijeOporezivanja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_GubitakPrijeOporez") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_GubitakPrijeOporezivanja" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_FinansijskiPrihodi") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_FinansijskiPrihodi" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_FinansijskiRashodi") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_FinansijskiRashodi" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_OstaliPrihodiIDobici") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_OstaliPrihodiIDobici" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_OstaliRashodiIGubici") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_OstaliRashodiIGubici" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_Dobitak") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_Dobitak" width="100" height="30"></canvas>
                    </td>
                </tr>
                <tr>
                    <td class="small font-weight-bold">{{ $t("companies_JP_Gubitak") }}</td>
                    <td class="small text-right">
                        <canvas ref="bU_JP_Gubitak" width="100" height="30"></canvas>
                    </td>
                </tr>
            </tbody>
        </table> 
            

        <div class="text-center small text-muted p-3" v-show="!financialIndicator && selectedYear">
            {{ $t("noResultsForSelectedYear") }}
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
    data() {
        return {
            financialIndicators: [],
            financialReports: [],
            selectedYear: null,
            selectedFinancialReportId: null,
            window: {
                width: null
            }
        }
    },
    props: ["legalEntityId", "years"],
    methods: {
        ...mapActions(["fetchFinancialIndicators"]),
        formatNumber(value) {
            return new Intl.NumberFormat('de-DE').format(value);
        },
        selectYear(year) {
            this.selectedYear = year;
            //TODO: Log Search

            if (this.financialIndicators.length > 0) {
                this.financialReports = this.financialIndicators.sort(fi => fi.reportSubmissionDate).filter(x => x.year == this.selectedYear).map(x => { return x.reportType });
                this.selectFinancialReport(this.financialIndicators.filter(fi => fi.year == this.selectedYear).sort(fi => fi.reportSubmissionDate)[0].reportTypeId);
            }
        },
        selectFinancialReport(financialReportId) {

            this.selectedFinancialReportId = financialReportId;

            if (this.financialIndicator) {
                for (let [key, value] of Object.entries(this.$refs)) {

                    let ctx = value.getContext("2d");
                    ctx.clearRect(0, 0, value.width, value.height); //Clear canvas before drawing new text into it
                    ctx.font = "12px Roboto"; //setting font
                    ctx.textAlign = "end" //Aligning text

                    let width = 95;

                    let negativeValue = false;

                    if (key == "bU_225" && this.financialIndicator[key] == 0) {
                        key = "bU_226";
                        if (this.financialIndicator[key] != 0)
                            negativeValue = true;
                    }

                    if (key == "bU_311" && this.financialIndicator[key] == 0) {
                        key = "bU_312";
                        if (this.financialIndicator[key] != 0)
                            negativeValue = true;
                    }

                    ctx.fillText(this.formatNumber((negativeValue ? (-1) : 1) * this.financialIndicator[key]), width, 24); //Drawing text into canvas
                }
            }

        },
        handleResize() {
            this.window.width = window.innerWidth;
        }
    },
    computed: {
        //years() {
        //    return this.financialIndicators.map(fi => fi.year).sort((a, b) => a - b);
        //},
        financialIndicator() {

            if (this.selectedYear == null || this.selectedFinancialReportId == null)
                return null;

            return this.financialIndicators.find(fi => fi.year == this.selectedYear && fi.reportTypeId == this.selectedFinancialReportId);
        },
        windowWidth() {
            return this.window.width;
        }
    },
    created() {
        let searchObject = { legalEntityId: this.legalEntityId, searchType: 2 };

        this.fetchFinancialIndicators(searchObject).then((financialIndicators) => {
            this.financialIndicators = financialIndicators;
            this.selectYear(this.years[this.years.length - 1]);
        });

        //window.addEventListener('resize', this.handleResize);
        //this.handleResize();
    }
}

</script>

<style scoped>
.tag {
    background-color: rgba(169, 169, 169, 0.5);
    color: #243B80;
    font-weight: bold;
    padding: 5px;
    cursor: pointer;
    transition: transform .2s;
}

.year-tag:hover {
    transform: scale(1.1);
}

.report-tag:hover {
    transform: scale(1.05);
}

.selected {
    background-color: #243B80;
    color: white;
}

td {
    vertical-align: middle;
}

.info-msg {
    font-size: 150%;
    position: absolute;
    top: 23px;
    cursor: pointer;
    transition: transform .2s;
}

.info-msg:hover {
    color: #243B80;
    transform: scale(1.1);
}

.bg-primary {
    background-color: rgba(169, 169, 169, 0.3) !important;
}

.text-primary {
    color: #243B80 !important;
}
</style>

