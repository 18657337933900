<template>
    <div v-if="hasHiglightedIfographic" class="content col-md-12 p-0">
        <div class="row">
            <div class="col-md-12 form-group">
                <div class="higlighted-infographic-header">
                    <div class="row">
                        <div class="col-lg-7 col-md-12 col-sm-6 col-6 pr-0">
                            <i class="fa fa-database ikonica"></i>
                            <span class="ml-1 font-weight-bold small">{{ getContentById(getHighlightedInfographics[0].parentId).title.toUpperCase() }}</span>
                        </div>
                        <router-link v-bind:to="computeRoute" tag="div" class="col-lg-5 col-md-12 col-sm-6 col-6 show-all pl-0">
                                <i class="fa fa-angle-right float-right font-weight-bold mt-1"></i>
                                <span class="float-right mr-1 mt-1 small">{{ $t("showAll") }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <app-infographic-item v-for="highlightedItem in getHighlightedInfographics" v-bind:key="highlightedItem.id" v-bind:infographic="highlightedItem"></app-infographic-item>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import InfographicItem from "./InfographicItem.vue"
    export default {
        data() {
            return {
                parent: null
            }
        },
        components: {
            appInfographicItem: InfographicItem
        },
        computed: {
            ...mapGetters(["getHighlightedInfographics", "getContentById"]),
            hasHiglightedIfographic() {
                return this.getHighlightedInfographics.length > 0;
            },
            computeRoute() {
                var parent = this.getContentById(this.getHighlightedInfographics[0].parentId);
                return { name: 'content', params: { id: parent.id, slug: parent.slug } };
            }
        }
    }
</script>
 
<style scoped>
    .higlighted-infographic-header {
        width: 100%;
        padding: 5%;
        border-radius: 5px;
        background-color: whitesmoke;
        box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.33);
        transition: transform .2s;
    }

        .higlighted-infographic-header:hover {
            cursor: pointer;
            transform: scale(1.03);
        }

    .higlighted-infographic-header {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.33);
        background-color: #243B80;
        color: #FFFFFF;
    }

    .higlighted-infographic-header:hover {
        background-color: #2f3c89;
    }

    .show-all {
        cursor: pointer;
    }

    .higlighted-product-item-content {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
    }

    .pdf-icon {
        height: 25px;
        width: 25px;
    }

    .higlighted-product-item .fa {
        font-size: 25px;
    }

</style>

