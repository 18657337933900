<template>
    <div>
        <div class="row">
            <div class="col-md-12 form-group">
                <div class="search-header p-3">
                    <i class="fa fa-search mr-3"></i>
                    <label>{{ $t("search") }}</label>
                    <hr />
                    <div class="input-group">
                        <input type="text" class="form-control" v-model="getSearch.params.text" v-bind:placeholder="$t('startSearching')" v-on:keyup.enter="search" style="z-index: auto">
                        <div class="input-group-append">
                            <button class="btn" style="z-index: auto" type="button" v-on:click="search"><i class="fa fa-search mr-3"></i> {{ $t("search") }}</button>
                        </div>
                    </div>
                    <span class="text-muted d-inline-flex mt-3" v-if="getSelectedTag || getSelectedContentType || getSelectedYear">{{ $t("searchExecutedBy") }}</span>
                    <span v-if="getSelectedContentType" class="text-muted">
                        {{ $t("contentType") | lowerCase }}
                        <span class="tag small" v-on:click="clearSelectedContentType" v-bind:title="$t('clickToRemove')">
                            {{ $t("contentType"+getSelectedContentType) }}
                            <i class="fa fa-times-circle ml-2"></i>
                        </span>
                    </span>
                    <span v-if="getSelectedTag" class="text-muted">
                        {{ $t("tag") | lowerCase }}
                        <span class="tag small" v-on:click="clearSelectedTag" v-bind:title="$t('clickToRemove')">
                            # {{ getSelectedTag.name }}
                            <i class="fa fa-times-circle ml-2"></i>
                        </span>
                    </span>
                    <span v-if="getSelectedYear" class="text-muted">
                        {{ $t("year") | lowerCase }}
                        <span class="tag small" v-on:click="clearSelectedYear" v-bind:title="$t('clickToRemove')">
                            {{ getSelectedYear }}
                            <i class="fa fa-times-circle ml-2"></i>
                        </span>
                    </span>
                </div>
            </div>
        </div>
        <div class="row" v-if="hasSearchResults">
            <div class="col-lg-2 col-md-4 form-group">
                <!--Advanced search-->
                <div class="search-advance p-3">
                    <i class="fa fa-search-plus mr-3"></i>
                    <label>{{ $t("enhanceYourSearch") }}</label>
                    <hr />
                    <div>
                        <!--Advanced search by content types -->
                        <div class="row form-group" v-if="getSearch.contentTypes && getSearch.contentTypes.length > 0">
                            <div class="col-md-12">
                                <label class="advanced-search-title">{{ $t("contentType") }}</label>
                            </div>
                            <div class="col-md-12 advanced-search-item pb-1 pt-1 rounded" v-for="contentType in getSearch.contentTypes" v-bind:key="contentType.key" v-on:click="searchByContentType(contentType.key)">
                                <span class="float-left small advanced-search-item-title">{{ $t("contentType" + contentType.key)  }}</span> <span class="badge float-right rounded-pill">{{ contentType.value }}</span>
                            </div>
                        </div>
                        <!--Advanced search by tags -->
                        <div class="row form-group" v-if="getSearch.tags && getSearch.tags.length > 0">
                            <div class="col-md-12">
                                <label class="advanced-search-title">{{ $t("tags") }}</label>
                            </div>
                            <div class="col-md-12 advanced-search-item pb-1 pt-1 rounded" v-for="tag in getSearch.tags" v-bind:key="tag.key.tagId" v-on:click="searchByTag(tag.key)">
                                <span class="float-left small advanced-search-item-title"># {{ tag.key.name }}</span> <span class="badge float-right rounded-pill">{{ tag.value }}</span>
                            </div>
                        </div>
                        <!--Advanced search by years -->
                        <div class="row form-group" v-if="getSearch.years && getSearch.years.length > 0">
                            <div class="col-md-12">
                                <label class="advanced-search-title">{{ $t("year") }}</label>
                            </div>
                            <div class="col-md-12 advanced-search-item pb-1 pt-1 rounded" v-for="year in getSearch.years" v-bind:key="year.key" v-on:click="searchByYear(year.key)">
                                <span class="float-left small advanced-search-item-title">{{ year.key }}</span> <span class="badge float-right rounded-pill">{{ year.vaalue }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 col-md-8 form-group">
                <!--Search results-->
                <div class="search-result p-3">
                    <i class="fa fa-list mr-3"></i>
                    <label>{{ $t("searchResults") }}</label>
                    <hr />
                    <div>
                        <app-search-item v-for="searchItem in getSearch.contents" v-bind:key="searchItem.id" v-bind:searchItem="searchItem"></app-search-item>
                        <hr />
                        <div class="col-md-12 text-center">
                            <p class="d-block small text-muted">{{ $t('paginationInfo', getPaginationInfo)}}</p>
                            <app-paginate class="d-inline-flex"
                                          v-model="getSearch.pagination.currentPage"
                                          v-bind:page-count="getSearch.pagination.numberOfPages"
                                          v-bind:click-handler="changePage"
                                          v-bind:prev-text="$t('previous')"
                                          v-bind:next-text="$t('next')"
                                          v-bind:container-class="'pagination'"
                                          v-bind:page-class="'page-item'"
                                          v-bind:page-link-class="'page-link small'"
                                          v-bind:next-class="'page-item'"
                                          v-bind:next-link-class="'page-link small'"
                                          v-bind:prev-class="'page-item'"
                                          v-bind:prev-link-class="'page-link small'"
                                          v-bind:page-range="3"
                                          v-bind:margin-pages="1">
                            </app-paginate>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row" v-else-if="anyParameterEntered">
            <div class="col-md-12 form-group">
                <div class="no-match-found p-3">
                    <div class="bg-white d-block p-2 rounded text-center text-muted">{{ $t("searchNoResults") }}</div>
                </div>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-md-12 form-group">
                <div class="no-match-found p-3">
                    <div class="bg-white d-block p-2 rounded text-center text-muted">{{ $t("enterSearchTerm") }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SearchItem from "./SearchItem"
    import Paginate from 'vuejs-paginate'

    import { mapActions, mapGetters } from "vuex"


    export default {
        data() {
            return {
                skipRouteChange: false
            }
        },
        components: {
            appSearchItem: SearchItem,
            appPaginate: Paginate
        },
        methods: {
            ...mapActions(["searchContents", "searchChangePage", "setSelectedTag", "setSelectedYear", "setSelectedContentType", "fetchTagById"]),
            search() {

                if (!this.skipRouteChange) {
                    var topic = this.getSearch.params.text;
                    var tag = this.getSearch.params.tag;
                    var year = this.getSearch.params.year;
                    var contentTypeId = this.getSearch.params.contentTypeId;

                    if (topic != this.$route.query.topic || (tag != null && tag.tagId != this.$route.query.tagId) || (tag == null && this.$route.query.tagId) || (year != this.$route.query.year) || (contentTypeId != this.$route.query.contentTypeId)) {

                        this.$router.replace({ name: "search", query: { topic: topic, tagId: (tag ? tag.tagId : null), contentTypeId: contentTypeId, year: year } });
                    }
                }

                this.searchContents({ text: this.$route.query.topic });
                this.skipRouteChange = false;
            },
            changePage(clickedPageNumber) {
                this.searchChangePage(clickedPageNumber);
            },
            clearSelectedTag() {
                this.setSelectedTag(null);
                this.search();
            },
            clearSelectedYear() {
                this.setSelectedYear(null);
                this.search();
            },
            clearSelectedContentType() {
                this.setSelectedContentType(null);
                this.search();
            },
            searchByTag(tag) {
                this.setSelectedTag({ tag: tag });
                this.search();
            },
            searchByYear(year) {
                this.setSelectedYear(year);
                this.search();
            },
            searchByContentType(contentType) {
                this.setSelectedContentType(contentType);
                this.search();
            }
        },
        computed: {
            ...mapGetters(["getSearch"]),
            hasSearchResults() {
                return this.getSearch.contents && this.getSearch.contents.length > 0;
            },
            hasEnteredSearchParam() {
                return this.getSearch.params.text && this.getSearch.params.text.length > 0;
            },
            getPaginationInfo() {
                var pagination = this.getSearch.pagination;

                var start = ((pagination.currentPage - 1) * pagination.pageLength) + 1;
                var end = pagination.currentPage > pagination.numberOfPages ? pagination.pageLength * pagination.currentPage : (pagination.pageLength * (pagination.currentPage - 1) + this.getSearch.contents.length);

                return [start, end, pagination.totalRecords];
            },
            getSelectedTag() {
                return this.getSearch.params.tag;
            },
            getSelectedYear() {
                return this.getSearch.params.year;
            },
            getSelectedContentType() {
                return this.getSearch.params.contentTypeId;
            },
            anyParameterEntered() {
                return this.getSelectedTag || this.getSelectedYear || this.getSelectedContentType || this.getSearch.params.text;
            }
        },
        filters: {
            lowerCase(value) {
                return value.toLowerCase();
            }
        },
        created() {
            this.search();
            this.$store.state.isSearching = true;
        },
        beforeDestroy() {
            this.$store.state.isSearching = false;
            this.$store.state.search.params.text= "";
        },
        watch: {
            '$route'() {
                this.skipRouteChange = true;

                var routeTagId = this.$route.query.tagId;
                var routeContentTypeId = this.$route.query.contentTypeId;
                var routeYear = this.$route.query.year;

                if ((routeYear && routeYear != this.getSearch.params.year) || (!routeYear && this.getSearch.params.year))
                    this.setSelectedYear(routeYear);

                if ((routeContentTypeId && routeContentTypeId != this.getSearch.params.contentTypeId) || (!routeContentTypeId && this.getSearch.params.contentTypeId))
                    this.setSelectedContentType(routeContentTypeId);

                if (routeTagId) {
                    var tag = this.getSearch.params.tag;
                    if ((!tag && routeTagId) || (tag && tag.tagId != routeTagId)) {
                        this.fetchTagById(this.$route.query.tagId);
                        setTimeout(() => { this.search(); }, 400);  //needs timeout due to async api request above didn't set selectedTag
                    }
                    else {
                        this.search();
                    }

                } else {
                    this.setSelectedTag(null);
                    this.search();
                }
            }
        }
    }
</script>

<style scoped>
    .content {
        background-color: blue;
    }

    .search-header, .search-result, .search-advance, .no-match-found {
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-radius: 5px;
        background-color: #FFF;
        border-top: 1px solid rgba(0,0,0,0.06);
    }

    .no-search-entered-message {
        cursor: pointer;
    }

    .tag {
        background-color: #243B80;
        color: white;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        cursor: pointer;
    }


    .advanced-search-title {
        color: #243B80;
        font-weight: bold;
    }

    .badge {
        background-color: #243B80;
        color: #FFFFFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
    }

    .advanced-search-item {
        cursor: pointer;
        transition: transform .2s;
    }

        .advanced-search-item:hover .advanced-search-item-title {
            color: #243B80;
            font-weight: bold;
        }

        .advanced-search-item:hover {
            transform: scale(0.95);
            background-color: lightgray;
        }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #374e96;
            color: white;
        }
</style>
