const locations = [
    {
        "id": 2027,
        "name": "UNSKO-SANSKI KANTON",
        "code": "01",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2028,
        "name": "POSAVSKI KANTON",
        "code": "02",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2029,
        "name": "TUZLANSKI KANTON",
        "code": "03",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2030,
        "name": "ZENIČKO-DOBOJSKI KANTON",
        "code": "04",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2031,
        "name": "BOSANSKO-PODRINJSKI KANTON",
        "code": "05",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2032,
        "name": "SREDNJOBOSANSKI KANTON",
        "code": "06",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2033,
        "name": "HERCEGOVAČKO-NERETVANSKI KANTON",
        "code": "07",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2034,
        "name": "ZAPADNOHERCEGOVAČKI KANTON",
        "code": "08",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2035,
        "name": "KANTON SARAJEVO",
        "code": "09",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 2036,
        "name": "KANTON 10",
        "code": "10",
        "locationTypeId": 1,
        "parentId": null,
    },
    {
        "id": 12048,
        "name": "BIHAĆ",
        "code": "104 -10049",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12049,
        "name": "BOSANSKA KRUPA",
        "code": "101 -11428",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12050,
        "name": "BOSANSKI PETROVAC",
        "code": "102 -11436",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12051,
        "name": "BUŽIM",
        "code": "109 -11240",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12052,
        "name": "CAZIN",
        "code": "110 -10227",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12053,
        "name": "KLJUČ",
        "code": "138 -11509",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12054,
        "name": "SANSKI MOST",
        "code": "158 -11541",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12055,
        "name": "VELIKA KLADUŠA",
        "code": "171-11118",
        "locationTypeId": 3,
        "parentId": 2027,
    },
    {
        "id": 12056,
        "name": "DOMALJEVAC-ŠAMAC",
        "code": "118 -11282",
        "locationTypeId": 3,
        "parentId": 2028,
    },
    {
        "id": 12057,
        "name": "ODŽAK",
        "code": "151 -11525",
        "locationTypeId": 3,
        "parentId": 2028,
    },
    {
        "id": 12058,
        "name": "ORAŠJE",
        "code": "153 -11533",
        "locationTypeId": 3,
        "parentId": 2028,
    },
    {
        "id": 12059,
        "name": "BANOVIĆI",
        "code": "103 -10014",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12060,
        "name": "ČELIĆ",
        "code": "113 -11231",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12061,
        "name": "DOBOJ-ISTOK",
        "code": "115 -11258",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12062,
        "name": "GRACANICA",
        "code": "126 -11479",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12063,
        "name": "GRADAČAC",
        "code": "127 -10391",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12064,
        "name": "KALESIJA",
        "code": "135 -11495",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12065,
        "name": "KLADANJ",
        "code": "137 -10499",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12066,
        "name": "LUKAVAC",
        "code": "143 -10600",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12067,
        "name": "SAPNA",
        "code": "159 -11312",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12068,
        "name": "SREBRENIK",
        "code": "160 -10987",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12069,
        "name": "TEOČAK",
        "code": "164-11339",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12070,
        "name": "TUZLA",
        "code": "168-11088",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12071,
        "name": "ŽIVINICE",
        "code": "178-11215",
        "locationTypeId": 3,
        "parentId": 2029,
    },
    {
        "id": 12072,
        "name": "BREZA",
        "code": "106 -10189",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12073,
        "name": "DOBOJ-JUG",
        "code": "116 -11266",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12074,
        "name": "KAKANJ",
        "code": "134 -10448",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12075,
        "name": "MAGLAJ",
        "code": "145 -10634",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12076,
        "name": "OLOVO",
        "code": "152 -10715",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12077,
        "name": "TEŠANJ",
        "code": "165 -11045",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12078,
        "name": "USORA",
        "code": "169-11622",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12079,
        "name": "VAREŠ",
        "code": "170-11100",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12080,
        "name": "VISOKO",
        "code": "172-11126",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12081,
        "name": "ZAVIDOVIĆI",
        "code": "175-11177",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12082,
        "name": "ZENICA",
        "code": "176-11185",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12083,
        "name": "ŽEPČE",
        "code": "177-11207",
        "locationTypeId": 3,
        "parentId": 2030,
    },
    {
        "id": 12084,
        "name": "FOČA",
        "code": "121 -11444",
        "locationTypeId": 3,
        "parentId": 2031,
    },
    {
        "id": 12085,
        "name": "GORAŽDE",
        "code": "124 -11452",
        "locationTypeId": 3,
        "parentId": 2031,
    },
    {
        "id": 12086,
        "name": "PALE",
        "code": "154 -11576",
        "locationTypeId": 3,
        "parentId": 2031,
    },
    {
        "id": 12087,
        "name": "BUGOJNO",
        "code": "107 -10197",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12088,
        "name": "BUSOVAČA",
        "code": "108 -10219",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12089,
        "name": "DOBRETIĆI",
        "code": "117 -11274",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12090,
        "name": "DONJI VAKUF",
        "code": "119 -10294",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12091,
        "name": "FOJNICA",
        "code": "122 -10324",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12092,
        "name": "GORNJI VAKUF-USKOPLJE",
        "code": "125 -10375",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12093,
        "name": "JAJCE",
        "code": "133 -11487",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12094,
        "name": "KISELJAK",
        "code": "136 -10472",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12095,
        "name": "KREŠEVO",
        "code": "140 -10545",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12096,
        "name": "NOVI TRAVNIK",
        "code": "149 -10774",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12097,
        "name": "TRAVNIK",
        "code": "167-11061",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12098,
        "name": "VITEZ",
        "code": "173-11142",
        "locationTypeId": 3,
        "parentId": 2032,
    },
    {
        "id": 12099,
        "name": "ČAPLJINA",
        "code": "112 -10243",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12100,
        "name": "ČITLUK",
        "code": "114 -10260",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12101,
        "name": "JABLANICA",
        "code": "132 -10421",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12102,
        "name": "KONJIC",
        "code": "139 -10529",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12103,
        "name": "MOSTAR",
        "code": "146 -11410",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12104,
        "name": "NEUM",
        "code": "147 -10685",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12105,
        "name": "PROZOR",
        "code": "156 -10766",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12106,
        "name": "RAVNO",
        "code": "157 -11304",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12107,
        "name": "STOLAC",
        "code": "162 -11606",
        "locationTypeId": 3,
        "parentId": 2033,
    },
    {
        "id": 12108,
        "name": "GRUDE",
        "code": "128 -10405",
        "locationTypeId": 3,
        "parentId": 2034,
    },
    {
        "id": 12109,
        "name": "LJUBUŠKI",
        "code": "144 -10626",
        "locationTypeId": 3,
        "parentId": 2034,
    },
    {
        "id": 12110,
        "name": "POSUŠJE",
        "code": "155 -10731",
        "locationTypeId": 3,
        "parentId": 2034,
    },
    {
        "id": 12111,
        "name": "ŠIROKI BRIJEG",
        "code": "163-10570",
        "locationTypeId": 3,
        "parentId": 2034,
    },
    {
        "id": 12112,
        "name": "CENTAR SARAJEVO",
        "code": "111 -10839",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12113,
        "name": "HADŽIĆI",
        "code": "129 -10847",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12114,
        "name": "ILIDŽA",
        "code": "130 -11550",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12115,
        "name": "ILIJAŠ",
        "code": "131 -10863",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12116,
        "name": "NOVI GRAD SARAJEVO",
        "code": "148 -10871",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12117,
        "name": "NOVO SARAJEVO",
        "code": "150 -11568",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12118,
        "name": "STARI GRAD SARAJEVO",
        "code": "161 -11584",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12119,
        "name": "TRNOVO",
        "code": "179-11592",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12120,
        "name": "VOGOŠĆA",
        "code": "174-10928",
        "locationTypeId": 3,
        "parentId": 2035,
    },
    {
        "id": 12121,
        "name": "BOSANSKO GRAHOVO",
        "code": "105 -10146",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 12122,
        "name": "DRVAR",
        "code": "120 -11614",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 12123,
        "name": "GLAMOČ",
        "code": "123 -10359",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 12124,
        "name": "KUPRES",
        "code": "141 -11517",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 12125,
        "name": "LIVNO",
        "code": "142 -10588",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 12126,
        "name": "TOMISLAVGRAD",
        "code": "166-10308",
        "locationTypeId": 3,
        "parentId": 2036,
    },
    {
        "id": 15488,
        "name": "Frankfurt",
        "locationTypeId": 3,
        "parentId": 15487,
    },
    {
        "id": 15490,
        "name": "Zagreb",
        "locationTypeId": 3,
        "parentId": 15489,
    }
];

export { locations }