<template>
    <div>
        <div class="row">
            <div class="col-md-12 form-group">
                <app-breadcrumb class="breadcrumb p-0" :items="breadcrumbItems"></app-breadcrumb>
            </div>
            <div class="col-lg-4 col-md-12">
                <!-- Basic info - START -->
                <div class="panel mb-1 rounded p-4 mb-4">
                    <div class="form-group text-center bg-primary rounded p-2">
                        <span class="text-primary small font-weight-bold">{{ $t("info") | upperCase }}</span>
                    </div>
                    <hr class="mt-0" />
                    <div class="row">
                        <!--<div class="col-md-12" v-html="$t('financialReportTemplatesInfoTextHtml')">
                        </div>-->
                        <!--<div class="col-md-12">
                            <p>{{$t("usefullLinks")}}</p>
                            <hr />
                        </div>-->
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <a class="btn btn-block" href="\Upload\Predlosci\Uputstvo računovođama 2022.pdf">
                                    {{ $t("instructionForAccountants") }}
                                </a>
                            </div>
                            <div class="col-sm-12 mt-2 text-center">Savjeti i napomene za korištenje predložaka</div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <a class="btn btn-block" href="\Upload\Fonts\BarCode39.ttf">
                                    <i class="fa fa-barcode mr-3"></i>{{ $t("barcodeFont") }}
                                </a>
                            </div>
                            <div class="col-sm-12 mt-2 text-center">Za prikazivanje barkodova kontrolnih brojeva &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12">
                                <a class="btn btn-block" href="https://www.libreoffice.org/download/download">
                                    <i class="fa fa-file mr-3"></i>{{ $t("libreOffice") }}
                                </a>
                            </div>
                            <div class="col-sm-12 mt-2 text-center">Besplatna alternativa za Microsoft Excel  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        </div>
                        <div class="row form-group">
                            <div class="col-sm-12 mt-2">  <hr class="mt-0" />Preuzmite predloške u Microsoft Wordu:</div>
                            <div class="col-sm-12" v-show="selectedLanguage == 'bs'">
                                <a href="\Upload\Predlosci\Bilješke uz finansijske izvještaje.docx">
                                    {{ $t("downloadNotes") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Godišnji izvještaj o poslovanju.docx">
                                    {{ $t("downloadAnnualBusinessReport") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o raspodjeli poslovnog rezultata.docx">
                                    {{ $t("downloadBusinessResult") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o utvrđivanju finansijskih izvještaja.docx">
                                    {{ $t("downloadDetermingFinancialReport") }}
                                </a><br />
                            </div>
                            <div class="col-sm-12" v-show="selectedLanguage == 'hr'">
                                <a href="\Upload\Predlosci\Bilješke uz finansijske izvještaje - HRV.docx">
                                    {{ $t("downloadNotes") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Godišnji izvještaj o poslovanju - HRV.docx">
                                    {{ $t("downloadAnnualBusinessReport") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o raspodjeli poslovnog rezultata - HRV.docx">
                                    {{ $t("downloadBusinessResult") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o utvrđivanju financijskih izvještaja - HRV.docx">
                                    {{ $t("downloadDetermingFinancialReport") }}
                                </a><br />
                            </div>
                            <div class="col-sm-12" v-show="selectedLanguage == 'en'">
                                <a href="\Upload\Predlosci\Bilješke uz finansijske izvještaje.docx">
                                    {{ $t("downloadNotes") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Godišnji izvještaj o poslovanju.docx">
                                    {{ $t("downloadAnnualBusinessReport") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o raspodjeli poslovnog rezultata.docx">
                                    {{ $t("downloadBusinessResult") }}
                                </a><br />
                                <a href="\Upload\Predlosci\Odluka o utvrđivanju finansijskih izvještaja.docx">
                                    {{ $t("downloadDetermingFinancialReport") }}
                                </a><br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <!-- <div class="panel rounded p-4 mb-4">
                    <div class="form-group text-center bg-danger rounded p-3">
                        <span class="small font-weight-bold" style="color: white;">{{ $t("oldReportsNote") | upperCase }}</span>
                        <button v-show="selectedCreatorType != null" type="button" class="btn btn-sm pull-right" v-on:click="clearForm()">{{ $t("clearForm") }} <i class="fa fa-clear"></i></button>
                    </div>
                </div> -->
                <div class="panel rounded p-4 mb-4">
                    <div class="form-group text-center bg-danger rounded p-3">
                        <span class="small font-weight-bold" style="color: white;">{{ $t("oldReportsNote") | upperCase }}</span>
                    </div>
                    <div class="form-group text-center bg-primary rounded p-3">
                        <span class="text-primary small font-weight-bold">{{ $t("excelTemplateDownload") | upperCase }}</span>
                        <button v-show="selectedCreatorType != null" type="button" class="btn btn-sm pull-right" v-on:click="clearForm()">{{ $t("clearForm") }} <i class="fa fa-clear"></i></button>
                    </div>
                    <hr class="mt-0" />
                    <div class="row">
                        <div class="col-md-12"><label>{{ $t("chooseCreatorTypeInfo") }}</label></div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-block text-center p-2"
                                    v-bind:class="{'creator-type-selected': selectedCreatorType == 1}"
                                    v-on:click="selectCreatorType(1)">
                                {{ $t("accountant") }}
                            </button>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-block text-center p-2"
                                    v-bind:class="{'creator-type-selected': selectedCreatorType == 2}"
                                    v-on:click="selectCreatorType(2)">
                                {{ $t("auditCompany") }}
                            </button>
                        </div>
                        <p class="small text-danger m-0" v-if="$v.selectedCreatorType.$dirty && !$v.selectedCreatorType.required">{{ $t('creatorTypeIsRequired') }}</p>
                    </div>
                    <div class="row" v-show="selectedCreatorType != null">
                        <div class="col-6 form-group mt-3">
                            <app-select v-show="selectedCreatorType == 1" v-model="selectedCreatorId" v-bind:options="accountants" v-bind:reduce="accountant => accountant.id" label="displayName" v-bind:placeholder="$t('chooseAccountant')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <app-select v-show="selectedCreatorType == 2" v-model="selectedCreatorId" v-bind:options="auditCompanies" v-bind:reduce="auditCompany => auditCompany.id" label="displayName" v-bind:placeholder="$t('chooseAuditCompany')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <p class="small text-danger m-0" v-if="$v.selectedCreatorId.$dirty && !$v.selectedCreatorId.required">{{ $t('creatorIdIsRequired') }}</p>
                        </div>
                        <div class="col-md-6 form-group mt-3">
                            <app-select v-model="selectedLegalEntityTypeId" v-bind:options="legalEntityTypes" v-bind:reduce="legalEntityType => legalEntityType.id" label="name" v-bind:placeholder="$t('chooseLegalEntitiyType')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <p class="small text-danger m-0" v-if="$v.selectedLegalEntityTypeId.$dirty && !$v.selectedLegalEntityTypeId.required">{{ $t('legaleEntityTypeIsRequired') }}</p>
                        </div>
                    </div>
                    <div class="row" v-show="selectedCreatorType != null">
                        <div class="col-md-6 form-group">
                            <input v-model="selectedJIB" class="form-control" type="number" v-bind:placeholder="$t('enterJIB')" min="0" />
                        </div>
                        <div class="col-md-6 form-group">
                            <app-select v-model="selectedReportTypeId" v-bind:options="reportTypes" v-bind:reduce="reportType => reportType.id" label="name" v-bind:placeholder="$t('chooseReportType')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <p class="small text-danger m-0" v-if="$v.selectedReportTypeId.$dirty && !$v.selectedReportTypeId.required">{{ $t('reportTypeIsRequired') }}</p>
                        </div>
                    </div>
                    <div class="row" v-show="selectedCreatorType != null">                 
                        <div class="col-md-6 form-group">
                            <div class="form-check form-switch">
                                <input v-model="selectedPublicInterest" @change="publicInterestOnChange($event)" class="form-check-input pointer" type="checkbox" style="width: 20px; height: 20px;">
                                <label class="form-check-label" for="flexCheckDefault" style="margin-left: 30px; margin-top: 5px;">
                                    {{ $t('choosePublicInterestSubject') }}
                                </label>
                            </div>
                            <!--<app-select @change="publicInterestOnChange()" v-bind:options="publicInterests" v-bind:reduce="publicInterest => publicInterest.id" label="name" v-bind:placeholder="$t('choosePublicInterestSubject')" class="select2 pointer" v-model="selectedPublicInterest">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>-->
                            <!--<p class="small text-danger m-0" v-if="$v.selectedPublicInterest.$dirty && !$v.selectedPublicInterest.required">{{ $t('publicInterestIsRequired') }}</p>-->
                        </div>
                        <div class="col-md-6 form-group">
                            <app-select v-model="selectedReportingStandard" v-bind:options="reportingStandards" v-bind:reduce="reportingStandard => reportingStandard.id" label="name" v-bind:placeholder="$t('chooseReportingStandard')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <!--<p class="small text-danger m-0" v-if="$v.selectedReportingStandard.$dirty && !$v.selectedReportingStandard.required">{{ $t('reportingStandardIsRequired') }}</p>-->
                        </div>
                        <div class="col-md-6 form-group">
                            <!--<input v-model="selectedAccountingPeriod" class="form-control" type="number" min="2000" v-bind:placeholder="$t('accountingPeriod')" />-->
                            <app-select v-model="selectedAccountingPeriod" v-bind:options="reportingYears" v-bind:placeholder="$t('accountingPeriod')" class="select2 pointer">
                                <div slot="no-options" class="text-muted">{{ $t("searchNoResults") }}</div>
                                <template #search="{ attributes, events }">
                                    <input class="vs__search"
                                           v-bind="attributes"
                                           v-on="events">
                                </template>
                            </app-select>
                            <p class="small text-danger m-0" v-if="$v.selectedAccountingPeriod.$dirty && !$v.selectedAccountingPeriod.required">{{ $t('accountingPeriodIsRequired') }}</p>
                        </div>
                    </div>
                    <!--<div class="row" v-show="selectedCreatorType != null">-->
                    <!--<div class="col-md-6"><label class="labelStyle">{{ $t("accountingIntervalFrom") }}</label></div>
                    <div class="col-md-6"><label class="labelStyle">{{ $t("accountingIntervalTo") }}</label></div>-->
                    <!--<div class="col-md-6 form-group">
                            <input v-model="selectedDateFrom" class="form-control" type="date" v-bind:placeholder="$t('dateFrom')" />
                            <label class="labelStyle">{{ $t("accountingIntervalFrom") }}</label>
                        </div>
                        <div class="col-md-6 form-group">
                            <input v-model="selectedDateTo" class="form-control" type="date" v-bind:placeholder="$t('dateTo')" />
                            <label class="labelStyle">{{ $t("accountingIntervalTo") }}</label>
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-md-12">
                            <hr />
                        </div>
                        <div class="col-md-12">
                            <button type="button" class="btn btn-block pull-right" v-on:click="downloadTemplate()" :disabled="isLoading ? true : false">{{ $t("downloadTemplate") }} <i class="fa fa-download"></i></button>
                        </div>
                        <div class="col-md-12 mt-5" v-show="showSpinner == true">
                            <div class="row d-flex justify-content-center">
                                <div class="loader"> <p class="mt-4">Pričekajte...</p></div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <p class="p-0 mt-3" style="color:red">
                                Ukoliko ne možete pronaći računovođu u listi molimo Vas da se obratite SRR-FBiH.
                            </p>
                            <!-- <p class="p-0 mt-3" style="color:red">
                                Za sva pitanja i tehničke poteškoće kod popunjavanja elektronskih predložaka obratite se na broj telefona: 036/281-371.
                            </p> -->
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-sm-12 mt-5" v-show="isTemplatedGenerated == true">
                            <p class="p-0">Excel predložak je preuzet. Nakon popunjavanja, molimo provedite kontrolu na našoj web stranici prije zaključivanja, štampanja i snimanja finansijskog izvještaja.</p>
                            <p class="p-0" style="color:red">Napomena: ostale podatke sa osnovnih podataka možete popuniti ručno.</p>
                            <div class="row d-flex justify-content-center">
                                <div class="col-md-4">
                                    <a type="button" class="btn btn-block pull-right" href="https://fia.ba/bs/kontrola-finansijskih-izvjestaja">{{ $t("financialReportsControl") }} <i class="fa fa-check"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    //import moment from 'moment'

    import { BBreadcrumb } from 'bootstrap-vue'
    import { mapActions, mapGetters } from "vuex"
    import Select from 'vue-select'
    import 'vue-select/dist/vue-select.css';
    import { required } from "vuelidate/lib/validators"
    import { publicInterests } from '../../../assets/staticData/publicInterests'
    import { reportingStandards } from '../../../assets/staticData/reportingStandards'
    import { Date } from 'core-js';

    export default {
        data() {
            return {
                reportTypes: [],
                legalEntityTypes: [],
                accountants: [],
                auditCompanies: [],
                reportingYears: [],
                reportingStandards: [],
                publicInterests: [],
                selectedLegalEntityTypeId: null,
                selectedCreatorId: null,
                selectedCreatorType: null,
                selectedJIB: null,
                selectedReportTypeId: null,
                selectedReportingStandard: null,
                selectedAccountingPeriod: null, //new Date().getFullYear(),
                //selectedDateFrom: new Date("2021-01-01").toISOString().substr(0, 10),
                //selectedDateTo: new Date("2021-12-31").toISOString().substr(0, 10),
                selectedLanguage: null,
                isTemplatedGenerated: false,
                showSpinner: false,
                isLoading: false,
                selectedPublicInterest: 0
            }
        },
        components: {
            appBreadcrumb: BBreadcrumb,
            appSelect: Select,
        },
        methods: {
            ...mapActions(["fetchLegalEntityTypes", "fetchReportTypes", "fetchAccountants", "fetchAuditCompanies", "generateFileTemplateOld"]),
            calculateSharePercentage(share) {
                let sum = 0;

                this.foundingStructure.forEach((founder) => sum += founder.share);

                return ((share / sum) * 100).toFixed(2);
            },
            formatNumber(value) {
                return new Intl.NumberFormat('de-DE').format(value);
            },
            //selectReportType(id) {
            //    this.selectedReportTypeId = id;
            //},
            publicInterestOnChange($event) {
                //console.log($event.target.checked);
                if ($event.target.checked == true) {
                    this.selectedReportingStandard = 2;
                    this.selectedPublicInterest = 1;
                }
                else {
                    this.selectedReportingStandard = null;
                    this.selectedPublicInterest = 0;
                }
            },
            clearForm() {
                this.isTemplatedGenerated = null;
                this.selectedCreatorType = null;
                this.selectedCreatorId = null;
                this.selectedLegalEntityTypeId = null;
                this.selectedJIB = null;
                this.selectedReportTypeId = null;
                this.selectedReportingStandard = null;
                this.selectedPublicInterest = null;
                this.selectedAccountingPeriod = null;
                //this.selectedDateFrom = new Date("2021-01-01").toISOString().substr(0, 10);
                //this.selectedDateTo = new Date("2021-12-31").toISOString().substr(0, 10);
                this.showSpinner = false;
                this.isLoading = false;
            },
            selectReportType(id) {
                this.selectedLegalEntityTypeId = id;
            },
            selectCreatorType(id) {
                this.selectedCreatorType = id;
                this.selectedCreatorId = null;
                if (this.selectedCreatorType == 1 && this.accountants.length < 1) {
                    this.fetchAccountants().then((accountants) => {
                        if (accountants.length > 0)
                            accountants.forEach((accountant) => {
                                this.accountants.push({
                                    id: accountant.id, displayName: `${accountant.firstName} (${accountant.parentName}) ${accountant.lastName}; ${this.$t('licence')}  ${accountant.licenceNumber}`,
                                    firstName: accountant.firstName, parentName: accountant.parentName, lastName: accountant.lastName, licenceNumber: accountant.licenceNumber
                                });
                            });
                        //console.log(this.accountants);
                    });
                } else if (this.selectedCreatorType == 2 && this.auditCompanies.length < 1) {
                    this.fetchAuditCompanies().then((auditCompanies) => {
                        if (auditCompanies.length > 0)
                            auditCompanies.forEach((auditCompany) => {
                                this.auditCompanies.push({ id: auditCompany.id, displayName: auditCompany.displayName });
                            });
                        //console.log(this.auditCompanies);
                    });
                }
            },
            downloadTemplate() {
                if (this.$v.$invalid) {
                    this.$v.$touch();
                    /*   loader.hide();*/
                    return;
                }

                this.showSpinner = true;
                this.isLoading = true;

                if (this.selectedReportingStandard === null) {
                    this.selectedReportingStandard = 0;
                }

                var selectedReportType=this.reportTypes.find(x=>x.id===this.selectedReportTypeId);
                var reportTypeNameSelected=selectedReportType.name;

                this.generateFileTemplateOld({
                    creatorType: this.selectedCreatorType, creatorId: this.selectedCreatorId, legalEntityTypeId: this.selectedLegalEntityTypeId,
                    jib: this.selectedJIB, reportType: this.selectedReportTypeId, reportingYear: this.selectedAccountingPeriod,
                    reportingStandard: this.selectedReportingStandard,  reportTypeName:reportTypeNameSelected,
                    //reportingStandardName: this.reportingStandards[this.selectedReportingStandard - 1].name,
                    //dateFrom: this.selectedDateFrom, dateTo: this.selectedDateTo,
                    publicInterest: this.selectedPublicInterest
                }).then((excelTemplate) => {
                    const url = URL.createObjectURL(new Blob([excelTemplate.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.legalEntityTypes.find(x => x.id == this.selectedLegalEntityTypeId).name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.isTemplatedGenerated = true;
                    this.showSpinner = false;
                    this.isLoading = false;
                }).catch(err => {
                    console.log(err);
                    //alert("JIB koji ste unijeli nije validan ili ne postoji u našoj bazi podataka.");
                    if (confirm('Uneseni JIB ne postoji u bazi podataka. Možete preuzeti predložak bez unešenog JIB-a te naknadno popuniti osnovne podatke.')) {
                        this.selectedJIB = null;
                    }
                    this.showSpinner = false;
                    this.isLoading = false;
                });
            }
        },
        computed: {
            ...mapGetters(["getContentByUrl", "getContentById", "getSelectedLanguage"]),
            breadcrumbItems() {
                this.selectedLanguage = this.getSelectedLanguage.code;
                let urlToCheck = this.$t("financial-reports-templates-old");
                let content = this.getContentByUrl(urlToCheck);

                if (!content) {
                    urlToCheck = "financial-reports-templates-old";
                    content = this.getContentByUrl(urlToCheck);
                }

                let contentId = content.id;
                let items = [];

                items.push({ text: this.$t("generateFile"), to: null });

                do {
                    let content = this.getContentById(contentId);
                    let routeName = content.url == urlToCheck ? urlToCheck : content.contentTypeId <= 2 ? "menuDetails" : "content";
                    items.push({ text: content.title, to: { name: routeName, params: { id: content.id, slug: content.slug } } })
                    contentId = content.parentId;
                } while (contentId);

                items.push({ html: "<i class='fa fa-home mr-1'></i> " + this.$t("homePage"), to: "/" });

                items[0].to = null;
                items[0].active = true;
                items = items.reverse()

                return items;
            },
        },
        filters: {
            lowerCase(value) {
                return value.toLowerCase();
            },
            upperCase(value) {
                return value.toUpperCase();
            }
        },
        created() {
            this.fetchReportTypes().then((reportTypes) => {
                if (reportTypes.length > 0)
                    reportTypes.forEach((reportType) => {
                        if(reportType.year==2021)
                        {
                            this.reportTypes.push({ id: reportType.id, name: this.$t(reportType.localizationKey), localizationKey: reportType.localizationKey });
                        }
                    });
            });
            this.fetchLegalEntityTypes().then((legalEntityTypes) => {
                //console.log(legalEntityTypes);
                if (this.legalEntityTypes.length >= 0)
                    legalEntityTypes.forEach((legalEntityType) => {
                        if(legalEntityType.id!=7)
                        {
                        this.legalEntityTypes.push({ id: legalEntityType.id, name: legalEntityType.name });
                        }
                    });
            });

            this.reportingYears = [2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003];
            this.publicInterests = publicInterests.sort((a, b) => { a.name - b.name });
            this.reportingStandards = reportingStandards.sort((a, b) => { a.name - b.name });                  
        },
        validations: {
            selectedCreatorId: {
                required
            },
            selectedLegalEntityTypeId: {
                required
            },
            selectedCreatorType: {
                required
            },
            selectedReportTypeId: {
                required
            },
            //selectedReportingStandard: {
            //    required
            //},
            selectedAccountingPeriod: {
                required
            }
            //selectedPublicInterest: {
            //    required
            //}
        },
        watch: {
            '$route'() {
                if (this.reportTypes.length > 0) {
                    let reportTypesCopy = this.reportTypes;
                    this.reportTypes = [];
                    reportTypesCopy.forEach((reportType) => {
                        this.reportTypes.push({ id: reportType.id, name: this.$t(reportType.localizationKey), localizationKey: reportType.localizationKey });
                    });
                }

                if (this.legalEntityTypes.length >= 0) {
                    this.legalEntityTypes = [];
                    this.fetchLegalEntityTypes().then((legalEntityTypes) => {
                        if (this.legalEntityTypes.length >= 0)
                            legalEntityTypes.forEach((legalEntityType) => {
                                this.legalEntityTypes.push({ id: legalEntityType.id, name: legalEntityType.name });
                            });
                    });
                }

                if (this.accountants.length > 0) {
                    let accountantsCopy = this.accountants;
                    this.accountants = [];
                    accountantsCopy.forEach((accountant) => {
                        this.accountants.push({
                            id: accountant.id, displayName: `${accountant.firstName} (${accountant.parentName}) ${accountant.lastName}; ${this.$t('licence')}  ${accountant.licenceNumber}`,
                            firstName: accountant.firstName, parentName: accountant.parentName, lastName: accountant.lastName, licenceNumber: accountant.licenceNumber
                        });
                    });
                }
                //catch when change language
                this.selectedLanguage = this.getSelectedLanguage.code;
            }
        }
    }

</script>

<style scoped>
    .content {
        background-color: blue;
    }

    .search-header, .search-result, .search-advance, .no-match-found {
        box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
        border-top: 1px solid rgba(0, 0, 0, 0.06);
        border-radius: 5px;
        background-color: #FFF;
    }

    .advanced-search-item {
        cursor: pointer;
        transition: transform .2s;
    }

        .advanced-search-item:hover .advanced-search-item-title {
            color: #243B80;
            font-weight: bold;
        }

        .advanced-search-item:hover {
            transform: scale(0.95);
            background-color: lightgray;
        }

    .select2 {
        background-color: white;
    }

    .vs__search {
        height: 28px;
        color: #495057;
    }

        .vs__search:v-bind:placeholder {
            color: rgba(73, 80, 87, 0.9);
        }

    .register-item {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        cursor: pointer;
        transition: transform .2s;
    }

        .register-item:hover {
            transform: scale(1.02);
            background-color: #243B80;
            color: white;
        }

    .selected {
        background-color: #243B80;
        color: white;
    }

    .selected-danger {
        background-color: #dc3545;
        color: white;
    }

    .btn {
        border-color: #243B80;
        color: #243B80;
    }

        .btn:hover {
            background-color: #243B80;
            color: white;
        }

    .nav-item {
        cursor: pointer;
        background-color: darkgray;
    }

        .nav-item:last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
            border-left: 1px solid white;
        }

        .nav-item:first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
            border-right: 1px solid white;
        }

        .nav-item:hover {
            background-color: #243B80;
        }

    .selected {
        background-color: #243B80;
    }

    .breadcrumb {
        background-color: #FFF;
    }

        .breadcrumb a {
            color: #243B80;
            font-weight: bold;
        }

            .breadcrumb a:hover {
                color: #374e96;
            }

    .nfis-links, .nfis-links:active {
        text-decoration: none !important;
        color: black;
    }

    .panel {
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }


    .text-primary {
        color: #243B80 !important;
    }

    .bg-primary {
        background-color: rgba(169, 169, 169, 0.3) !important;
    }

    .report-type-option {
        border: 1px dashed gray;
        transition: transform .2s;
    }

        .report-type-option:hover {
            transform: scale(1.1);
            background-color: #243B80;
            color: white;
        }

    .report-type-option-selected {
        background-color: #243B80;
        color: white;
    }

    .pointer {
        cursor: pointer !important;
    }

    .creator-type-selected {
        background-color: #243B80 !important;
        color: white;
    }

    .labelStyle {
        font-size: 12px;
    }
    /*    spinner*/
    .loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid #243B80;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
        0% {
            -webkit-transform: rotate(0deg);
        }

        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>