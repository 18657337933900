<template>
    <div class="content col-md-12 p-0" v-if="hasFeaturedItems">
        <div class="row">
            <div class="col-md-12 form-group">
                <div class="highlights-header rounded">
                    <div class="row">
                        <div class="col-lg-12">
                            <i class="fa fa-star"></i>
                            <span class="ml-3 font-weight-bold small" style="font-weight: 500 !important;">{{ $t("featured").toUpperCase() }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <app-featured-item v-for="featuredItem in getFeaturedItems" v-bind:key="featuredItem.id" v-bind:featuredItem="featuredItem"></app-featured-item>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import FeaturedItem from "./FeaturedItem"

    export default {
        data() {
            return {
                parent: null
            }
        },
        components: {
            appFeaturedItem: FeaturedItem
        },
        computed: {
            ...mapGetters(["getFeaturedItems"]),
            hasFeaturedItems() {
                return this.getFeaturedItems.length > 0;
            },
        }
    }
</script>
 
<style scoped>

    .highlights-header {
        width: 100%;
        padding: 10px;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.33);
        background-color: #243B80;
        color: #FFFFFF;
    }

    .highlights-header:hover {
        background-color: #374e96;
    }

</style>

