<template>
    <div class="col-lg-6 col-md-12">
        <div class="content col-md-12 p-1">
            <div class="featured-item rounded">
                <div class="row">
                    <router-link v-if="!isExternalLink(null)" v-bind:to="computeRoute" tag="div" class="col-lg-12">
                        <span class="font-weight-normal small featured-item-content" :title="featuredItem.title">
                            <span class="text-muted font-weight-bold">
                            <!--{{ $t("contentTypeSingular" + featuredItem.contentTypeId).toUpperCase() }}--> 
                                <i :class="$t('contentTypeIcon' + featuredItem.contentTypeId)"></i> | 
                            </span> 
                            {{ featuredItem.title }}
                        </span>
                    </router-link>
                    <div v-else class="col-lg-12">
                        <a :href="featuredItem.url" target="_blank" class="text-decoration-none">
                            <span class="font-weight-normal small featured-item-content text-dark" :title="featuredItem.title">
                                <span class="text-muted font-weight-bold">
                                    <!--{{ $t("contentTypeSingular" + featuredItem.contentTypeId).toUpperCase() }}-->
                                    <i :class="$t('contentTypeIcon' + featuredItem.contentTypeId)"></i> |
                                </span>
                                {{ featuredItem.title }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        props: ["featuredItem"],
        computed: {
            ...mapGetters(["getContentById","getContentTypes"]),
            computeRoute() {
                let content = this.featuredItem;

                if (content.contentTypeId >= this.getContentTypes.documentsContentTypeId && content.contentTypeId <= this.getContentTypes.videoContentTypeId)
                    content = this.getContentById(content.parentId);

                return this.isInternalLink(content) ? { name: content.url } : { name: 'content', params: { id: content.id, slug: content.slug } };
            }
        },
        methods: {
            isExternalLink(menu) {
                if (menu)
                    return menu.contentTypeId == this.getContentTypes.linkContentTypeId && menu.url.startsWith("http");

                return this.featuredItem.contentTypeId == this.getContentTypes.linkContentTypeId && this.featuredItem.url.startsWith("http");
            },
            isInternalLink() {
                return this.featuredItem.contentTypeId == this.getContentTypes.linkContentTypeId && !this.featuredItem.url.startsWith("http");
            },
        }
    }
</script>

<style scoped>

    .featured-item {
        width: 100%;
        padding: 2%;
        background-color: #FFF;
        border-top: 1px solid rgba(0,0,0,0.07);
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        cursor: pointer;
        transition: transform .2s;
    }

        .featured-item:hover {
            transform: scale(1.02);
        }

    .featured-item-content {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .text-muted {
        color: #243B80 !important;
    }
</style>

