<template>
    <div class="dropdown" v-on:mouseleave="menuHover(null)">
        <div class="row small">
            <div class="col-lg-12">
                <ul v-if="showMainMenu" class="nav nav-pills nav-fill main-menu">
                    <router-link :to="{ name:'menuDetails', params:{ id: menuItem.id, slug: menuItem.slug } }" tag="li" active-class="active-main-menu" v-for="menuItem in mainMenuItems" v-bind:key="menuItem.id" class="nav-item main menu-items-padding"> 
                        <a v-on:mouseover="menuHover(menuItem)" v-on:click="menuClick" class="nav-link">{{ menuItem.title.toUpperCase() }}</a>
                    </router-link>
                    <router-link :to="{ name:'menuDetails', params:{ id: menuItem.id, slug: menuItem.slug } }" tag="li" active-class="active-main-menu" v-for="menuItem in secondaryMenuItems" v-bind:key="menuItem.id" class="nav-item main menu-items-padding">
                        <a v-on:mouseover="menuHover(menuItem)" v-on:click="menuClick" class="nav-link">{{ menuItem.title.toUpperCase() }}</a>
                    </router-link>
                </ul>
                <!-- <ul v-if="showSecondaryMenu" class="nav nav-pills nav-fill secondary-menu">
                    <router-link :to="{ name:'menuDetails', params:{ id: menuItem.id, slug: menuItem.slug } }" tag="li" active-class="active-secondary-menu" v-for="menuItem in secondaryMenuItems" v-bind:key="menuItem.id" class="nav-item secondary">
                        <a v-on:mouseover="menuHover(menuItem)" v-on:click="menuClick" class="nav-link">{{ menuItem.title.toUpperCase() }}</a>
                    </router-link>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 align-self-end remove-left-padding">
                <ul v-if="showSecondaryMenu" class="nav nav-pills nav-fill secondary-menu">
                    <router-link :to="{ name:'menuDetails', params:{ id: menuItem.id, slug: menuItem.slug } }" tag="li" active-class="active-secondary-menu" v-for="menuItem in secondaryMenuItems" v-bind:key="menuItem.id" class="nav-item secondary">
                        <a v-on:mouseover="menuHover(menuItem)" v-on:click="menuClick" class="nav-link">{{ menuItem.title.toUpperCase() }}</a>
                    </router-link>
                </ul>
            </div> -->
        </div>
        <div class="row">
            <div class="col-md-12 p-0">
                <app-sub-menu v-if="getShowSubMenu" :menu="hoveredMenu"></app-sub-menu>
            </div>
        </div>
    </div>
</template>

<script>
    import SubMenu from "./SubMenu.vue"

    import { mapGetters, mapActions } from 'vuex'

    export default {
        data() {
            return {
                //showSubMenu: false
            }
        },
        components: {
            appSubMenu: SubMenu
        },
        computed: {
            ...mapGetters([
                "hoveredMenu",
                "mainMenuItems",
                "secondaryMenuItems",
                "getShowSubMenu"
            ]),
            showMainMenu() {
                return this.mainMenuItems.length > 0;
            },
            showSecondaryMenu() {
                return this.secondaryMenuItems.length > 0;
            }
        },
        methods: {
            ...mapActions(["setHoveredMenu"]),
            menuHover(menuItem) {
                this.setHoveredMenu(menuItem);
            },
            menuClick() {
                this.setHoveredMenu(null);
            }
        }
    }
</script>

<style scoped>
    li a {
        color: #FFFFFF;
        text-decoration: none;
        font-weight: bold;
    }

    .nav-link{
        font-weight: 500 !important;
        padding: 6px;
    }

    .main-menu {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        box-shadow: -8px -8px 30px 10px rgba(0,0,0,0.15);
        background-color: #0165B2;
    }

    .secondary-menu {
        border-top-right-radius: 5px;
        box-shadow: 0px -8px 30px 10px rgba(0,0,0,0.10);
        background-color: #0165B2;
    }

    li.main {
        padding: 1%;
    }

    li.main:first-child {
        border-top-left-radius: 5px;
    }

    li:last-child {
        border-top-right-radius: 5px;
    }

    li:hover {
        background-color: #FFFFFF !important;
    }

        li:hover a {
            color: #243B80;
        }

    .dropdown {
        position: relative;
    }

    .secondary {
        background-color: #0165B2;
    }

    .active-main-menu {
        background-color: #2985cb !important;
    }

    .active-secondary-menu {
        background-color: #3883d3 !important;
        color: #ffffff !important;
    }

        .active-secondary-menu a {
            color: #0a0e27 !important;
        }

    .menu-items-padding{
        padding: 4px !important;
    }
    @media only screen and (max-width: 1020px){
        ul {
            width: 100% !important;
        }

        li {
            width: 100% !important;
        }

            li a {
                text-align: start !important;
            }

        .secondary-menu {
            box-shadow: none;
        }

        .remove-right-padding {
            padding-right: 3 !important;
        }

        .remove-left-padding {
            padding-left: 3 !important;
        }
    }

    @media only screen and (min-width: 992px) {

        .remove-right-padding {
            padding-right: 0 !important;
        }

        .remove-left-padding {
            padding-left: 0 !important;
        }
    }
</style>
