<template>
    <div class="col-md-12 form-group">
        <div class="row">
            <div class="col form-group pr-0"  v-for="tag in tags" v-bind:key="tag.tagId">
                <span class="tag small" v-on:click="initSearch(tag)"> # {{ tag.name }} </span>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapActions } from "vuex"

    export default {
        data() {
            return {
                showHower: false,
            }
        },
        props:["tags"],
        methods: {
            ...mapActions(["setSelectedTag"]),
            initSearch(tag) {
                this.setSelectedTag({tag: tag, clearText: true});
                this.$router.push({ name: 'search', query: { tagId: tag.tagId} });
            }
        }
    }
</script>

<style scoped>

    .tag {
        background-color: #243B80;
        color: white;
        margin-right: 5px;
        white-space: nowrap;
        padding: 5px;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        cursor: pointer;
    }


</style>
