import Axios from "axios"
import interceptors from "../interceptors"

const accounts = Axios.create({
    //baseURL: "https://localhost:44378/api",
    baseURL: "https://accounts.fia.ba:4889/api",
    timeout: 5000,
    headers: {
        "Content-Type": "application/json",
        // anything you want to add to the headers
    }
});

accounts.interceptors.request.use(interceptors.requestInterceptor, interceptors.requestErrorInterceptor);
accounts.interceptors.response.use(interceptors.responseInterceptor, interceptors.responseErrorInterceptor);

export default accounts;
