const publicInterests = [
    {
        "id": 0,
        "name": "NE",
    },
    {
        "id": 1,
        "name": "DA",
    }
];

export { publicInterests }