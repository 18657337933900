<template>
    <button class="btn btn-sm dropleft p-0 float-right rounded" style="border-color: white !important;">
        <div class="btn-sm text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" style="border: white;">
            <span class="small mr-1 language-code">{{ getSelectedLanguage.code.toUpperCase() }}</span>
            <div class="language-icon-placeholder d-inline-block">
                <img v-bind:src="getSelectedLanguage.url" class="rounded"/>
            </div>
        </div>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a v-for="language in getLanguages" v-bind:key="language.id" class="dropdown-item btn" v-on:click="changeLocale(language)">
                <div class="language-icon-placeholder d-inline-block">
                    <img v-bind:src="language.url" v-bind:alt="language.alt" class="rounded"/>
                </div>
                <span class="ml-2 language-name">{{ language.name }}</span>
            </a>
        </div>
    </button>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'

    export default {
        data() {
            return {
            }
        },
        computed: {
            ...mapGetters(["getLanguages", "getSelectedLanguage"])
        },
        methods: {
            ...mapActions(["changeLanguage", "loadContents"]),
            changeLocale(language) {

                if (language.code != this.getSelectedLanguage.code) {
                    this.changeLanguage(language);
                    this.loadContents();
                    this.$router.push({
                        params: { lang: language.code },
                        query: this.$route.query
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .language-icon-placeholder {
        width: 15px;
        height: 12px;
    }

        .language-icon-placeholder img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin-bottom: 5%;
        }

    .language-name {
        color: #243B80;
    }

    .dropleft {
        border: 1px solid #243B80 !important;
        border-radius: 5px;
    }

        .dropleft:active {
            
        }

    .dropleft:hover {
        background-color: #374e96 !important;
    }

    .language-code {
        line-height: 1px;
        font-size: 13px;
    }
</style>
