<template>
    <div class="col-lg-3 col-md-6 form-group">
            <div class="content col-md-12 p-0">
                <div class="higlighted-product-item rounded">
                    <div class="row">
                        <router-link v-bind:to="computeRoute" tag="div">
                            <div class="col-md-12">
                                <span class="font-weight-bold small">{{ product.title }}</span>
                                <hr />
                            </div>
                            <div class="col-md-12 higlighted-product-item-content small text-muted">
                                {{ product.subtitle }}
                            </div>
                        </router-link>
                        <a v-bind:href="product.url" target="_blank" class="col-md-12" v-bind:title="$t('openInNewTab')">
                            <hr />
                            <span>
                                <i class="fa fa-file-pdf-o float-left text-dark"></i>
                                <i class="fa fa-globe float-right text-dark"></i>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
    

    export default {
        props: ["product"],
        computed: {
            computeRoute() {
                return { name: 'content', params: { id: this.product.id, slug: this.product.slug } };
            }
        }
    }
</script>

<style scoped>
    .higlighted-product-item {
        width: 100%;
        padding: 5%;
        background-color: #FFF;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.15);
        border-top: 1px solid rgba(0,0,0,0.06);
        transition: transform .2s;
    }

        .higlighted-product-item:hover {
            cursor: pointer;
            transform: scale(1.03);
        }

    .higlighted-products-header {
        width: 100%;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0px 5px 6px 1px rgba(0,0,0,0.33);
        background-color: #243B80;
        color: #FFFFFF;
    }

    .show-all {
        cursor: pointer;
    }

    .higlighted-product-item-content {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
    }

    .pdf-icon {
        height: 25px;
        width: 25px;
    }

    .higlighted-product-item .fa {
        font-size: 25px;
    }

    

</style>

