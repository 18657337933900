<template>
    <div class="col-lg-12 col-md-12 form-group">
        <div class="content col-md-12 p-0">
            <div class="search-item p-3">
                <router-link v-if="!isExternalLink" v-bind:to="computeRoute" tag="div">
                    <div class="row">
                        <div class="col-md-12">
                            <i class="float-left mt-1 mr-2" v-bind:class="$t('contentTypeIcon' + searchItem.contentTypeId)"></i>
                            <span class="font-weight-bold">{{ searchItem.title }}</span>
                            <span class="text-muted small float-right"><i class="fa fa-calendar"></i> {{ searchItem.dateTime | moment($t("dateFormat")) }}</span>
                            <hr />
                        </div>
                        <div class="col-md-12 search-item-content small text-muted">
                            {{ searchItem.content}}
                        </div>
                    </div>
                    <div class="row" v-if="hasTags">
                        <div class="col-md-12">
                            <hr />
                            <span v-for="tag in getItemTags" v-bind:key="tag.tagId" class="tag small"> # {{ tag.name }} </span>
                        </div>
                    </div>
                </router-link>
                <a v-else v-bind:href="searchItem.url" target="_blank">
                    <div class="row">
                        <div class="col-md-12">
                            <i class="float-left mt-1 mr-3" v-if="searchItem.icon" v-bind:class="searchItem.icon"></i>
                            <span class="font-weight-bold">{{ searchItem.title }}</span>
                            <hr />
                        </div>
                        <div class="col-md-12 search-item-content small text-muted">
                            {{ searchItem.content }}
                        </div>
                    </div>
                    <div class="row" v-if="hasTags">
                        <div class="col-md-12">
                            <hr />
                            <div class="row">
                                <div class="col-md-6 form-group pr-0" v-for="tag in getItemTags" v-bind:key="tag.tagId">
                                    <span  class="tag small"> # {{ tag.name }} </span>
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex"

    export default {
        props: ["searchItem"],
        computed: {
            ...mapGetters(["getContentById" ,"getContentTypes"]),
            computeRoute() {

                if (this.isImageOrVideo) {
                    var parent = this.getContentById(this.searchItem.parentId);
                    if (parent)
                        return { name: 'content', params: { id: parent.id, slug: parent.slug } };
                }

                return { name: 'content', params: { id: this.searchItem.id, slug: this.searchItem.slug } };
            },
            hasTags() {
                return this.searchItem.tags && this.searchItem.tags.length > 0;
            },
            getItemTags() {
                return  JSON.parse(this.searchItem.tags);
            },
            isExternalLink() {
                return this.searchItem.contentTypeId == this.getContentTypes.linkContentTypeId && this.searchItem.url.startsWith("http");
            },
            isImageOrVideo() {
                return this.searchItem.contentTypeId == this.getContentTypes.videoContentTypeId || this.searchItem.contentTypeId == this.getContentTypes.imagesContentTypeId;
            }
        }
    }
</script>

<style scoped>
    .search-item {
        width: 100%;
        border-radius: 5px;
        background-color: white;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
        border-top: 1px solid rgba(0,0,0,0.07);
        transition: transform .2s;
    }

        .search-item:hover {
            cursor: pointer;
            transform: scale(1.01);
        }

    .search-item-content {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: justify;
    }

    .tag {
        background-color: #243B80;
        color: white;
        padding: 5px;
        margin-right: 5px;
        white-space: nowrap;
        border-radius: 5px;
        box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.10);
    }
</style>

