import Vue from 'vue'
import Home from "./components/content/Home.vue"
import MenuDetails from "./components/menu/MenuDetails.vue"
import Content from "./components/content/Content.vue"
import Search from "./components/content/search/Search.vue"
import Nfis from "./components/content/nfis/Nfis.vue"
import LegalEntityProfile from "./components/content/business-entities-register/LegalEntityProfile.vue"
import LegalEntityProfilePublic from "./components/content/business-entities-register/LegalEntityProfilePublic.vue"
import BusinessEntitiesRegister from "./components/content/business-entities-register/BusinessEntitiesRegister.vue"
import UniqueAccountsRegister from "./components/content/uniqueAccountsRegister/UniqueAccountsRegister"
import Contact from "./components/content/contact/Contact.vue"
import BusinessUnits from "./components/content/contact/BusinessUnits.vue"
import FinancialReportControl from "./components/content/rfi/FinancialReportControl.vue"
import LegalEntityDocumentsIndex from "./components/content/legal-entity-documents/Index.vue"
import FinancialReportsTemplates from "./components/content/financial-reports-templates/Index.vue"
import FinancialReportsTemplates22 from "./components/content/financial-reports-templates/Index2022.vue"
import FinancialReportControl2022 from "./components/content/rfi/FinancialReportControl2022.vue"

import VueRouter from "vue-router"
import i18n from './i18n'

//import { store } from "./store/store.js"

Vue.use(VueRouter);

export default new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes:[
        {
            path: "/",
            redirect: `${i18n.locale}`,
            component: Home,
        },
        {
            path: "/:lang",
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: "",
                    component: Home,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "details/:id/:slug",
                    component: MenuDetails,
                    name: "menuDetails",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "content/:id/:slug",
                    component: Content,
                    name: "content",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "search",
                    component: Search,
                    name: "search",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "nfis",
                    component: Nfis,
                    name: "nfis",
                    props: true,
                    meta: {
                        allowAnonymous: false    //switch to false
                    }
                },
                //{
                //    path: "business-entities-profile",
                //    component: BusinessEntitiesRegister,
                //    name: "business-entities-profile",
                //    props: true,
                //    meta: {
                //        allowAnonymous: false    //switch to false
                //    }
                //},
                {
                    path: "profil-pravnih-lica",
                    component: BusinessEntitiesRegister,
                    name: "profil-pravnih-lica",
                    props: {isPublic:false},
                    meta: {
                        allowAnonymous: false    //switch to false
                    }
                },
                {
                    path: "profil-pravnih-lica/:id",
                    component: LegalEntityProfile,
                    name: "legalEntityProfile",
                    props: true,
                    meta: {
                        allowAnonymous: false    //switch to false
                    }
                },
                {
                    path: "profil-javnih-preduzeca",
                    component: BusinessEntitiesRegister,
                    name: "profil-javnih-preduzeca",
                    props: {isPublic:true},
                    meta: {
                        allowAnonymous: false    //switch to false
                    }
                },
                {
                    path: "profil-javnih-preduzeca/:id",
                    component: LegalEntityProfilePublic,
                    name: "legalEntityProfilePublic",
                    props: true,
                    meta: {
                        allowAnonymous: false    //switch to false
                    }
                },
                //{
                //    path: "registar-poslovnih-lica/:id",
                //    component: LegalEntityProfile,
                //    name: "legalEntityProfile",
                //    props: true,
                //    meta: {
                //        allowAnonymous: false    //switch to false
                //    }
                //},

                {
                    path: "jedinstveni-registar-racuna",
                    component: UniqueAccountsRegister,
                    name: "jedinstveni-registar-racuna",
                    props: true,
                    meta: {
                        allowAnonymous: false //switch to false
                    }
                },
                {
                    path: "unique-accounts-register",
                    component: UniqueAccountsRegister,
                    name: "unique-accounts-register",
                    props: true,
                    meta: {
                        allowAnonymous: false //switch to false
                    }
                },
                {
                    path: "pre-bankruptcy-and-bankruptcy-proceedings",
                    component: LegalEntityDocumentsIndex,
                    name: "pre-bankruptcy-and-bankruptcy-proceedings",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "predstecajni-i-stecajni-postupci",
                    component: LegalEntityDocumentsIndex,
                    name: "predstecajni-i-stecajni-postupci",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                // {
                //     path: "financial-reports-templates",
                //     component: FinancialReportsTemplates,
                //     name: "financial-reports-templates",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "predlosci-finansijskih-izvjestaja",
                //     component: FinancialReportsTemplates,
                //     name: "predlosci-finansijskih-izvjestaja",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "financial-reports-templates-22",
                //     component: FinancialReportsTemplates22,
                //     name: "financial-reports-templates-22",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "financial-reports-templates-22",
                //     component: FinancialReportsTemplates22,
                //     name: "financial-reports-templates-hr",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                {
                    path: "financial-reports-templates",
                    component: FinancialReportsTemplates22,
                    name: "financial-reports-templates",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "predlosci-finansijskih-izvjestaja",
                    component: FinancialReportsTemplates22,
                    name: "predlosci-finansijskih-izvjestaja",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "financial-reports-templates-old",
                    component: FinancialReportsTemplates,
                    name: "financial-reports-templates-old",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "financial-reports-templates-old",
                    component: FinancialReportsTemplates,
                    name: "financial-reports-templates-hr",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "contact-us",
                    component: Contact,
                    name: "contact-us",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "kontaktirajte-nas",
                    component: Contact,
                    name: "kontaktirajte-nas",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "locations-and-business-units",
                    component: BusinessUnits,
                    name: "locations-and-business-units",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "lokacije-i-poslovne-jedinice",
                    component: BusinessUnits,
                    name: "lokacije-i-poslovne-jedinice",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                // {
                //     path: "kontrola-finansijskih-izvjestaja",
                //     component: FinancialReportControl,
                //     name: "kontrola-finansijskih-izvjestaja",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "kontrola-financijskih-izvjestaja",
                //     component: FinancialReportControl,
                //     name: "kontrola-financijskih-izvjestaja",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "financial-reports-control",
                //     component: FinancialReportControl,
                //     name: "financial-reports-control",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "financial-reports-control-2022",
                //     component: FinancialReportControl2022,
                //     name: "financial-reports-control-2022",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "kontrola-finansijskih-izvjestaja-2022",
                //     component: FinancialReportControl2022,
                //     name: "kontrola-finansijskih-izvjestaja-2022",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                // {
                //     path: "kontrola-financijskih-izvjestaja-2022",
                //     component: FinancialReportControl2022,
                //     name: "kontrola-financijskih-izvjestaja-2022",
                //     props: true,
                //     meta: {
                //         allowAnonymous: true
                //     }
                // },
                {
                    path: "kontrola-finansijskih-izvjestaja",
                    component: FinancialReportControl2022,
                    name: "kontrola-finansijskih-izvjestaja",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "kontrola-financijskih-izvjestaja",
                    component: FinancialReportControl2022,
                    name: "kontrola-financijskih-izvjestaja",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "financial-reports-control",
                    component: FinancialReportControl2022,
                    name: "financial-reports-control",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "financial-reports-control-old",
                    component: FinancialReportControl,
                    name: "financial-reports-control-old",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "kontrola-finansijskih-izvjestaja-old",
                    component: FinancialReportControl,
                    name: "kontrola-finansijskih-izvjestaja-old",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
                {
                    path: "kontrola-financijskih-izvjestaja-old",
                    component: FinancialReportControl,
                    name: "kontrola-financijskih-izvjestaja-old",
                    props: true,
                    meta: {
                        allowAnonymous: true
                    }
                },
            ]
        }
    ]
})
